import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "teacher";

const GET_ALL_TEACHER_URL = `${prefix}/all`;
const GET_ALL_TEACHER_URL_EXCEL = `${prefix}/all-excel`;
const CREATE_TEACHER_URL = `${prefix}/create`;
const UPDATE_TEACHER_BY_ID_URL = `${prefix}/update-by-id`;
const DELETE_TEACHER_BY_ID_URL = `${prefix}/delete-by-id`;
const GET_TEACHER_OPTION_URL = `${prefix}/get-option`;
const GET_TEACHER_BY_ID_URL = `${prefix}/teacher-by-id`;
const GET_TEACHER_CLASS_HISTORY_URL = `${prefix}/get-teacher-class-history`;
const GET_TEACHER_MENU_URL = `${prefix}/get-teacher-menu`;
const GET_TEACHER_HOLIDAYS_URL = `${prefix}/get-teacher-holidays`;
const EXPORT_TEACHER_CLASS_HISTTORY_URL = `${prefix}/export-teacher-class-history`;

/**
 * @func getAllTeacherForExcel
 * @desc get all teacher data for excel
 * @author march
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} teacher array
 */
export const getAllTeacherForExcel = async (body, signal) => {
    try {
        const { data, status } = await axios.post(GET_ALL_TEACHER_URL_EXCEL, body, {
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllTeacherForExcel");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getAllTeacher */

/**
 * @func getAllTeacher
 * @desc get all package data
 * @author jew
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} package array
 */
export const getAllTeacher = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_ALL_TEACHER_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllTeacher");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getAllTeacher */

/**
 * @func createTeacher
 * @desc create new package
 * @author jew
 * @param {Object} body - create package body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} create status
 */
export const createTeacher = async (body, signal) => {
    try {
        const { status } = await axios.post(CREATE_TEACHER_URL, body, { signal });
        if (status === 201) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "createTeacher");
        if (err) throw err;
        return undefined;
    }
};
/** End @func createTeacher */

/**
 * @func updateTeacherById
 * @desc update package by id
 * @author jew
 * @param {number} id - package id
 * @param {Object} body - update package body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} update status
 */
export const updateTeacherById = async (id, body, signal) => {
    try {
        const { status } = await axios.put(`${UPDATE_TEACHER_BY_ID_URL}/${id}`, body, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "updateTeacherById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func updateTeacherById */

/**
 * @func deleteTeacherById
 * @desc delete package by id
 * @author jew
 * @param {number} id - package id
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} delete status
 */
export const deleteTeacherById = async (id, signal) => {
    try {
        const { status } = await axios.delete(`${DELETE_TEACHER_BY_ID_URL}/${id}`, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "deleteTeacherById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func deleteTeacherById */

/**
 * @func getOptionsTeacher
 * @desc get teacher option
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Object} teacher option
 */
export const getOptionsTeacher = async signal => {
    try {
        const { data, status } = await axios.get(`${GET_TEACHER_OPTION_URL}`, { signal });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getOptionsTeacher");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getOptionsTeacher */

/**
 * @func getTeacherById
 * @desc get package by id
 * @author jew
 * @param {number} id - package id
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} get status
 */
export const getTeacherById = async (id, signal) => {
    try {
        const { data, status } = await axios.get(`${GET_TEACHER_BY_ID_URL}/${id}`, { signal });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getTeacherById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getTeacherById */

/**
 * @func getTeacherClassHistory
 * @desc get teacher class history
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} teacher class history
 */
export const getTeacherClassHistory = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_TEACHER_CLASS_HISTORY_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getTeacherClassHistory");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getTeacherClassHistory */

/**
 * @func getTeacherMenu
 * @desc get teacher menu
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} teacher menu
 */
export const getTeacherMenu = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_TEACHER_MENU_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getTeacherMenu");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getTeacherMenu */

/**
 * @func getTeacherHolidays
 * @desc get teacher hoildays
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} teacher hoildays
 */
export const getTeacherHolidays = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_TEACHER_HOLIDAYS_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getTeacherHolidays");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getTeacherHolidays */

/**
 * @func exportTeacherClassHistory
 * @desc export teacher class history
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} teacher class history
 */
export const exportTeacherClassHistory = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(EXPORT_TEACHER_CLASS_HISTTORY_URL, { params: queryParams, signal });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "exportTeacherClassHistory");
        if (err) throw err;
        return undefined;
    }
};
/** End @func exportTeacherClassHistory */
