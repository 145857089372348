/* eslint-disable */
import React from "react";
import { Column } from "material-table";
import SwitchApp from "../../../componentsUtils/SwitchApp";
import { object, string } from "yup";

export const columnTableRole = props => {
    /** @type Column */
    const column = [
        {
            field: "id",
            title: "#",
            sorting: false,
            render: obj => (
                <div>{props?.tableOptions?.currentPage * props?.tableOptions?.pageSize + obj.tableData?.id + 1}</div>
            ),
        },
        {
            field: "roleName",
            title: "Name",
        },
        {
            field: "status",
            title: "Status",
            sorting: false,
            render: obj => <SwitchApp checked={obj?.status} onChange={e => props?.handleChangeStatus(e, obj?.id)} />,
        },
    ];
    const data = column.map((col, index) => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};

export const initialValuesRole = {
    name: "",
    administrator: false,
    check_permission: [],
    role: {},
};

export const validationSchemaRole = object({
    name: string().required("Required."),
});
