import { Box, Button, FormControl, Grid, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Add, Close, Search } from "@material-ui/icons";
import MaterialTable from "material-table";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Modal, ModalTitle } from "react-bootstrap";
import { useSelector } from "react-redux";
import { createRole, deleteRoleById, getAllRole, updateRoleById } from "../../../apis/roleApi";
import { swalCondition } from "../../../utils/swal";
import { getOptionsTableApp } from "../../helpers/useOption";
import FormRole from "./FormRole";
import { columnTableRole, initialValuesRole } from "./helpers/rolepermissionOption";

export default function ContentRolePermission() {
    // ref
    const tableRef = useRef(null);
    const controllerRef = useRef(null);

    // global redux state
    const { thisMenuPermission } = useSelector(state => {
        const { menuPermission } = state?.mainMenu;
        return {
            thisMenuPermission: menuPermission?.find(menu => menu?.path === window.location.pathname)?.permission,
        };
    });

    // component state
    const [modalForm, setModalForm] = useState(false);
    const [valuesSearch, setValuesSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isCreateMode, setIsCreateMode] = useState(false);
    const [tableOptions, setTableOptions] = useState(getOptionsTableApp);
    const [initialFormValues, setInitialFormValues] = useState(initialValuesRole);

    // close modal
    const handleCloseModal = useCallback(() => {
        setModalForm(false);
        setInitialFormValues(initialValuesRole);
    }, []);

    // open modal create form
    const handleOpenCreateFormModal = useCallback(() => {
        setIsCreateMode(true);
        setInitialFormValues(initialValuesRole);
        setModalForm(true);
    }, []);

    // open modal edit form
    const handleOpenEditFormModal = useCallback(role => {
        setIsCreateMode(false);
        setModalForm(true);
        setInitialFormValues(currentState => ({
            ...currentState,
            name: role?.roleName,
            administrator:
                role?.RoleManagements?.filter(item => item?.status)?.length === role?.RoleManagements?.length
                    ? true
                    : false,
            check_permission: role?.RoleManagements,
            role,
        }));
    }, []);

    // search input
    const handleOnchangeSearch = useCallback(
        evt => {
            const value = evt.target?.value;
            setValuesSearch(value);
            tableRef.current.onQueryChange({ search: value, page: 0 });
        },
        [tableRef],
    );

    // fetch data
    const fetchData = useCallback(async query => {
        try {
            setIsLoading(true);
            const queryParams = {
                page: query?.page + 1 || 1,
                limit: query?.pageSize || 10,
                search: query?.search || "",
                order: query?.orderBy?.field || "id",
                direction: query?.orderBy?.field ? query?.orderDirection : "desc",
            };
            const res = await getAllRole(queryParams, controllerRef.current?.signal);
            if (res) {
                setTableOptions(currentState => ({
                    ...currentState,
                    currentPage: query?.page,
                    pageSize: query?.pageSize || 10,
                    totalData: res?.pagination?.totalData || 0,
                    order: query?.orderBy?.field || "timestamp",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                }));
                setIsLoading(false);
                return {
                    data: res?.data || [],
                    page: query?.page || 0,
                    totalCount: res?.pagination?.totalData || 0,
                };
            }
            setIsLoading(false);
        } catch (error) {
            console.dir(error);
            setIsLoading(false);
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
    }, []);

    // create role logic
    const handleCreateRole = useCallback(async values => {
        try {
            const roleManagement = [];
            values.check_permission.forEach(item => {
                const permission = [
                    { menuId: item?.id, permissionAccess: 1, status: item?.values?.view },
                    { menuId: item?.id, permissionAccess: 2, status: item?.values?.create },
                    { menuId: item?.id, permissionAccess: 3, status: item?.values?.edit },
                    { menuId: item?.id, permissionAccess: 4, status: item?.values?.delete },
                ];
                roleManagement.push(...permission);
            });
            const createBody = {
                roleName: values?.name,
                status: true,
                roleManagement,
            };
            const isCreate = await createRole(createBody, controllerRef.current?.signal);
            if (isCreate) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // edit role logic
    const handleEditRole = useCallback(async values => {
        try {
            const roleManagement = [];
            values.check_permission.forEach(item => {
                const permission = [
                    { id: item?.values?.viewId, menuId: item?.id, permissionAccess: 1, status: item?.values?.view },
                    { id: item?.values?.createId, menuId: item?.id, permissionAccess: 2, status: item?.values?.create },
                    { id: item?.values?.editId, menuId: item?.id, permissionAccess: 3, status: item?.values?.edit },
                    { id: item?.values?.deleteId, menuId: item?.id, permissionAccess: 4, status: item?.values?.delete },
                ];
                roleManagement.push(...permission);
            });
            const updateBody = {
                roleName: values?.name,
                status: values?.role?.status,
                roleManagement,
            };
            const isUpdate = await updateRoleById(values?.role?.id, updateBody, controllerRef.current?.signal);
            if (isUpdate) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // change status logic
    const handleChangeStatus = useCallback(async (e, roleId) => {
        try {
            const isUpdateStatus = await updateRoleById(
                roleId,
                { status: e.target?.checked },
                controllerRef.current?.signal,
            );
            if (isUpdateStatus) {
                tableRef.current.onQueryChange();
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // delete role logic
    const handleDeleteRole = useCallback(async role => {
        try {
            const bool = await swalCondition("Confirm delete ?", role?.roleName, {
                icon: "warning",
            });
            if (bool) {
                const isDelete = await deleteRoleById(role?.id, controllerRef.current?.signal);
                if (isDelete) {
                    tableRef.current.onQueryChange();
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // memo
    const tableColumns = useMemo(() => columnTableRole({ handleChangeStatus, tableOptions }), [
        handleChangeStatus,
        tableOptions,
    ]);

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, []);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className="mb-3 mb-md-5">
                <div className="d-md-flex align-items-center justify-content-between">
                    <div className="mb-3 mb-md-0">
                        <FormControl style={{ height: "100%" }}>
                            <TextField
                                size="small"
                                variant="outlined"
                                value={valuesSearch}
                                onChange={handleOnchangeSearch}
                                placeholder="Search..."
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search className="text-muted" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </div>
                    {thisMenuPermission?.create && (
                        <div className="d-flex align-items-center">
                            <Button
                                onClick={handleOpenCreateFormModal}
                                size="large"
                                className="btn btn-lg t-btn-primary">
                                <Add /> Create Role
                            </Button>
                        </div>
                    )}
                </div>
            </Grid>

            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        data={fetchData}
                        options={tableOptions}
                        columns={tableColumns}
                        components={{ Container: Box }}
                        actions={[
                            {
                                icon: "edit",
                                iconProps: { className: "action-edit" },
                                tooltip: "Edit",
                                onClick: (event, rowData) => handleOpenEditFormModal(rowData),
                                hidden: !thisMenuPermission?.edit,
                            },
                            {
                                icon: "delete",
                                iconProps: { className: "action-delete" },
                                tooltip: "Delete",
                                onClick: (event, rowData) => handleDeleteRole(rowData),
                                hidden: !thisMenuPermission?.delete,
                            },
                        ]}
                    />
                </div>
            </Grid>

            <Grid item xs={12}>
                <Modal show={modalForm} scrollable centered size="lg" onHide={handleCloseModal}>
                    <Modal.Header>
                        <ModalTitle>{isCreateMode ? "Create" : "Edit"} Role Permission</ModalTitle>
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body>
                        <FormRole
                            onCancel={handleCloseModal}
                            onSubmit={isCreateMode ? handleCreateRole : handleEditRole}
                            initialFormValues={initialFormValues}
                        />
                    </Modal.Body>
                </Modal>
            </Grid>
        </Grid>
    );
}
