import { Button, FormControl, FormLabel, Grid, TextField } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { object, string } from "yup";

export default function FormClassroom({ onCancel, onSubmit, initialFormValues }) {
    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={object({
                classroomName: string().required("Required."),
                color: string().required("Required."),
            })}
            onSubmit={onSubmit}>
            {({ values, handleChange, handleSubmit, errors, isSubmitting }) => (
                <Grid container spacing={3}>
                    {/* name branch */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel>
                                Classroom name <span className="text-danger">*</span>
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                type="email"
                                id="classroomName"
                                placeholder="Classroom name"
                                value={values.classroomName}
                                onChange={handleChange}
                                error={Boolean(errors.classroomName)}
                                helperText={errors.classroomName}
                            />
                        </FormControl>
                    </Grid>
                    {/* Color */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <FormLabel>Color</FormLabel>
                            <TextField
                                variant="outlined"
                                type="color"
                                id="color"
                                value={values.color}
                                onChange={handleChange}
                                error={Boolean(errors.color)}
                                helperText={errors.color}
                            />
                        </FormControl>
                    </Grid>
                    {/* action */}
                    <Grid item xs={12}>
                        <div className="d-flex justify-content-md-end align-items-center">
                            <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                                <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                            </Button>
                            <Button disabled={isSubmitting} onClick={handleSubmit} className="btn t-btn-secondary">
                                {isSubmitting ? (
                                    <Spinner animation="border" variant="primary" />
                                ) : (
                                    <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                                )}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
}
