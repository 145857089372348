import { Box, Fade, Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getTeacherHolidays } from "../../../apis/teacherApi";
import { getOptionsTableApp } from "../../helpers/useOption";
import { getColumnsTeacherDayOff } from "./helpers/teacherProfileOption";

export default function TeacherDayOff({ teacherId }) {
    // ref
    const controllerRef = useRef(null);

    // componnt state
    const [isLoading, setIsLoading] = useState(false);
    const [tableOptions, setTableOptions] = useState(getOptionsTableApp);

    // fetch data
    const fetchData = useCallback(
        async query => {
            try {
                setIsLoading(true);
                const queryParams = {
                    page: query?.page + 1 || 1,
                    limit: query?.pageSize || 10,
                    order: query?.orderBy?.field || "id",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    teacherId,
                };
                const res = await getTeacherHolidays(queryParams, controllerRef.current?.signal);
                if (res) {
                    setTableOptions(currentState => ({
                        ...currentState,
                        currentPage: query?.page,
                        pageSize: query?.pageSize || 10,
                        totalData: res?.pagination?.totalData || 0,
                        order: query?.orderBy?.field || "id",
                        direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    }));
                    setIsLoading(false);
                    return {
                        data: res?.data || [],
                        page: query?.page || 0,
                        totalCount: res?.pagination?.totalData || 0,
                    };
                }
                setIsLoading(false);
            } catch (error) {
                console.dir(error);
                setIsLoading(false);
                return {
                    data: [],
                    page: 0,
                    totalCount: 0,
                };
            }
        },
        [teacherId],
    );

    // memo
    const tableColumns = useMemo(() => getColumnsTeacherDayOff({ tableOptions }), [tableOptions]);

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, []);

    return (
        <Fade in>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <div className="t-table-custom student-profile">
                        <MaterialTable
                            isLoading={isLoading}
                            options={tableOptions}
                            components={{ Container: Box }}
                            data={fetchData}
                            columns={tableColumns}
                        />
                    </div>
                </Grid>
            </Grid>
        </Fade>
    );
}
