import { Button, FormControl, Grid, InputAdornment, MenuItem, TextField } from "@material-ui/core";
import { Add, GetApp, Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import SelectApp from "../../componentsUtils/SelectApp";
import { getOptionsTeacher } from "../../../apis/teacherApi";

export default function TeacherSearchForm({
    onSubmit,
    setExcelFilter,
    thisMenuPermission,
    handleExportExcel,
    isExport,
    handleOpenCreateFormModal,
}) {
    // ref
    const controllerRef = useRef(null);

    // component state
    const [options, setOptions] = useState([]);

    useEffect(() => {
        controllerRef.current = new AbortController();
        const fetchOption = async () => {
            const res = await getOptionsTeacher(controllerRef.current?.signal);
            setOptions(res?.courseMenuOption);
        };
        if (options?.length < 1) {
            fetchOption();
        }
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, [options]);

    return (
        <Formik
            initialValues={{
                status: "",
                menu: "",
                search: "",
            }}
            onSubmit={onSubmit}>
            {({ values, setFieldValue, handleSubmit }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={3}>
                                <FormControl style={{ height: "100%", width: "100%" }}>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        value={values?.search}
                                        onChange={value => {
                                            setFieldValue("search", value?.target?.value);
                                            setExcelFilter(state => ({ ...state, search: value?.target?.value }));
                                        }}
                                        placeholder="Search..."
                                        className="fixed-h-lg"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search className="text-muted" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            {/* Status */}
                            <Grid item xs={12} md={6} lg={3}>
                                <div className="d-flex align-items-center">
                                    <label className="mb-0 mr-5">Status : </label>
                                    <div className="flex-fill">
                                        <SelectApp
                                            value={values.status}
                                            onChange={value => {
                                                setFieldValue("status", value);
                                                setExcelFilter(state => ({ ...state, status: value }));
                                            }}
                                            options={[
                                                { value: "1", title: "Active" },
                                                { value: "0", title: "Inactive" },
                                            ]}
                                            placeholder="All"
                                            RenderItem={(option, idx) => (
                                                <MenuItem key={idx} value={option.value}>
                                                    {" "}
                                                    {option.title}{" "}
                                                </MenuItem>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            {/* Menu */}
                            <Grid item xs={12} md={6} lg={4}>
                                <div className="d-flex align-items-center">
                                    <label className="mb-0 mr-5">By Menu : </label>
                                    <div className="flex-fill">
                                        <Autocomplete
                                            fullWidth
                                            size="small"
                                            options={options}
                                            value={values?.menu || null}
                                            getOptionLabel={option => option?.courseMenuName}
                                            getOptionSelected={(option, value) => option?.id === value?.id}
                                            onChange={(_, value) => {
                                                setFieldValue("menu", value);
                                                setExcelFilter(state => ({ ...state, menu: value?.id }));
                                            }}
                                            renderInput={params => {
                                                return (
                                                    <TextField
                                                        key={params?.id}
                                                        {...params}
                                                        label="Search"
                                                        variant="outlined"
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3} lg={2}>
                                <div className="d-flex">
                                    <Button
                                        size="large"
                                        className="t-btn-primary fixed-h-lg flex-fill flex-lg-grow-0"
                                        onClick={() => {
                                            setFieldValue("menu", "");
                                            setFieldValue("search", "");
                                            setFieldValue("status", "");
                                            setExcelFilter({
                                                menu: "",
                                                search: "",
                                                status: "",
                                            });
                                            handleSubmit();
                                        }}>
                                        Clear{" "}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className="d-flex flex-row flex-md-column flex-xl-row">
                            <Button
                                size="large"
                                onClick={handleSubmit}
                                className={`${!thisMenuPermission?.create &&
                                    "mr-4"} t-btn-primary fixed-h-lg mb-0 mb-md-4 mb-xl-0`}>
                                <Search /> Search{" "}
                            </Button>
                            {thisMenuPermission?.create && (
                                <Button
                                    size="large"
                                    onClick={handleOpenCreateFormModal}
                                    className="btn flex-fill t-btn-primary fixed-h-lg mx-5 mx-md-0 mx-xl-5 mb-0 mb-md-4 mb-xl-0">
                                    <Add /> Create Teacher
                                </Button>
                            )}
                            <Button
                                size="large"
                                onClick={handleExportExcel}
                                disabled={isExport}
                                className="btn flex-fill flex-lg-grow-0 t-btn-primary fixed-h-lg mb-0 mb-md-4 mb-xl-0">
                                {isExport ? "Loading..." : <GetApp style={{ fontSize: 22 }} />}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
}
