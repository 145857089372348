import { Grid, Paper } from "@material-ui/core";
import React from "react";
import PackageSummaryContent from "../../components/report_package/PackageSummaryContent";

export default function PackageSummary() {
    return (
        <Paper className="p-2 p-lg-3">
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <PackageSummaryContent />
                </Grid>
            </Grid>
        </Paper>
    );
}
