import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Grid, IconButton } from "@material-ui/core";
import MaterialTable from "material-table";
import { Close } from "@material-ui/icons";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { swalCondition } from "../../../utils/swal";
import { getOptionsTableApp } from "../../helpers/useOption";
import { columnsTag, initialValuesTag } from "./helpers/TagOption";
import TagSearch from "./TagSearch";
import TagForm from "./TagForm";
import { createTag, getAllTag, updateTagById, deleteTagById, importTag } from "../../../apis/tagApi";
import PreviewImport from "../../componentsUtils/PreviewImport";
import { SwalFileImport, difference, exportExcelUtimate, xlsxFileReader } from "../../../utils/xlsx";
import ModalBoostrap from "../../componentsUtils/ModalBoostrap";

const TagList = () => {
    // ref
    const tableRef = useRef(null);
    const controllerRef = useRef(null);

    // component state
    const [isImport, setIsImport] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);
    const [fileImport, setFileImport] = useState({
        name: "",
        data: [],
        columns: [],
        author: "",
    });
    const [modalForm, setModalForm] = useState(false);
    const [valuesSearch, setValuesSearch] = useState({ status: "", search: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [isCreateMode, setIsCreateMode] = useState(false);
    const [tableOptions, setTableOptions] = useState(getOptionsTableApp);
    const [initialFormValues, setInitialFormValues] = useState(initialValuesTag);

    // global redux state
    const { thisMenuPermission } = useSelector(state => {
        const { menuPermission } = state?.mainMenu;
        return {
            thisMenuPermission: menuPermission?.find(menu => menu?.path === window.location.pathname)?.permission,
        };
    });

    // update tag logic
    const handleEditTag = useCallback(async tag => {
        try {
            const isUpdate = await updateTagById(tag?.tagId, tag, controllerRef.current?.signal);
            if (isUpdate) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // create tag logic
    const handleCreateTag = useCallback(async tag => {
        try {
            const isCreate = await createTag(tag, controllerRef.current?.signal);
            if (isCreate) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // delete tag logic
    const handleDeleteTag = useCallback(async tag => {
        try {
            const bool = await swalCondition("Confirm delete ?", tag?.tagName, {
                icon: "warning",
            });
            if (bool) {
                const isDelete = await deleteTagById(tag?.tagId, controllerRef.current?.signal);
                if (isDelete) {
                    tableRef.current.onQueryChange();
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // change status logic
    const handleChangeStatus = useCallback(async (e, tagId) => {
        try {
            const isUpdateStatus = await updateTagById(
                tagId,
                {
                    status: e?.target?.checked ? "1" : "0",
                },
                controllerRef?.current?.signal,
            );
            if (isUpdateStatus) {
                tableRef.current.onQueryChange();
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // memo
    const tableColumns = useMemo(() => columnsTag({ handleChangeStatus, tableOptions }), [
        handleChangeStatus,
        tableOptions,
    ]);

    // submit search
    const handleSubmitSearch = useCallback(
        values => {
            setValuesSearch({
                status: values?.status,
                search: values?.search,
            });
            tableRef.current.onQueryChange({ search: values?.search, page: 0 });
        },
        [tableRef],
    );

    // open modal edit form
    const handleOpenEditFormModal = useCallback(tag => {
        setIsCreateMode(false);
        setModalForm(true);
        setInitialFormValues(tag);
    }, []);

    // close modal
    const handleCloseModal = useCallback(() => {
        setModalForm(false);
        setInitialFormValues(initialValuesTag);
    }, []);

    // open modal create form
    const handleOpenCreateFormModal = useCallback(() => {
        setIsCreateMode(true);
        setInitialFormValues(initialValuesTag);
        setModalForm(true);
    }, []);

    // fetch data table
    const fetchData = useCallback(
        async query => {
            try {
                setIsLoading(true);
                const queryParams = {
                    page: query?.page + 1 || 1,
                    limit: query?.pageSize || 10,
                    search: valuesSearch?.search || "",
                    status: valuesSearch?.status || "",
                    order: query?.orderBy?.field || "tagId",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                };
                const response = await getAllTag(queryParams, controllerRef?.current?.signal);
                if (response) {
                    setTableOptions(currentState => ({
                        ...currentState,
                        currentPage: query?.page,
                        pageSize: query?.pageSize || 10,
                        totalData: response?.pagination?.totalData || 0,
                        order: query?.orderBy?.field || "tagId",
                        direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    }));
                    setIsLoading(false);
                    return {
                        data: response?.data || [],
                        page: query?.page || 0,
                        totalCount: response?.pagination?.totalData || 0,
                    };
                }
                setIsLoading(false);
            } catch (error) {
                console.dir(error);
                setIsLoading(false);
                return {
                    data: [],
                    page: 0,
                    totalCount: 0,
                };
            }
        },
        [valuesSearch],
    );

    const handleSave = useCallback(async excel => {
        setIsImport(true);
        try {
            const data = excel?.map(item => item["Tag Name"]);
            const res = await importTag(data, controllerRef?.current?.signal);
            if (res) {
                await swalCondition(
                    "Successfully !!!",
                    res?.newTag?.length ? `Create : ${res?.newTag?.join(" , ")}` : "",
                    {
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    },
                );
                setIsShowModal(false);
                return tableRef.current.onQueryChange();
            }
        } catch (error) {
            console.dir(error);
        } finally {
            setIsImport(false);
        }
    }, []);

    const handleImportTag = useCallback(async () => {
        const { file } = await SwalFileImport();
        if (file) {
            const { data, header, fileName, author, lastAuthor, error } = await xlsxFileReader(file);
            if (!error) {
                if (data?.length > 12000) {
                    return swalCondition("Error !!!", "Maximum Rows is 12,000", {
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                }

                // expect 18 column
                if (header?.length !== 1) {
                    return swalCondition("Error !!!", `Import Tag Expect 1 Columns But Got ${header?.length}`, {
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                }
                const columns = ["Tag Name"];

                const incorrectColumns = Array?.from(difference(header, columns));
                if (incorrectColumns?.length > 0) {
                    return swalCondition("Error !!!", "Incorrect Form Input, Please Download Templete", {
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                }

                setIsShowModal(true);

                setFileImport({
                    data,
                    columns: header?.map(data => {
                        return { title: data, field: data };
                    }),
                    name: fileName,
                    author: author || lastAuthor,
                });
            }
        }
    }, []);

    const handleExportTemplate = useCallback(() => {
        const data = [
            {
                "Tag Name": "March",
            },
            {
                "Tag Name": "Test",
            },
        ];
        const colWidth = [{ width: 20 }];
        // const highlight = ["A1", "B1", "C1", "E1", "F1", "L1", "O1"];
        exportExcelUtimate(data, "Template-Tag", colWidth);
    }, []);

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, []);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className="mb-3 mb-md-5 d-flex">
                <TagSearch
                    importTag={handleImportTag}
                    template={handleExportTemplate}
                    onSearch={handleSubmitSearch}
                    onCreate={handleOpenCreateFormModal}
                />
            </Grid>
            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        options={tableOptions}
                        components={{ Container: Box }}
                        columns={tableColumns}
                        data={fetchData}
                        actions={[
                            {
                                icon: "edit",
                                iconProps: { className: "action-view" },
                                tooltip: "Edit",
                                onClick: (event, rowData) => handleOpenEditFormModal(rowData),
                                hidden: !thisMenuPermission?.edit,
                            },
                            {
                                icon: "delete",
                                iconProps: { className: "action-delete" },
                                tooltip: "Delete",
                                onClick: (event, rowData) => handleDeleteTag(rowData),
                                hidden: !thisMenuPermission?.delete,
                            },
                        ]}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <Modal show={modalForm} scrollable centered size="lg" onHide={handleCloseModal}>
                    <Modal.Header>
                        <Modal.Title>{isCreateMode ? "Create" : "Edit"} Tag</Modal.Title>
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body>
                        <TagForm
                            onCancel={handleCloseModal}
                            onSubmit={isCreateMode ? handleCreateTag : handleEditTag}
                            initialFormValues={initialFormValues}
                            controllerRef={controllerRef}
                        />
                    </Modal.Body>
                </Modal>
            </Grid>
            <Grid item xs={12}>
                <ModalBoostrap
                    title="Import Tag"
                    size="xl"
                    show={isShowModal}
                    handleClose={() => setIsShowModal(false)}
                    handleSave={() => handleSave(fileImport.data)}
                    isSaveButtonLoading={isImport}
                    showSaveButton>
                    <PreviewImport
                        columns={fileImport.columns}
                        data={fileImport.data}
                        fileName={fileImport.name}
                        author={fileImport.author}
                    />
                </ModalBoostrap>
            </Grid>
        </Grid>
    );
};

export default TagList;
