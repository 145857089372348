import React from "react";
import { renderToStaticMarkup } from "react-dom/server";

export default async function RenderEventCalendar(arg) {
    const { view, event } = arg;
    /** @type HTMLElement */
    const el = arg?.el;
    const { classroomName, time, customTitle } = event?.extendedProps;

    // event schedule weeks
    if (view?.type === "timeGridWeek") {
        el.querySelector(".fc-event-main").innerHTML = renderToStaticMarkup(
            <div className="bg-white rounded text-center p-1" style={{ color: "black" }}>
                <div>{classroomName}</div>
                <div>{time}</div>
            </div>,
        );
        return el;
    }
    // event schedule months ที่ ไม่ใช่ holidays
    if (!el.classList.contains("schedule-holidays-app")) {
        el.querySelector(".fc-event-title").innerHTML = renderToStaticMarkup(<div>{customTitle}</div>);
        return el;
    }
}
