import { Button, Fade, Grid } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { exportTeacherClassHistory, getTeacherClassHistory } from "../../../apis/teacherApi";
import { getOptionsTableApp } from "../../helpers/useOption";
import { getColumnsTeacherClassHistory } from "./helpers/teacherProfileOption";
import exportToExcel from "../../../utils/exportToExcel";

export default function TeacherClassHistory({ teacherProfile, teacherId }) {
    // ref
    const controllerRef = useRef(null);

    // componnt state
    const [isLoading, setIsLoading] = useState(false);
    const [isExportLoading, setIsExportLoading] = useState(false);
    const [tableOptions, setTableOptions] = useState({ ...getOptionsTableApp, toolbar: true });

    // fetch data
    const fetchData = useCallback(
        async query => {
            try {
                setIsLoading(true);
                const queryParams = {
                    page: query?.page + 1 || 1,
                    limit: query?.pageSize || 10,
                    order: query?.orderBy?.field || "scheduleClassroomHistoryId",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    teacherId,
                };
                const res = await getTeacherClassHistory(queryParams, controllerRef.current?.signal);
                if (res) {
                    setTableOptions(currentState => ({
                        ...currentState,
                        currentPage: query?.page,
                        pageSize: query?.pageSize || 10,
                        totalData: res?.pagination?.totalData || 0,
                        order: query?.orderBy?.field || "scheduleClassroomHistoryId",
                        direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    }));
                    setIsLoading(false);
                    return {
                        data: res?.data || [],
                        page: query?.page || 0,
                        totalCount: res?.pagination?.totalData || 0,
                    };
                }
                setIsLoading(false);
            } catch (error) {
                console.dir(error);
                setIsLoading(false);
                return {
                    data: [],
                    page: 0,
                    totalCount: 0,
                };
            }
        },
        [teacherId],
    );

    // export excel
    const handleExportExcel = useCallback(async () => {
        try {
            setIsExportLoading(true);
            const queryParams = {
                order: tableOptions?.order || "scheduleClassroomHistoryId",
                direction: tableOptions?.direction || "desc",
                teacherId,
            };
            const res = await exportTeacherClassHistory(queryParams, controllerRef.current?.signal);
            if (res) {
                const data = res?.map(item => ({
                    "Date/Time": item?.bookingDate ? moment(item?.bookingDate).format("DD/MM/YYYY HH:mm:ss") : "",
                    "Course/Menu": item?.menu || "",
                    Student: item?.studentName || "",
                }));
                exportToExcel(data, {
                    fileName: "SCA_class_history",
                    title: `ชื่อคุณครู : ${teacherProfile?.teacherFirstname || ""} ${teacherProfile?.teacherLastname ||
                        ""} (${teacherProfile?.teacherNickname || "-"})`,
                });
            }
            setIsExportLoading(false);
        } catch (error) {
            console.dir(error);
            setIsExportLoading(false);
        }
    }, [tableOptions, teacherId, teacherProfile]);
    // memo
    const tableColumns = useMemo(() => getColumnsTeacherClassHistory({ tableOptions }), [tableOptions]);

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, []);

    return (
        <Fade in>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <div className="t-table-custom student-profile">
                        <MaterialTable
                            title=""
                            isLoading={isLoading}
                            options={tableOptions}
                            data={fetchData}
                            columns={tableColumns}
                            components={{
                                Toolbar: props => (
                                    <div className="d-flex justify-content-end align-items-center px-2">
                                        <MTableToolbar {...props} />
                                        <Button
                                            className="btn t-btn-primary"
                                            onClick={handleExportExcel}
                                            disabled={isExportLoading}>
                                            Export Excel
                                        </Button>
                                    </div>
                                ),
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </Fade>
    );
}
