import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "user";

const LOGIN_URL = `${prefix}/login`;
const REGISTER_URL = `${prefix}/register`;
const GET_LOGIN_BRANCH_OPTION_URL = `${prefix}/get-login-branch-option`;
const GET_USER_ME_URL = `${prefix}/get-user-me`;
const GET_USER_BYID_URL = `${prefix}/get-user-id`;
const GET_ALL_USER_URL = `${prefix}/all`;
const GET_USER_OPTION_URL = `${prefix}/get-option`;
const UPDATE_USER_BY_ID_URL = `${prefix}/update-by-id`;
const DELETE_USER_BY_ID_URL = `${prefix}/delete-by-id`;
const RESET_PASSWORD_URL = `${prefix}/reset-password`;
const UPDATE_PASSWORD_URL = `${prefix}/update-password`;

/**
 * @func loginSystem
 * @desc user login
 * @author izeberg
 * @param {string} username - login username
 * @param {string} password - login password
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Object} login token
 */
export const loginSystem = async (loginBody, signal) => {
    try {
        const { data, status } = await axios.post(LOGIN_URL, loginBody, {
            signal,
            withCredentials: true,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "loginSystem");
        if (err) throw err;
        return undefined;
    }
};
/** End @func loginSystem */

/**
 * @func registerSystem
 * @desc user register
 * @author izeberg
 * @param {Object} body - create user body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} create status
 */
export const registerSystem = async (body, signal) => {
    try {
        const { status } = await axios.post(REGISTER_URL, body, { signal });
        if (status === 201) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "registerSystem");
        if (err) throw err;
        return undefined;
    }
};
/** End @func registerSystem */

/**
 * @func resetPassword
 * @desc user reset password
 * @author march
 * @param {Object} body - reset password user body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} reset password
 */
export const resetPassword = async (body, signal) => {
    try {
        const { status, data } = await axios.post(RESET_PASSWORD_URL, body, { signal });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "registerSystem");
        if (err) throw err;
        return undefined;
    }
};
/** End @func resetPassword */

/**
 * @func updatePassword
 * @desc user update password
 * @author march
 * @param {Object} body - update password user body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} update password
 */
export const updatePassword = async (body, signal) => {
    try {
        const { status, data } = await axios.post(UPDATE_PASSWORD_URL, body, {
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "registerSystem");
        if (err) throw err;
        return undefined;
    }
};
/** End @func updatePassword */

/**
 * @func getLoginBranchOption
 * @desc get login branch option
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Object} login branch option
 */
export const getLoginBranchOption = async signal => {
    try {
        const { data, status } = await axios.get(GET_LOGIN_BRANCH_OPTION_URL, {
            signal,
        });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getLoginBranchOption");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getLoginBranchOption */

/**
 * Start
 * @func getUserMe
 * @desc get user data by token
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Object} user data
 */
export const getUserMe = async signal => {
    try {
        const { data, status } = await axios.get(GET_USER_ME_URL, { signal });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getUserMe");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getUserMe */

/**
 * Start
 * @func getUserById
 * @desc get user data by token
 * @author nakharin
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Object} user data
 */
export const getUserById = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(`${GET_USER_BYID_URL}/${queryParams?.id}`, {
            signal,
        });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getUserById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getUserById */

/**
 * @func getAllUser
 * @desc get all user data
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} user array
 */
export const getAllUser = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_ALL_USER_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllUser");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getAllUser */

/**
 * @func getUserOption
 * @desc get user option
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Object} user option
 */
export const getUserOption = async signal => {
    try {
        const { data, status } = await axios.get(GET_USER_OPTION_URL, {
            signal,
        });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getUserOption");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getUserOption */

/**
 * @func updateUserById
 * @desc update user by id
 * @author izeberg
 * @param {number} id - user id
 * @param {Object} body - update user body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} update status
 */
export const updateUserById = async (id, body, signal) => {
    try {
        const { status } = await axios.put(`${UPDATE_USER_BY_ID_URL}/${id}`, body, {
            signal,
        });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "updateUserById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func updateUserById */

/**
 * @func deleteUserById
 * @desc delete user by id
 * @author izeberg
 * @param {number} id - user id
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} delete status
 */
export const deleteUserById = async (id, signal) => {
    try {
        const { status } = await axios.delete(`${DELETE_USER_BY_ID_URL}/${id}`, {
            signal,
        });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "deleteUserById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func deleteUserById */
