import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "branch";

const GET_ALL_BRANCH_URL = `${prefix}/all`;
const CREATE_BRANCH_URL = `${prefix}/create`;
const UPDATE_BRANCH_BY_ID_URL = `${prefix}/update-by-id`;
const DELETE_BRANCH_BY_ID_URL = `${prefix}/delete-by-id`;

/**
 * @func getAllBranch
 * @desc get all branch data
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} branch array
 */
export const getAllBranch = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_ALL_BRANCH_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllBranch");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getAllBranch */

/**
 * @func createBranch
 * @desc create new branch
 * @author izeberg
 * @param {Object} body - create branch body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} create status
 */
export const createBranch = async (body, signal) => {
    try {
        const { status } = await axios.post(CREATE_BRANCH_URL, body, { signal });
        if (status === 201) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "createBranch");
        if (err) throw err;
        return undefined;
    }
};
/** End @func createBranch */

/**
 * @func updateBranchById
 * @desc update branch by id
 * @author izeberg
 * @param {number} id - branch id
 * @param {Object} body - update branch body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} update status
 */
export const updateBranchById = async (id, body, signal) => {
    try {
        const { status } = await axios.put(`${UPDATE_BRANCH_BY_ID_URL}/${id}`, body, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "updateBranchById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func updateBranchById */

/**
 * @func deleteBranchById
 * @desc delete branch by id
 * @author izeberg
 * @param {number} id - branch id
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} delete status
 */
export const deleteBranchById = async (id, signal) => {
    try {
        const { status } = await axios.delete(`${DELETE_BRANCH_BY_ID_URL}/${id}`, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "deleteBranchById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func deleteBranchById */
