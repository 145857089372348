import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "course";

const GET_ALL_COURSE_URL = `${prefix}/all`;
const SEARCH_COURSE_MENU_URL = `${prefix}/search-course-menu`;
const CREATE_COURSE_URL = `${prefix}/create`;
const UPDATE_COURSE_BY_ID_URL = `${prefix}/update-by-id`;
const DELETE_COURSE_BY_ID_URL = `${prefix}/delete-by-id`;

/**
 * @func getAllCourse
 * @desc get all course data
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} course array
 */
export const getAllCourse = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_ALL_COURSE_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllCourse");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getAllCourse */

/**
 * @func searchCourseMenu
 * @desc search course menu list
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} course menu array
 */
export const searchCourseMenu = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(SEARCH_COURSE_MENU_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "searchCourseMenu");
        if (err) throw err;
        return undefined;
    }
};
/** End @func searchCourseMenu */

/**
 * @func createCourse
 * @desc create new course
 * @author izeberg
 * @param {Object} body - create course body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} create status
 */
export const createCourse = async (body, signal) => {
    try {
        const { status } = await axios.post(CREATE_COURSE_URL, body, { signal });
        if (status === 201) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "createCourse");
        if (err) throw err;
        return undefined;
    }
};
/** End @func createCourse */

/**
 * @func updateCourseById
 * @desc update course by id
 * @author izeberg
 * @param {number} id - course id
 * @param {Object} body - update course body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} update status
 */
export const updateCourseById = async (id, body, signal) => {
    try {
        const { status } = await axios.put(`${UPDATE_COURSE_BY_ID_URL}/${id}`, body, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "updateCourseById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func updateCourseById */

/**
 * @func deleteCourseById
 * @desc delete course by id
 * @author izeberg
 * @param {number} id - course id
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} delete status
 */
export const deleteCourseById = async (id, signal) => {
    try {
        const { status } = await axios.delete(`${DELETE_COURSE_BY_ID_URL}/${id}`, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "deleteCourseById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func deleteCourseById */
