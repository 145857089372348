import React from "react";
import { object, string } from "yup";
import SwitchApp from "../../../componentsUtils/SwitchApp";
import { currencyTHB } from "../../../../utils/formatCurrency";

export const columnTablePackage = props => {
    /** @type Column */
    const column = [
        {
            field: "id",
            title: "#",
            sorting: false,
            render: obj => (
                <div>{props?.tableOptions?.currentPage * props?.tableOptions?.pageSize + obj.tableData?.id + 1}</div>
            ),
        },
        {
            field: "packageName",
            title: "Package Name",
        },
        {
            field: "credit",
            title: "Credit",
            render: obj => <div> {currencyTHB(obj?.credit)} </div>,
        },
        {
            field: "price",
            title: "Price",
            render: obj => <div> {currencyTHB(obj?.price)} </div>,
        },
        {
            field: "expiredPeriod",
            title: "Expired period",
            render: obj => {
                return <div> {`${obj?.expiredPeriod} Months`} </div>;
            },
        },
        {
            field: "status",
            title: "Status",
            render: obj => <SwitchApp checked={obj?.status} onChange={e => props?.handleChangeStatus(e, obj?.id)} />,
        },
    ];
    const data = column.map(col => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};

export const initialValuesPackage = {
    name_package: "",
    credit: "",
    discount: "",
    price: "",
    expired_period: "",
    description: "",
};

export const validateSchemaFormPackage = object({
    name_package: string()
        .max(255)
        .required("Required."),
    credit: string()
        .matches(/^[0-9]+$/g, "Number only.")
        .max(10)
        .required("Required."),
    // discount: string().matches(/^[0-9]+$/g, "Number only.").max(3).required("Required."),
    price: string()
        .matches(/^[0-9]+$/g, "Number only.")
        .max(10)
        .required("Required."),
    expired_period: string()
        .matches(/^[0-9]+$/g, "Number only.")
        .max(10)
        .required("Required."),
    description: string().max(255),
});
