import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "package";

const GET_ALL_PACKAGE_URL = `${prefix}/all`;
const CREATE_PACKAGE_URL = `${prefix}/create`;
const UPDATE_PACKAGE_BY_ID_URL = `${prefix}/update-by-id`;
const DELETE_PACKAGE_BY_ID_URL = `${prefix}/delete-by-id`;

/**
 * @func getAllPackage
 * @desc get all package data
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} package array
 */
export const getAllPackage = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_ALL_PACKAGE_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllPackage");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getAllPackage */

/**
 * @func createPackage
 * @desc create new package
 * @author izeberg
 * @param {Object} body - create package body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} create status
 */
export const createPackage = async (body, signal) => {
    try {
        const { status } = await axios.post(CREATE_PACKAGE_URL, body, { signal });
        if (status === 201) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "createPackage");
        if (err) throw err;
        return undefined;
    }
};
/** End @func createPackage */

/**
 * @func updatePackageById
 * @desc update package by id
 * @author izeberg
 * @param {number} id - package id
 * @param {Object} body - update package body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} update status
 */
export const updatePackageById = async (id, body, signal) => {
    try {
        const { status } = await axios.put(`${UPDATE_PACKAGE_BY_ID_URL}/${id}`, body, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "updatePackageById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func updatePackageById */

/**
 * @func deletePackageById
 * @desc delete package by id
 * @author izeberg
 * @param {number} id - package id
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} delete status
 */
export const deletePackageById = async (id, signal) => {
    try {
        const { status } = await axios.delete(`${DELETE_PACKAGE_BY_ID_URL}/${id}`, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "deletePackageById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func deletePackageById */
