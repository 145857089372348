/* eslint-disable */
import Swal, { SweetAlertOptions } from "sweetalert2";

/**
 * Start
 * @func swalCondition
 * @desc sweetalert2 function helper
 * @author izeberg
 * @param {string} title - title ของ Sweetalert2 Popup ("title")
 * @param {string} message - message ของ Sweetalert2 Popup ("message")
 * @param {object} options -
 * @param {string} options.icon - icon ของ Sweetalert2 Popup (["error", "info", "question", "success", "warning"])
 * @param {boolean} options.showCancelButton - จะให้แสดงปุ่ม cancel ไหม (true or false)
 * @param {string} options.confirmButtonText - text ของปุ่ม confirm ("Confirm")
 * @param {string} options.cancelButtonText - text ของปุ่ม cancel ("Cancel")
 * @returns Sweetalert2 Popup
 */
export const swalCondition = async (title, message, options) => {
    return Swal.fire({
        heightAuto: false,
        icon: options?.icon || "",
        title,
        text: message,
        showCancelButton: options?.showCancelButton === true ? true : options?.showCancelButton !== false,
        confirmButtonText: options?.confirmButtonText || "CONFIRM",
        cancelButtonText: options?.cancelButtonText || "CANCEL",
        customClass: {
            confirmButton: "swal-confirm-button",
            cancelButton: "swal-cancel-button",
        },
    }).then(result => {
        if (result.isConfirmed) {
            return true;
        }
        return false;
    });
};
/** End @func swalCondition */

export const swalToast = async (/** @type SweetAlertOptions */ options) => {
    return Swal.fire({
        showConfirmButton: false,
        ...options,
        position: options?.position || "top-end",
        icon: options?.icon || "info",
        timer: options?.timer || 2000,
        toast: true,
        timerProgressBar: true,
    }).then(result => {
        return result.isConfirmed;
    });
};
