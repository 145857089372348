import React from "react";
import { Button, Grid, IconButton, InputAdornment, MenuItem, TextField } from "@material-ui/core";
import { Search, Add } from "@material-ui/icons";
import { Formik } from "formik";
import SelectApp from "../../componentsUtils/SelectApp";
import { initialValuesSearchTag, validateSchemaSearchTag } from "./helpers/TagOption";

const TagSearch = ({ onSearch, onCreate }) => {
    return (
        <Formik initialValues={initialValuesSearchTag} validationSchema={validateSchemaSearchTag} onSubmit={onSearch}>
            {({ values, handleChange, errors, handleSubmit, setFieldValue }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} xl={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <div className="d-flex align-items-center">
                                    <div style={{ flex: 1 }}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            id="search"
                                            size="small"
                                            value={values?.search}
                                            onChange={handleChange}
                                            error={Boolean(errors?.search)}
                                            helperText={errors?.search}
                                            placeholder="Search..."
                                            className="fixed-h-lg"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconButton disabled>
                                                            <Search style={{ color: "#808080" }} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className="d-md-flex align-items-center">
                                    <label className="mb-0 mr-4">Status : </label>
                                    <div className="flex-fill">
                                        <SelectApp
                                            value={values?.status}
                                            size="small"
                                            onChange={value => setFieldValue("status", value)}
                                            options={[
                                                { value: "0", title: "Inactive" },
                                                { value: "1", title: "Active" },
                                            ]}
                                            selectClass="fixed-h-lg"
                                            placeholder="All"
                                            RenderItem={(option, idx) => (
                                                <MenuItem key={`type${idx}`} value={option?.value}>
                                                    {" "}
                                                    {option.title}{" "}
                                                </MenuItem>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className="d-flex d-md-block flex-column align-content-stretch">
                                    <Button
                                        size="large"
                                        className="t-btn-primary fixed-h-lg"
                                        onClick={() => {
                                            setFieldValue("menu", "");
                                            setFieldValue("search", "");
                                            setFieldValue("status", "");
                                            handleSubmit();
                                        }}>
                                        Clear{" "}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} xl={4}>
                        <div className="d-flex align-items-center justify-content-md-end">
                            <Button
                                onClick={handleSubmit}
                                size="large"
                                className="btn btn-lg t-btn-primary fixed-h-lg mr-5 flex-fill flex-md-grow-0">
                                <Search className="mr-4" /> Search
                            </Button>
                            <Button
                                onClick={onCreate}
                                size="large"
                                className="btn btn-lg t-btn-primary fixed-h-lg flex-fill flex-md-grow-0">
                                <Add className="mr-2" /> Create Tag
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
};

export default TagSearch;
