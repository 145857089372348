import { Grid, Paper } from "@material-ui/core";
import React from "react";
import UserLogsContent from "../../../components/UserLogsActivity/UserLogsContent";

export default function UserLogsActivity() {
    return (
        <Paper className="p-2 p-md-3 py-3 py-md-5">
            <Grid container spacing={0}>
                <Grid item xs={12} className="mb-3">
                    <h4>Logs Activity</h4>
                </Grid>
                <Grid item xs={12}>
                    <UserLogsContent />
                </Grid>
            </Grid>
        </Paper>
    );
}
