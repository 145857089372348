import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "students";

const GET_ALL_STUDENT_URL = `${prefix}/get-all`;
const GET_ALL_STUDENT_EXCEL_URL = `${prefix}/get-all-excel`;
const CREATE_STUDENT_URL = `${prefix}/create`;
const IMPORT_STUDENT_URL = `${prefix}/import`;
const UPDATE_STUDENT_BY_ID_URL = `${prefix}/update-by-id`;
const UPDATE_STUDENT_NOTE_BY_ID_URL = `${prefix}/update-note`;
const DELETE_STUDENT_BY_ID_URL = `${prefix}/delete-by-id`;
const GET_STUDENT_BY_ID_URL = `${prefix}/student-by-id`;
const GET_STUDENT_CLASS_HISTORY_URL = `${prefix}/get-student-class-history`;
const GET_STUDENT_ABSENT_URL = `${prefix}/get-absent`;
const GET_STUDENT_ORDER_URL = `${prefix}/get-student-order`;
const UPDATE_STUDENT_SCORE_URL = `${prefix}/update-student-score`;
const EXPORT_STUDENT_CLASS_HISTTORY_URL = `${prefix}/export-student-class-history`;

/**
 * @func getAllStudent
 * @desc get all package data
 * @author jew
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} package array
 */
export const getAllStudent = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_ALL_STUDENT_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllStudent");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getAllStudent */

/**
 * @func getAllStudentExcel
 * @desc get all student excel
 * @author jew
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} student array
 */
export const getAllStudentExcel = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_ALL_STUDENT_EXCEL_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllStudentExcel");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getAllStudentExcel */

/**
 * @func getStudentOrder
 * @desc get student order history
 * @author jew
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} order history array
 */
export const getStudentOrder = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_STUDENT_ORDER_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getStudentOrder");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getStudentOrder */

/**
 * @func createStudent
 * @desc create new package
 * @author jew
 * @param {Object} body - create package body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} create status
 */
export const createStudent = async (body, signal) => {
    try {
        const { status } = await axios.post(CREATE_STUDENT_URL, body, { signal });
        if (status === 201) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "createStudent");
        if (err) throw err;
        return undefined;
    }
};
/** End @func createStudent */

/**
 * @func updateStudentById
 * @desc update student by id
 * @author jew
 * @param {number} id - package id
 * @param {Object} body - update package body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} update status
 */
export const updateStudentById = async (id, body, signal) => {
    try {
        const { status } = await axios.put(`${UPDATE_STUDENT_BY_ID_URL}/${id}`, body, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "updateStudentById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func updateStudentById */

/**
 * @func updateStudentNoteById
 * @desc update student by id
 * @author march
 * @param {number} id - package id
 * @param {Object} body - update package body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} update status
 */
export const updateStudentNoteById = async (id, body, signal) => {
    try {
        const { status } = await axios.put(`${UPDATE_STUDENT_NOTE_BY_ID_URL}/${id}`, body, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "updateStudentById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func updateStudentNoteById */

/**
 * @func deleteStudentById
 * @desc delete package by id
 * @author jew
 * @param {number} id - package id
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} delete status
 */
export const deleteStudentById = async (id, signal) => {
    try {
        const { status } = await axios.delete(`${DELETE_STUDENT_BY_ID_URL}/${id}`, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "deleteStudentById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func deleteStudentById */

/**
 * @func getStudentById
 * @desc get package by id
 * @author jew
 * @param {number} id - package id
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} get status
 */
export const getStudentById = async (id, signal) => {
    try {
        const { data, status } = await axios.get(`${GET_STUDENT_BY_ID_URL}/${id}`, { signal });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getStudentById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getStudentById */

/**
 * @func getStudentClassHistory
 * @desc get student class history
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} student class history
 */
export const getStudentClassHistory = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_STUDENT_CLASS_HISTORY_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getStudentClassHistory");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getStudentClassHistory */

/**
 * @func updateStudentClassHistoryScore
 * @desc update student class history score
 * @author izeberg
 * @param {number} scheduleClassroomHistoryId - schedule classroom history id
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} update status
 */
export const updateStudentClassHistoryScore = async (scheduleClassroomHistoryId, body, signal) => {
    try {
        const { status } = await axios.put(`${UPDATE_STUDENT_SCORE_URL}/${scheduleClassroomHistoryId}`, body, {
            signal,
        });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "updateStudentClassHistoryScore");
        if (err) throw err;
        return undefined;
    }
};
/** End @func updateStudentClassHistoryScore */

/**
 * @func getStudentAbsent
 * @desc get student absent by student id
 * @author march
 * @param {number} id - student id
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} get student absent
 */
export const getStudentAbsent = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(`${GET_STUDENT_ABSENT_URL}`, { params: queryParams, signal });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getStudentAbsent");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getStudentAbsent */

/**
 * @func importStudent
 * @desc import students
 * @author march
 * @param {Object} body - import students body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} import student
 */
export const importStudent = async (body, signal) => {
    try {
        const { status } = await axios.post(IMPORT_STUDENT_URL, body, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "importStudent");
        if (err) throw err;
        return undefined;
    }
};
/** End @func importStudent */

/**
 * @func exportStudentClassHistory
 * @desc export student class history
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} student class history
 */
export const exportStudentClassHistory = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(EXPORT_STUDENT_CLASS_HISTTORY_URL, { params: queryParams, signal });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "exportStudentClassHistory");
        if (err) throw err;
        return undefined;
    }
};
/** End @func exportStudentClassHistory */
