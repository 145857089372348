import React from "react";
import { Grid, Paper } from "@material-ui/core";

import ContentSchedule from "../../components/ScheduleManagement/ContentSchedule";

export default function ScheduleManagement() {
    return (
        <Paper className="p-2 p-lg-3">
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                    <ContentSchedule />
                </Grid>
            </Grid>
        </Paper>
    );
}
