import React, { useCallback, useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import CryptoJS from "crypto-js";
import { FormattedMessage } from "react-intl";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField,
} from "@material-ui/core";
import { object, string } from "yup";
import { Close, MailOutline, Visibility, VisibilityOff } from "@material-ui/icons";
import { Modal, ModalFooter } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SelectApp from "../../componentsUtils/SelectApp";
import { getLoginBranchOption, getUserMe, loginSystem, resetPassword, updatePassword } from "../../../apis/userApi";
import { swalCondition } from "../../../utils/swal";
import ForgetPassword from "./ForgetPassword";
import ResetPassword from "./ResetPassword";
import { axiosAuthInstance } from "../../../axios/auth.axios";

const initialValues = {
    email: "",
    password: "",
};

const LoginSchema = object({
    email: string()
        // eslint-disable-next-line
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]+$/g, "Invalid email format ex. example@email.com")
        .required("Required."),
    password: string().required("Required."),
});

const LoginPage = () => {
    // ref
    const controllerRef = useRef(null);

    // state
    const [showPassword, setShowPassword] = useState(false);
    const [dialogBranch, setDialogBranch] = useState(false);
    const [email, setEmail] = useState(null);
    const [modalResetPassword, setModelResetPassword] = useState(false);
    const [modalForgetPass, setModalForgetPass] = useState(false);
    const [button, setButton] = useState(false);
    const [isStatusSendEmail, setIsStatusSendEmail] = useState(false);
    const [isStatusUpdateEmail, setIsStatusUpdateEmail] = useState(false);
    const [branchs, setBranchs] = useState([]);
    const [loginUser, setLoginUser] = useState({ email: "", password: "", branchId: "" });

    // sign in
    const handleOnSubmit = useCallback(async values => {
        try {
            const loginRes = await loginSystem(
                { email: values?.email, password: values?.password },
                controllerRef.current?.signal,
            );
            if (loginRes) {
                setLoginUser(currentState => ({ ...currentState, email: values?.email, password: values?.password }));
                setDialogBranch(true);
            }
        } catch (error) {
            console.dir(error);
            if (error?.response?.status === 400) {
                swalCondition("Login Fail !!!", "Incorrect Email or Password.", {
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "OK",
                });
                localStorage.clear();
            }
        }
    }, []);

    // select branch
    const handleOnSubmitSelectBranch = useCallback(
        async values => {
            try {
                setLoginUser(currentState => ({ ...currentState, branchId: values }));
                const loginRes = await loginSystem(
                    { email: loginUser?.email, password: loginUser?.password, branchId: values?.branch },
                    controllerRef.current?.signal,
                );
                if (loginRes) {
                    localStorage.setItem("authToken", loginRes?.accessToken);
                    localStorage.setItem("currentBranchId", values?.branch);
                    const userMe = await getUserMe(controllerRef.current?.signal);
                    if (userMe) {
                        localStorage.setItem("userMe", JSON.stringify({ id: userMe?.id }));
                        setDialogBranch(false);
                        window.location.reload();
                    }
                }
            } catch (error) {
                console.dir(error);
                localStorage.clear();
                setDialogBranch(false);
            }
        },
        [loginUser],
    );

    // open forgot password modal
    const handleOpenModalForgetPassword = useCallback(() => {
        setModalForgetPass(true);
    }, []);

    // close forgot password modal
    const handleCloseModalForgetPassword = useCallback(() => {
        setModalForgetPass(false);
    }, []);

    // close reset password modal
    const handleCloseModalResetPassword = useCallback(() => {
        setModelResetPassword(false);
    }, []);

    // reset password
    const handleResetPassword = async email => {
        setButton(true);
        try {
            const reset = await resetPassword(email, controllerRef?.current?.signal);
            if (reset) {
                setIsStatusSendEmail(true);
            }
        } catch (error) {
            console.dir(error);
        }
    };

    // decryption function
    const decrypt = encryptedText => {
        const decrypted = CryptoJS.AES.decrypt(encryptedText, process.env.REACT_APP_CRYPT_KEY).toString(
            CryptoJS.enc.Utf8,
        );
        return decrypted;
    };

    // reset password
    const handleSubmitResetPassword = async data => {
        const update = await updatePassword(data, controllerRef?.current?.signal);
        if (update) {
            setIsStatusUpdateEmail(true);
        }
    };

    const handleCancelBranch = async () => {
        await axiosAuthInstance.get("web-refresh-access/logout");
        setDialogBranch(false);
    };

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        const init = async () => {
            try {
                const params = new URLSearchParams(window?.location?.search);
                const enCreyEmail = params?.get("email");
                if (enCreyEmail) {
                    const email = decrypt(enCreyEmail);
                    if (email) {
                        setEmail(email);
                        setModelResetPassword(true);
                    }
                }
                const loginBranchOption = await getLoginBranchOption(controllerRef.current?.signal);
                if (loginBranchOption) {
                    setBranchs(loginBranchOption);
                }
            } catch (error) {
                console.dir(error);
            }
        };

        init();
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, []);

    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            <div className="text-center mb-10 mb-lg-20">
                <div className="w-100">
                    <img
                        style={{ width: 100 }}
                        src={toAbsoluteUrl("/media/assets/images/logos/cottage.png")}
                        alt="logo-sweet-cottage"
                    />
                </div>
                <h3 className="font-size-h1">
                    <FormattedMessage id="AUTH.LOGIN.WELCOME" />
                </h3>
                <h5 className="text-muted">Sweets Cottage Academy Management.</h5>
            </div>
            {/* end::Head */}
            <Formik initialValues={initialValues} validationSchema={LoginSchema} onSubmit={handleOnSubmit}>
                {({ values, handleChange, handleSubmit, errors }) => (
                    <Grid container spacing={0}>
                        <Grid item xs={12} className="py-3">
                            <FormControl fullWidth>
                                <FormLabel>Email</FormLabel>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="email"
                                    name="email"
                                    error={Boolean(errors?.email)}
                                    helperText={errors?.email}
                                    value={values?.email}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton disabled>
                                                    <MailOutline style={{ color: "#808080" }} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className="py-3">
                            <FormControl fullWidth>
                                <FormLabel>Password</FormLabel>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    error={Boolean(errors?.password)}
                                    helperText={errors?.password}
                                    value={values?.password}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(prev => !prev)}>
                                                    {showPassword ? (
                                                        <VisibilityOff style={{ color: "#808080" }} />
                                                    ) : (
                                                        <Visibility style={{ color: "#808080" }} />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className="pt-3">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-12 col-sm-4 col-lg-3">
                                    <Button onClick={handleSubmit} className="t-btn-secondary w-100">
                                        <FormattedMessage id="AUTH.LOGIN.BUTTON" />
                                    </Button>
                                </div>
                                <div className="col-12 col-sm-4 col-lg-4">
                                    <Box
                                        component="a"
                                        onClick={handleOpenModalForgetPassword}
                                        className="w-100"
                                        style={{ textDecoration: "underline" }}>
                                        {" "}
                                        Forget password{" "}
                                    </Box>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                )}
            </Formik>
            {/* end::Form */}

            {/* modal forget password */}
            <Modal show={modalForgetPass} centered scrollable onHide={handleCloseModalForgetPassword}>
                <Modal.Body>
                    {!isStatusSendEmail ? (
                        <ForgetPassword button={button} onSubmit={handleResetPassword} />
                    ) : (
                        <div>
                            {/* begin::Head */}
                            <div className="text-center mb-5 mb-lg-10">
                                <div className="w-100">
                                    <img
                                        style={{ width: 100 }}
                                        src={toAbsoluteUrl("/media/assets/images/logos/cottage.png")}
                                        alt="logo-sweet-cottage"
                                    />
                                </div>
                                <h4 className="font-size-h1 mb-4">Please check your E-mail</h4>
                                <h4 className="font-size-h1 mb-4">For reset password.</h4>
                                <div className="d-flex justify-content-center">
                                    <div className="col-12 col-sm-4 col-lg-3">
                                        <Button
                                            onClick={handleCloseModalForgetPassword}
                                            className="t-btn-secondary w-100">
                                            Back
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            {/* end::Head */}
                        </div>
                    )}
                </Modal.Body>
            </Modal>
            {/* == end == modal forget password */}

            {/* modal reset forget  */}
            <Modal show={modalResetPassword} centered scrollable onHide={handleCloseModalResetPassword}>
                <Modal.Body>
                    {!isStatusUpdateEmail ? (
                        <ResetPassword onSubmit={handleSubmitResetPassword} email={email} />
                    ) : (
                        <div>
                            {/* begin::Head */}
                            <div className="text-center mb-5 mb-lg-10">
                                <div className="w-100">
                                    <img
                                        style={{ width: 100 }}
                                        src={toAbsoluteUrl("/media/assets/images/logos/cottage.png")}
                                        alt="logo-sweet-cottage"
                                    />
                                </div>
                                <h4 className="font-size-h1 mb-4">Successfully...</h4>
                                <div className="d-flex justify-content-center">
                                    <div className="col-12 col-sm-4 col-lg-3">
                                        <Button
                                            onClick={handleCloseModalResetPassword}
                                            className="t-btn-secondary w-100">
                                            Back
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            {/* end::Head */}
                        </div>
                    )}
                </Modal.Body>
            </Modal>
            {/* == end == modal reset password */}

            {/* begin modal branch */}
            <Dialog
                maxWidth="sm"
                fullWidth
                open={dialogBranch}
                onClose={() => {
                    handleCancelBranch();
                }}>
                <div className="w-100 d-flex justify-content-between align-items-center pt-3">
                    <DialogTitle style={{ fontSize: 16 }}>Select Branch</DialogTitle>
                    <DialogActions>
                        <IconButton onClick={handleCancelBranch}>
                            <Close />
                        </IconButton>
                    </DialogActions>
                </div>
                <DialogContent className="px-5 pt-3 pb-5">
                    <Formik
                        initialValues={{ branch: "" }}
                        validationSchema={object({ branch: string().required("Required.") })}
                        onSubmit={handleOnSubmitSelectBranch}>
                        {({ values, setFieldValue, errors, handleSubmit }) => (
                            <div>
                                <div className="mb-3">
                                    <SelectApp
                                        placeholder="Select Branch"
                                        value={values?.branch}
                                        onChange={value => setFieldValue("branch", value)}
                                        options={branchs}
                                        error={Boolean(errors?.branch)}
                                        helperText={errors?.branch}
                                        RenderItem={(option, idx) => (
                                            <MenuItem key={`select${idx}`} value={option?.id}>
                                                {option?.branchName}
                                            </MenuItem>
                                        )}
                                    />
                                </div>
                                <ModalFooter className="border-top-0">
                                    <Button onClick={handleCancelBranch} className="t-btn-gray mr-3">
                                        <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                                    </Button>
                                    <Button onClick={handleSubmit} className="t-btn-secondary">
                                        <FormattedMessage id="BTN.ACTOIN.SAVE" />
                                    </Button>
                                </ModalFooter>
                            </div>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
            {/* end modal branch */}
        </div>
    );
};

export default LoginPage;
