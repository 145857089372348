import { Button, FormControl, FormLabel, Grid, IconButton, MenuItem, TextField, Zoom } from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import { FieldArray, Formik } from "formik";
import moment from "moment";
import React, { useCallback, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import DatePickerApp from "../../componentsUtils/DatePickerApp";
import SelectApp from "../../componentsUtils/SelectApp";
import SelectSearchApp from "../../componentsUtils/SelectSearchApp";
import TextareaApp from "../../componentsUtils/TextareaApp";
import { initialValuesHolidays, validateSchemaCreateHolidays } from "./helpers/scheduleOption";

export default function FormHolidays({
    onCancel,
    onSubmit,
    teacherOption = [],
    targetHoliday = [],
    modalFormHolidays = { isOpen: false, mode: "Create" },
    setTargetHoliday = () => {},
}) {
    // component state
    const [initialvalues, setInitialvalues] = useState(initialValuesHolidays);
    const [isLoading, setIsLoading] = useState(true);

    // setup formik
    const setupFormik = useCallback(holidays => {
        if (holidays?.length) {
            const dates = holidays?.reduce((result, date) => {
                const dateArr = [];
                if (date?.start && date?.end) {
                    const currentDate = moment(date?.start);
                    const stopDate = moment(date?.end);
                    while (currentDate <= stopDate) {
                        dateArr.push(currentDate.format("YYYY-MM-DD"));
                        currentDate.add(1, "day");
                    }
                }
                return [...result, ...dateArr];
            }, []);
            setInitialvalues({
                id: holidays?.map(holiday => holiday?.id),
                type: holidays[0]?.typeHolidays || "",
                titleInput: holidays[0]?.titleInput || "",
                teacher: holidays[0]?.teacher || "-",
                field_date: dates,
                description: holidays[0]?.descriptionHolidays || "",
            });
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        if (modalFormHolidays?.isOpen) {
            setupFormik(targetHoliday);
        } else {
            setInitialvalues(initialValuesHolidays);
            setTargetHoliday([]);
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setupFormik, setTargetHoliday]);

    return isLoading ? null : (
        <Formik initialValues={initialvalues} validationSchema={validateSchemaCreateHolidays} onSubmit={onSubmit}>
            {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
                <Grid container spacing={3}>
                    {/* type */}
                    <Grid item xs={12}>
                        <div className="row align-items-center mb-3">
                            <div className="col-12 col-md-3">
                                <FormLabel className="mb-md-0">Type : </FormLabel>
                            </div>
                            <div className="col-12 col-md-9">
                                <FormControl fullWidth>
                                    <SelectApp
                                        options={[
                                            { title: "School Holidays", value: "0", color: "#dc3545" },
                                            { title: "Day off - Teacher", value: "1", color: "#73c2fb" },
                                        ]}
                                        value={values?.type}
                                        onChange={async value => {
                                            await setFieldValue("type", value);
                                            if (value === "0") {
                                                await setFieldValue("teacher", "-");
                                            } else {
                                                await setFieldValue("teacher", "");
                                            }
                                        }}
                                        error={Boolean(errors?.type)}
                                        helperText={errors?.type}
                                        placeholder="Select Type"
                                        RenderItem={(option, idx) => (
                                            <MenuItem
                                                key={`type__${idx}`}
                                                value={option?.value}
                                                style={{ backgroundColor: option?.color, color: "#fff" }}>
                                                {option?.title}
                                            </MenuItem>
                                        )}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </Grid>

                    {/* title */}
                    <Grid item xs={12}>
                        <div className="row align-items-center mb-3">
                            <div className="col-12 col-md-3">
                                <FormLabel className="mb-md-0">Title : </FormLabel>
                            </div>
                            <div className="col-12 col-md-9">
                                <FormControl fullWidth>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        value={values?.titleInput}
                                        name="titleInput"
                                        onChange={handleChange}
                                        error={Boolean(errors?.titleInput)}
                                        helperText={errors?.titleInput}
                                        placeholder="Title"
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </Grid>

                    {/* Teacher */}
                    {values?.type === "1" && (
                        <Grid item xs={12}>
                            <Zoom in={values?.type === "1"} timeout={{ enter: 400, exit: 200 }}>
                                <div className="row align-items-center mb-3">
                                    <div className="col-12 col-md-3">
                                        <FormLabel className="mb-md-0">Teacher : </FormLabel>
                                    </div>
                                    <div className="col-12 col-md-9">
                                        <FormControl fullWidth>
                                            <SelectSearchApp
                                                options={teacherOption}
                                                value={values?.teacher}
                                                onChange={newVal => {
                                                    setFieldValue("teacher", newVal);
                                                }}
                                                getOptionLabel={opt =>
                                                    `${opt?.teacherFirstname} ${opt?.teacherLastname} (${opt?.teacherNickname})`
                                                }
                                                placeholder="Select Teacher"
                                                size="sm"
                                                error={errors?.teacher}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </Zoom>
                        </Grid>
                    )}

                    {/* date */}
                    <Grid item xs={12}>
                        <div className="row align-items-center">
                            <div className="col-12 col-md-3">
                                <FormLabel className="mb-md-0">Date : </FormLabel>
                            </div>
                            <div className="col-12 col-md-9">
                                <FieldArray name="field_date">
                                    {({ remove, push }) => {
                                        return (
                                            <div>
                                                {values?.field_date?.length > 0 &&
                                                    values?.field_date.map((date, idxDate) => {
                                                        const minDate =
                                                            idxDate !== 0
                                                                ? moment(values?.field_date[idxDate - 1])
                                                                      .add(1, "day")
                                                                      .format("YYYY-MM-DD")
                                                                : moment(new Date())
                                                                      .add(1, "day")
                                                                      .format("YYYY-MM-DD");
                                                        return (
                                                            <div
                                                                key={`field_date${idxDate}`}
                                                                className="d-flex align-items-center mb-3">
                                                                <div className="flex-fill">
                                                                    <FormControl fullWidth>
                                                                        <DatePickerApp
                                                                            value={date}
                                                                            minDate={minDate}
                                                                            onChange={async dateVal => {
                                                                                await setFieldValue(
                                                                                    "field_date",
                                                                                    values?.field_date
                                                                                        ?.map((item, index) =>
                                                                                            index === idxDate
                                                                                                ? moment(
                                                                                                      dateVal,
                                                                                                  ).format("YYYY-MM-DD")
                                                                                                : item,
                                                                                        )
                                                                                        ?.sort(
                                                                                            (a, b) =>
                                                                                                new Date(a).getTime() -
                                                                                                new Date(b).getTime(),
                                                                                        ),
                                                                                );
                                                                            }}
                                                                            size="small"
                                                                            error={Boolean(errors?.field_date)}
                                                                            helperText={
                                                                                errors?.field_date
                                                                                    ? errors.field_date[idxDate]
                                                                                    : ""
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                                {idxDate !== 0 ? (
                                                                    <div
                                                                        style={{ width: 50 }}
                                                                        className="d-flex justify-content-center">
                                                                        <IconButton
                                                                            onClick={() => remove(idxDate)}
                                                                            size="small"
                                                                            className="btn btn-danger rounded">
                                                                            <Delete />
                                                                        </IconButton>
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        style={{ width: 50 }}
                                                                        className="d-flex justify-content-center">
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                push(
                                                                                    moment(
                                                                                        values?.field_date[
                                                                                            values.field_date.length - 1
                                                                                        ],
                                                                                    )
                                                                                        .add(1, "day")
                                                                                        .format("YYYY-MM-DD"),
                                                                                )
                                                                            }
                                                                            size="small"
                                                                            className="btn t-btn-primary rounded">
                                                                            <Add />
                                                                        </IconButton>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        );
                                    }}
                                </FieldArray>
                            </div>
                        </div>
                    </Grid>

                    {/* description */}
                    <Grid item xs={12}>
                        <div className="row align-items-center mb-3">
                            <div className="col-12 col-md-3">
                                <FormLabel className="mb-md-0">Description : </FormLabel>
                            </div>
                            <div className="col-12 col-md-9">
                                <FormControl fullWidth>
                                    <TextareaApp
                                        props={{
                                            textAreaProps: {
                                                value: values?.description,
                                                name: "description",
                                                onChange: handleChange,
                                                rows: 5,
                                                placeholder: "Description",
                                            },
                                        }}
                                        errorMessage={errors?.description}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </Grid>

                    {/* action */}
                    <Grid item xs={12}>
                        <div className="d-flex justify-content-md-end align-items-center">
                            <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                                <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                            </Button>
                            <Button type="submit" onClick={handleSubmit} className="btn t-btn-secondary">
                                <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
}
