import { FormControl, Grid, InputAdornment, TextField, Box } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import MaterialTable from "material-table";
import React, { useCallback, useRef, useState } from "react";
import { getStudentLog } from "../../../apis/logApi";
import { getOptionsTableApp } from "../../helpers/useOption";
import { columnTableLog } from "./helpers/studentManageOption";

export default function LogActivityStudentContent() {
    // state or variable
    const [tableOptions, setTableOptions] = useState(getOptionsTableApp);
    const tableRef = useRef(null);
    const [valueSearch, setValueSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const controllerRef = useRef(null);

    // function
    const getData = async query => {
        try {
            setIsLoading(true);
            const queryParams = {
                page: query?.page + 1 || 1,
                limit: query?.pageSize || 10,
                search: query?.search || "",
                order: query?.orderBy?.field || "id",
                direction: query?.orderBy?.field ? query?.orderDirection : "desc",
            };

            const res = await getStudentLog(queryParams, controllerRef.current?.signal);
            if (res) {
                setTableOptions(currentState => ({
                    ...currentState,
                    currentPage: query?.page,
                    pageSize: query?.pageSize || 10,
                    totalData: res?.pagination?.totalData || 0,
                    order: query?.orderBy?.field || "timestamp",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                }));
                setIsLoading(false);
                return {
                    data: res?.data || [],
                    page: query?.page || 0,
                    totalCount: res?.pagination?.totalData || 0,
                };
            }
            setIsLoading(false);
        } catch (error) {
            console.dir(error);
            setIsLoading(false);
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
    };

    // function or useEffect
    const handleOnchangeSearch = useCallback(
        evt => {
            const value = evt.target?.value;
            setValueSearch(value);
            tableRef.current.onQueryChange({ search: value, page: 0 });
            tableRef.current.dataManager.changeSearchText(value);
            tableRef.current.setState({ searchText: value });
            tableRef.current.setState(tableRef.current.dataManager.getRenderState());
        },
        [tableRef],
    );

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className="mb-3 mb-lg-5">
                <FormControl style={{ height: "100%" }}>
                    <TextField
                        size="small"
                        variant="outlined"
                        value={valueSearch}
                        onChange={handleOnchangeSearch}
                        placeholder="Search..."
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search className="text-muted" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        isLoading={isLoading}
                        tableRef={tableRef}
                        options={tableOptions}
                        components={{ Container: Box }}
                        columns={columnTableLog({ tableOptions })}
                        data={val => getData(val)}
                    />
                </div>
            </Grid>
        </Grid>
    );
}
