import { Grid, Paper } from "@material-ui/core";
import React from "react";
import TransactionReportContent from "../../components/report_transaction/TransactionReportContent";

export default function TransactionReport() {
    return (
        <Paper className="p-2 p-lg-3">
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <TransactionReportContent />
                </Grid>
            </Grid>
        </Paper>
    );
}
