/* eslint no-unused-vars: */
import moment from "moment";
import { Column } from "material-table";
import { currencyTHB } from "../../../../utils/formatCurrency";

export const getColumnsTableCreditBalanceStd = () => {
    /** @type Column */
    const column = [
        {
            field: "studentFirstname",
            title: "Student",
            render: rowData => `${rowData?.studentFirstname} ${rowData?.studentLastname} (${rowData?.studentNickname})`,
        },
        {
            field: "LogStudentPackages.Package.packageName",
            title: "Latest Package",
            render: rowData =>
                rowData?.LogStudentPackages[0]?.Package?.packageName
                    ? rowData?.LogStudentPackages[0]?.Package?.packageName
                    : "-",
        },
        {
            field: "balanceExpired",
            title: "Expired Date",
            render: rowData => (rowData?.balanceExpired ? moment(rowData?.balanceExpired).format("DD/MM/YYYY") : "-"),
        },
        {
            field: "balance",
            title: "Credit Balance",
            render: rowData => currencyTHB(rowData?.balance || 0),
        },
    ];
    const data = column?.map((col, index) => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};
