/* eslint-disable  no-unused-vars */
import { Switch, withStyles, SwitchProps } from "@material-ui/core";
import React from "react";

const IOSSwitch = withStyles(theme => ({
    root: {
        width: 38,
        height: 22,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        "&$checked": {
            transform: "translateX(16px)",
            color: `${theme.palette.common.white} !important`,
            "& + $track": {
                backgroundColor: "#0eb682 !important",
                opacity: 1,
                border: "none",
                color: "red",
            },
        },
        "&$focusVisible $thumb": {
            color: "#0eb682 !important",
            border: "6px solid #fff",
        },
        "&:active $thumb": {
            width: 22,
        },
        "&:active$checked $thumb": {
            width: 22,
            transform: "translateX(-2px)",
        },
    },
    thumb: {
        width: 20,
        height: 20,
        transition: "all .3s",
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
}))((/** @type SwitchProps */ { classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export default function SwitchApp(/** @type SwitchProps */ props) {
    return <IOSSwitch {...props} />;
}
