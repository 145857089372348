import { Button, FormControl, FormLabel, TextField } from "@material-ui/core";
import { PlayArrowRounded } from "@material-ui/icons";
import { Field, FieldArray, Form, Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { validationSchemaRole } from "./helpers/rolepermissionOption";

export default function FormRole({ onCancel, onSubmit, initialFormValues }) {
    // ref
    const formikRef = useRef(null);

    // global redux state
    const { menuForRolePermssion, isLoad } = useSelector(state => state?.mainMenu);

    // component state
    const [initialArrMain, setInitialArrMain] = useState([]);

    // ** handle change select all chk
    const handleChageAllPermission = useCallback(
        (evt, name = "", item = {}, index = "") => {
            const checked = evt.target?.checked;
            const changeValue = formikRef?.current?.setFieldValue;
            const formikValues = formikRef.current?.values;
            if (!name.includes("sub1") && !name.includes("sub2")) {
                const mainMenu = formikValues?.check_permission?.find(
                    permission => permission?.main?.sidebarMenuMainIndex === item?.main?.sidebarMenuMainIndex,
                );
                if (mainMenu?.sub1?.length) {
                    mainMenu.sub1.forEach((sub1, sub1Index) => {
                        changeValue(`check_permission.${index}.sub1.${sub1Index}.values.all`, checked);
                        changeValue(`check_permission.${index}.sub1.${sub1Index}.values.view`, checked);
                        changeValue(`check_permission.${index}.sub1.${sub1Index}.values.create`, checked);
                        changeValue(`check_permission.${index}.sub1.${sub1Index}.values.edit`, checked);
                        changeValue(`check_permission.${index}.sub1.${sub1Index}.values.delete`, checked);
                    });
                }
                if (mainMenu?.sub2?.length) {
                    mainMenu.sub2.forEach((sub2, sub2Index) => {
                        changeValue(`check_permission.${index}.sub2.${sub2Index}.values.all`, checked);
                        changeValue(`check_permission.${index}.sub2.${sub2Index}.values.view`, checked);
                        changeValue(`check_permission.${index}.sub2.${sub2Index}.values.create`, checked);
                        changeValue(`check_permission.${index}.sub2.${sub2Index}.values.edit`, checked);
                        changeValue(`check_permission.${index}.sub2.${sub2Index}.values.delete`, checked);
                    });
                }
            }
            if (name.includes("sub1")) {
                const sub2Menus = formikValues?.check_permission?.find(
                    permission => permission?.main?.sidebarMenuMainIndex === item?.obj?.sidebarMenuMainIndex,
                )?.sub2;
                if (
                    sub2Menus?.filter(
                        sub2 =>
                            sub2?.obj?.sidebarMenuMainIndex === item?.obj?.sidebarMenuMainIndex &&
                            sub2?.obj?.sidebarMenuSubIndex === item?.obj?.sidebarMenuSubIndex,
                    )?.length
                ) {
                    sub2Menus.forEach((sub2, sub2Index) => {
                        if (
                            sub2?.obj?.sidebarMenuMainIndex === item?.obj?.sidebarMenuMainIndex &&
                            sub2?.obj?.sidebarMenuSubIndex === item?.obj?.sidebarMenuSubIndex
                        ) {
                            changeValue(name.replace(`sub1.${index}`, `sub2.${sub2Index}`), checked);
                            changeValue(
                                name.replace(`sub1.${index}`, `sub2.${sub2Index}`).replace("all", "view"),
                                checked,
                            );
                            changeValue(
                                name.replace(`sub1.${index}`, `sub2.${sub2Index}`).replace("all", "create"),
                                checked,
                            );
                            changeValue(
                                name.replace(`sub1.${index}`, `sub2.${sub2Index}`).replace("all", "edit"),
                                checked,
                            );
                            changeValue(
                                name.replace(`sub1.${index}`, `sub2.${sub2Index}`).replace("all", "delete"),
                                checked,
                            );
                        }
                    });
                }
            }
            changeValue(name, checked);
            changeValue(name.replace("all", "view"), checked);
            changeValue(name.replace("all", "create"), checked);
            changeValue(name.replace("all", "edit"), checked);
            changeValue(name.replace("all", "delete"), checked);
        },
        [formikRef],
    );

    // onChnage Administrator Access
    const handleChangeAdministratorAccess = useCallback(
        async evt => {
            const checked = evt.target?.checked;
            const formikValues = formikRef.current?.values;
            const changeValue = formikRef.current?.setFieldValue;
            changeValue("administrator", checked);
            formikValues.check_permission.forEach((item, index) => {
                changeValue(`check_permission.${index}.values.all`, checked);
                changeValue(`check_permission.${index}.values.view`, checked);
                changeValue(`check_permission.${index}.values.create`, checked);
                changeValue(`check_permission.${index}.values.edit`, checked);
                changeValue(`check_permission.${index}.values.delete`, checked);
                item.sub1.forEach((itemSub1, indexSub1) => {
                    changeValue(`check_permission.${index}.sub1.${indexSub1}.values.all`, checked);
                    changeValue(`check_permission.${index}.sub1.${indexSub1}.values.view`, checked);
                    changeValue(`check_permission.${index}.sub1.${indexSub1}.values.create`, checked);
                    changeValue(`check_permission.${index}.sub1.${indexSub1}.values.edit`, checked);
                    changeValue(`check_permission.${index}.sub1.${indexSub1}.values.delete`, checked);
                });
                item.sub2.forEach((itemSub2, indexSub2) => {
                    changeValue(`check_permission.${index}.sub2.${indexSub2}.values.all`, checked);
                    changeValue(`check_permission.${index}.sub2.${indexSub2}.values.view`, checked);
                    changeValue(`check_permission.${index}.sub2.${indexSub2}.values.create`, checked);
                    changeValue(`check_permission.${index}.sub2.${indexSub2}.values.edit`, checked);
                    changeValue(`check_permission.${index}.sub2.${indexSub2}.values.delete`, checked);
                });
            });
        },
        [formikRef],
    );

    // handle submit form
    const handleSubmitForm = useCallback(
        valuesf => {
            const name = valuesf?.name;
            const checkboxs = valuesf?.check_permission;
            const listMain = [];
            const listSub1 = [];
            const listSub2 = [];
            checkboxs.map(row => {
                listMain.push({ id: row?.main?.id, values: row?.values });
                row.sub1.map(rows => {
                    return listSub1.push({ id: rows.obj?.id, values: rows?.values });
                });
                row.sub2.map(rows => {
                    return listSub2.push({ id: rows.obj?.id, values: rows?.values });
                });
                return [];
            });
            onSubmit({
                name,
                administrator: valuesf?.administrator,
                check_permission: [...listMain, ...listSub1, ...listSub2],
                role: valuesf?.role,
            });
        },
        [onSubmit],
    );

    useEffect(() => {
        if (isLoad) {
            // edit form mode
            if (initialFormValues?.check_permission?.length) {
                const permissionArray = menuForRolePermssion?.map(row => {
                    const filterMainMenu = initialFormValues?.check_permission?.filter(
                        item => item?.menuId === row?.main?.id,
                    );
                    const mainView = filterMainMenu?.find(view => view?.permissionAccess === "1");
                    const mainCreate = filterMainMenu?.find(view => view?.permissionAccess === "2");
                    const mainEdit = filterMainMenu?.find(view => view?.permissionAccess === "3");
                    const mainDelete = filterMainMenu?.find(view => view?.permissionAccess === "4");
                    return {
                        roleManagementId: row?.main?.id,
                        main: row?.main,
                        values: {
                            all: mainView?.status && mainCreate?.status && mainEdit?.status && mainDelete?.status,
                            view: mainView?.status,
                            create: mainCreate?.status,
                            edit: mainEdit?.status,
                            delete: mainDelete?.status,
                            viewId: mainView?.id,
                            createId: mainCreate?.id,
                            editId: mainEdit?.id,
                            deleteId: mainDelete?.id,
                        },
                        sub1: row?.subMenu1.map(row1 => {
                            const filterSubMenu1 = initialFormValues?.check_permission?.filter(
                                item => item?.menuId === row1?.id,
                            );
                            const subMenu1View = filterSubMenu1?.find(view => view?.permissionAccess === "1");
                            const subMenu1Create = filterSubMenu1?.find(view => view?.permissionAccess === "2");
                            const subMenu1Edit = filterSubMenu1?.find(view => view?.permissionAccess === "3");
                            const subMenu1Delete = filterSubMenu1?.find(view => view?.permissionAccess === "4");
                            return {
                                roleManagementId: row1?.id,
                                obj: row1,
                                values: {
                                    all:
                                        subMenu1View?.status &&
                                        subMenu1Create?.status &&
                                        subMenu1Edit?.status &&
                                        subMenu1Delete?.status,
                                    view: subMenu1View?.status,
                                    create: subMenu1Create?.status,
                                    edit: subMenu1Edit?.status,
                                    delete: subMenu1Delete?.status,
                                    viewId: subMenu1View?.id,
                                    createId: subMenu1Create?.id,
                                    editId: subMenu1Edit?.id,
                                    deleteId: subMenu1Delete?.id,
                                },
                            };
                        }),
                        sub2: row?.subMenu2.map(row2 => {
                            const filterSubMenu2 = initialFormValues?.check_permission?.filter(
                                item => item?.menuId === row2?.id,
                            );
                            const subMenu2View = filterSubMenu2?.find(view => view?.permissionAccess === "1");
                            const subMenu2Create = filterSubMenu2?.find(view => view?.permissionAccess === "2");
                            const subMenu2Edit = filterSubMenu2?.find(view => view?.permissionAccess === "3");
                            const subMenu2Delete = filterSubMenu2?.find(view => view?.permissionAccess === "4");
                            return {
                                roleManagementId: row2?.id,
                                obj: row2,
                                values: {
                                    all:
                                        subMenu2View?.status &&
                                        subMenu2Create?.status &&
                                        subMenu2Edit?.status &&
                                        subMenu2Delete?.status,
                                    view: subMenu2View?.status,
                                    create: subMenu2Create?.status,
                                    edit: subMenu2Edit?.status,
                                    delete: subMenu2Delete?.status,
                                    viewId: subMenu2View?.id,
                                    createId: subMenu2Create?.id,
                                    editId: subMenu2Edit?.id,
                                    deleteId: subMenu2Delete?.id,
                                },
                            };
                        }),
                    };
                });
                setInitialArrMain(permissionArray);
                return;
            }
            // create form mode
            setInitialArrMain(
                menuForRolePermssion?.map(row => {
                    return {
                        main: row.main,
                        values: {
                            all: false,
                            view: false,
                            create: false,
                            edit: false,
                            delete: false,
                        },
                        sub1: row?.subMenu1.map(row1 => {
                            return {
                                obj: row1,
                                values: {
                                    all: false,
                                    view: false,
                                    create: false,
                                    edit: false,
                                    delete: false,
                                },
                            };
                        }),
                        sub2: row?.subMenu2.map(row2 => {
                            return {
                                obj: row2,
                                values: {
                                    all: false,
                                    view: false,
                                    create: false,
                                    edit: false,
                                    delete: false,
                                },
                            };
                        }),
                    };
                }),
            );
        }
    }, [isLoad, menuForRolePermssion, initialFormValues.check_permission]);

    // retrun loading
    if (!isLoad) return <>Loading...</>;

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                name: initialFormValues?.name,
                administrator: initialFormValues?.administrator,
                check_permission: initialArrMain,
                role: initialFormValues?.role,
            }}
            validationSchema={validationSchemaRole}
            enableReinitialize
            onSubmit={handleSubmitForm}>
            {({ values, handleChange, errors, handleSubmit }) => (
                <Form className="px-3 role-form-sive">
                    <FormControl fullWidth className="mb-4">
                        <FormLabel>
                            Name <span className="text-danger">*</span>{" "}
                        </FormLabel>
                        <TextField
                            variant="outlined"
                            id="name"
                            value={values.name}
                            onChange={handleChange}
                            error={Boolean(errors.name)}
                            helperText={errors.name}
                            placeholder="Role Name"
                        />
                    </FormControl>
                    <div className="row align-items-center">
                        <div className="col-4 font-weight-bold">Administrator Access</div>
                        <div className="col-8">
                            <div className="row align-items-center row-chk-role">
                                <div className="col-2 chk-custom-formcontrol">
                                    <Field
                                        type="checkbox"
                                        id="administrator"
                                        name="administrator"
                                        onChange={handleChangeAdministratorAccess}
                                    />
                                    <FormLabel htmlFor="administrator">All</FormLabel>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FieldArray name="check_permission.test1">
                        {({ _insert, _remove, _push }) => (
                            <div>
                                {values.check_permission.length > 0 &&
                                    values?.check_permission.map((item, idx) => {
                                        return (
                                            <div key={idx}>
                                                <div className="row align-items-center mt-3">
                                                    <div className="col-4 font-weight-bold">
                                                        {item?.main?.sidebarMenuName}
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="row align-items-center justify-content-end row-chk-role">
                                                            <div className="col-2 chk-custom-formcontrol">
                                                                <Field
                                                                    type="checkbox"
                                                                    id={`check_permission.${idx}.values.all`}
                                                                    name={`check_permission.${idx}.values.all`}
                                                                    onChange={evt => {
                                                                        handleChageAllPermission(
                                                                            evt,
                                                                            `check_permission.${idx}.values.all`,
                                                                            item,
                                                                            idx,
                                                                        );
                                                                    }}
                                                                />
                                                                <FormLabel
                                                                    htmlFor={`check_permission.${idx}.values.all`}>
                                                                    All
                                                                </FormLabel>
                                                            </div>
                                                            <div className="col-2 chk-custom-formcontrol">
                                                                <Field
                                                                    type="checkbox"
                                                                    id={`check_permission.${idx}.values.view`}
                                                                    name={`check_permission.${idx}.values.view`}
                                                                />
                                                                <FormLabel
                                                                    htmlFor={`check_permission.${idx}.values.view`}>
                                                                    View
                                                                </FormLabel>
                                                            </div>
                                                            <div className="col-2 chk-custom-formcontrol">
                                                                <Field
                                                                    type="checkbox"
                                                                    id={`check_permission.${idx}.values.create`}
                                                                    name={`check_permission.${idx}.values.create`}
                                                                />
                                                                <FormLabel
                                                                    htmlFor={`check_permission.${idx}.values.create`}>
                                                                    Create
                                                                </FormLabel>
                                                            </div>
                                                            <div className="col-2 chk-custom-formcontrol">
                                                                <Field
                                                                    type="checkbox"
                                                                    id={`check_permission.${idx}.values.edit`}
                                                                    name={`check_permission.${idx}.values.edit`}
                                                                />
                                                                <FormLabel
                                                                    htmlFor={`check_permission.${idx}.values.edit`}>
                                                                    Edit
                                                                </FormLabel>
                                                            </div>
                                                            <div className="col-2 chk-custom-formcontrol">
                                                                <Field
                                                                    type="checkbox"
                                                                    id={`check_permission.${idx}.values.delete`}
                                                                    name={`check_permission.${idx}.values.delete`}
                                                                />
                                                                <FormLabel
                                                                    htmlFor={`check_permission.${idx}.values.delete`}>
                                                                    Delete
                                                                </FormLabel>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* sub1 */}
                                                    {item.sub1.length > 0 && (
                                                        <div className="col-12">
                                                            <div className="">
                                                                {item.sub1.map((itemS1, idxS1) => {
                                                                    return (
                                                                        <div
                                                                            key={`Sub_${idxS1}`}
                                                                            className="row align-items-center mt-3">
                                                                            <div className="col-4">
                                                                                <div className="pl-3 pl-md-5">
                                                                                    <PlayArrowRounded />
                                                                                    &nbsp;
                                                                                    {itemS1?.obj?.sidebarMenuName || ""}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <div className="row align-items-center justify-content-end row-chk-role">
                                                                                    <div className="col-2 chk-custom-formcontrol">
                                                                                        <Field
                                                                                            type="checkbox"
                                                                                            id={`check_permission.${idx}.sub1.${idxS1}.values.all`}
                                                                                            name={`check_permission.${idx}.sub1.${idxS1}.values.all`}
                                                                                            onChange={evt => {
                                                                                                handleChageAllPermission(
                                                                                                    evt,
                                                                                                    `check_permission.${idx}.sub1.${idxS1}.values.all`,
                                                                                                    itemS1,
                                                                                                    idxS1,
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                        <FormLabel
                                                                                            htmlFor={`check_permission.${idx}.sub1.${idxS1}.values.all`}>
                                                                                            All
                                                                                        </FormLabel>
                                                                                    </div>
                                                                                    <div className="col-2 chk-custom-formcontrol">
                                                                                        <Field
                                                                                            type="checkbox"
                                                                                            id={`check_permission.${idx}.sub1.${idxS1}.values.view`}
                                                                                            name={`check_permission.${idx}.sub1.${idxS1}.values.view`}
                                                                                        />
                                                                                        <FormLabel
                                                                                            htmlFor={`check_permission.${idx}.sub1.${idxS1}.values.view`}>
                                                                                            View
                                                                                        </FormLabel>
                                                                                    </div>
                                                                                    <div className="col-2 chk-custom-formcontrol">
                                                                                        <Field
                                                                                            type="checkbox"
                                                                                            id={`check_permission.${idx}.sub1.${idxS1}.values.create`}
                                                                                            name={`check_permission.${idx}.sub1.${idxS1}.values.create`}
                                                                                        />
                                                                                        <FormLabel
                                                                                            htmlFor={`check_permission.${idx}.sub1.${idxS1}.values.create`}>
                                                                                            Create
                                                                                        </FormLabel>
                                                                                    </div>
                                                                                    <div className="col-2 chk-custom-formcontrol">
                                                                                        <Field
                                                                                            type="checkbox"
                                                                                            id={`check_permission.${idx}.sub1.${idxS1}.values.edit`}
                                                                                            name={`check_permission.${idx}.sub1.${idxS1}.values.edit`}
                                                                                        />
                                                                                        <FormLabel
                                                                                            htmlFor={`check_permission.${idx}.sub1.${idxS1}.values.edit`}>
                                                                                            Edit
                                                                                        </FormLabel>
                                                                                    </div>
                                                                                    <div className="col-2 chk-custom-formcontrol">
                                                                                        <Field
                                                                                            type="checkbox"
                                                                                            id={`check_permission.${idx}.sub1.${idxS1}.values.delete`}
                                                                                            name={`check_permission.${idx}.sub1.${idxS1}.values.delete`}
                                                                                        />
                                                                                        <FormLabel
                                                                                            htmlFor={`check_permission.${idx}.sub1.${idxS1}.values.delete`}>
                                                                                            Delete
                                                                                        </FormLabel>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {/* sub2 */}
                                                                            {item.sub2.length > 0 && (
                                                                                <div className="col-12">
                                                                                    <div>
                                                                                        {item.sub2.map(
                                                                                            (itemS2, idxS2) => {
                                                                                                // sidebarMenuMainIndex sidebarMenuSubIndex
                                                                                                return item?.main
                                                                                                    ?.sidebarMenuMainIndex ===
                                                                                                    itemS2?.obj
                                                                                                        ?.sidebarMenuMainIndex &&
                                                                                                    itemS1?.obj
                                                                                                        ?.sidebarMenuSubIndex ===
                                                                                                        itemS2?.obj
                                                                                                            ?.sidebarMenuSubIndex ? (
                                                                                                    <div
                                                                                                        key={`sub2${idxS2}`}
                                                                                                        className="row align-items-center mt-3">
                                                                                                        <div className="col-4">
                                                                                                            <div className="pl-5 pl-md-15">
                                                                                                                <li>
                                                                                                                    {itemS2
                                                                                                                        ?.obj
                                                                                                                        ?.sidebarMenuName ||
                                                                                                                        ""}
                                                                                                                </li>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-8">
                                                                                                            <div className="row align-items-center justify-content-end row-chk-role">
                                                                                                                <div className="col-2 chk-custom-formcontrol">
                                                                                                                    <Field
                                                                                                                        type="checkbox"
                                                                                                                        id={`check_permission.${idx}.sub2.${idxS2}.values.all`}
                                                                                                                        name={`check_permission.${idx}.sub2.${idxS2}.values.all`}
                                                                                                                        onChange={evt => {
                                                                                                                            handleChageAllPermission(
                                                                                                                                evt,
                                                                                                                                `check_permission.${idx}.sub2.${idxS2}.values.all`,
                                                                                                                            );
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    <FormLabel
                                                                                                                        htmlFor={`check_permission.${idx}.sub2.${idxS2}.values.all`}>
                                                                                                                        All
                                                                                                                    </FormLabel>
                                                                                                                </div>
                                                                                                                <div className="col-2 chk-custom-formcontrol">
                                                                                                                    <Field
                                                                                                                        type="checkbox"
                                                                                                                        id={`check_permission.${idx}.sub2.${idxS2}.values.view`}
                                                                                                                        name={`check_permission.${idx}.sub2.${idxS2}.values.view`}
                                                                                                                    />
                                                                                                                    <FormLabel
                                                                                                                        htmlFor={`check_permission.${idx}.sub2.${idxS2}.values.view`}>
                                                                                                                        View
                                                                                                                    </FormLabel>
                                                                                                                </div>
                                                                                                                <div className="col-2 chk-custom-formcontrol">
                                                                                                                    <Field
                                                                                                                        type="checkbox"
                                                                                                                        id={`check_permission.${idx}.sub2.${idxS2}.values.create`}
                                                                                                                        name={`check_permission.${idx}.sub2.${idxS2}.values.create`}
                                                                                                                    />
                                                                                                                    <FormLabel
                                                                                                                        htmlFor={`check_permission.${idx}.sub2.${idxS2}.values.create`}>
                                                                                                                        Create
                                                                                                                    </FormLabel>
                                                                                                                </div>
                                                                                                                <div className="col-2 chk-custom-formcontrol">
                                                                                                                    <Field
                                                                                                                        type="checkbox"
                                                                                                                        id={`check_permission.${idx}.sub2.${idxS2}.values.edit`}
                                                                                                                        name={`check_permission.${idx}.sub2.${idxS2}.values.edit`}
                                                                                                                    />
                                                                                                                    <FormLabel
                                                                                                                        htmlFor={`check_permission.${idx}.sub2.${idxS2}.values.edit`}>
                                                                                                                        Edit
                                                                                                                    </FormLabel>
                                                                                                                </div>
                                                                                                                <div className="col-2 chk-custom-formcontrol">
                                                                                                                    <Field
                                                                                                                        type="checkbox"
                                                                                                                        id={`check_permission.${idx}.sub2.${idxS2}.values.delete`}
                                                                                                                        name={`check_permission.${idx}.sub2.${idxS2}.values.delete`}
                                                                                                                    />
                                                                                                                    <FormLabel
                                                                                                                        htmlFor={`check_permission.${idx}.sub2.${idxS2}.values.delete`}>
                                                                                                                        Delete
                                                                                                                    </FormLabel>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ) : null;
                                                                                            },
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        )}
                    </FieldArray>
                    {/* actions */}
                    <div className="d-flex justify-content-md-end align-items-center my-4">
                        <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                            <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                        </Button>
                        <Button onClick={handleSubmit} className="btn t-btn-secondary">
                            <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
