export const isBase64 = str => {
    if (str === "" || str.trim() === "") {
        return false;
    }
    try {
        const base64regex = /data:image\/([a-zA-Z]*);base64,([^"]*)/;
        return base64regex.test(str);
    } catch (err) {
        return false;
    }
};

export const isEmail = email => {
    const rule = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return rule.test(String(email).toLowerCase());
};

export const validateDate = dateString => {
    // regex to match the date in the format dd/mm/yyyy
    const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;

    // check if the date matches the format
    if (!regex.test(dateString)) {
        return false;
    }

    // check if the date is valid
    const [day, month, year] = dateString?.split("/");
    const date = new Date(`${year}-${month}-${day}`);

    return (
        date.getDate() === Number(day) && date.getMonth() === Number(month) - 1 && date.getFullYear() === Number(year)
    );
};
