/* eslint-disable  no-unused-vars */
import { KeyboardDatePicker, MuiPickersUtilsProvider, KeyboardDatePickerProps } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import React from "react";
import th from "date-fns/locale/th";

export default function DatePickerApp(/** @type KeyboardDatePickerProps */ props) {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableToolbar
                autoOk
                variant="inline"
                format="dd/MM/yyyy"
                inputVariant="outlined"
                KeyboardButtonProps={{ size: "small" }}
                {...props}
            />
        </MuiPickersUtilsProvider>
    );
}
