import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "sidebar-menu";

const GET_ALL_SIDE_MENU_URL = `${prefix}/all`;
const GET_SIDE_MENU_BY_PERMISSION_URL = `${prefix}/sort-by-index`;

export const getAllSideMenu = async signal => {
    try {
        const { data, status } = await axios.get(GET_ALL_SIDE_MENU_URL, { signal });
        if (status === 200) {
            return data?.data;
        }
    } catch (error) {
        const err = apiErrorHandler(error, "getAllSideMenu");
        if (err) throw err;
        return undefined;
    }
};

export const getSideMenuByPermission = async signal => {
    try {
        const { data, status } = await axios.get(GET_SIDE_MENU_BY_PERMISSION_URL, { signal });
        if (status === 200) {
            return data?.data;
        }
    } catch (error) {
        const err = apiErrorHandler(error, "getSideMenuByPermission");
        if (err) throw err;
        return undefined;
    }
};
