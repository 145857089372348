import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "tag";

const GET_ALL = `${prefix}/all`;
const CREATE = `${prefix}/create`;
const IMPORT = `${prefix}/import`;
const UPDATE = `${prefix}/update-by-id`;
const DELETE = `${prefix}/delete-by-id`;

/**
 * @func getAllTag
 * @desc get all tag data
 * @author march
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} tag array
 */
export const getAllTag = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_ALL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllTag");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getAllTag */

/**
 * @func getAllTag
 * @desc get all tag data
 * @author march
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} tag array
 */
export const getAllTagV2 = async signal => {
    try {
        const { data, status } = await axios.get(`${GET_ALL}-v2`, {
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllTag");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getAllTag */

/**
 * @func createTag
 * @desc create new tag
 * @author march
 * @param {Object} body - create tag body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} create tag
 */
export const createTag = async (body, signal) => {
    try {
        const { status } = await axios.post(CREATE, body, { signal });
        if (status === 201) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "createTag");
        if (err) throw err;
        return undefined;
    }
};
/** End @func createTag */

/**
 * @func importTag
 * @desc import new tag
 * @author march
 * @param {Object} body - import tag body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} create tag
 */
export const importTag = async (body, signal) => {
    try {
        const { status, data } = await axios.post(IMPORT, body, { signal });
        if (status === 201) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "createTag");
        if (err) throw err;
        return undefined;
    }
};
/** End @func createTag */

/**
 * @func updateTagById
 * @desc update tag by id
 * @author march
 * @param {number} id - tag id
 * @param {Object} body - update tag body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} update tag
 */
export const updateTagById = async (id, body, signal) => {
    try {
        const { status } = await axios.put(`${UPDATE}/${id}`, body, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "updateTagById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func updateTagById */

/**
 * @func deleteTagById
 * @desc delete tag by id
 * @author march
 * @param {number} id - tag id
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} delete tag
 */
export const deleteTagById = async (id, signal) => {
    try {
        const { status } = await axios.delete(`${DELETE}/${id}`, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "deleteTagById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func deleteTagById */
