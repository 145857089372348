import { Button, FormControl, FormLabel, Grid, TextField } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Spinner } from "react-bootstrap";
import TextareaApp from "../../componentsUtils/TextareaApp";
import { validateSchemaBranch } from "./helpers/branchOptionEvt";

export default function FormBranch({ onCancel, onSubmit, initialFormValues }) {
    return (
        <Formik initialValues={initialFormValues} validationSchema={validateSchemaBranch} onSubmit={onSubmit}>
            {({ values, handleChange, errors, handleSubmit, isSubmitting }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12} className="mb-3">
                        <FormControl fullWidth>
                            <FormLabel>
                                ID <span className="text-danger">*</span>
                            </FormLabel>
                            <TextField
                                placeholder="Branch ID"
                                variant="outlined"
                                id="id"
                                value={values.id}
                                onChange={handleChange}
                                error={Boolean(errors.id)}
                                helperText={errors.id}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className="mb-3">
                        <FormControl fullWidth>
                            <FormLabel>
                                Name <span className="text-danger">*</span>
                            </FormLabel>
                            <TextField
                                placeholder="Branch Name"
                                variant="outlined"
                                id="name"
                                value={values.name}
                                onChange={handleChange}
                                error={Boolean(errors.name)}
                                helperText={errors.name}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className="mb-3">
                        <FormControl fullWidth>
                            <FormLabel>
                                Address <span className="text-danger">*</span>
                            </FormLabel>
                            <TextareaApp
                                props={{
                                    textAreaProps: {
                                        rows: 5,
                                        id: "address",
                                        value: values?.address,
                                        onChange: handleChange,
                                        placeholder: "Enter Address",
                                    },
                                }}
                                errorMessage={errors?.address}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className="mb-3">
                        <FormControl fullWidth>
                            <FormLabel>
                                Contact Detail <span className="text-danger">*</span>
                            </FormLabel>
                            <TextareaApp
                                props={{
                                    textAreaProps: {
                                        rows: 5,
                                        id: "contact",
                                        value: values?.contact,
                                        onChange: handleChange,
                                        placeholder: "Enter Contact Detail",
                                    },
                                }}
                                errorMessage={errors?.contact}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="d-flex align-items-center justify-content-md-end">
                            <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                                <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                            </Button>
                            <Button
                                disabled={isSubmitting}
                                onClick={handleSubmit}
                                className="btn t-btn-secondary d-flex">
                                {isSubmitting ? (
                                    <Spinner animation="border" variant="primary" />
                                ) : (
                                    <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                                )}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
}
