import { Workbook } from "exceljs";
import { defaultStyleCell, saveByteArray } from "./exportToExcel";
import { invertColor } from "./invertColor";

const absentSchema = {
    studentName: "",
    note: "",
};
const menuSchema = {
    menuName: "",
    teacherName: "",
    students: [""],
};
/**
 * @type {{
 * type?: "private" | "group" | "free-scheudle";
 * time?: string;
 * roomName: string;
 * studentName: string;
 * classroomName: string;
 * classroomColor: string;
 * menus: menuSchema[];
 * absents: absentSchema;
 * }}
 */
const scheduleSchema = {
    type: "",
    time: "",
    roomName: "",
    studentName: "",
    classroomName: "",
    classroomColor: "",
    menus: [menuSchema],
    absents: absentSchema,
};
// eslint-disable-next-line no-unused-vars
const dataSchema = {
    bookingDay: "",
    bookingDate: "",
    schedules: [scheduleSchema],
};

function formatHexColor2Fill(colorStr = "") {
    return {
        type: "pattern",
        pattern: "darkTrellis",
        bgColor: { argb: colorStr.replace("#", "ff") },
        fgColor: { argb: colorStr.replace("#", "ff") },
    };
}

/**
 * @function exportExcelShedule
 * @param {dataSchema[]} data
 * @param {{
 *  fileName?: string;
 *  sliceSchedule?: number;
 *  groupBy?: string;
 * }} options
 * @returns
 */
async function exportExcelShedule(data, options) {
    try {
        if (!data?.length) {
            return;
        }

        const wb = new Workbook();
        const ws = wb.addWorksheet("Schedule Sheet", {
            properties: { defaultColWidth: 20, defaultRowHeight: 15 },
        });
        /** @description start */
        let START_ROW = 2;
        data.forEach(itemDate => {
            const metaRowOfScheduleArr = [];
            const rowOfScheduleArr = [];
            const cellofDate = ws.getCell(START_ROW, 1);
            cellofDate.style = {
                ...defaultStyleCell,
                alignment: { ...defaultStyleCell.alignment, horizontal: "left" },
                font: {
                    bold: true,
                    size: 12,
                },
            };
            cellofDate.value = `${itemDate?.bookingDay}\n${itemDate?.bookingDate}`;

            /** loop schedules */
            if (itemDate?.schedules?.length) {
                const scheduleGroups = itemDate?.schedules?.reduce((groups, schedule) => {
                    if (!groups[schedule[options?.groupBy || "id"]]) {
                        groups[schedule[options?.groupBy || "id"]] = [schedule];
                    } else {
                        groups[schedule[options?.groupBy || "id"]].push(schedule);
                    }
                    return groups;
                }, {});

                if (Object.keys(scheduleGroups)?.length) {
                    const scheduleGroupsNew = [];
                    const sliceNum = options?.sliceSchedule || 1;
                    Object.entries(scheduleGroups).forEach((_, index) => {
                        scheduleGroupsNew.push(
                            Object.entries(scheduleGroups).slice(index * sliceNum, index * sliceNum + sliceNum),
                        );
                    });
                    let META_ROW = START_ROW;
                    const scheduleGroupsNewFilterEmpty = scheduleGroupsNew.filter(schedule => schedule?.length);
                    scheduleGroupsNewFilterEmpty.forEach(scheduleRow => {
                        const scheduleGroupsObj = {};
                        scheduleRow.forEach(([classId, scheduleLists]) => {
                            scheduleGroupsObj[classId] = scheduleLists;
                        });
                        Object.entries(scheduleGroupsObj).forEach(([_, schedules], indexItemSlice) => {
                            /** for increment column */
                            let COL_START_ITEM = 2;
                            let ROW_SCHEDULE_START = META_ROW;
                            if (schedules?.length) {
                                schedules.forEach((/** @type {scheduleSchema} */ schedule) => {
                                    let COL_START_SCHEDULE = COL_START_ITEM + indexItemSlice;
                                    const cellofSchedule = ws.getCell(
                                        ROW_SCHEDULE_START,
                                        COL_START_SCHEDULE + indexItemSlice,
                                    );
                                    COL_START_SCHEDULE++;
                                    const cellofScheduleRoom = ws.getCell(
                                        ROW_SCHEDULE_START,
                                        COL_START_SCHEDULE + indexItemSlice,
                                    );
                                    const headerTitleStyle = {
                                        ...defaultStyleCell,
                                        font: {
                                            bold: true,
                                            color: { argb: invertColor(schedule?.classroomColor).replace("#", "ff") },
                                        },
                                        alignment: { ...defaultStyleCell.alignment },
                                        fill: formatHexColor2Fill(schedule?.classroomColor),
                                    };
                                    cellofSchedule.style = headerTitleStyle;
                                    cellofScheduleRoom.style = headerTitleStyle;
                                    const title =
                                        schedule?.type === "Private" ? schedule?.studentName : schedule?.courseName;
                                    cellofSchedule.value = `${schedule?.time || ""}\n${title || ""}`;
                                    cellofScheduleRoom.value = `${schedule.classroomName}`; // title
                                    if (schedule?.menus?.length) {
                                        schedule.menus.forEach(menu => {
                                            /** for increment column menu */
                                            let COL_START_MENU = COL_START_SCHEDULE + indexItemSlice - 1;
                                            const cellofMenu = ws.getCell(ROW_SCHEDULE_START + 1, COL_START_MENU);
                                            COL_START_MENU++;
                                            const cellofScheduleRoom = ws.getCell(
                                                ROW_SCHEDULE_START + 1,
                                                COL_START_MENU,
                                            );
                                            cellofMenu.style = defaultStyleCell;
                                            cellofScheduleRoom.style = defaultStyleCell;
                                            cellofMenu.value = `${menu?.menuName} ${
                                                schedule?.type !== "Private" && menu?.students?.length
                                                    ? `\n(${menu?.students?.join(", ")})`
                                                    : ""
                                            }`;
                                            cellofScheduleRoom.value = `${menu?.teacherName}`;
                                            ROW_SCHEDULE_START++;
                                        });
                                    }
                                    ROW_SCHEDULE_START++;
                                });
                            }
                            rowOfScheduleArr.push(ROW_SCHEDULE_START); // + 1 is title schedule
                            COL_START_ITEM++;
                        });
                        // increment ROW
                        const maxLengthOfRowInSchedule = rowOfScheduleArr?.length
                            ? rowOfScheduleArr?.reduce((a, b) => (a >= b ? a : b), 0)
                            : 0;
                        META_ROW = maxLengthOfRowInSchedule + 1; // 3 is space between dates
                        metaRowOfScheduleArr.push(META_ROW); // + 1 is title schedule
                    });
                }
            }
            // increment ROW
            const maxLengthOfRowInSchedule = metaRowOfScheduleArr?.length
                ? metaRowOfScheduleArr?.reduce((a, b) => (a >= b ? a : b), 0)
                : 0;
            START_ROW = maxLengthOfRowInSchedule + 1; // 3 is space between dates
        });
        /**
         * @description `download excel`
         */
        const buffer = await wb.xlsx.writeBuffer();
        saveByteArray(`${options?.fileName || new Date().getTime()}.xlsx`, buffer);
    } catch (error) {
        if (error) {
            console.warn("[exportExcelShedule] : ", error?.message);
            throw error;
        }
    }
}

export default exportExcelShedule;
