import { useCallback, useState } from "react";

export const useForceRender = () => {
    // state
    const [value, setValue] = useState(false);
    // change state function
    const setValueFn = useCallback(() => {
        setValue(currentState => !currentState);
    }, []);
    return [value, setValueFn]; // update state to force render
    // An function that increment 👆🏻 the previous state like here
    // is better than directly setting `value + 1`
};
