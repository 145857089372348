import { Grid, Paper } from "@material-ui/core";
import React from "react";
import ContentMenu from "../../../components/MenuManagement/ContentMenu";

export default function Menu() {
    return (
        <Paper className="p-2 p-md-3 py-3 py-md-5">
            <Grid container spacing={0}>
                <Grid item xs={12} className="mb-3">
                    <h4>Menu</h4>
                </Grid>
                <Grid item xs={12}>
                    <ContentMenu />
                </Grid>
            </Grid>
        </Paper>
    );
}
