import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export default function Page404() {
    return (
        <div className="d-flex h-100">
            <div className="d-flex flex-column flex-root">
                <div
                    className="error error-4 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
                    style={{
                        background: `url(${toAbsoluteUrl("/media/error/bg4.jpg")}) no-repeat`,
                        backgroundSize: "cover",
                    }}>
                    <div className="d-flex flex-column flex-row-fluid align-items-center align-items-md-start justify-content-md-center text-center text-md-left px-10 px-md-30 py-10 py-md-0 line-height-xs">
                        <p className="error-subtitle text-primary font-weight-boldest mb-20">Oops...</p>
                        <p className="display-4 text-primary font-weight-boldest mt-md-0 line-height-md">
                            We are coming soon.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
