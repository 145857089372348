import { Grid } from "@material-ui/core";
import React from "react";
import CreateOrderContent from "../../components/CreateOrder/CreateOrderContent";

export default function CreateOrder() {
    return (
        <div className="p-2 p-lg-3'">
            <Grid container spacing={0}>
                <Grid item xs={12} lg={12}>
                    <CreateOrderContent />
                </Grid>
            </Grid>
        </div>
    );
}
