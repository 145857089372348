import axios from "axios";

export const axiosAuthInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_PATH}/`,
    withCredentials: true,
});

axiosAuthInstance.interceptors.request.use(
    config => {
        return config;
    },
    err => Promise.reject(err),
);

axiosAuthInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error?.response?.status === 401) {
            localStorage.clear();
            window.location.reload();
        }
        return Promise.reject(error);
    },
);
