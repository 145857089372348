import { Button, FormControl, FormLabel, Grid, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { MailOutline } from "@material-ui/icons";
import { Formik } from "formik";
import React from "react";
import { object, string } from "yup";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export default function ForgetPassword({ onSubmit, button }) {
    return (
        <div className="px-4">
            {/* begin::Head */}
            <div className="text-center mb-5 mb-lg-10">
                <div className="w-100">
                    <img
                        style={{ width: 100 }}
                        src={toAbsoluteUrl("/media/assets/images/logos/cottage.png")}
                        alt="logo-sweet-cottage"
                    />
                </div>
                <h4 className="font-size-h1">Please input your registered</h4>
                <h4 className="font-size-h1">E-mail address.</h4>
                {/* <h5 className="text-muted">Sweets Cottage Academy Management.</h5> */}
            </div>
            {/* end::Head */}
            <Formik
                initialValues={{ email: "" }}
                validationSchema={object({
                    email: string()
                        // eslint-disable-next-line
                        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]+$/g, "Invalid email format ex. example@email.com")
                        .required("Required."),
                })}
                onSubmit={onSubmit}>
                {({ values, handleChange, handleSubmit, errors }) => (
                    <Grid container spacing={0}>
                        <Grid item xs={12} className="py-3">
                            <FormControl fullWidth>
                                <FormLabel>Email</FormLabel>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="email"
                                    name="email"
                                    error={Boolean(errors?.email)}
                                    helperText={errors?.email}
                                    value={values?.email}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton disabled>
                                                    <MailOutline style={{ color: "#808080" }} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className="py-3">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-4 col-lg-3">
                                    {button ? (
                                        <Button className="t-btn-secondary w-100">Sending...</Button>
                                    ) : (
                                        <Button onClick={handleSubmit} className="t-btn-secondary w-100">
                                            Next
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                )}
            </Formik>
            {/* end::Form */}
        </div>
    );
}
