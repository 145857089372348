import { combineReducers } from "redux";
import { userReducers } from "./slices";
import { menuReducers } from "./slices/menuSlice";
import { mainMenuReducers } from "./slices/mainMenuSlice";

export const rootReducer = combineReducers({
    user: userReducers,
    mainMenu: mainMenuReducers,
    menu: menuReducers,
});

export function* rootSaga() {}
