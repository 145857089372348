import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "holidays";

const GET_ALL_HOLIDAYS_URL = `${prefix}/all`;
const GET_HOLIDAYS_BY_MONTH_URL = `${prefix}/get-by-month`;
const GET_HOLIDAYS_OPTION_URL = `${prefix}/get-option`;
const CREATE_HOLIDAYS_URL = `${prefix}/create`;
const UPDATE_HOLIDAYS_BY_ID_URL = `${prefix}/update`;
const DELETE_HOLIDAYS_BY_ID_URL = `${prefix}/delete`;

/**
 * @func getAllHolidays
 * @desc get all holidays data
 * @author jew
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} holidays array
 */
export const getAllHolidays = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_ALL_HOLIDAYS_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllHolidays");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getAllHolidays */

/**
 * @func getHolidaysByMonth
 * @desc get hoildays by month
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} hoildays by month
 */
export const getHolidaysByMonth = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_HOLIDAYS_BY_MONTH_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getHolidaysByMonth");
        if (err) throw err;
        return undefined;
    }
};

/**
 * @func getHolidaysOption
 * @desc get hoildays option
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Object} hoildays option
 */
export const getHolidaysOption = async signal => {
    try {
        const { data, status } = await axios.get(GET_HOLIDAYS_OPTION_URL, {
            signal,
        });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getHolidaysOption");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getHolidaysOption */

/**
 * @func createHolidays
 * @desc create new holidays
 * @author jew
 * @param {Object} body - create holidays body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} create status
 */
export const createHolidays = async (body, signal) => {
    try {
        const { status } = await axios.post(CREATE_HOLIDAYS_URL, body, { signal });
        if (status === 201) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "createHolidays");
        if (err) throw err;
        return undefined;
    }
};
/** End @func createHolidays */

/**
 * @func updateHolidaysById
 * @desc update holidays by id
 * @author jew
 * @param {number} id - holidays id
 * @param {Object} body - update holidays body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} update status
 */
export const updateHolidaysById = async (body, signal) => {
    try {
        const { status } = await axios.put(UPDATE_HOLIDAYS_BY_ID_URL, body, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "updateHolidaysById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func updateHolidaysById */

/**
 * @func deleteHolidaysById
 * @desc delete holidays by id
 * @author jew
 * @param {number} id - holidays id
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} delete status
 */
export const deleteHolidaysById = async (body, signal) => {
    try {
        const { status } = await axios.put(DELETE_HOLIDAYS_BY_ID_URL, body, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "deleteHolidaysById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func deleteHolidaysById */
