import { Box, Button, Grid, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Close, GetApp, RemoveRedEye, Search } from "@material-ui/icons";
import MaterialTable from "material-table";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { capitalize } from "lodash";
import { useSelector } from "react-redux";
import { ReactComponent as RefundIcon } from "./refund-svgrepo-com 2.svg";
import { getOptionsTableApp } from "../../helpers/useOption";
import {
    getColumnsOrderTransaction,
    getStatusBooking,
    getStatusPaid,
    initialReceiptData,
    initialValuesEditOrder,
    initialValuesRecieve,
    initialValuesRefund,
} from "./helpers/orderTransactionOption";
import {
    getAllOrder,
    getOrderOption,
    updateOrderById,
    getAllOrderForExcel,
    refundOrderById,
} from "../../../apis/orderApi";
import DatePickerApp from "../../componentsUtils/DatePickerApp";
import ReceiptPDF from "../CreateOrder/ReceiptPDF";
import { swalCondition } from "../../../utils/swal";
import FormRefund from "./FormRefund";
import exportToExcel from "../../../utils/exportToExcel";
import FormEditOrder from "./FormEditOrder";
import FormPayment from "./FormPayment";
import { currencyNoSymbol } from "../../../utils/formatCurrency";
import { getOrdinalSuffix } from "../../../utils/number";

export default function OrderTransactionContent() {
    // ref
    const tableRef = useRef(null);
    const controllerRef = useRef(null);

    // global redux state
    const { thisMenuPermission } = useSelector(state => {
        const { menuPermission } = state?.mainMenu;
        return {
            thisMenuPermission: menuPermission?.find(menu => menu?.path === window.location.pathname)?.permission,
        };
    });

    // component state
    const [dateStartEnd, setDateStartEnd] = useState([new Date(), new Date()]);
    const [modalForm, setModalForm] = useState(false);
    const [modalShowPdf, setModalShowPdf] = useState(false);
    const [modalRefund, setModalRefund] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [valuesSearch, setValuesSearch] = useState("");
    const [searchUser, setSearchUser] = useState("");
    const [searchStudent, setSearchStudent] = useState("");
    const [isExport, setIsExport] = useState(false);
    const [isPDF, setIsPDF] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tableOptions, setTableOptions] = useState(getOptionsTableApp);
    const [option, setOption] = useState({
        payment: [],
        course: [],
        menu: [],
    });
    const [initialPaymentFormValues, setInitialPaymentFormValues] = useState(initialValuesRecieve);
    const [initialRefundFormValues, setInitialRefundFormValues] = useState(initialValuesRefund);
    const [initialEditOrderFormValues, setInitialEditOrderFormValues] = useState(initialValuesEditOrder);
    const [receiptData, setReceiptData] = useState(initialReceiptData);

    // export excel
    const handleExportExcel = useCallback(async () => {
        setIsExport(true);
        try {
            const body = {
                search: valuesSearch || "",
                user: searchUser || "",
                student: searchStudent || "",
                start: dateStartEnd[0],
                end: dateStartEnd[1],
            };
            const res = await getAllOrderForExcel(body, controllerRef?.current?.signal);
            if (res) {
                const excel = [];
                for (const item of res) {
                    for (const [index, list] of item?.OrderLists?.entries()) {
                        excel.push({
                            Date: item?.date ? moment(item?.date).format("DD/MM/YYYY") : "",
                            "Booking NO.": item?.bookingNo || "",
                            Student: `${item?.Student?.studentFirstname} ${item?.Student?.studentLastname}` || "",
                            "Order Detail": list?.name || "",
                            Payment: index !== 0 ? "" : item?.PaymentMethods?.map(item => item?.name)?.join(", ") || "",
                            Package:
                                index !== 0
                                    ? ""
                                    : item?.PaymentMethods?.find(method => method?.paymentMethodId === 5)
                                          ?.OrderHasPayment?.payment || "",
                            SCB:
                                index !== 0
                                    ? ""
                                    : item?.PaymentMethods?.find(method => method?.paymentMethodId === 6)
                                          ?.OrderHasPayment?.payment || "",
                            "K Bank":
                                index !== 0
                                    ? ""
                                    : item?.PaymentMethods?.find(method => method?.paymentMethodId === 7)
                                          ?.OrderHasPayment?.payment || "",
                            BAY:
                                index !== 0
                                    ? ""
                                    : item?.PaymentMethods?.find(method => method?.paymentMethodId === 8)
                                          ?.OrderHasPayment?.payment || "",
                            "Credit Card":
                                index !== 0
                                    ? ""
                                    : item?.PaymentMethods?.find(method => method?.paymentMethodId === 3)
                                          ?.OrderHasPayment?.payment || "",
                            Kshop:
                                index !== 0
                                    ? ""
                                    : item?.PaymentMethods?.find(method => method?.paymentMethodId === 9)
                                          ?.OrderHasPayment?.payment || "",
                            ผ่อนผ่านบัตร:
                                index !== 0
                                    ? ""
                                    : item?.PaymentMethods?.find(method => method?.paymentMethodId === 10)
                                          ?.OrderHasPayment?.payment || "",
                            TTB:
                                index !== 0
                                    ? ""
                                    : item?.PaymentMethods?.find(method => method?.paymentMethodId === 11)
                                          ?.OrderHasPayment?.payment || "",
                            PAYPAL:
                                index !== 0
                                    ? ""
                                    : item?.PaymentMethods?.find(method => method?.paymentMethodId === 12)
                                          ?.OrderHasPayment?.payment || "",
                            UOB:
                                index !== 0
                                    ? ""
                                    : item?.PaymentMethods?.find(method => method?.paymentMethodId === 13)
                                          ?.OrderHasPayment?.payment || "",
                            Cash:
                                index !== 0
                                    ? ""
                                    : item?.PaymentMethods?.find(method => method?.paymentMethodId === 1)
                                          ?.OrderHasPayment?.payment || "",
                            Transfer:
                                index !== 0
                                    ? ""
                                    : item?.PaymentMethods?.find(method => method?.paymentMethodId === 2)
                                          ?.OrderHasPayment?.payment || "",
                            Other:
                                index !== 0
                                    ? ""
                                    : item?.PaymentMethods?.find(method => method?.paymentMethodId === 4)
                                          ?.OrderHasPayment?.payment || "",
                            Total: index !== 0 ? "" : item?.grandTotal || "",
                            "Paid Status": index !== 0 ? "" : getStatusPaid({ status: item?.status }) || "",
                            "Booking Status":
                                index !== 0 ? "" : getStatusBooking({ status: item?.bookingStatus }) || "",
                            User:
                                index !== 0
                                    ? ""
                                    : item?.UserCreate?.firstname && item?.UserCreate?.lastname
                                    ? `${item?.UserCreate?.firstname} ${item?.UserCreate?.lastname}`
                                    : "",
                        });
                    }
                }
                if (!excel?.length) return false;
                await exportToExcel(excel, {
                    fileName: "SCA_order_list",
                    sumColumn: [
                        "Package",
                        "SCB",
                        "K Bank",
                        "BAY",
                        "Credit Card",
                        "Kshop",
                        "ผ่อนผ่านบัตร",
                        "TTB",
                        "PAYPAL",
                        "UOB",
                        "Cash",
                        "Transfer",
                        "Other",
                        "Total",
                    ],
                });
            }
        } catch (error) {
            console.warn("ERR :", error?.message);
        } finally {
            setIsExport(false);
        }
    }, [dateStartEnd, valuesSearch, searchUser, searchStudent]);

    const handlePrintPDF = useCallback(() => {
        setIsPDF(true);
    }, []);

    const handleFinishPDF = useCallback(() => {
        setIsPDF(false);
    }, []);

    // close pdf modal
    const handleCloseModalPDF = useCallback(() => {
        setModalShowPdf(false);
    }, []);

    // open pdf modal
    const handleOpenModalPDF = useCallback(order => {
        setReceiptData({
            bookingNo: order?.bookingNo || "",
            status: order?.status || "",
            branchAddress: order?.Branch?.address || "",
            branchContact: order?.Branch?.contact || "",
            date: order?.date ? moment(order?.date).format("DD/MM/YYYY") : "",
            studentFullname:
                order?.Student?.studentFirstname || order?.Student?.studentLastname
                    ? `${order?.Student?.studentFirstname} ${order?.Student?.studentLastname}`
                    : "",
            studentNickname: order?.Student?.studentNickname || "",
            studentPhoneNumber: order?.Student?.studentTel || "",
            studentEmail: order?.Student?.studentEmail || "",
            total: currencyNoSymbol(order?.total || ""),
            discount: currencyNoSymbol(order?.discount || ""),
            grandTotal: currencyNoSymbol(order?.grandTotal || ""),
            // hasCash: cashPayment ? true : false,
            // hasTransfer: transferPayment ? true : false,
            // hasCreditCard: creditCardPayment ? true : false,
            // hasPackage: packagePayment ? true : false,
            // hasOther: otherPayment ? true : false,
            // cash: currencyNoSymbol(cashPayment || ""),
            // transfer: currencyNoSymbol(transferPayment || ""),
            // creditCard: currencyNoSymbol(creditCardPayment || ""),
            // package: currencyNoSymbol(packagePayment || ""),
            // other: currencyNoSymbol(otherPayment || ""),
            paid: currencyNoSymbol(order?.paid || ""),
            remaining: currencyNoSymbol(order?.remaining || ""),
            remark: order?.remark || "",
            cancelledDate: ["2", "4"].includes(order?.status) ? moment(order?.updatedAt).format("DD/MM/YYYY") : "",
            cancelledUser:
                order?.UserUpdate?.firstname || order?.UserUpdate?.lastname
                    ? `${order?.UserUpdate?.firstname} ${order?.UserUpdate?.lastname}`
                    : "",
            refund: ["4"].includes(order?.status) ? currencyNoSymbol(order?.refund || "") : "",
            refundNote: ["4"].includes(order?.status) ? order?.refundNote : "",
            createdBy:
                order?.UserCreate?.firstname || order?.UserCreate?.lastname
                    ? `${order?.UserCreate?.firstname} ${order?.UserCreate?.lastname}`
                    : "",
            collector:
                order?.UserCreate?.firstname || order?.UserCreate?.lastname
                    ? `${order?.UserCreate?.firstname} ${order?.UserCreate?.lastname}`
                    : "",
            orderList:
                order?.OrderLists?.map(item => ({
                    name: item?.name || "",
                    bookingDate: item?.bookingDate ? moment(item?.bookingDate).format("DD/MM/YYYY") : "",
                    bookingTime: item?.bookingTime || "",
                    quantity: item?.quantity || "",
                    price: currencyNoSymbol(item?.price || ""),
                    total: currencyNoSymbol(item?.price * item?.quantity || ""),
                })) || [],
            transactions:
                order?.OrderHasPayments?.map(item => ({
                    paymentDate: item?.createdAt ? moment(item?.createdAt).format("DD/MM/YYYY HH:mm") : "",
                    amount: currencyNoSymbol(item?.payment || ""),
                    paymentMethod: item?.PaymentMethod?.name || "",
                    status: Number(item?.status)
                        ? `${item?.status}${getOrdinalSuffix(Number(item?.status))} Payment`
                        : capitalize(item?.status),
                })) || [],
        });
        setModalShowPdf(true);
    }, []);

    // close modal payment form
    const handleClosePaymentModal = useCallback(() => {
        setModalForm(false);
    }, []);

    // open modal payment form
    const handleOpenPaymentModal = useCallback(order => {
        let studentBalance = "";
        if (order?.Student?.balance && order?.Student?.balanceExpired) {
            if (new Date(order?.Student?.balanceExpired) > new Date()) {
                studentBalance = order?.Student?.balance;
            }
        } else {
            studentBalance = order?.Student?.balance;
        }
        setInitialPaymentFormValues({
            order,
            paymentSelected: order?.payments?.map(item => ({
                paymentMethodId: item?.paymentMethodId,
                type: item?.name,
                name: item?.name,
                payment: "",
            })),
            prevPaymentSelected: order?.payments?.map(item => ({
                orderHasPaymentId: item?.OrderHasPayment?.orderHasPaymentId,
                paymentMethodId: item?.paymentMethodId,
                type: item?.name,
                name: item?.name,
                payment: item?.payment,
            })),
            studentBalance,
            totalAmount: order?.grandTotal || "",
            paid: order?.paid || "0.00",
            remaining: order?.remaining || "0.00",
        });
        setModalForm(true);
    }, []);

    // change search filter
    const handleChangeSearchFilter = useCallback(
        evt => {
            const value = evt.target?.value;
            setValuesSearch(value);
            tableRef.current.onQueryChange({ search: value, page: 0 });
        },
        [tableRef],
    );

    // change user filter
    const handleChangeUserFilter = useCallback(
        evt => {
            const value = evt.target?.value;
            setSearchUser(value);
            tableRef.current.onQueryChange({ user: value, page: 0 });
        },
        [tableRef],
    );

    // change student filter
    const handleChangeStudentFilter = useCallback(
        evt => {
            const value = evt.target?.value;
            setSearchStudent(value);
            tableRef.current.onQueryChange({ student: value, page: 0 });
        },
        [tableRef],
    );

    // change date filter
    const handleChangeDateFilter = useCallback(
        (date, indexField) => {
            setDateStartEnd(prev => prev.map((r, i) => (i === indexField ? date : r)));
            if (indexField === 0) {
                if (date.getTime() > dateStartEnd[1].getTime()) {
                    setDateStartEnd(prev => prev.map((r, i) => (i === 1 ? date : r)));
                }
            }
            tableRef.current.onQueryChange();
        },
        [setDateStartEnd, dateStartEnd],
    );

    // clear filter
    const handleClearFilter = useCallback(() => {
        setDateStartEnd([new Date(), new Date()]);
        setValuesSearch("");
        setSearchUser("");
        setSearchStudent("");
        tableRef.current.onQueryChange({ search: "", page: 0 });
    }, []);

    // edit order payment logic
    const handleEditOrderPayment = useCallback(async values => {
        try {
            const isConfirmed = await swalCondition("Confirm Payment?", "", {
                icon: "warning",
            });
            if (isConfirmed) {
                const orderHasPayment = values?.paymentSelected
                    ?.filter(item => item?.payment)
                    ?.map(item => ({
                        paymentMethodId: item?.paymentMethodId,
                        payment: item?.payment,
                    }));
                const updateBody = {
                    studentId: values?.order?.studentId,
                    paid: values?.paid,
                    remaining: values?.remaining,
                    status: values?.remaining > 0 ? (values?.paid > 0 ? "1" : "3") : "0",
                    orderHasPayment,
                };
                const isUpdate = await updateOrderById(
                    values?.order?.orderId,
                    updateBody,
                    controllerRef.current?.signal,
                );
                if (isUpdate) {
                    tableRef.current.onQueryChange();
                    setModalForm(false);
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // cancel order logic
    const handleCancelOrder = useCallback(async order => {
        try {
            const isConfirmed = await swalCondition("Confirm cancel?", "", {
                icon: "warning",
            });
            if (isConfirmed) {
                const isUpdate = await updateOrderById(order?.orderId, { status: "2" }, controllerRef.current?.signal);
                if (isUpdate) {
                    tableRef.current.onQueryChange();
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // open refund modal
    const handleOpenRefundModal = useCallback(order => {
        setInitialRefundFormValues({ order, refundValue: order?.packageUsages + order?.salesTotal });
        setModalRefund(true);
    }, []);

    // close refund modal
    const handleCloseRefundModal = useCallback(() => {
        setModalRefund(false);
    }, []);

    // refund order logic
    const handleRefundOrder = useCallback(async values => {
        try {
            const isConfirmed = await swalCondition("Confirm Refund?", "", {
                icon: "warning",
            });
            if (isConfirmed) {
                const isSuccess = await refundOrderById(
                    values?.order?.orderId,
                    { credit: parseFloat(values?.refundValue || "0.00"), note: values?.refundNote },
                    controllerRef.current?.signal,
                );
                if (isSuccess) {
                    tableRef.current.onQueryChange();
                    setModalRefund(false);
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // open edit order modal
    const handleOpenEditOrderModal = useCallback(
        order => {
            setInitialEditOrderFormValues({
                order,
                total: order?.total || "",
                discount: order?.discount || "",
                grandTotal: order?.grandTotal || "",
                paid: order?.paid || "",
                date: order?.date || "",
                remaining: order?.remaining || "",
                orderLists:
                    order?.OrderLists?.map(item => ({
                        orderListId: item?.orderListId,
                        value: item?.course
                            ? option?.course?.find(item2 => item2?.id === item?.course)
                            : item?.menu
                            ? option?.menu?.find(item2 => item2?.id === item?.menu)
                            : {},
                        type: item?.course ? "course" : item?.menu ? "menu" : "",
                        options: item?.course ? option?.course : item?.menu ? option?.menu : [],
                        bookingDate: item?.bookingDate,
                        bookingTime: item?.bookingTime ? moment(item?.bookingTime, "HH:mm:ss").toDate() : null,
                        price: item?.price,
                        quantity: item?.quantity,
                    })) || [],
                transactions:
                    order?.OrderHasPayments?.map(item => ({
                        paymentDate: item?.createdAt ? moment(item?.createdAt).format("DD/MM/YYYY HH:mm") : "",
                        amount: currencyNoSymbol(item?.payment || ""),
                        paymentMethod: item?.PaymentMethod?.name || "",
                        status: Number(item?.status)
                            ? `${item?.status}${getOrdinalSuffix(Number(item?.status))} Payment`
                            : capitalize(item?.status),
                    })) || [],
            });
            setModalEdit(true);
        },
        [option],
    );

    // close edit order modal
    const handleCloseEditOrderModal = useCallback(() => {
        setModalEdit(false);
    }, []);

    // edit order list
    const handleEditOrderList = useCallback(async values => {
        try {
            const isConfirmed = await swalCondition("Confirm Order?", "", {
                icon: "warning",
            });
            if (isConfirmed) {
                const orderList = values?.orderLists?.map(item => ({
                    orderListId: item?.orderListId,
                    bookingDate: item?.bookingDate,
                    bookingTime: item?.bookingTime ? moment(item?.bookingTime).format("HH:mm") : null,
                    name: item?.value?.label,
                    course: item?.type === "course" ? item?.value?.id : null,
                    menu: item?.type === "menu" ? item?.value?.id : null,
                    price: item?.price,
                }));
                const updateBody = {
                    total: values?.total,
                    grandTotal: values?.grandTotal,
                    remaining: values?.remaining,
                    status: Number(values?.paid) === 0 ? "3" : Number(values?.remaining) > 0 ? "1" : undefined,
                    orderList: orderList?.length ? orderList : [],
                };
                const isUpdate = await updateOrderById(
                    values?.order?.orderId,
                    updateBody,
                    controllerRef.current?.signal,
                );
                if (isUpdate) {
                    tableRef.current.onQueryChange();
                    setModalEdit(false);
                }
            }
        } catch (error) {
            console.dir(error);
            setModalEdit(false);
        }
    }, []);

    // fetch data
    const fetchData = useCallback(
        async query => {
            try {
                setIsLoading(true);
                const queryParams = {
                    page: query?.page + 1 || 1,
                    limit: query?.pageSize || 10,
                    search: query?.search || "",
                    user: searchUser || "",
                    student: searchStudent || "",
                    order: query?.orderBy?.field || "orderId",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    startDate: dateStartEnd[0] || "",
                    endDate: dateStartEnd[1] || "",
                    branchId: localStorage.getItem("currentBranchId") || undefined,
                };
                const res = await getAllOrder(queryParams, controllerRef.current?.signal);
                if (res) {
                    setTableOptions(currentState => ({
                        ...currentState,
                        currentPage: query?.page,
                        pageSize: query?.pageSize || 10,
                        totalData: res?.pagination?.totalData || 0,
                        order: query?.orderBy?.field || "timestamp",
                        direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    }));
                    setIsLoading(false);
                    return {
                        data: res?.data || [],
                        page: query?.page || 0,
                        totalCount: res?.pagination?.totalData || 0,
                    };
                }
                setIsLoading(false);
            } catch (error) {
                console.dir(error);
                setIsLoading(false);
                return {
                    data: [],
                    page: 0,
                    totalCount: 0,
                };
            }
        },
        [dateStartEnd, searchUser, searchStudent],
    );

    // fetch option data
    const fetchOption = useCallback(async signal => {
        try {
            const orderOption = await getOrderOption(signal);
            if (orderOption) {
                const opt = {
                    payment: orderOption?.paymentMethodOption?.map(item => ({
                        paymentMethodId: item?.paymentMethodId,
                        type: item?.name,
                        name: item?.name,
                    })),
                    course: orderOption?.courseOption?.map(item => ({
                        id: item?.id,
                        label: item?.courseName,
                        price: item?.price,
                    })),
                    menu: orderOption?.courseMenuOption?.map(item => ({
                        id: item?.id,
                        label: item?.courseMenuName,
                        price: item?.price,
                    })),
                };
                return opt;
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // memo
    const tableColumns = useMemo(() => getColumnsOrderTransaction({ tableOptions }), [tableOptions]);

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        const init = async () => {
            const orderOption = await fetchOption(controllerRef.current?.signal);
            if (orderOption) {
                setOption(currentState => ({
                    ...currentState,
                    ...orderOption,
                }));
            }
        };
        init();
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, [fetchOption]);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className="mb-3 mb-lg-5">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={10}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={2}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    className="fixed-h-lg"
                                    value={valuesSearch}
                                    onChange={handleChangeSearchFilter}
                                    placeholder="Search Order..."
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search className="text-muted" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    className="fixed-h-lg"
                                    value={searchUser}
                                    onChange={handleChangeUserFilter}
                                    placeholder="Search User..."
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search className="text-muted" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    className="fixed-h-lg"
                                    value={searchStudent}
                                    onChange={handleChangeStudentFilter}
                                    placeholder="Search Student..."
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search className="text-muted" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className="d-flex align-items-center justify-content-center flex-fill">
                                    <DatePickerApp
                                        className="flex-fill mb-3 fixed-h-lg"
                                        size="small"
                                        value={dateStartEnd[0]}
                                        onChange={date => {
                                            handleChangeDateFilter(date, 0);
                                        }}
                                    />
                                    <small className="mx-3 mb-3">To</small>
                                    <DatePickerApp
                                        className="flex-fill mb-3 fixed-h-lg"
                                        size="small"
                                        minDate={dateStartEnd[0]}
                                        value={dateStartEnd[1]}
                                        onChange={date => {
                                            handleChangeDateFilter(date, 1);
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <div className="d-flex">
                                    <Button
                                        size="large"
                                        className="btn t-btn-primary flex-fill flex-lg-grow-0 fixed-h-lg"
                                        onClick={handleClearFilter}>
                                        Clear
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <div className="d-flex justify-content-lg-end">
                            <Button
                                size="large"
                                disabled={isExport}
                                className="btn t-btn-primary flex-fill flex-lg-grow-0 fixed-h-lg"
                                onClick={handleExportExcel}>
                                {isExport ? "Loading..." : <GetApp style={{ fontSize: 22 }} />}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        options={tableOptions}
                        components={{ Container: Box }}
                        data={fetchData}
                        columns={tableColumns}
                        actions={[
                            {
                                icon: () => <RemoveRedEye />,
                                iconProps: { className: "action-view" },
                                tooltip: "View",
                                onClick: (event, rowData) => handleOpenModalPDF(rowData),
                            },
                            rowData => ({
                                icon: "edit",
                                iconProps: { className: "action-edit" },
                                tooltip: "Edit",
                                onClick: (event, rowData) => handleOpenEditOrderModal(rowData),
                                hidden:
                                    !thisMenuPermission?.edit ||
                                    ["2", "4"].includes(rowData?.status) ||
                                    rowData?.OrderLists?.filter(item => item?.package)?.length > 0,
                            }),
                            {
                                icon: "payment",
                                iconProps: { className: "action-edit" },
                                tooltip: "Payment",
                                onClick: (event, rowData) => handleOpenPaymentModal(rowData),
                                hidden: !thisMenuPermission?.edit,
                            },
                            rowData => ({
                                icon: "close",
                                iconProps: { className: "action-close" },
                                tooltip: "Cancel",
                                onClick: (event, rowData) => handleCancelOrder(rowData),
                                hidden: !thisMenuPermission?.delete || ["2", "4"].includes(rowData?.status),
                            }),
                            rowData => ({
                                icon: () => <RefundIcon />,
                                iconProps: { className: "action-refund" },
                                tooltip: "Refund",
                                onClick: (event, rowData) => handleOpenRefundModal(rowData),
                                hidden:
                                    !thisMenuPermission?.edit ||
                                    ["3", "4"].includes(rowData?.status) ||
                                    rowData?.OrderLists?.filter(item => item?.package)?.length > 0 ||
                                    rowData?.paid <= 0,
                            }),
                        ]}
                    />
                </div>
            </Grid>
            {/* modal */}
            <Grid item xs={12}>
                <Modal show={modalForm} scrollable centered onHide={handleClosePaymentModal}>
                    <Modal.Header>
                        <Modal.Title>Payment</Modal.Title>
                        <IconButton onClick={handleClosePaymentModal}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body>
                        <FormPayment
                            onCancel={handleClosePaymentModal}
                            onSubmit={handleEditOrderPayment}
                            initialFormValues={initialPaymentFormValues}
                            paymentOption={option.payment}
                        />
                    </Modal.Body>
                </Modal>
                <Modal show={modalShowPdf} size="xl" centered onHide={handleCloseModalPDF}>
                    <Modal.Header>
                        <Modal.Title />
                        <div>
                            <IconButton onClick={handlePrintPDF}>
                                <GetApp />
                            </IconButton>
                            <IconButton onClick={handleCloseModalPDF}>
                                <Close />
                            </IconButton>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="overflow-auto" style={{ maxWidth: "100%" }}>
                        <ReceiptPDF state={receiptData} autoPrint={isPDF} handleFinishPDF={handleFinishPDF} />
                    </Modal.Body>
                </Modal>
                <Modal show={modalRefund} scrollable centered onHide={handleCloseRefundModal}>
                    <Modal.Header>
                        <Modal.Title>Confirm cancel and refund?</Modal.Title>
                        <IconButton onClick={handleCloseRefundModal}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body>
                        <FormRefund
                            onCancel={handleCloseRefundModal}
                            onSubmit={handleRefundOrder}
                            initialFormValues={initialRefundFormValues}
                        />
                    </Modal.Body>
                </Modal>
                <Modal show={modalEdit} size="xl" scrollable centered onHide={handleCloseEditOrderModal}>
                    <Modal.Header>
                        <Modal.Title>Edit Order</Modal.Title>
                        <IconButton onClick={handleCloseEditOrderModal}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body>
                        <FormEditOrder
                            onCancel={handleCloseEditOrderModal}
                            onSubmit={handleEditOrderList}
                            initialFormValues={initialEditOrderFormValues}
                        />
                    </Modal.Body>
                </Modal>
            </Grid>
        </Grid>
    );
}
