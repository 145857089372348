/* eslint-disable  no-unused-vars */
import { Options, Column } from "material-table";
import React from "react";
import { object, string } from "yup";
import SwitchApp from "../../../componentsUtils/SwitchApp";

export const columnTableBranchManage = props => {
    /** @type Column */
    const columns = [
        {
            field: "id",
            title: "#",
            sorting: false,
            render: obj => (
                <div>{props?.tableOptions?.currentPage * props?.tableOptions?.pageSize + obj.tableData?.id + 1}</div>
            ),
        },
        {
            field: "branchCode",
            title: "Branch ID",
        },
        {
            field: "branchName",
            title: "Branch Name",
        },
        {
            field: "address",
            title: "Branch Address",
        },
        {
            field: "status",
            title: "Status",
            sorting: false,
            render: obj => <SwitchApp checked={obj?.status} onChange={e => props?.handleChangeStatus(e, obj?.id)} />,
        },
    ];
    const data = columns?.map((col, index) => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};

export const initialValuesBranch = {
    id: "",
    name: "",
    address: "",
    contact: "",
    branch: {},
};

export const validateSchemaBranch = object({
    id: string().required("Required."),
    name: string().required("Required."),
    address: string().required("Required."),
    contact: string().required("Required."),
});
