/* eslint-disable */
import React from "react";
import { Column } from "material-table";
import { convertDateTimeFromApi } from "../../../../utils/format";

export const columnsUsersLogs = props => {
    /** @type Column */

    const { tableOptions } = props;

    const columns = [
        {
            field: "id",
            title: "#",
            sorting: false,
            render: obj => <div>{tableOptions?.currentPage * tableOptions?.pageSize + obj.tableData?.id + 1}</div>,
        },
        {
            field: "timestamp",
            title: "Date Time",
            render: obj => <div>{convertDateTimeFromApi(obj.date)}</div>,
        },
        {
            field: "User.firstname",
            title: "User",
            render: obj => <div>{obj?.user}</div>,
        },
        {
            field: "action",
            title: "Action",
            render: obj => (
                <div>
                    {obj.action == 1 ? <div>Create</div> : obj.action == 2 ? <div>Update</div> : <div>Delete</div>}
                </div>
            ),
        },
        {
            field: "detail",
            title: "Detail",
        },
    ];
    const data = columns?.map(col => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};
