/* eslint-disable */
import React from "react";
import { Column } from "material-table";
import { Image } from "react-bootstrap";
import { array, boolean, number, object, string } from "yup";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { currencyTHB } from "../../../../utils/formatCurrency";
import SwitchApp from "../../../componentsUtils/SwitchApp";
import { toImageUrl } from "../../../../utils/format";

export const columnsCourse = props => {
    /** @type Column */
    const columns = [
        {
            field: "id",
            title: "#",
            sorting: false,
            render: obj => (
                <div>{props?.tableOptions?.currentPage * props?.tableOptions?.pageSize + obj.tableData?.id + 1}</div>
            ),
        },
        {
            field: "image",
            title: "Image",
            sorting: false,
            render: obj => (
                <div className="d-flex align-items-center justify-content-center">
                    <Image
                        src={
                            obj?.imagePath
                                ? toImageUrl(obj?.imagePath)
                                : toAbsoluteUrl("/media/assets/images/noImage/non-menu.png")
                        }
                        className="image-products-app"
                        onError={e =>
                            e.target.setAttribute("src", toAbsoluteUrl("/media/assets/images/noImage/non-menu.png"))
                        }
                        alt={obj?.imagePath}
                    />
                </div>
            ),
        },
        {
            field: "courseName",
            title: "Course name",
        },
        {
            field: "courseType",
            title: "Type",
            render: obj => (
                <div>{obj?.courseType === "1" ? "Free Schedule" : obj?.courseType === "2" ? "Group" : ""}</div>
            ),
        },
        {
            field: "courseLimitedStudent",
            title: "Limited Stu.",
        },
        {
            field: "price",
            title: "Price",
            render: obj => <div>{currencyTHB(obj?.price)}</div>,
        },
        {
            field: "status",
            title: "Status",
            sorting: false,
            render: obj => <SwitchApp checked={obj?.status} onChange={e => props?.handleChangeStatus(e, obj?.id)} />,
        },
    ];
    columns?.forEach(col => {
        col.cellStyle = { textAlign: "center" };
    });
    return columns;
};

export const initialValuesSearchCourse = {
    search: "",
    type: "",
    status: "",
    rangeprice: [0, 300000],
};

export const validateSchemaSearchCourse = object({
    search: string(),
    type: string(),
    status: boolean(),
    rangeprice: array(),
});

export const initialValuesCourse = {
    name: "",
    price: "",
    limitStd: "0",
    description: "",
    type: "",
    tag: [],
    durationHr: "",
    durationMin: "",
    imagePath: "",
    image: {
        id: "",
        name: "",
        base64: "",
    },
    listMenuName: [],
    listMenuNameDelete: [],
};

export const validateSchemaCourse = object({
    name: string()
        .max(255)
        .required("Required."),
    limitStd: string()
        .matches(/^[0-9]+$/g, "Number only.")
        .max(5)
        .required("Required."),
    price: string()
        .matches(/^[0-9]+$/g, "Number only.")
        .max(10)
        .required("Required."),
    description: string().max(255),
    tag: array(),
    durationHr: string(),
    durationMin: string(),
    type: string()
        .max(255)
        .required("Required."),
    image: object({ id: string(), name: string(), base64: string() }),
    listMenuName: array().of(object({ id: number(), courseMenuName: string() }).required("Required.")),
    listMenuNameDelete: array().of(object({ id: number(), courseMenuName: string() })),
});
