import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "upload";

const UPLOAD_IMAGE_URL = `${prefix}/upload-image`;
const UPLOAD_IMAGE_FILE_URL = `${prefix}/upload-file-image`;

/**
 * Start
 * @func uploadImage
 * @desc upload image
 * @author izeberg
 * @param {string} imageBase64 - base64 string
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {string} image path
 */
export const uploadImage = async (imageBase64, signal) => {
    try {
        const { data, status } = await axios.post(
            UPLOAD_IMAGE_URL,
            { imageBase64 },
            {
                signal,
            },
        );
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "uploadImage");
        if (err) throw err;
        return undefined;
    }
};
/** End @func uploadImage */

/**
 * Start
 * @func uploadImageFile
 * @desc upload image file
 * @author kop_ter
 * @param {File} image - File
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {string} File name
 */
export const uploadImageFile = async (file, signal) => {
    try {
        const form = new FormData();
        form.append("image", file);

        const { data, status } = await axios.post(
            UPLOAD_IMAGE_FILE_URL,

            form,
            {
                signal,
            },
            {
                headers: {
                    accept: "application/json",
                    "Accept-Language": "en-US,en;q=0.8",
                    "Content-Type": `multipart/form-data; boundary=${file._boundary}`,
                },
            },
        );

        if (status === 200) {
            return data.data;
        }
    } catch (error) {
        const err = apiErrorHandler(error, "uploadImageFile");
        if (err) throw err;
        return undefined;
    }
};
