/* eslint-disable */
import { FormControl, FormHelperText, makeStyles, MenuItem, Select } from "@material-ui/core";
import React from "react";

const useStyle = makeStyles(theme => ({
    formControl: {
        position: "relative",
        backgroundColor: "#fff",
        borderRadius: 5,
        // "& .MuiOutlinedInput-inputMarginDense": {
        //     paddingTop: 15,
        //     paddingBottom: 15,
        // },
        // '& .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':{
        //     borderColor: 'rgba(0, 0, 0, 0.23)',
        //     borderWidth: 1
        // },
        "& .MuiSelect-icon": {
            color: "inherit",
            top: "calc(50% - 10px)",
        },
        "& .MuiInputBase-root .MuiSelect-root": {
            position: "relative",
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-marginDense": {
            minHeight: 40.5,
        },
        "& .MuiInputBase-root.placeholder .MuiSelect-root::before": {
            content: "attr(textplaceholder)",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            textAlign: "start",
            color: "#dbdbdb",
        },
        "& .MuiInputBase-root": {
            color: "inherit",
            overflow: "hidden",
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderWidth: 1,
        },
        "& .MuiFormHelperText-root": {
            position: "absolute",
            left: 0,
            bottom: -20,
            width: "100%",
            marginLeft: 0,
            marginRigth: 0,
            // '&.Mui-error':{
            //     color:'red'
            // }
        },
    },
}));

/**
 * @function SelectApp
 * @type React.SelectHTMLAttributes
 * @param value string
 * @param onChange callback value
 * @param options []
 * @param RenderItem callback value ( option , index )
 * @param placeholder string
 * @returns
 */
export default function SelectApp({
    value = "",
    onChange = () => {},
    options = [],
    RenderItem = (/** @type Object */ option, /** @type Number */ index) => <MenuItem></MenuItem>,
    placeholder = "",
    helperText = "",
    error = false,
    containerClass = "",
    selectClass = "",
    size = "small",
    disabled = false,
}) {
    const classes = useStyle();
    const handleChange = (/** @type React.ChangeEvent<{name?: string | undefined;value: unknown;}> */ event) => {
        onChange(event.target.value);
    };
    return (
        <FormControl size={size} fullWidth className={`${classes.formControl} ${containerClass}`}>
            <Select
                variant="outlined"
                value={value}
                onChange={handleChange}
                MenuProps={{
                    anchorOrigin: { vertical: "bottom", horizontal: "left" },
                    getContentAnchorEl: null,
                }}
                error={error}
                className={`${
                    (!value || value === "") && (placeholder || placeholder === "") ? "placeholder" : ""
                } ${selectClass}`}
                SelectDisplayProps={{ textplaceholder: placeholder }}
                disabled={disabled}>
                {placeholder !== "" && placeholder && (
                    <MenuItem value="">
                        <em className="text-muted">{placeholder}</em>
                    </MenuItem>
                )}
                {options?.map((option, index) => RenderItem(option, index))}
            </Select>
            {error ? <FormHelperText {...(error ? { error: true } : {})}>{helperText}</FormHelperText> : null}
        </FormControl>
    );
}
