/* eslint no-unused-vars: */
import React from "react";
import { Chip } from "@material-ui/core";
import { Column } from "material-table";
import moment from "moment";
import { convertDateTimeFromApi } from "../../../../utils/format";
import { currencyTHB } from "../../../../utils/formatCurrency";

export const getColumnsTablePackageReport = () => {
    /** @type Column */
    const column = [
        {
            field: "branchName",
            title: "Branch",
        },
        {
            field: "packageName",
            title: "Package Name",
        },
        {
            field: "period",
            title: "Period",
        },
        {
            field: "studentName",
            title: "Student",
        },
        {
            field: "purchasedDate",
            title: "Purchased Date",
        },
        {
            field: "expiredDate",
            title: "Expired Date",
        },
        {
            field: "price",
            title: "Price",
            render: rowData => currencyTHB(rowData?.price || 0),
        },
        {
            field: "credit",
            title: "Credit",
            render: rowData => currencyTHB(rowData?.credit || 0),
        },
    ];
    const data = column?.map((col, index) => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};
