import React from "react";
import { object, string } from "yup";
import SwitchApp from "../../../componentsUtils/SwitchApp";
import { convertDateTimeFromApi } from "../../../../utils/format";

export const columnTableUsers = props => {
    /** @type Column */
    const columns = [
        {
            field: "id",
            title: "#",
            sorting: false,
            render: obj => (
                <div>{props?.tableOptions?.currentPage * props?.tableOptions?.pageSize + obj.tableData?.id + 1}</div>
            ),
        },
        {
            field: "firstname",
            title: "Name",
            render: obj => (
                <div>
                    {obj?.firstname} {obj?.lastname}
                </div>
            ),
        },
        {
            field: "Role.roleName",
            title: "Role",
            render: obj => (!obj?.isAdmin ? <div>{obj?.roleName}</div> : "Administrator"),
        },
        {
            field: "Branch.branchName",
            title: "Branch",
            render: obj => (!obj?.isAdmin ? <div>{obj?.branchName}</div> : "All Branch"),
        },
        {
            field: "lastLogin",
            title: "Last login",
            render: obj => convertDateTimeFromApi(obj?.lastLogin),
        },
        {
            field: "status",
            title: "Status",
            sorting: false,
            render: obj =>
                !obj?.isAdmin ? (
                    <SwitchApp checked={obj?.status} onChange={e => props?.handleChangeStatus(e, obj?.id)} />
                ) : null,
        },
    ];
    const data = columns?.map(col => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};

export const initialValuesUsers = {
    fname: "",
    lname: "",
    email: "",
    password: "",
    branchId: "",
    roleId: "",
    user: {},
};

export const getValidateSchemaUsers = isCreateMode => {
    return object({
        fname: string()
            .max(255)
            .required("Required."),
        lname: string()
            .max(255)
            .required("Required."),
        email: string()
            .email("Must be a valid email ex: example@email.com")
            .max(255)
            .required("Required."),
        password: isCreateMode
            ? string()
                  .max(255)
                  .required("Required.")
            : string().max(255),
        branchId: string()
            .max(255)
            .required("Required."),
        roleId: string()
            .max(255)
            .required("Required."),
    });
};
