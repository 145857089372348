import { Box, Button, FormControl, Grid, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Add, Close, Search } from "@material-ui/icons";
import MaterialTable from "material-table";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Modal, ModalTitle } from "react-bootstrap";
import { useSelector } from "react-redux";
import { createPackage, deletePackageById, getAllPackage, updatePackageById } from "../../../apis/packageApi";
import { swalCondition } from "../../../utils/swal";
import { getOptionsTableApp } from "../../helpers/useOption";
import FormPackage from "./FormPackage";
import { columnTablePackage, initialValuesPackage } from "./helpers/packageOption";

export default function ContentPackage() {
    // ref
    const tableRef = useRef(null);
    const controllerRef = useRef(null);

    // global redux state
    const { thisMenuPermission } = useSelector(state => {
        const { menuPermission } = state?.mainMenu;
        return {
            thisMenuPermission: menuPermission?.find(menu => menu?.path === window.location.pathname)?.permission,
        };
    });

    // component state
    const [modalForm, setModalForm] = useState(false);
    const [valuesSearch, setValuesSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isCreateMode, setIsCreateMode] = useState(false);
    const [tableOptions, setTableOptions] = useState(getOptionsTableApp);
    const [initialFormValues, setInitialFormValues] = useState(initialValuesPackage);

    // close modal
    const handleCloseModal = useCallback(() => {
        setModalForm(false);
        setInitialFormValues(initialValuesPackage);
    }, []);

    // open modal create form
    const handleOpenCreateFormModal = useCallback(() => {
        setIsCreateMode(true);
        setInitialFormValues(initialValuesPackage);
        setModalForm(true);
    }, []);

    // open modal edit form
    const handleOpenEditFormModal = useCallback(pkg => {
        setIsCreateMode(false);
        setModalForm(true);
        setInitialFormValues(currentState => ({
            ...currentState,
            name_package: pkg?.packageName,
            credit: parseInt(pkg?.credit),
            price: parseInt(pkg?.price),
            expired_period: pkg?.expiredPeriod,
            description: pkg?.description,
            pkg,
        }));
    }, []);

    // search input
    const handleOnchangeSearch = useCallback(
        evt => {
            const value = evt.target?.value;
            setValuesSearch(value);
            tableRef.current.onQueryChange({ search: value, page: 0 });
        },
        [tableRef],
    );

    // fetch data
    const fetchData = useCallback(async query => {
        try {
            setIsLoading(true);
            const queryParams = {
                page: query?.page + 1 || 1,
                limit: query?.pageSize || 10,
                search: query?.search || "",
                order: query?.orderBy?.field || "id",
                direction: query?.orderBy?.field ? query?.orderDirection : "desc",
            };
            const res = await getAllPackage(queryParams, controllerRef.current?.signal);
            if (res) {
                setTableOptions(currentState => ({
                    ...currentState,
                    currentPage: query?.page,
                    pageSize: query?.pageSize || 10,
                    totalData: res?.pagination?.totalData || 0,
                    order: query?.orderBy?.field || "timestamp",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                }));
                setIsLoading(false);
                return {
                    data: res?.data || [],
                    page: query?.page || 0,
                    totalCount: res?.pagination?.totalData || 0,
                };
            }
            setIsLoading(false);
        } catch (error) {
            console.dir(error);
            setIsLoading(false);
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
    }, []);

    // create package logic
    const handleCreatePackage = useCallback(async values => {
        try {
            const createBody = {
                packageName: values?.name_package,
                credit: values?.credit,
                price: values?.price,
                expiredPeriod: values?.expired_period,
                description: values?.description,
            };
            const isCreate = await createPackage(createBody, controllerRef.current?.signal);
            if (isCreate) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // edit package logic
    const handleEditPackage = useCallback(async values => {
        try {
            const updateBody = {
                packageName: values?.name_package,
                credit: values?.credit,
                price: values?.price,
                expiredPeriod: values?.expired_period,
                description: values?.description,
            };
            const isUpdate = await updatePackageById(values?.pkg?.id, updateBody, controllerRef.current?.signal);
            if (isUpdate) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // change status logic
    const handleChangeStatus = useCallback(async (e, classroomId) => {
        try {
            const isUpdateStatus = await updatePackageById(
                classroomId,
                { status: e.target?.checked },
                controllerRef.current?.signal,
            );
            if (isUpdateStatus) {
                tableRef.current.onQueryChange();
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // delete package logic
    const handleDeletePackage = useCallback(async pkg => {
        try {
            const bool = await swalCondition("Confirm delete ?", pkg?.packageName, {
                icon: "warning",
            });
            if (bool) {
                const isDelete = await deletePackageById(pkg?.id, controllerRef.current?.signal);
                if (isDelete) {
                    tableRef.current.onQueryChange();
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // memo
    const tableColumns = useMemo(() => columnTablePackage({ handleChangeStatus, tableOptions }), [
        handleChangeStatus,
        tableOptions,
    ]);

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, []);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className="mb-3 mb-md-5">
                <div className="d-md-flex align-items-center justify-content-between">
                    <div className="mb-3 mb-md-0">
                        <FormControl style={{ height: "100%" }}>
                            <TextField
                                size="small"
                                variant="outlined"
                                value={valuesSearch}
                                onChange={handleOnchangeSearch}
                                placeholder="Search..."
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search className="text-muted" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </div>
                    {thisMenuPermission?.create && (
                        <div className="d-flex align-items-center">
                            <Button
                                onClick={handleOpenCreateFormModal}
                                size="large"
                                className="btn btn-lg t-btn-primary">
                                <Add /> Create Package
                            </Button>
                        </div>
                    )}
                </div>
            </Grid>

            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        data={fetchData}
                        options={tableOptions}
                        columns={tableColumns}
                        components={{ Container: Box }}
                        actions={[
                            {
                                icon: "edit",
                                iconProps: { className: "action-edit" },
                                tooltip: "Edit",
                                onClick: (event, rowData) => handleOpenEditFormModal(rowData),
                                hidden: !thisMenuPermission?.edit,
                            },
                            {
                                icon: "delete",
                                iconProps: { className: "action-delete" },
                                tooltip: "Delete",
                                onClick: (event, rowData) => handleDeletePackage(rowData),
                                hidden: !thisMenuPermission?.delete,
                            },
                        ]}
                    />
                </div>
            </Grid>

            <Grid item xs={12}>
                <Modal show={modalForm} scrollable centered onHide={handleCloseModal}>
                    <Modal.Header>
                        <ModalTitle>{isCreateMode ? "Create" : "Edit"} Package</ModalTitle>
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body>
                        <FormPackage
                            onCancel={handleCloseModal}
                            onSubmit={isCreateMode ? handleCreatePackage : handleEditPackage}
                            initialFormValues={initialFormValues}
                        />
                    </Modal.Body>
                </Modal>
            </Grid>
        </Grid>
    );
}
