import Swal from "sweetalert2";
import { utils, writeFile, read } from "xlsx";

/**
 * Start
 * @func exportExcelUtimate
 * @desc export data to excel format
 * @author march
 * @param data - ข้อมูลที่จะ export ([{},{},{}]) , ([[{},{},{}]])
 * @param fileName - ชื่อไฟล์ที่ทำการ export ("HelloWorld") (["HelloWorld","HelloWorld"])
 * @param colWidth - ชื่อไฟล์ที่ทำการ ขนาดความยาวของแต่ละ column ของแต่ละ sheet ([{},{},{}]) , ([[{},{},{}]]) [
                { width: 20 },
                { width: 15 },
                { width: 15 },
                { width: 25 },
                { width: 25 },
                { width: 30 },
                { width: 25 },
                { width: 10 },
                { width: 15 },
                { width: 15 }
            ]
 * @param highligh - key ที่ต้องการจะ highligh ["A1","B2","K3"]
 * @returns undefined
 */
export const exportExcelUtimate = (data = [], fileName = [], colWidth = []) => {
    if (Array.isArray(data) && !Array.isArray(data[0])) {
        // Convert array of objects to array of arrays of objects
        data = [data];
    }
    if (Array.isArray(colWidth) && !Array.isArray(colWidth[0])) {
        // Convert array of objects to array of arrays of objects
        colWidth = [colWidth];
    }
    if (typeof fileName === "string" && fileName && typeof fileName !== "object") {
        fileName = [fileName];
    }

    if (data?.length) {
        const wb = utils.book_new();
        let name = 1;
        for (let i = 0; i < data?.length; i += 1) {
            const header = Object.keys(data[i][0]);
            const ws = utils.json_to_sheet(data[i], { header, skipHeader: false });

            // if input colWidth
            if (colWidth[i]?.length > 0) {
                ws["!cols"] = colWidth[i];
            } else {
                const defaultColWidth = 12;
                const numColumns = header.length;
                ws["!cols"] = Array(numColumns).fill({ width: defaultColWidth });
            }

            // if no input file name
            if (!fileName[i] && i === 0) {
                fileName[i] = "exports";
            } else if (!fileName[i]) {
                fileName[i] = `data ${name}`;
                name += 1;
            }

            utils.book_append_sheet(wb, ws, fileName[i]);
        }
        writeFile(wb, `${fileName[0]}.xlsx`);
        return true;
    }
    return false;
};
/** End @func exportExcelUtimate */

export const SwalFileImport = async () => {
    return Swal.fire({
        heightAuto: false,
        title: "Select file",
        html: "You can upload a model for your work here",
        input: "file",
        showCancelButton: true,
        customClass: {
            confirmButton: "swal-confirm-button",
            cancelButton: "swal-cancel-button",
        },
        inputAttributes: {
            accept: ".xlsx",
            "aria-label": "Upload your xlsx file",
        },
    }).then(result => {
        if (result?.isConfirmed) {
            return { file: result?.value };
        }
        return { file: undefined };
    });
};

export const xlsxFileReader = async file => {
    return new Promise((resolve, reject) => {
        // new instance.
        const reader = new FileReader();
        // read file
        reader.readAsArrayBuffer(file);
        // on loaded
        reader.onload = async e => {
            const data = e.target.result;
            const workbook = read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const header = [];
            const json = utils.sheet_to_json(worksheet, {
                // header: ["no", "Type", "SubType", "Description"]
            });
            // total column
            const columnCount = utils.decode_range(worksheet["!ref"]).e.c + 1;
            // append header name.
            for (let i = 0; i < columnCount; i += 1) {
                header[i] = worksheet[`${utils.encode_col(i)}1`]?.v;
            }
            resolve({
                data: json,
                fileName: sheetName,
                author: workbook?.Props?.Author,
                lastAuthor: workbook?.Props?.LastAuthor,
                header,
                error: false,
            });
        };
        // on false condition.
        reader.onerror = () => {
            const reason = { data: undefined, fileName: "", header: [], author: "", lastAuthor: "", error: true };
            reject(reason);
        };
    });
};

/**
 * Start
 * @func difference
 * @desc find difference value in array
 * @author G
 * @param {Array} setA
 * @param {Array} setB
 * @returns {Array} differ value in array.
 */
export const difference = (setA, setB) => {
    const _difference = new Set(setA);
    for (const elem of setB) {
        _difference.delete(elem);
    }
    return _difference;
};
