import { Button, FormControl, FormLabel, Grid, TextField } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import TextareaApp from "../../componentsUtils/TextareaApp";
import { validateSchemaCategory } from "./helpers/categoryOption";

export default function FormCategory({ onCancel, onSubmit, initialFormValues }) {
    return (
        <Formik initialValues={initialFormValues} validationSchema={validateSchemaCategory} onSubmit={onSubmit}>
            {({ values, errors, handleChange, handleSubmit }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12} className="mb-3">
                        <FormControl fullWidth>
                            <FormLabel>
                                {" "}
                                Skill Name <span className="text-danger">*</span>{" "}
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                type="text"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                error={Boolean(errors.name)}
                                helperText={errors.name}
                                placeholder="Skill Name"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className="mb-3">
                        <FormControl fullWidth>
                            <FormLabel>Description</FormLabel>
                            <TextareaApp
                                props={{
                                    textAreaProps: {
                                        rows: 5,
                                        id: "description",
                                        value: values?.description,
                                        onChange: handleChange,
                                    },
                                }}
                                errorMessage={errors?.description}
                            />
                        </FormControl>
                    </Grid>
                    {/* action */}
                    <Grid item xs={12}>
                        <div className="d-flex justify-content-md-end align-items-center">
                            <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                                <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                            </Button>
                            <Button onClick={handleSubmit} className="btn t-btn-secondary">
                                <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
}
