import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
    namemenu: "22222",
    menuopen: false,
};

export const menuSlice = createSlice({
    name: "menu",
    initialState: initialUserState,
    reducers: {
        setMenuname: (state, action) => {
            state.namemenu = action.payload;
        },
        setMenuclose: (state, action) => {
            state.menuopen = action.payload;
        },
    },
});

export const menuReducers = menuSlice.reducer;
export const menuActions = menuSlice.actions;
