/* eslint-disable  no-unused-vars */
import React from "react";
import { Column } from "material-table";
import { Avatar } from "@material-ui/core";
import { date, object, string } from "yup";
import { convertDateTimeFromApi, toImageUrl } from "../../../../utils/format";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export const getToKnowFromOption = [
    { title: "Facebook", value: "1" },
    { title: "Google", value: "2" },
    { title: "Line", value: "3" },
    { title: "Tiktok", value: "4" },
    { title: "Youtube", value: "5" },
    { title: "เพื่อนแนะนำ", value: "6" },
    { title: "อื่นๆ", value: "7" },
];

export const levelOfExpertise = [
    {
        label: "Never bake before",
        value: "1",
    },
    {
        label: "Beginner",
        value: "2",
    },
    {
        label: "Intermediate",
        value: "3",
    },
    {
        label: "Expert",
        value: "4",
    },
];

export const socialMediaPermissionOption = [
    { title: "Yes", value: "1" },
    { title: "No", value: "0" },
];

export const columnTableStudents = props => {
    /** @type Column */
    const columns = [
        {
            field: "id",
            title: "#",
            sorting: false,
            render: obj => (
                <div>{props?.tableOptions?.currentPage * props?.tableOptions?.pageSize + obj.tableData?.id + 1}</div>
            ),
        },
        {
            field: "studentImagePath",
            title: "Image",
            sorting: false,
            render: obj => (
                <div className="d-flex justify-content-center align-items-center">
                    <Avatar
                        src={
                            obj?.studentImagePath
                                ? toImageUrl(obj?.studentImagePath)
                                : toAbsoluteUrl("/media/users/blank.png")
                        }
                        alt="Student"
                    />
                </div>
            ),
        },
        {
            field: "studentFirstname",
            title: "Firstname",
        },
        {
            field: "studentLastname",
            title: "Lastname",
        },
        {
            field: "studentNickname",
            title: "Nickname",
        },
        {
            field: "studentEmail",
            title: "Email",
        },
        {
            field: "studentTel",
            title: "Tel",
        },
        {
            field: "studentLineid",
            title: "Line ID",
        },
        {
            field: "status",
            title: "Status",
            render: obj => {
                let checkSt = { class: "text-danger", title: "Inactive" };
                if (obj?.status === "1") {
                    checkSt = { class: "text-success", title: "Active" };
                }
                return (
                    <div
                        role="button"
                        className={checkSt.class}
                        onClick={val => props?.handleChangeStatus({ obj, val })}>
                        {" "}
                        {checkSt.title}{" "}
                    </div>
                );
            },
        },
    ];
    const data = columns.map((col, index) => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};

export const columnTableLog = props => {
    /** @type Column */
    const { tableOptions } = props;
    const columns = [
        {
            field: "index",
            title: "#",
            sorting: false,
            render: obj => <div>{tableOptions?.currentPage * tableOptions?.pageSize + obj.tableData?.id + 1}</div>,
        },
        {
            field: "timestamp",
            title: "Date Time",
            render: obj => <div>{convertDateTimeFromApi(obj.date)}</div>,
        },
        {
            field: "User.firstname",
            title: "User",
            render: obj => <div>{obj.user}</div>,
        },
        {
            field: "action",
            title: "Action",
            render: obj => (
                <div>
                    {obj?.action === "1" ? (
                        <div>Create</div>
                    ) : obj?.action === "2" ? (
                        <div>Update</div>
                    ) : obj?.action === "3" ? (
                        <div>Delete</div>
                    ) : (
                        ""
                    )}
                </div>
            ),
        },
        {
            field: "detail",
            title: "Detail",
        },
    ];
    const data = columns?.map((col, index) => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};

export const initialValuesStudent = {
    fname: "",
    lname: "",
    nickname: "",
    classOf: "",
    email: "",
    tel: "",
    lineId: "",
    birthday: new Date(),
    address: "",
    province: "",
    country: "",
    expertise: "",
    sizeApron: "",
    studentNote: "",
    learnObjt: "",
    knowChannels: "",
    allowSocial: "",
    studentImagePath: "",
    image: {
        id: "",
        name: "",
        base64: "",
    },
};

export const validationSchemaStudent = object({
    fname: string().required("Required."),
    lname: string().required("Required."),
    nickname: string(),
    classOf: string(),
    email: string()
        // eslint-disable-next-line
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]+$/g, "ex: example@email.com"),
    tel: string()
        .matches(/^[0-9]+$/g, "Number only.")
        .max(15, "Max 15 Character")
        .required("Required."),
    lineId: string(),
    birthday: date(),
    address: string(),
    province: string(),
    country: string(),
    expertise: string(),
    sizeApron: string(),
    studentNote: string(),
    learnObjt: string(),
    knowChannels: string(),
    allowSocial: string(),
});
