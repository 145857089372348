import { Box, Grid, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import MaterialTable from "material-table";
import React, { useEffect, useCallback, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
    createCourseMenu,
    deleteCourseMenu,
    getAllCourseMenu,
    getCourseMenuOption,
    updateCourseMenu,
} from "../../../apis/courseMenuApi";
import { uploadImageFile } from "../../../apis/uploadApi";
import { swalCondition } from "../../../utils/swal";
import { getOptionsTableApp } from "../../helpers/useOption";
import FormMenu from "./FormMenu";
import { columnsMenu, initialValuesFormMenu } from "./helpers/menuOption";
import { getAllTagV2 } from "../../../apis/tagApi";
import SearchMenu from "./SearchMenu";

export default function ContentMenu() {
    // global redux state
    const { thisMenuPermission } = useSelector(state => {
        const { menuPermission } = state?.mainMenu;
        return {
            thisMenuPermission: menuPermission?.find(menu => menu?.path === window.location.pathname)?.permission,
        };
    });

    // ref
    const tableRef = useRef(null);
    const controllerRef = useRef(null);

    // component state
    const [modalForm, setModalForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tableOptions, setTableOptions] = useState({ ...getOptionsTableApp, pageSize: 100 });
    const [initialFormValues, setInitialFormValues] = useState(initialValuesFormMenu);
    const [isCreateMode, setIsCreateMode] = useState(false);
    const [option, setOption] = useState({ category: [] });

    // close modal
    const handleCloseModal = useCallback(() => {
        setModalForm(false);
        setInitialFormValues(initialValuesFormMenu);
    }, []);

    // open modal create form
    const handleOpenCreateFormModal = useCallback(() => {
        setIsCreateMode(true);
        setInitialFormValues(initialValuesFormMenu);
        setModalForm(true);
    }, []);

    // open modal edit form
    const handleOpenEditFormModal = useCallback(courseMenu => {
        setIsCreateMode(false);
        setModalForm(true);
        setInitialFormValues(currentState => ({
            ...currentState,
            name: courseMenu?.courseMenuName,
            price: Number(courseMenu?.price),
            category: courseMenu?.courseCategoryId,
            description: courseMenu?.description,
            imagePath: courseMenu?.imagePath,
            tag: courseMenu?.CourseMenuHasTags?.map(courseTag => courseTag?.tagId),
            courseMenu,
            durationHr: `${courseMenu?.durationHour || ""}`,
            durationMin: `${courseMenu?.durationMinute || ""}`,
        }));
    }, []);

    // create course menu logic
    const handleCreateCourseMenu = useCallback(async courseMenu => {
        try {
            const createBody = {
                courseCategoryId: courseMenu?.category,
                courseMenuName: courseMenu?.name,
                tag: courseMenu?.tag,
                price: courseMenu?.price,
                durationHour: Number(courseMenu?.durationHr),
                durationMinute: Number(courseMenu?.durationMin),
                description: courseMenu?.description,
            };
            if (courseMenu?.image?.file) {
                createBody.imagePath = await uploadImageFile(courseMenu?.image?.file, controllerRef.current?.signal);
            }
            const isCreate = await createCourseMenu(createBody, controllerRef.current?.signal);
            if (isCreate) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // update course menu logic
    const handleEditCourseMenu = useCallback(async courseMenu => {
        try {
            const updateBody = {
                courseCategoryId: courseMenu?.category,
                courseMenuName: courseMenu?.name,
                price: courseMenu?.price,
                tag: courseMenu?.tag,
                durationHour: Number(courseMenu?.durationHr),
                durationMinute: Number(courseMenu?.durationMin),
                description: courseMenu?.description,
            };
            if (courseMenu?.image) {
                if (courseMenu?.image?.file) {
                    updateBody.imagePath = await uploadImageFile(
                        courseMenu?.image?.file,
                        controllerRef.current?.signal,
                    );
                }
            } else {
                updateBody.imagePath = "";
            }

            const isUpdateStatus = await updateCourseMenu(
                courseMenu?.courseMenu.id,
                updateBody,
                controllerRef.current?.signal,
            );
            if (isUpdateStatus) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // delete course menu logic
    const handleDeleteCourseMenu = useCallback(async courseMenu => {
        try {
            const bool = await swalCondition("Confirm delete ?", courseMenu?.courseMenuName, {
                icon: "warning",
            });
            if (bool) {
                const isDelete = await deleteCourseMenu(courseMenu.id, controllerRef.current?.signal);

                if (isDelete) {
                    tableRef.current.onQueryChange();
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // change status logic
    const handleChangeStatus = useCallback(async (e, courseMenuId) => {
        try {
            const isUpdateStatus = await updateCourseMenu(
                courseMenuId,
                {
                    status: e.target?.checked,
                },
                controllerRef.current?.signal,
            );
            if (isUpdateStatus) {
                tableRef.current.onQueryChange();
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // fetch option data
    const fetchOption = useCallback(async signal => {
        try {
            const [tag, courseMenuOption] = await Promise.all([getAllTagV2(signal), getCourseMenuOption(signal)]);
            if (courseMenuOption && tag) {
                setOption(currentState => ({
                    ...currentState,
                    category: courseMenuOption?.data.categoryOption,
                    tag: tag?.data,
                }));
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // fetch data
    const fetchData = async query => {
        try {
            setIsLoading(true);
            const queryParams = {
                page: query?.page + 1 || 1,
                limit: query?.pageSize || 10,
                search: query?.search || "",
                status: query?.status || "",
                minprice: query?.minprice || "",
                maxprice: query?.maxprice || "",
                order: query?.orderBy?.field || "id",
                direction: query?.orderBy?.field ? query?.orderDirection : "desc",
            };
            const res = await getAllCourseMenu(queryParams, controllerRef.current?.signal);
            if (res) {
                setTableOptions(currentState => ({
                    ...currentState,
                    currentPage: query?.page,
                    pageSize: query?.pageSize || 10,
                    totalData: res?.pagination?.totalData || 0,
                    order: query?.orderBy?.field || "timestamp",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                }));
                setIsLoading(false);
                return {
                    data: res?.data || [],
                    page: query?.page || 0,
                    totalCount: res?.pagination?.totalData || 0,
                };
            }
            setIsLoading(false);
        } catch (error) {
            console.dir(error);
            setIsLoading(false);
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
    };

    // click search button
    const handleSubmitSearch = useCallback(
        val => {
            tableRef.current.onQueryChange({
                minprice: val.rangeprice[0] === 0 ? "0" : val.rangeprice[0],
                maxprice: val.rangeprice[1] === 0 ? "0" : val.rangeprice[1],
                status: val.status,
                search: val.search,
                page: 0,
            });
        },
        [tableRef],
    );

    // memo
    const tableColumns = useMemo(() => columnsMenu({ tableOptions, handleChangeStatus }), [
        handleChangeStatus,
        tableOptions,
    ]);

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        const init = async () => {
            await fetchOption(controllerRef.current?.signal);
        };
        init();
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, [fetchOption]);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className="mb-3 mb-md-5">
                <SearchMenu
                    onSubmit={values => handleSubmitSearch(values)}
                    permission={thisMenuPermission?.create}
                    handleOpenCreateFormModal={handleOpenCreateFormModal}
                />
            </Grid>
            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        tableRef={tableRef}
                        options={tableOptions}
                        components={{ Container: Box }}
                        columns={tableColumns}
                        data={fetchData}
                        isLoading={isLoading}
                        actions={[
                            {
                                icon: "edit",
                                iconProps: { className: "action-edit" },
                                tooltip: "Edit",
                                onClick: (event, rowData) => handleOpenEditFormModal(rowData),
                                hidden: !thisMenuPermission?.edit,
                            },
                            {
                                icon: "delete",
                                iconProps: { className: "action-delete" },
                                tooltip: "Delete",
                                onClick: (event, rowData) => handleDeleteCourseMenu(rowData),
                                hidden: !thisMenuPermission?.delete,
                            },
                        ]}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <Modal show={modalForm} scrollable centered size="md" onHide={handleCloseModal}>
                    <Modal.Header>
                        <Modal.Title>{isCreateMode ? "Create" : "Edit"} Menu</Modal.Title>
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body>
                        <FormMenu
                            onCancel={handleCloseModal}
                            onSubmit={isCreateMode ? handleCreateCourseMenu : handleEditCourseMenu}
                            initialFormValues={initialFormValues}
                            option={option}
                        />
                    </Modal.Body>
                </Modal>
            </Grid>
        </Grid>
    );
}
