import { Grid, Paper } from "@material-ui/core";
import React from "react";
import StudentContent from "../../components/StudentsManagement/StudentContent";

export default function StudentsList() {
    return (
        <Paper className="p-2 p-lg-3">
            <Grid container spacing={0}>
                <Grid item xs={12} className="mb-3">
                    <h4>{" Students List "}</h4>
                </Grid>
                <Grid item xs={12}>
                    <StudentContent />
                </Grid>
            </Grid>
        </Paper>
    );
}
