import { Button, IconButton, makeStyles } from "@material-ui/core";
import { Close, Edit } from "@material-ui/icons";
import React, { useCallback } from "react";
import Dropzone from "react-dropzone";
import { uniqueId } from "lodash";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

const useStyle = makeStyles(theme => ({
    container: {
        position: "relative",
        boxShadow: "1px 1px 6px #a0a0a073",
        width: "100%",
        height: "100%",
        cursor: "pointer",
        transition: "all 0.25s",
        "&:hover": {
            boxShadow: "1px 1px 15px #a0a0a073",
        },
        "&:active": {
            transform: "scale(1.01)",
        },
    },
    btnUploadFixed: {
        position: "absolute",
        top: -7,
        right: -7,
        backgroundColor: "#ffffff",
        borderRadius: "50%",
        boxShadow: "1px 1px 6px #a0a0a060",
    },
    btnRemoveFixed: {
        position: "absolute",
        zIndex: 10,
        opacity: 0,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#a0a0a099",
        boxShadow: "1px 1px 6px #a0a0a060",
        transition: "all 0.25s",
        "&:hover": {
            opacity: 1,
        },
    },
    imgStyle: {
        maxWidth: "100%",
        borderRadius: 3,
    },
}));

const interfaceFiles = { id: "", name: "", base64: "", file: "" };

export default function DropzoneApp({
    onChange = (/** @type interfaceFiles */ files) => {},
    onRemove = (/** @type interfaceFiles */ files) => {},
    src = "",
    styles = {
        /** @type React.CSSProperties */
        styleImage: {},
        /** @type React.CSSProperties */
        styleContainer: {},
        /** @type React.CSSProperties */
        styleButtonUpload: {},
        /** @type React.CSSProperties */
        styleLabelFile: {},
    },
    className = {
        classButton: "",
        classPreview: "",
        classContainer: "",
    },
    uploadBotton = false,
    noImage = toAbsoluteUrl("/media/users/blank.png"),
}) {
    // state and props
    const classes = useStyle();

    const onDrop = useCallback(
        (acceptedFiles = []) => {
            acceptedFiles.map(file => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = evt => {
                    if (evt.target.result) {
                        onChange({ id: uniqueId(), name: file?.name, base64: evt?.target?.result, file });
                    }
                };
                reader.onerror = function(error) {
                    console.log("Error: ", error);
                };
                return reader;
            });
        },
        [onChange],
    );

    // const { getInputProps, getRootProps } = useDropzone({onDrop});
    return !uploadBotton ? (
        <div className="t-dropzone-custom" style={{ width: styles.styleImage.width, height: styles.styleImage.height }}>
            <Dropzone onDrop={onDrop} multiple={false} accept={{ "image/*": [] }}>
                {({ getInputProps, getRootProps }) => (
                    <div className={classes.container} style={styles.styleContainer}>
                        {src !== "" ? (
                            <div className={classes.btnRemoveFixed}>
                                <Button
                                    onClick={() => {
                                        onRemove(interfaceFiles);
                                    }}
                                    className="w-100 h-100 d-flex justify-content-center align-items-center text-danger">
                                    {" "}
                                    <Close style={{ width: 50, height: 50 }} />{" "}
                                </Button>
                            </div>
                        ) : null}

                        <input {...getInputProps()} />
                        <div {...getRootProps()}>
                            {src === "" ? (
                                <div className={classes.btnUploadFixed}>
                                    <IconButton size="small">
                                        {" "}
                                        <Edit />{" "}
                                    </IconButton>
                                </div>
                            ) : null}

                            {src !== "" ? (
                                <img src={src} alt="name" className={classes.imgStyle} style={styles.styleImage} />
                            ) : (
                                <img src={noImage} alt="name" className={classes.imgStyle} style={styles.styleImage} />
                            )}
                        </div>
                    </div>
                )}
            </Dropzone>
        </div>
    ) : (
        <div className="t-dropzone-custom">
            <Dropzone onDrop={onDrop} multiple={false} accept={{ "image/*": [] }}>
                {({ getInputProps, getRootProps }) => (
                    <div>
                        <input {...getInputProps()} />
                        <div className={`row ${className.classContainer}`} style={styles.styleContainer}>
                            <div
                                {...getRootProps()}
                                className={`col-12 col-sm-6 mb-3 mb-sm-0 ${className.classButton}`}>
                                <Button className="btn t-btn-primary w-100">Upload file</Button>
                            </div>

                            {src !== "" ? (
                                <div className={`col-12 col-sm-6 ${className.classPreview}`}>
                                    <div className="position-relative">
                                        <img
                                            src={src}
                                            alt="name"
                                            className={classes.imgStyle}
                                            style={styles.styleImage}
                                        />
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                onRemove(interfaceFiles);
                                            }}
                                            style={{ top: 0, right: 0 }}
                                            className="position-absolute d-flex justify-content-center align-items-center text-danger">
                                            {" "}
                                            <Close style={{ width: 20, height: 20 }} />{" "}
                                        </IconButton>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                )}
            </Dropzone>
        </div>
    );
}
