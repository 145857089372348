import React from "react";
import moment from "moment";
// eslint-disable-next-line no-unused-vars
import { Column } from "material-table";
import { Typography } from "@material-ui/core";
import { array, object, string } from "yup";
import { currencyTHB } from "../../../../utils/formatCurrency";

export const getColumnsOrderTransaction = props => {
    /** @type Column */
    const column = [
        {
            field: "orderId",
            title: "#",
            sorting: false,
            render: obj => (
                <div>{props?.tableOptions?.currentPage * props?.tableOptions?.pageSize + obj.tableData?.id + 1}</div>
            ),
        },
        {
            field: "date",
            title: "Date",
            render: obj => <div>{obj?.date ? moment(obj?.date).format("DD/MM/YYYY") : ""}</div>,
        },
        {
            field: "bookingNo",
            title: "Booking NO.",
        },
        {
            field: "Student.studentFirstname",
            title: "Student",
            render: obj => (
                <div>
                    {obj?.Student?.studentFirstname} {obj?.Student?.studentLastname}
                </div>
            ),
        },
        {
            field: "PaymentMethods.name",
            title: "Payment",
            render: obj => obj?.payments?.map(item => item?.name)?.join(", "),
        },
        {
            field: "total",
            title: "Sales Total",
            render: obj => <div>{currencyTHB(obj?.salesTotal)}</div>,
        },
        {
            field: "package",
            title: "Package Usages",
            render: obj => <div>{currencyTHB(obj?.packageUsages)}</div>,
        },
        {
            field: "status",
            title: "Paid Status",
            render: obj => <StatusPaidComponent status={obj?.status} />,
        },
        {
            field: "bookingStatus",
            title: "Booking Status",
            render: obj => <StatusBookingComponent status={obj?.bookingStatus} />,
        },
        {
            field: "",
            title: "User",
            sorting: false,
            render: obj => `${obj?.UserCreate?.firstname || ""} ${obj?.UserCreate?.lastname || ""}`,
        },
    ];
    const data = column?.map(col => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};

export const StatusPaidComponent = ({ status = "" }) => {
    if (status === "0") {
        return <Typography className="mb-0 text-warning">Paid</Typography>;
    }
    if (status === "1") {
        return <Typography className="mb-0 text-success">Partial</Typography>;
    }
    if (status === "2") {
        return <Typography className="mb-0 text-danger">Cancel</Typography>;
    }
    if (status === "3") {
        return <Typography className="mb-0 text-success">Deposit</Typography>;
    }
    if (status === "4") {
        return (
            <Typography className="mb-0" style={{ color: "#7A36FF" }}>
                Refund
            </Typography>
        );
    }
    return null;
};

export const getStatusPaid = ({ status = "" }) => {
    if (status === "0") {
        return "Paid";
    }
    if (status === "1") {
        return "Partial";
    }
    if (status === "2") {
        return "Cancel";
    }
    if (status === "3") {
        return "Deposit";
    }
    if (status === "4") {
        return "Refund";
    }
    return "";
};

const StatusBookingComponent = ({ status = "" }) => {
    if (status === "0") {
        return <Typography className="mb-0">Active</Typography>;
    }
    if (status === "1") {
        return <Typography className="mb-0">Pending</Typography>;
    }
    if (status === "2") {
        return <Typography className="mb-0 text-danger">Cancel</Typography>;
    }
    return null;
};

export const getStatusBooking = ({ status = "" }) => {
    if (status === "0") {
        return "Active";
    }
    if (status === "1") {
        return "Pending";
    }
    if (status === "2") {
        return "Cancel";
    }
    return "";
};

export const initialValuesRecieve = {
    paymentSelected: [],
    prevPaymentSelected: [],
    studentBalance: "",
    totalAmount: "",
    paid: "",
    remaining: "",
};

export const validationSchemaRecieve = object({
    paymentSelected: array(),
    prevPaymentSelected: array(),
    studentBalance: string(),
    totalAmount: string(),
    paid: string(),
    remaining: string(),
});

export const initialValuesRefund = {
    refundValue: 0,
    refundNote: "",
};

export const initialValuesEditOrder = {
    total: "",
    discount: "",
    grandTotal: "",
    paid: "",
    remaining: "",
    orderLists: [],
    transactions: [],
};

export const validationSchemaOrder = object({
    total: string(),
    discount: string(),
    grandTotal: string(),
    paid: string(),
    remaining: string(),
    orderLists: array().of(
        object({
            value: object()
                .nullable()
                .required("Required!"),
        }),
    ),
});

export const initialReceiptData = {
    bookingNo: "",
    status: "",
    branchAddress: "",
    branchContact: "",
    date: "",
    studentFullname: "",
    studentNickname: "",
    studentPhoneNumber: "",
    studentEmail: "",
    orderList: [],
    total: "",
    discount: "",
    grandTotal: "",
    // hasCash: false,
    // hasTransfer: false,
    // hasCreditCard: false,
    // hasPackage: false,
    // hasOther: false,
    // cash: "",
    // transfer: "",
    // creditCard: "",
    // package: "",
    // other: "",
    paid: "",
    remaining: "",
    remark: "",
    cancelledDate: "",
    cancelledUser: "",
    refund: "",
    refundNote: "",
    createdBy: "",
    collector: "",
    transactions: [],
};
