import ExcelJS from "exceljs";

/**
 * @description for download xlsx
 * @param {string} reportName
 * @param {Buffer} byte
 */
export const saveByteArray = (reportName, byte) => {
    const blob = new Blob([byte], { type: "application/octet-stream" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    const fileName = reportName;
    link.download = fileName;
    link.click();
};

/** @type {Partial<ExcelJS.Style>} */
export const defaultStyleCell = {
    border: {
        top: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
        bottom: { style: "thin" },
    },
    alignment: {
        wrapText: true,
        horizontal: "center",
        vertical: "middle",
    },
};

/**
 * @function exportToExcel
 * @param {any[]} data
 * @param {{
 *  fileName?: string;
 *  title?: string;
 *  headers?: {[k:string]:string;};
 *  sumColumn?: string[];
 * }} options
 */
const exportToExcel = async (data, { fileName, title, headers, sumColumn }) => {
    const workbook = new ExcelJS.Workbook();
    const workSheet = workbook.addWorksheet("Sheet 1", {
        properties: { defaultColWidth: 20, defaultRowHeight: 15 },
    });
    /** @description start */
    let START_ROW = 1;
    if (title) {
        const rowTitle = workSheet.getCell(START_ROW, 1);
        const cellForMerge = workSheet.getCell(START_ROW, Object.entries(data[0]).length);
        workSheet.getRow(START_ROW).height = 20;
        workSheet.mergeCells(`${rowTitle.address}:${cellForMerge.address}`);
        rowTitle.style = {
            ...defaultStyleCell,
            alignment: { ...defaultStyleCell, horizontal: "left", vertical: "middle" },
            font: { bold: true },
        };
        rowTitle.value = title;
        START_ROW += 1;
    }

    /** @description set header colunm */
    Object.entries(data[0]).forEach(([key], indexCol) => {
        const rowHeader = workSheet.getCell(START_ROW, indexCol + 1);
        rowHeader.style = { ...defaultStyleCell, font: { bold: true } };
        workSheet.getRow(START_ROW).height = 20;
        const titleColumn = headers && typeof headers === "object" ? headers[key] || key : key;
        rowHeader.value = titleColumn;
    });
    START_ROW += 1;

    const forSum = {};
    /** @description set content */
    data.forEach(item => {
        if (typeof item === "object") {
            Object.entries(item).forEach(([key, value], indexCol) => {
                const rowData = workSheet.getCell(START_ROW, indexCol + 1);
                rowData.style = { ...defaultStyleCell };
                rowData.value = value;

                // for push cell to sum list.
                if (sumColumn && sumColumn?.includes(key)) {
                    forSum[key] = forSum[key]?.length ? [...forSum[key], rowData] : [rowData];
                }
            });
        }
        START_ROW += 1;
    });

    if (Object.entries(forSum)?.length) {
        Object.entries(forSum).forEach(([_, listsCol]) => {
            /** @type {ExcelJS.Cell} */
            const firstCell = listsCol[0];
            /** @type {ExcelJS.Cell} */
            const lastCell = listsCol[listsCol?.length - 1];
            const rowData = workSheet.getCell(START_ROW, lastCell.col);
            rowData.style = {
                ...defaultStyleCell,
                alignment: { ...defaultStyleCell.alignment, horizontal: "right" },
                font: { bold: true, size: 14 },
            };
            rowData.value = { formula: `SUM(${firstCell?.address}:${lastCell?.address})`, date1904: false };
        });
    }
    /**
     * @description `download excel`
     */
    const buffer = await workbook.xlsx.writeBuffer();
    saveByteArray(`${fileName || new Date().getTime()}.xlsx`, buffer);
};

export default exportToExcel;
