import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "order";

const GET_ALL_ORDER_URL = `${prefix}/all`;
const GET_ALL_ORDER_URL_EXPORT = `${prefix}/all-export`;
const GET_ORDER_OPTION_URL = `${prefix}/get-option`;
const GET_ORDER_BOOKING_NO_URL = `${prefix}/get-booking-no`;
const CREATE_ORDER_URL = `${prefix}/create`;
const UPDATE_ORDER_BY_ID_URL = `${prefix}/update-by-id`;
const REFUND_ORDER_BY_ID_URL = `${prefix}/refund-by-id`;

/**
 * @func getAllOrder
 * @desc get all order data
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} order array
 */
export const getAllOrder = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_ALL_ORDER_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllOrder");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getAllOrder */

/**
 * @func getAllOrderForExcel
 * @desc get all order data
 * @author march
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} order array
 */
export const getAllOrderForExcel = async (body, signal) => {
    try {
        const { data, status } = await axios.post(GET_ALL_ORDER_URL_EXPORT, body, {
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllOrderForExcel");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getAllOrderForExcel */

/**
 * @func getOrderOption
 * @desc get order option
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Object} order option
 */
export const getOrderOption = async signal => {
    try {
        const { data, status } = await axios.get(GET_ORDER_OPTION_URL, {
            signal,
        });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getOrderOption");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getOrderOption */

/**
 * @func getOrderBookingNo
 * @desc get order booking no
 * @author izeberg
 * @param {number} signal - AbortController Signal
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Object} order booking no
 */
export const getOrderBookingNo = async (branchId, signal) => {
    try {
        const { data, status } = await axios.get(`${GET_ORDER_BOOKING_NO_URL}/branch/${branchId}`, {
            signal,
        });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getOrderBookingNo");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getOrderBookingNo */

/**
 * @func createOrder
 * @desc create new order
 * @author izeberg
 * @param {Object} body - create order body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} create status
 */
export const createOrder = async (body, signal) => {
    try {
        const { status } = await axios.post(CREATE_ORDER_URL, body, { signal });
        if (status === 201) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "createOrder");
        if (err) throw err;
        return undefined;
    }
};
/** End @func createOrder */

/**
 * @func updateOrderById
 * @desc update orer by id
 * @author izeberg
 * @param {number} orderId - order id
 * @param {Object} body - update order body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} update status
 */
export const updateOrderById = async (orderId, body, signal) => {
    try {
        const { status } = await axios.put(`${UPDATE_ORDER_BY_ID_URL}/${orderId}`, body, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "updateOrderById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func updateOrderById */

/**
 * @func refundOrderById
 * @desc refund orer by id
 * @author izeberg
 * @param {number} orderId - order id
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} refund status
 */
export const refundOrderById = async (orderId, body, signal) => {
    try {
        const { status } = await axios.patch(`${REFUND_ORDER_BY_ID_URL}/${orderId}`, body, {
            signal,
        });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "refundOrderById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func refundOrderById */
