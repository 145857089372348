/* eslint no-unused-vars: */
import moment from "moment";
import { Column } from "material-table";
import { date, number, object } from "yup";
import { currencyTHB } from "../../../../utils/formatCurrency";

export const getColumnsTableCredit = () => {
    /** @type Column */
    const column = [
        {
            field: "studentFirstname",
            title: "Student",
            render: rowData => `${rowData?.studentFirstname} ${rowData?.studentLastname} (${rowData?.studentNickname})`,
        },
        {
            field: "balanceExpired",
            title: "Expired Date",
            render: rowData => (rowData?.balanceExpired ? moment(rowData?.balanceExpired).format("DD/MM/YYYY") : "-"),
        },
        {
            field: "balance",
            title: "Credit Balance",
            render: rowData => currencyTHB(rowData?.balance || 0),
        },
    ];
    const data = column?.map((col, index) => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};

export const initialValuesCredit = {
    balance: 0,
    balanceExpired: null,
    newBalance: 0,
    newBalanceExpired: null,
    student: {},
};

export const validationSchemaCredit = object({
    balance: number(),
    balanceExpired: date()
        .typeError("Invalid Date")
        .nullable(true),
    newBalance: number(),
    newBalanceExpired: date()
        .typeError("Invalid Date")
        .nullable(true),
});
