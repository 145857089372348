/* eslint-disable  no-unused-vars */
import { Avatar } from "@material-ui/core";
import { Options, Column } from "material-table";
import React from "react";
import { array, date, object, string } from "yup";
import { convertDateTimeFromApi, toImageUrl } from "../../../../utils/format";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SwitchApp from "../../../componentsUtils/SwitchApp";

export const initialValues = {
    fname: "",
    lname: "",
    birthday: new Date(),
    nickname: "",
    email: "",
    tel: "",
    lineId: "",
    courseMenus: [],
    image: {
        id: "",
        name: "",
        base64: "",
    },
};

export const validationSchema = object({
    base64: string(),
    fname: string().required("Required."),
    lname: string().required("Required."),
    birthday: date(),
    nickname: string(),
    email: string()
        // eslint-disable-next-line
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]+$/g, "ex: example@email.com"),
    tel: string().matches(/^[0-9]+$/g, "Number only."),
    lineId: string(),
    courseMenus: array(),
});

export const columnTableTeachers = props => {
    /** @type Column */
    const columns = [
        {
            field: "id",
            title: "#",
            sorting: false,
            render: obj => (
                <div>{props?.tableOptions?.currentPage * props?.tableOptions?.pageSize + obj.tableData?.id + 1}</div>
            ),
        },
        {
            field: "image",
            title: "Image",
            sorting: false,
            render: obj => (
                <div className="d-flex justify-content-center align-items-center">
                    <Avatar
                        src={
                            obj?.teacherImagePath
                                ? toImageUrl(obj?.teacherImagePath)
                                : toAbsoluteUrl("/media/users/blank.png")
                        }
                        alt="Teacher"
                    />
                </div>
            ),
        },
        {
            field: "teacherFirstname",
            title: "Firstname",
        },
        {
            field: "teacherLastname",
            title: "Lastname",
        },
        {
            field: "teacherNickname",
            title: "Nickname",
        },
        {
            field: "teacherEmail",
            title: "Email",
        },
        {
            field: "teacherTel",
            title: "Tel",
        },
        {
            field: "teacherLineid",
            title: "Line ID",
        },
        {
            field: "status",
            title: "Status",
            sorting: false,
            render: obj => {
                let checkSt = false;
                if (obj?.status === "1") {
                    checkSt = true;
                }
                return <SwitchApp checked={checkSt} onChange={val => props?.handleChangeStatus({ obj, val })} />;
            },
        },
    ];
    const data = columns?.map((col, index) => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};

export const columnTableLog = props => {
    /** @type Column */
    const { tableOptions } = props;
    const columns = [
        {
            field: "index",
            title: "#",
            sorting: false,
            render: obj => <div>{tableOptions?.currentPage * tableOptions?.pageSize + obj.tableData?.id + 1}</div>,
        },
        {
            field: "timestamp",
            title: "Date Time",
            render: obj => <div>{convertDateTimeFromApi(obj.date)}</div>,
        },
        {
            field: "User.firstname",
            title: "User",
            render: obj => <div>{obj.user}</div>,
        },
        {
            field: "action",
            title: "Action",
            render: obj => (
                <div>
                    {obj?.action === "1" ? (
                        <div>Create</div>
                    ) : obj?.action === "2" ? (
                        <div>Update</div>
                    ) : obj?.action === "3" ? (
                        <div>Delete</div>
                    ) : (
                        ""
                    )}
                </div>
            ),
        },
        {
            field: "detail",
            title: "Detail",
        },
    ];
    const data = columns?.map((col, index) => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};
