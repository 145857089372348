import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "report";

const GET_OPTION = `${prefix}/branch-option`;
const GET_MONTHLY = `${prefix}/monthly`;
const GET_MENU_OPTION = `${prefix}/menu-option`;
const GET_MENU = `${prefix}/menu`;
const GET_COURSE = `${prefix}/course`;
const GET_PACKAGE = `${prefix}/package`;
const GET_BALANCE_REPORT = `${prefix}/balance`;
const EXPORT_BALANCE_REPORT = `${prefix}/balance-export`;
const GET_TRANSACTION_REPORT = `${prefix}/transaction`;
const GET_TOTAL_TRANSACTION_REPORT = `${prefix}/total-transaction`;
const EXPORT_TRANSACTION_REPORT = `${prefix}/transaction/export`;

/**
 * @func getBranchOption
 * @desc get all branch option and check if is admin
 * @author march
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} branch array
 */
export const getBranchOption = async signal => {
    try {
        const { data, status } = await axios.get(GET_OPTION, {
            signal,
        });

        if (status === 200) {
            return data;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getBranchOption");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getBranchOption */

/**
 * @func getReportMonthly
 * @desc get all report monthly
 * @author march
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} report monthly array
 */
export const getReportMonthly = async (body, signal) => {
    try {
        const { data, status } = await axios.post(GET_MONTHLY, body, {
            signal,
        });

        if (status === 200) {
            return data;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getReportMonthly");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getReportMonthly */

/**
 * @func getMenuOption
 * @desc get all option menu
 * @author march
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} option menu array
 */
export const getMenuOption = async (body, signal) => {
    try {
        const { data, status } = await axios.post(GET_MENU_OPTION, body, {
            signal,
        });

        if (status === 200) {
            return data;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getMenuOption");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getMenuOption */

/**
 * @func getReportMenu
 * @desc get all report menu
 * @author march
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} report menu array
 */
export const getReportMenu = async (body, signal) => {
    try {
        const { data, status } = await axios.post(GET_MENU, body, {
            signal,
        });

        if (status === 200) {
            return data;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getReportMenu");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getReportMenu */

/**
 * @func getReportCourse
 * @desc get all report course
 * @author march
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} report course array
 */
export const getReportCourse = async (body, signal) => {
    try {
        const { data, status } = await axios.post(GET_COURSE, body, {
            signal,
        });

        if (status === 200) {
            return data;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getReportMenu");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getReportCourse */

/**
 * @func getReportPackage
 * @desc get all report package
 * @author march
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} report package array
 */
export const getReportPackage = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_PACKAGE, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getReportPackage");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getReportPackage */

/**
 * @func getBalanceReport
 * @desc get report student balance
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} student balance
 * */
export const getBalanceReport = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_BALANCE_REPORT, {
            params: queryParams,
            signal,
        });

        if (status === 200) {
            return data;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getBalanceReport");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getBalanceReport */

/**
 * @func exportBalanceReport
 * @desc export report student balance
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} student balance
 * */
export const exportBalanceReport = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(EXPORT_BALANCE_REPORT, {
            params: queryParams,
            signal,
        });

        if (status === 200) {
            return data?.data;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "exportBalanceReport");
        if (err) throw err;
        return undefined;
    }
};
/** End @func exportBalanceReport */

/**
 * @func getTransactionReport
 * @desc get report transaction
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} order transaction report
 * */
export const getTransactionReport = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_TRANSACTION_REPORT, {
            params: queryParams,
            signal,
        });

        if (status === 200) {
            return data;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getTransactionReport");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getTransactionReport */

/**
 * @func getTotalTransactionReport
 * @desc get total transaction
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Promise<object>}
 * */
export const getTotalTransactionReport = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_TOTAL_TRANSACTION_REPORT, {
            params: queryParams,
            signal,
        });

        if (status === 200) {
            return data?.data;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getTotalTransactionReport");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getTotalTransactionReport */

/**
 * @func exportTransactionReport
 * @desc get report transaction
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} order transaction
 * */
export const exportTransactionReport = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(EXPORT_TRANSACTION_REPORT, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "exportTransactionReport");
        if (err) throw err;
        return undefined;
    }
};
/** End @func exportTransactionReport */
