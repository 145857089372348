import moment from "moment";
import { array, object, string } from "yup";

export const initialValuesCreateOrder = {
    bookingNo: "",
    orderDate: moment(new Date()).format("YYYY-MM-DD"),
    branchId: localStorage.getItem("currentBranchId") || "",
    studentObj: "",
    initialStudentBalance: "",
    studentBalance: "",
    remark: "",
    courseSelected: [],
    amount: "",
    discount: "",
    discountTemp: "",
    total: "",
    grandTotal: "",
    paymentSelected: [],
    paid: "",
    remaining: "",
};

export const validateSchemaCreateOrder = object({
    bookingNo: string(),
    orderDate: string(),
    branchId: string().required("Required."),
    studentObj: string().required("Required."),
    remark: string(),
    initialStudentBalance: string(),
    studentBalance: string(),
    courseSelected: array(),
    amount: string(),
    discount: string(),
    discountTemp: string(),
    total: string(),
    grandTotal: string(),
    paymentSelected: array(),
    paid: string(),
    remaining: string(),
});

export const initialValuesStudent = {
    base64: "",
    fname: "",
    lname: "",
    nickname: "",
    classOf: "",
    email: "",
    tel: "",
    lineId: "",
    birthday: new Date(),
    address: "",
    province: "",
    country: "",
    expertise: "",
    sizeApron: "",
    studentNote: "",
    learnObjt: "",
    knowChannels: "",
    allowSocial: "",
};
