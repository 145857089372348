import { Box, Button, FormControl, Grid, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Add, Close, Search } from "@material-ui/icons";
import MaterialTable from "material-table";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
    createCourseCategory,
    deleteCourseCategory,
    getAllCourseCategory,
    updateCourseCategory,
} from "../../../apis/categoryApi";
import { swalCondition } from "../../../utils/swal";
import { getOptionsTableApp } from "../../helpers/useOption";
import FormCategory from "./FormCategory";
import { initialValuesCategory, tableColumnsCategory } from "./helpers/categoryOption";

export default function ContentCategory() {
    // ref
    const tableRef = useRef(null);
    const controllerRef = useRef(null);

    // global redux state
    const { thisMenuPermission } = useSelector(state => {
        const { menuPermission } = state?.mainMenu;
        return {
            thisMenuPermission: menuPermission?.find(menu => menu?.path === window.location.pathname)?.permission,
        };
    });

    // component state
    const [modalForm, setModalForm] = useState(false);
    const [valuesSearch, setValuesSearch] = useState("");
    const [tableOptions, setTableOptions] = useState(getOptionsTableApp);
    const [isCreateMode, setIsCreateMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState(initialValuesCategory);

    // close modal
    const handleCloseModal = useCallback(() => {
        setModalForm(false);
        setInitialFormValues(initialValuesCategory);
    }, []);

    // open modal create form
    const handleOpenCreateFormModal = useCallback(() => {
        setIsCreateMode(true);
        setInitialFormValues(initialValuesCategory);
        setModalForm(true);
    }, []);

    // open modal edit form
    const handleOpenEditFormModal = useCallback(async category => {
        setIsCreateMode(false);
        setModalForm(true);
        setInitialFormValues(currentState => ({
            ...currentState,
            name: category?.categoryName,
            description: category?.categoryDescription,
            category,
        }));
    }, []);

    // create category logic
    const handleCreateCategory = useCallback(async category => {
        try {
            const createBody = {
                categoryName: category?.name,
                categoryDescription: category?.description,
            };
            const isCreate = await createCourseCategory(createBody, controllerRef.current?.signal);
            if (isCreate) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // edit category logic
    const handleEditCategory = useCallback(async values => {
        try {
            const updateBody = {
                categoryName: values?.name,
                categoryDescription: values?.description,
            };
            const isUpdate = await updateCourseCategory(values.category?.id, updateBody, controllerRef.current?.signal);
            if (isUpdate) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // delete category logic
    const handleDeleteCategory = useCallback(async category => {
        try {
            const bool = await swalCondition("Confirm delete ?", category?.categoryName, {
                icon: "warning",
            });
            if (bool) {
                const isDelete = await deleteCourseCategory(category?.id, controllerRef.current?.signal);
                if (isDelete) {
                    tableRef.current.onQueryChange();
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // change status logic
    const handleChangeStatus = useCallback(async (status, categoryId) => {
        try {
            const isUpdateStatus = await updateCourseCategory(categoryId, { status }, controllerRef.current?.signal);
            if (isUpdateStatus) {
                tableRef.current.onQueryChange();
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // memo
    const tableColumns = useMemo(() => tableColumnsCategory({ tableOptions, handleChangeStatus }), [
        handleChangeStatus,
        tableOptions,
    ]);

    const getData = async query => {
        try {
            setIsLoading(true);
            const queryParams = {
                page: query?.page + 1 || 1,
                limit: query?.pageSize || 10,
                search: query?.search || "",
                order: query?.orderBy?.field || "id",
                direction: query?.orderBy?.field ? query?.orderDirection : "desc",
            };
            const res = await getAllCourseCategory(queryParams, controllerRef.current?.signal);
            if (res) {
                setTableOptions(currentState => ({
                    ...currentState,
                    currentPage: query?.page,
                    pageSize: query?.pageSize || 10,
                    totalData: res?.pagination?.totalData || 0,
                    order: query?.orderBy?.field || "timestamp",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                }));
                setIsLoading(false);
                return {
                    data: res?.data || [],
                    page: query?.page || 0,
                    totalCount: res?.pagination?.totalData || 0,
                };
            }
            setIsLoading(false);
        } catch (error) {
            console.dir(error);
            setIsLoading(false);
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
    };

    // search input
    const handleOnchangeSearch = useCallback(
        evt => {
            const value = evt.target?.value;
            setValuesSearch(value);
            tableRef.current.onQueryChange({ search: value, page: 0 });
        },
        [tableRef],
    );

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className="mb-3 mb-md-4">
                <div className="d-md-flex justify-content-md-between align-items-center">
                    <FormControl style={{ height: "100%" }}>
                        <TextField
                            size="small"
                            variant="outlined"
                            value={valuesSearch}
                            onChange={handleOnchangeSearch}
                            placeholder="Search..."
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search className="text-muted" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                    {thisMenuPermission?.create && (
                        <Button
                            onClick={handleOpenCreateFormModal}
                            size="large"
                            className="fixed-h-lg btn btn-lg t-btn-primary flex-fill flex-xl-grow-0 fixed-h-lg">
                            <Add className="mr-3" /> Create Skill
                        </Button>
                    )}
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        data={getData}
                        options={tableOptions}
                        columns={tableColumns}
                        components={{ Container: Box }}
                        title=""
                        actions={[
                            {
                                icon: "edit",
                                iconProps: { className: "action-edit" },
                                tooltip: "Edit",
                                onClick: (event, rowData) => handleOpenEditFormModal(rowData),
                                hidden: !thisMenuPermission?.edit,
                            },
                            {
                                icon: "delete",
                                iconProps: { className: "action-delete" },
                                tooltip: "Delete",
                                onClick: (event, rowData) => handleDeleteCategory(rowData),
                                hidden: !thisMenuPermission?.delete,
                            },
                        ]}
                    />
                </div>
            </Grid>
            {/* modal */}
            <Grid item xs={12}>
                <Modal show={modalForm} scrollable centered onHide={handleCloseModal}>
                    <Modal.Header>
                        <Modal.Title>{isCreateMode ? "Create" : "Edit"} Skill Level</Modal.Title>
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body className="py-5">
                        {" "}
                        <FormCategory
                            onCancel={handleCloseModal}
                            onSubmit={isCreateMode ? handleCreateCategory : handleEditCategory}
                            initialFormValues={initialFormValues}
                        />{" "}
                    </Modal.Body>
                </Modal>
            </Grid>
        </Grid>
    );
}
