/* eslint-disable  no-unused-vars */
import { Options } from "material-table";

/** @type Options */
export const getOptionsTableApp = {
    currentPage: 0,
    pageSize: 10,
    pageSizeOptions: [10, 20, 50, 100],
    emptyRowsWhenPaging: false,
    actionsColumnIndex: -1,
    paginationType: "stepped",
    debounceInterval: 500,
    search: false,
    toolbar: false,
};
