import { Grid, Paper } from "@material-ui/core";
import React from "react";
import ContentFree from "../../../components/ScheduleList/ContentFree";

export default function ScheduleListFree() {
    return (
        <Paper className="p-2 p-lg-3">
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                    <ContentFree />
                </Grid>
            </Grid>
        </Paper>
    );
}
