import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, FormLabel, Grid, TablePagination } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import moment from "moment";
import MaterialTable from "material-table";
import { getMenuOption, exportBalanceReport, getBalanceReport } from "../../../apis/reportApi";
import { getColumnsTableCreditBalanceStd } from "./helpers/optionCreditBalance";
import SelectSearchApp from "../../componentsUtils/SelectSearchApp";
import DatePickerApp from "../../componentsUtils/DatePickerApp";
import { getOptionsTableApp } from "../../helpers/useOption";
import { swalCondition } from "../../../utils/swal";
import exportToExcel from "../../../utils/exportToExcel";

export default function CreditBalanceStdContent() {
    // ref
    const controllerRef = useRef(null);
    const tableRef = useRef(null);

    // component state
    const [isLoading, setIsLoading] = useState(false);
    const [dataExcel, setDataExcel] = useState([]);
    const [tableOptions, setTableOptions] = useState(getOptionsTableApp);
    const [dataFilter, setDataFilter] = useState({
        student: {},
        startDate: null,
        endDate: null,
    });
    const [filterError, setFilterError] = useState({
        startDate: "",
        endDate: "",
    });
    const [options, setOption] = useState({
        isAdmin: false,
        branchId: 0,
        branchOption: [],
        packageOption: [],
        studentOption: [],
        tagOption: [],
    });

    // fetch option
    const fetchOption = useCallback(async () => {
        try {
            const option = await getMenuOption({ type: "3" }, controllerRef?.current?.signal);
            if (option) {
                setOption(option);
                if (!option?.isAdmin) {
                    setDataFilter(state => ({ ...state, branch: { id: option?.branchId } }));
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // change expired date filter
    const handleChangeExpiredDateFilter = useCallback((value, typeField) => {
        if (value) {
            const timestamp = moment(value).unix();
            if (Number.isNaN(timestamp) || timestamp < 0) {
                setFilterError(prevState => ({
                    ...prevState,
                    [typeField]: "Invalid Date",
                }));
                return;
            }
        }
        setDataFilter(prevState => ({ ...prevState, [typeField]: moment(value).format("YYYY-MM-DD") }));
        setFilterError(prevState => ({
            ...prevState,
            [typeField]: "",
        }));
    }, []);

    // clear filter
    const handleClearFilter = useCallback(() => {
        setDataFilter({
            student: {},
            startDate: null,
            endDate: null,
        });
        setFilterError({
            startDate: "",
            endDate: "",
        });
    }, []);

    // export excel
    const handleExportExcel = useCallback(async () => {
        if (dataExcel.length < 1) return false;
        exportToExcel(dataExcel, { fileName: "report-balance", sumColumn: ["Credit Balance"] });
    }, [dataExcel]);

    // fetch data
    const fetchData = useCallback(
        async query => {
            try {
                setIsLoading(true);
                const queryParams = {
                    page: query?.page + 1 || 1,
                    limit: query?.pageSize || 10,
                    order: query?.orderBy?.field || "balanceExpired",
                    direction: query?.orderBy?.field ? query?.orderDirection : "asc",
                    studentId: dataFilter?.student?.id,
                    startDate: dataFilter?.startDate ? moment(dataFilter?.startDate).format("YYYY-MM-DD") : undefined,
                    endDate: dataFilter?.endDate ? moment(dataFilter?.endDate).format("YYYY-MM-DD") : undefined,
                };
                const res = await getBalanceReport(queryParams, controllerRef?.current?.signal);
                if (res) {
                    if (res?.isBranchActive === false) {
                        return await swalCondition("Your Branch is not active", "Please Contact Admin", {
                            showCancelButton: false,
                            confirmButtonText: "OK",
                        });
                    }
                    setTableOptions(currentState => ({
                        ...currentState,
                        currentPage: query?.page,
                        pageSize: query?.pageSize || 10,
                        totalData: res?.pagination?.totalData || 0,
                        order: query?.orderBy?.field || "timestamp",
                        direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    }));
                    const balanceReport = await exportBalanceReport(queryParams, controllerRef?.current?.signal);
                    if (balanceReport) {
                        const excel = balanceReport?.map((report, index) => ({
                            No: index + 1,
                            Student: `${report?.studentFirstname} ${report?.studentLastname} (${report?.studentNickname})`,
                            "Latest Package": report?.LogStudentPackages[0]?.Package?.packageName || "-",
                            "Expired Date": report?.balanceExpired
                                ? moment(report?.balanceExpired).format("DD/MM/YYYY")
                                : "-",
                            "Credit Balance": report?.balance || 0,
                        }));
                        setDataExcel(excel);
                    }
                    setIsLoading(false);
                    return {
                        data: res?.data || [],
                        page: query?.page || 0,
                        totalCount: res?.pagination?.totalData || 0,
                    };
                }
                setIsLoading(false);
            } catch (error) {
                console.dir(error);
                setIsLoading(false);
                return {
                    data: [],
                    page: 0,
                    totalCount: 0,
                };
            }
        },
        [dataFilter],
    );

    useEffect(() => {
        controllerRef.current = new AbortController();
        fetchOption();
        return () => {
            controllerRef.current.abort();
        };
    }, [fetchOption]);

    useEffect(() => {
        if (dataFilter.startDate && dataFilter.endDate && dataFilter.startDate > dataFilter.endDate) {
            setFilterError(prevState => ({
                ...prevState,
                endDate: `Date should be after ${moment(dataFilter.startDate).format("DD/MM/YYYY")}`,
            }));
            return;
        }
        tableRef.current.onQueryChange({ page: 0 });
    }, [dataFilter]);

    return (
        <Grid container spacing={0}>
            {/* filter */}
            <Grid item xs={12} className="my-10">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={11}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3} className="d-md-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Student :</FormLabel>
                                <div className="flex-fill">
                                    <SelectSearchApp
                                        size="sm"
                                        options={[{ id: "" }, ...options?.studentOption]}
                                        value={
                                            options?.studentOption?.find(it => it.id === dataFilter?.student?.id) || ""
                                        }
                                        onChange={value =>
                                            setDataFilter(prevState => ({
                                                ...prevState,
                                                student: value.id ? value : {},
                                            }))
                                        }
                                        getOptionLabel={option => {
                                            if (!option?.id) return "All";
                                            return option?.student_firstname && option?.student_lastname
                                                ? `${option?.student_firstname} ${option?.student_lastname} (${option?.student_nickname})`
                                                : "";
                                        }}
                                        getOptionValue={option => option?.id}
                                        placeholder="All"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} className="d-md-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Expired Date : </FormLabel>
                                <div className="d-flex align-items-center justify-content-center flex-fill">
                                    <DatePickerApp
                                        className="flex-fill fixed-h-lg"
                                        size="small"
                                        value={dataFilter?.startDate}
                                        onChange={date => {
                                            handleChangeExpiredDateFilter(date, "startDate");
                                        }}
                                        error={Boolean(filterError.startDate)}
                                        helperText={filterError.startDate}
                                    />
                                    <small className="mx-3">To</small>
                                    <DatePickerApp
                                        className="flex-fill fixed-h-lg"
                                        size="small"
                                        value={dataFilter?.endDate}
                                        minDate={dataFilter?.startDate || undefined}
                                        onChange={date => {
                                            handleChangeExpiredDateFilter(date, "endDate");
                                        }}
                                        error={Boolean(filterError.endDate)}
                                        helperText={filterError.endDate}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <div className="d-flex align-items-center mt-lg-6">
                                    <Button
                                        size="large"
                                        className="btn t-btn-primary fixed-h-lg flex-lg-grow-0 flex-fill mr-2"
                                        onClick={handleClearFilter}>
                                        Clear
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <div className="d-flex align-items-center justify-content-lg-end mt-lg-6">
                            <Button
                                size="large"
                                className={`${dataExcel.length < 1 &&
                                    "disabled"} btn t-btn-primary fixed-h-lg flex-lg-grow-0 flex-fill`}
                                onClick={handleExportExcel}>
                                <GetApp style={{ fontSize: 22 }} />
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            {/* data table */}
            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        options={tableOptions}
                        data={fetchData}
                        columns={getColumnsTableCreditBalanceStd()}
                        components={{
                            Container: Box,
                            Pagination: propsMTablePagination => (
                                <>
                                    <td
                                        className="position-absolute d-flex align-items-center justify-content-center"
                                        style={{ left: 0, top: 0, height: "100%", zIndex: 5 }}>
                                        <div className="text-danger">* ข้อมูลเครดิตคงเหลือ Real-Time</div>
                                    </td>
                                    <TablePagination {...propsMTablePagination} />
                                </>
                            ),
                        }}
                    />
                </div>
            </Grid>
        </Grid>
    );
}
