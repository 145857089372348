import { Button, FormControl, FormLabel, Grid, TextField } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { object, string } from "yup";

const TagForm = ({ onCancel, onSubmit, initialFormValues, controllerRef }) => {
    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={object({
                tagName: string().required("Required."),
            })}
            onSubmit={onSubmit}>
            {({ values, handleChange, handleSubmit, errors }) => (
                <Grid container spacing={3}>
                    {/* name branch */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel>
                                Tag name <span className="text-danger">*</span>
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                type="string"
                                id="tagName"
                                placeholder="Tag name"
                                value={values?.tagName}
                                onChange={handleChange}
                                error={Boolean(errors?.tagName)}
                                helperText={errors?.tagName}
                            />
                        </FormControl>
                    </Grid>
                    {/* action */}
                    <Grid item xs={12}>
                        <div className="d-flex justify-content-md-end align-items-center">
                            <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                                <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                            </Button>
                            <Button onClick={handleSubmit} className="btn t-btn-secondary">
                                <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
};

export default TagForm;
