import { Box, Button, FormControl, Grid, IconButton, InputAdornment, TextField } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import { Add, Close, Search } from "@material-ui/icons";
import { columnTableClassroom, initialValuesClassroom } from "./helpers/classroomOption";
import FormClassroom from "./FormClassroom";
import { getOptionsTableApp } from "../../helpers/useOption";
import { createClassroom, deleteClassroomById, getAllClassroom, updateClassroomById } from "../../../apis/classroomApi";
import { swalCondition } from "../../../utils/swal";

// state
export default function ContentClassroom() {
    // ref
    const tableRef = useRef(null);
    const controllerRef = useRef(null);

    // global redux state
    const { thisMenuPermission } = useSelector(state => {
        const { menuPermission } = state?.mainMenu;
        return {
            thisMenuPermission: menuPermission?.find(menu => menu?.path === window.location.pathname)?.permission,
        };
    });

    // component state
    const [modalForm, setModalForm] = useState(false);
    const [valuesSearch, setValuesSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isCreateMode, setIsCreateMode] = useState(false);
    const [tableOptions, setTableOptions] = useState(getOptionsTableApp);
    const [initialFormValues, setInitialFormValues] = useState(initialValuesClassroom);

    // close modal
    const handleCloseModal = useCallback(() => {
        setModalForm(false);
        setInitialFormValues(initialValuesClassroom);
    }, []);

    // open modal create form
    const handleOpenCreateFormModal = useCallback(() => {
        setIsCreateMode(true);
        setInitialFormValues(initialValuesClassroom);
        setModalForm(true);
    }, []);

    // open modal edit form
    const handleOpenEditFormModal = useCallback(classroom => {
        setIsCreateMode(false);
        setModalForm(true);
        setInitialFormValues(currentState => ({
            ...currentState,
            classroomName: classroom?.classroomName,
            color: classroom?.colorHex,
            classroom,
        }));
    }, []);

    // search input
    const handleOnchangeSearch = useCallback(
        evt => {
            const value = evt.target?.value;
            setValuesSearch(value);
            tableRef.current.onQueryChange({ search: value, page: 0 });
        },
        [tableRef],
    );

    // fetch data
    const fetchData = useCallback(async query => {
        try {
            setIsLoading(true);
            const queryParams = {
                page: query?.page + 1 || 1,
                limit: query?.pageSize || 10,
                search: query?.search || "",
                order: query?.orderBy?.field || "id",
                direction: query?.orderBy?.field ? query?.orderDirection : "desc",
            };
            const res = await getAllClassroom(queryParams, controllerRef.current?.signal);
            if (res) {
                setTableOptions(currentState => ({
                    ...currentState,
                    currentPage: query?.page,
                    pageSize: query?.pageSize || 10,
                    totalData: res?.pagination?.totalData || 0,
                    order: query?.orderBy?.field || "timestamp",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                }));
                setIsLoading(false);
                return {
                    data: res?.data || [],
                    page: query?.page || 0,
                    totalCount: res?.pagination?.totalData || 0,
                };
            }
            setIsLoading(false);
        } catch (error) {
            console.dir(error);
            setIsLoading(false);
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
    }, []);

    // create classroom logic
    const handleCreateClassroom = useCallback(async values => {
        try {
            const createBody = {
                classroomName: values?.classroomName,
                colorHex: values?.color,
            };
            const isCreate = await createClassroom(createBody, controllerRef.current?.signal);
            if (isCreate) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // edit classroom logic
    const handleEditClassroom = useCallback(async values => {
        try {
            const updateBody = {
                classroomName: values?.classroomName,
                colorHex: values?.color,
            };
            const isUpdate = await updateClassroomById(
                values?.classroom?.id,
                updateBody,
                controllerRef.current?.signal,
            );
            if (isUpdate) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // change status logic
    const handleChangeStatus = useCallback(async (e, classroomId) => {
        try {
            const isUpdateStatus = await updateClassroomById(
                classroomId,
                { status: e.target?.checked },
                controllerRef.current?.signal,
            );
            if (isUpdateStatus) {
                tableRef.current.onQueryChange();
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // delete classroom logic
    const handleDeleteClassroom = useCallback(async classroom => {
        try {
            const bool = await swalCondition("Confirm delete ?", classroom?.classroomName, {
                icon: "warning",
            });
            if (bool) {
                const isDelete = await deleteClassroomById(classroom?.id, controllerRef.current?.signal);
                if (isDelete) {
                    tableRef.current.onQueryChange();
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // memo
    const tableColumns = useMemo(() => columnTableClassroom({ tableOptions, handleChangeStatus }), [
        handleChangeStatus,
        tableOptions,
    ]);

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, []);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className="mb-3 mb-md-4">
                <div className="d-md-flex justify-content-md-between align-items-center">
                    <div>
                        <FormControl style={{ height: "100%" }}>
                            <TextField
                                size="small"
                                variant="outlined"
                                value={valuesSearch}
                                onChange={handleOnchangeSearch}
                                placeholder="Search..."
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search className="text-muted" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </div>
                    {thisMenuPermission?.create && (
                        <div className="d-flex align-items-center">
                            <Button
                                onClick={handleOpenCreateFormModal}
                                size="large"
                                className="btn t-btn-primary mt-3 mt-md-0">
                                <Add /> Create Classroom
                            </Button>
                        </div>
                    )}
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        data={fetchData}
                        options={tableOptions}
                        columns={tableColumns}
                        components={{ Container: Box }}
                        title=""
                        actions={[
                            {
                                icon: "edit",
                                iconProps: { className: "action-edit" },
                                tooltip: "Edit",
                                onClick: (event, rowData) => handleOpenEditFormModal(rowData),
                                hidden: !thisMenuPermission?.edit,
                            },
                            {
                                icon: "delete",
                                iconProps: { className: "action-delete" },
                                tooltip: "Delete",
                                onClick: (event, rowData) => handleDeleteClassroom(rowData),
                                hidden: !thisMenuPermission?.delete,
                            },
                        ]}
                    />
                </div>
            </Grid>
            {/* modal */}
            <Grid item xs={12}>
                <Modal show={modalForm} scrollable centered onHide={handleCloseModal}>
                    <Modal.Header>
                        <ModalTitle>{isCreateMode ? "Create" : "Edit"} Classroom</ModalTitle>
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <ModalBody className="py-5">
                        <FormClassroom
                            onCancel={handleCloseModal}
                            onSubmit={isCreateMode ? handleCreateClassroom : handleEditClassroom}
                            initialFormValues={initialFormValues}
                        />
                    </ModalBody>
                </Modal>
            </Grid>
        </Grid>
    );
}
