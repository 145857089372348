import React, { useState } from "react";
import { Button, FormControl, FormLabel, Grid, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { MailOutline, VisibilityOff, Visibility } from "@material-ui/icons";
import { Formik } from "formik";
import { object, string, ref } from "yup";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const ResetPassword = ({ onSubmit, email }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    return (
        <div className="px-4">
            {/* begin::Head */}
            <div className="text-center mb-5 mb-lg-10">
                <div className="w-100">
                    <img
                        style={{ width: 100 }}
                        src={toAbsoluteUrl("/media/assets/images/logos/cottage.png")}
                        alt="logo-sweet-cottage"
                    />
                </div>
                <h4 className="font-size-h1">Please input your new password</h4>
                {/* <h5 className="text-muted">Sweets Cottage Academy Management.</h5> */}
            </div>
            {/* end::Head */}
            <Formik
                initialValues={{ email, password: "", confirmPassword: "" }}
                validationSchema={object({
                    email: string()
                        // eslint-disable-next-line
                        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]+$/g, "Invalid email format ex. example@email.com")
                        .required("Required."),
                    password: string()
                        .required("Required.")
                        .max(255),
                    confirmPassword: string()
                        .max(255)
                        .oneOf([ref("password"), null], "Passwords must match")
                        .required("Required."),
                })}
                onSubmit={onSubmit}>
                {({ values, handleChange, handleSubmit, errors }) => (
                    <Grid container spacing={0}>
                        <Grid item xs={12} className="py-3">
                            <FormControl fullWidth>
                                <FormLabel>Email</FormLabel>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="email"
                                    name="email"
                                    disabled
                                    error={Boolean(errors?.email)}
                                    helperText={errors?.email}
                                    value={values?.email}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton disabled>
                                                    <MailOutline style={{ color: "#808080" }} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className="py-3">
                            <FormControl fullWidth>
                                <FormLabel>New Password</FormLabel>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    error={Boolean(errors?.password)}
                                    helperText={errors?.password}
                                    value={values?.password}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(prev => !prev)}>
                                                    {showPassword ? (
                                                        <VisibilityOff style={{ color: "#808080" }} />
                                                    ) : (
                                                        <Visibility style={{ color: "#808080" }} />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className="py-3">
                            <FormControl fullWidth>
                                <FormLabel>Confirm Password</FormLabel>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type={showConfirmPassword ? "text" : "password"}
                                    name="confirmPassword"
                                    error={Boolean(errors?.confirmPassword)}
                                    helperText={errors?.confirmPassword}
                                    value={values?.confirmPassword}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowConfirmPassword(prev => !prev)}>
                                                    {showConfirmPassword ? (
                                                        <VisibilityOff style={{ color: "#808080" }} />
                                                    ) : (
                                                        <Visibility style={{ color: "#808080" }} />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} className="py-3">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-4 col-lg-3">
                                    <Button onClick={handleSubmit} className="t-btn-secondary w-100">
                                        OK
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                )}
            </Formik>
            {/* end::Form */}
        </div>
    );
};

export default ResetPassword;
