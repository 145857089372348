import moment from "moment";
import { array, date, object, ref, string, number } from "yup";

/* FormPrivateTab */
export const validateSchemaPrivate = object({
    student: string()
        .max(255)
        .required("Required."),
    bookingNo: string()
        .max(255)
        .required("Required."),
    listCourseMenu: array().of(
        object().shape({
            bookingDate: date()
                .typeError("Invalid Date")
                .nullable(true)
                .required("Required."),
            startTime: date()
                .nullable(true)
                .required("Required."),
            toTime: date()
                .min(ref("startTime"), "Start Time > To Time", value => {
                    return moment(value, "HH:mm").isSameOrAfter(moment(value, "HH:mm"));
                })
                .nullable(true)
                .required("Required."),
            course: string(),
            courseId: string(),
            menu: string(),
            menuId: string(),
            classroom: string().required("Required."),
            teacher: string().nullable(),
            durationHour: number(),
            durationMinute: number(),
        }),
    ),
});

export const initialValuesPrivate = {
    student: "",
    order: "",
    bookingNo: "",
    note: "",
    absence: [{ student: "", note: "" }],
    listCourseMenu: [],
};
/* end FormPrivateTab */

/* FormGroupTab */
export const validateSchemaGroup = object({
    course: string().required("Required."),
    roomname: string().max(255),
    note: string().max(255),
    limitstudent: string().required("Required."),
    durationHour: number(),
    durationMinute: number(),
    listCourseMenu: array().of(
        object().shape({
            bookingDate: date()
                .typeError("Invalid Date")
                .nullable(true)
                .required("Required."),
            startTime: date()
                .nullable(true)
                .required("Required."),
            toTime: date()
                .min(ref("startTime"), "Start Time > To Time", value => {
                    return moment(value, "HH:mm").isSameOrAfter(moment(value, "HH:mm"));
                })
                .nullable(true)
                .required("Required."),
            classroom: string().required("Required."),
            students: array().min(1, "Required."),
            menuAndTeacher: array().of(
                object().shape({
                    menu: string().required("Required."),
                    teacher: string().nullable(),
                }),
            ),
        }),
    ),
});

export const initialValuesGroup = {
    course: "",
    limitstudent: "",
    roomname: "",
    note: "",
    durationHour: 0,
    durationMinute: 0,
    listCourseMenu: [],
};
/* end FormGroupTab */

/* FormFreeScheduleTab */
export const validateSchemaFreeSchedule = object({
    course: string().required("Required."),
    roomname: string().max(255),
    note: string().max(255),
    limitstudent: string().required("Required."),
    listCourseMenu: array().of(
        object().shape({
            bookingDate: date()
                .typeError("Invalid Date")
                .nullable(true)
                .required("Required."),
            startTime: date()
                .nullable(true)
                .required("Required."),
            toTime: date()
                .min(ref("startTime"), "Start Time > To Time", value => {
                    return moment(value, "HH:mm").isSameOrAfter(moment(value, "HH:mm"));
                })
                .nullable(true)
                .required("Required."),
            classroom: string().required("Required."),
            students: array().min(1, "Required."),
            menuAndTeacher: array().of(
                object().shape({
                    menu: string().required("Required."),
                    teacher: string().nullable(),
                }),
            ),
        }),
    ),
});

export const initialValuesFreeSchedule = {
    course: "",
    limitstudent: "",
    roomname: "",
    note: "",
    listCourseMenu: [],
};
/* end FormFreeScheduleTab */
