import { Box, Button, FormControl, Grid, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Add, Close, Search } from "@material-ui/icons";
import MaterialTable from "material-table";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import { useSelector } from "react-redux";
import { deleteUserById, getAllUser, getUserOption, registerSystem, updateUserById } from "../../../apis/userApi";
import { swalCondition } from "../../../utils/swal";
import { getOptionsTableApp } from "../../helpers/useOption";
import FormCreateUser from "./FormCreateUser";
import { columnTableUsers, getValidateSchemaUsers, initialValuesUsers } from "./helpers/userManageOption";

export default function UsersManageContent() {
    const userMe = JSON.parse(localStorage.getItem("userMe"));

    // ref
    const tableRef = useRef(null);
    const controllerRef = useRef(null);

    // global redux state
    const { thisMenuPermission } = useSelector(state => {
        const { menuPermission } = state?.mainMenu;
        return {
            thisMenuPermission: menuPermission?.find(menu => menu?.path === window.location.pathname)?.permission,
        };
    });

    // component state
    const [modalForm, setModalForm] = useState(false);
    const [valuesSearch, setValuesSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isCreateMode, setIsCreateMode] = useState(false);
    const [tableOptions, setTableOptions] = useState(getOptionsTableApp);
    const [initialFormValues, setInitialFormValues] = useState(initialValuesUsers);
    const [option, setOption] = useState({ branch: [], role: [] });

    // fetch option data
    const fetchOption = useCallback(async signal => {
        try {
            const userOption = await getUserOption(signal);
            if (userOption) {
                setOption(currentState => ({
                    ...currentState,
                    branch: userOption?.branchOption,
                    role: userOption?.roleOption,
                }));
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // close modal
    const handleCloseModal = useCallback(() => {
        setModalForm(false);
        setInitialFormValues(initialValuesUsers);
    }, []);

    // open modal create form
    const handleOpenCreateFormModal = useCallback(() => {
        setIsCreateMode(true);
        setInitialFormValues(initialValuesUsers);
        setModalForm(true);
    }, []);

    // open modal edit form
    const handleOpenEditFormModal = useCallback(user => {
        setIsCreateMode(false);
        setInitialFormValues(currentState => ({
            ...currentState,
            fname: user?.firstname,
            lname: user?.lastname,
            email: user?.email,
            password: user?.password,
            branchId: `${user?.branchId}` || "",
            roleId: `${user?.roleId}` || "",
            user,
        }));
        setModalForm(true);
    }, []);

    // search input
    const handleOnchangeSearch = useCallback(
        evt => {
            const value = evt.target?.value;
            setValuesSearch(value);
            tableRef.current.onQueryChange({ search: value, page: 0 });
        },
        [tableRef],
    );

    // fetch data
    const fetchData = useCallback(async query => {
        try {
            setIsLoading(true);
            const queryParams = {
                page: query?.page + 1 || 1,
                limit: query?.pageSize || 10,
                search: query?.search || "",
                order: query?.orderBy?.field || "id",
                direction: query?.orderBy?.field ? query?.orderDirection : "desc",
            };
            const res = await getAllUser(queryParams, controllerRef.current?.signal);
            if (res) {
                setTableOptions(currentState => ({
                    ...currentState,
                    currentPage: query?.page,
                    pageSize: query?.pageSize || 10,
                    totalData: res?.pagination?.totalData || 0,
                    order: query?.orderBy?.field || "timestamp",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                }));
                setIsLoading(false);
                return {
                    data: res?.data || [],
                    page: query?.page || 0,
                    totalCount: res?.pagination?.totalData || 0,
                };
            }
            setIsLoading(false);
        } catch (error) {
            console.dir(error);
            setIsLoading(false);
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
    }, []);

    // create user logic
    const handleCreateUser = useCallback(async values => {
        try {
            const createBody = {
                roleId: Number(values?.roleId),
                branchId: Number(values?.branchId),
                // fullname: `${values?.fname} ${values?.lname}`,
                firstname: values?.fname,
                lastname: values?.lname,
                email: values?.email,
                password: values?.password,
            };
            const isCreate = await registerSystem(createBody, controllerRef.current?.signal);
            if (isCreate) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // edit user logic
    const handleEditUser = useCallback(async values => {
        try {
            const updateBody = {
                roleId: values?.roleId,
                branchId: values?.branchId,
                firstname: values?.fname,
                lastname: values?.lname,
                email: values?.email,
            };
            if (values?.password) {
                updateBody.password = values?.password;
            }
            const isUpdate = await updateUserById(values?.user?.id, updateBody, controllerRef.current?.signal);
            if (isUpdate) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // change status logic
    const handleChangeStatus = useCallback(async (e, userId) => {
        try {
            const isUpdateStatus = await updateUserById(
                userId,
                { status: e.target?.checked },
                controllerRef.current?.signal,
            );
            if (isUpdateStatus) {
                tableRef.current.onQueryChange();
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // delete user logic
    const handleDeleteUser = useCallback(async user => {
        try {
            const bool = await swalCondition("Confirm delete ?", `${user?.firstname} ${user?.lastname}`, {
                icon: "warning",
            });
            if (bool) {
                const isDelete = await deleteUserById(user?.id, controllerRef.current?.signal);
                if (isDelete) {
                    tableRef.current.onQueryChange();
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // memo
    const tableColumns = useMemo(() => columnTableUsers({ handleChangeStatus, tableOptions }), [
        handleChangeStatus,
        tableOptions,
    ]);

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        const init = async () => {
            await fetchOption(controllerRef.current?.signal);
        };
        init();
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, [fetchOption]);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className="mb-3 mb-md-5">
                <div className="d-md-flex align-items-center justify-content-between">
                    <div>
                        <FormControl style={{ height: "100%" }}>
                            <TextField
                                size="small"
                                variant="outlined"
                                value={valuesSearch}
                                onChange={handleOnchangeSearch}
                                placeholder="Search..."
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search className="text-muted" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </div>
                    {thisMenuPermission?.create && (
                        <div className="d-flex align-items-center">
                            <Button
                                onClick={handleOpenCreateFormModal}
                                size="large"
                                className="btn btn-lg t-btn-primary mt-3 mt-md-0">
                                <Add /> Create User
                            </Button>
                        </div>
                    )}
                </div>
            </Grid>

            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        data={fetchData}
                        options={tableOptions}
                        columns={tableColumns}
                        components={{ Container: Box }}
                        actions={[
                            rowData => ({
                                icon: "edit",
                                iconProps: { className: "action-edit" },
                                tooltip: "Edit",
                                onClick: (event, rowData) => handleOpenEditFormModal(rowData),
                                hidden: rowData?.isAdmin || !thisMenuPermission?.edit,
                            }),
                            rowData => ({
                                icon: "delete",
                                iconProps: { className: "action-delete" },
                                tooltip: "Delete",
                                onClick: (event, rowData) => handleDeleteUser(rowData),
                                hidden: rowData?.isAdmin || rowData?.id === userMe?.id || !thisMenuPermission?.delete,
                            }),
                        ]}
                    />
                </div>
            </Grid>

            <Grid item xs={12}>
                <Modal show={modalForm} scrollable centered onHide={handleCloseModal}>
                    <Modal.Header>
                        <ModalTitle>{isCreateMode ? "Create" : "Edit"} User</ModalTitle>
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <ModalBody>
                        <FormCreateUser
                            onCancel={handleCloseModal}
                            onSubmit={isCreateMode ? handleCreateUser : handleEditUser}
                            initialFormValues={initialFormValues}
                            validateSchemaUsers={getValidateSchemaUsers(isCreateMode)}
                            option={option}
                        />
                    </ModalBody>
                </Modal>
            </Grid>
        </Grid>
    );
}
