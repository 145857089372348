/* eslint-disable */
import React from "react";
import { Column } from "material-table";
import { convertDateTimeFromApi } from "../../../../utils/format";
import { currencyTHB } from "../../../../utils/formatCurrency";
import { StatusPaidComponent } from "../../OrderTransaction/helpers/orderTransactionOption";

export const getColumnsClassHistory = props => {
    /** @type Column */
    const columns = [
        {
            field: "scheduleClassroomHistoryId",
            title: "#",
            sorting: false,
            render: obj => (
                <div>{props?.tableOptions?.currentPage * props?.tableOptions?.pageSize + obj?.tableData?.id + 1}</div>
            ),
        },
        {
            field: "bookingDate",
            title: "Date/Time",
            render: obj => <div>{convertDateTimeFromApi(obj?.bookingDate)}</div>,
        },
        {
            field: "CourseMenu.courseMenuName",
            title: "Course/Menu",
            render: obj => <div>{obj?.menu}</div>,
        },
        {
            field: "Teacher.teacherFirstname",
            title: "Teacher",
            render: obj => <div>{obj?.teacherName}</div>,
        },
        {
            field: "totalScore",
            title: "Score",
            render: obj => <div>{obj?.totalScore || 0}/25</div>,
        },
    ];
    const data = columns?.map(col => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};

export const getColumnsOrderHistory = props => {
    /** @type Column */
    const columns = [
        {
            field: "orderId",
            title: "#",
            sorting: false,
            render: obj => (
                <div>{props?.tableOptions?.currentPage * props?.tableOptions?.pageSize + obj?.tableData?.id + 1}</div>
            ),
        },
        {
            field: "bookingNo",
            title: "Order ID",
        },
        {
            field: "date",
            title: "Purchase Date",
            render: rowData => convertDateTimeFromApi(rowData?.date),
        },
        {
            field: "sale",
            title: "Sales Total",
            render: obj => <div>{currencyTHB(obj?.sale)}</div>,
        },
        {
            field: "package",
            title: "Package Usages",
            render: obj => <div>{currencyTHB(obj?.package)}</div>,
        },
        {
            field: "status",
            title: "Status",
            render: obj => <StatusPaidComponent status={obj?.status} />,
        },
    ];
    const data = columns?.map(col => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};

export const getColumnsAbsence = props => {
    /** @type Column */
    const columns = [
        {
            title: "#",
            sorting: false,
            render: dt => props?.tableOptions?.currentPage * props?.tableOptions?.pageSize + dt?.tableData?.id + 1,
        },
        {
            title: "Date/Time",
            field: "date",
            render: dt =>
                dt?.ScheduleGroupList?.bookingStartDate
                    ? convertDateTimeFromApi(dt?.ScheduleGroupList?.bookingStartDate)
                    : "-",
        },
        {
            title: "Course/Menu",
            field: "CourseMenu.courseMenuName",
        },
        {
            title: "Teacher",
            field: "teacher",
            render: rowData =>
                rowData?.Teacher
                    ? `${rowData?.Teacher?.teacherFirstname} ${rowData?.Teacher?.teacherLastname} (${rowData?.Teacher?.teacherNickname})`
                    : "",
        },
        {
            title: "Note",
            field: "note",
        },
    ];
    const data = columns?.map(col => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};

export const initialStudentProfileFormValues = {
    fname: "",
    lname: "",
    nickname: "",
    classOf: "",
    email: "",
    tel: "",
    lineId: "",
    birthday: new Date(),
    address: "",
    province: "",
    country: "",
    expertise: "",
    sizeApron: "",
    studentNote: "",
    learnObjt: "",
    knowChannels: "",
    allowSocial: "",
    image: {
        id: "",
        name: "",
        base64: "",
    },
};

export const initialStudentScoreFormValues = {
    step: 0,
    taste: 0,
    skill: 0,
    station: 0,
    ontime: 0,
    note: "",
};
