import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "option";

const GET_ORDER_OPTION_URL = `${prefix}/order`;
const GET_ORDER_LIST_OPTION_URL = `${prefix}/order-list`;

/**
 * @func getOrderOption
 * @desc get order option
 * @author izeberg
 * @param {Object} query - query params
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Object} order option
 */
export const getOrderOption = async (query, signal) => {
    try {
        const { data, status } = await axios.get(GET_ORDER_OPTION_URL, {
            signal,
            params: query,
        });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getOrderOption");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getOrderOption */

/**
 * @func getOrderListOption
 * @desc get order list option
 * @author izeberg
 * @param {Object} query - query params
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Object} order list option
 */
export const getOrderListOption = async (query, signal) => {
    try {
        const { data, status } = await axios.get(GET_ORDER_LIST_OPTION_URL, {
            signal,
            params: query,
        });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getOrderListOption");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getOrderListOption */
