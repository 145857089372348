import { Box, Button, FormControl, Grid, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Add, Close, Search } from "@material-ui/icons";
import MaterialTable from "material-table";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import { useSelector } from "react-redux";
import { createBranch, deleteBranchById, getAllBranch, updateBranchById } from "../../../apis/branchApi";
import { swalCondition } from "../../../utils/swal";
import { getOptionsTableApp } from "../../helpers/useOption";
import FormBranch from "./FormBranch";
import { columnTableBranchManage, initialValuesBranch } from "./helpers/branchOptionEvt";

export default function ContentBranch() {
    // ref
    const tableRef = useRef(null);
    const controllerRef = useRef(null);

    // global redux state
    const { thisMenuPermission } = useSelector(state => {
        const { menuPermission } = state?.mainMenu;
        return {
            thisMenuPermission: menuPermission?.find(menu => menu?.path === window.location.pathname)?.permission,
        };
    });

    // component state
    const [modalForm, setModalForm] = useState(false);
    const [valuesSearch, setValuesSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isCreateMode, setIsCreateMode] = useState(false);
    const [tableOptions, setTableOptions] = useState(getOptionsTableApp);
    const [initialFormValues, setInitialFormValues] = useState(initialValuesBranch);

    // close modal
    const handleCloseModal = useCallback(() => {
        setModalForm(false);
        setInitialFormValues(initialValuesBranch);
    }, []);

    // open modal create form
    const handleOpenCreateFormModal = useCallback(() => {
        setIsCreateMode(true);
        setInitialFormValues(initialValuesBranch);
        setModalForm(true);
    }, []);

    // open modal edit form
    const handleOpenEditFormModal = useCallback(branch => {
        setIsCreateMode(false);
        setModalForm(true);
        setInitialFormValues(currentState => ({
            ...currentState,
            id: branch?.branchCode,
            name: branch?.branchName,
            address: branch?.address,
            contact: branch?.contact,
            branch,
        }));
    }, []);

    // search input
    const handleOnchangeSearch = useCallback(
        evt => {
            const value = evt.target?.value;
            setValuesSearch(value);
            tableRef.current.onQueryChange({ search: value, page: 0 });
        },
        [tableRef],
    );

    // fetch data
    const fetchData = useCallback(async query => {
        try {
            setIsLoading(true);
            const queryParams = {
                page: query?.page + 1 || 1,
                limit: query?.pageSize || 10,
                search: query?.search || "",
                order: query?.orderBy?.field || "id",
                direction: query?.orderBy?.field ? query?.orderDirection : "desc",
            };
            const res = await getAllBranch(queryParams, controllerRef.current?.signal);
            if (res) {
                setTableOptions(currentState => ({
                    ...currentState,
                    currentPage: query?.page,
                    pageSize: query?.pageSize || 10,
                    totalData: res?.pagination?.totalData || 0,
                    order: query?.orderBy?.field || "timestamp",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                }));
                setIsLoading(false);
                return {
                    data: res?.data || [],
                    page: query?.page || 0,
                    totalCount: res?.pagination?.totalData || 0,
                };
            }
            setIsLoading(false);
        } catch (error) {
            console.dir(error);
            setIsLoading(false);
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
    }, []);

    // create branch logic
    const handleCreateBranch = useCallback(async values => {
        try {
            const createBody = {
                branchCode: values?.id,
                branchName: values?.name,
                address: values?.address,
                contact: values?.contact,
            };
            const isCreate = await createBranch(createBody, controllerRef.current?.signal);
            if (isCreate) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // edit branch logic
    const handleEditBranch = useCallback(async values => {
        try {
            const updateBody = {
                branchCode: values?.id,
                branchName: values?.name,
                address: values?.address,
                contact: values?.contact,
            };
            const isUpdate = await updateBranchById(values?.branch?.id, updateBody, controllerRef.current?.signal);
            if (isUpdate) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // change status logic
    const handleChangeStatus = useCallback(async (e, branchId) => {
        try {
            const isUpdateStatus = await updateBranchById(
                branchId,
                { status: e.target?.checked },
                controllerRef.current?.signal,
            );
            if (isUpdateStatus) {
                tableRef.current.onQueryChange();
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // delete branch logic
    const handleDeleteBranch = useCallback(async branch => {
        try {
            const bool = await swalCondition("Confirm delete ?", branch?.branchName, {
                icon: "warning",
            });
            if (bool) {
                const isDelete = await deleteBranchById(branch?.id, controllerRef.current?.signal);
                if (isDelete) {
                    tableRef.current.onQueryChange();
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // memo
    const tableColumns = useMemo(() => columnTableBranchManage({ handleChangeStatus, tableOptions }), [
        handleChangeStatus,
        tableOptions,
    ]);

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, []);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className="mb-3 mb-lg-5">
                <div className="d-md-flex justify-content-between align-items-center">
                    <FormControl style={{ height: "100%" }}>
                        <TextField
                            size="small"
                            variant="outlined"
                            value={valuesSearch}
                            onChange={handleOnchangeSearch}
                            placeholder="Search..."
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search className="text-muted" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                    {thisMenuPermission?.create && (
                        <Button
                            onClick={handleOpenCreateFormModal}
                            size="large"
                            className="d-block d-md-inline btn btn-lg t-btn-primary mt-3 mt-md-0">
                            <Add /> Create Branch
                        </Button>
                    )}
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        options={tableOptions}
                        components={{ Container: Box }}
                        data={fetchData}
                        columns={tableColumns}
                        actions={[
                            {
                                icon: "edit",
                                iconProps: { className: "action-edit" },
                                tooltip: "Edit",
                                onClick: (event, rowData) => handleOpenEditFormModal(rowData),
                                hidden: !thisMenuPermission?.edit,
                            },
                            {
                                icon: "delete",
                                iconProps: { className: "action-delete" },
                                tooltip: "Delete",
                                onClick: (event, rowData) => handleDeleteBranch(rowData),
                                hidden: !thisMenuPermission?.delete,
                            },
                        ]}
                    />
                </div>
            </Grid>

            {/* Modal */}
            <Grid item xs={12}>
                <Modal show={modalForm} scrollable centered onHide={handleCloseModal}>
                    <Modal.Header>
                        <ModalTitle>{isCreateMode ? "Create" : "Edit"} Branch</ModalTitle>
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <ModalBody>
                        <FormBranch
                            onCancel={handleCloseModal}
                            onSubmit={isCreateMode ? handleCreateBranch : handleEditBranch}
                            initialFormValues={initialFormValues}
                        />
                    </ModalBody>
                </Modal>
            </Grid>
        </Grid>
    );
}
