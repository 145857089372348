import { Button, Fade, Grid } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import moment from "moment";
import {
    exportStudentClassHistory,
    getStudentClassHistory,
    updateStudentClassHistoryScore,
} from "../../../apis/studentApi";
import { getOptionsTableApp } from "../../helpers/useOption";
import FormEvaluation from "./FormEvaluation";
import { getColumnsClassHistory, initialStudentScoreFormValues } from "./helpers/studentOption";
import exportToExcel from "../../../utils/exportToExcel";

export default function ContentClassHistory({ studentId, studentProfile }) {
    // ref
    const controllerRef = useRef(null);
    const tableRef = useRef(null);

    // componnt state
    const [isLoading, setIsLoading] = useState(false);
    const [isExportLoading, setIsExportLoading] = useState(false);
    const [tableOptions, setTableOptions] = useState({ ...getOptionsTableApp, toolbar: true });
    const [toggleModalForm, setToggleModalForm] = useState(false);
    const [scoreData, setScoreData] = useState(initialStudentScoreFormValues);

    // fetch data
    const fetchData = useCallback(
        async query => {
            try {
                setIsLoading(true);
                const queryParams = {
                    page: query?.page + 1 || 1,
                    limit: query?.pageSize || 10,
                    order: query?.orderBy?.field || "scheduleClassroomHistoryId",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    studentId,
                };
                const res = await getStudentClassHistory(queryParams, controllerRef.current?.signal);
                if (res) {
                    setTableOptions(currentState => ({
                        ...currentState,
                        currentPage: query?.page,
                        pageSize: query?.pageSize || 10,
                        totalData: res?.pagination?.totalData || 0,
                        order: query?.orderBy?.field || "scheduleClassroomHistoryId",
                        direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    }));
                    setIsLoading(false);
                    return {
                        data: res?.data || [],
                        page: query?.page || 0,
                        totalCount: res?.pagination?.totalData || 0,
                    };
                }
                setIsLoading(false);
            } catch (error) {
                console.dir(error);
                setIsLoading(false);
                return {
                    data: [],
                    page: 0,
                    totalCount: 0,
                };
            }
        },
        [studentId],
    );

    // function toggle modal form
    const handleModalForm = useCallback(
        ({ is = false, data = null }) => {
            if (is && data !== null) {
                setScoreData({
                    scheduleClassroomHistoryId: data?.scheduleClassroomHistoryId,
                    step: data?.stepScore || 0,
                    taste: data?.tasteScore || 0,
                    skill: data?.skillScore || 0,
                    station: data?.stationScore || 0,
                    ontime: data?.ontimeScore || 0,
                    note: data?.opinionNote || "",
                });
                setToggleModalForm(true);
                return toggleModalForm;
            }
            setToggleModalForm(false);
        },
        [toggleModalForm],
    );

    // update score logic
    const updateStudentScore = useCallback(
        async values => {
            try {
                const stepScore = values?.step || 0;
                const tasteScore = values?.taste || 0;
                const skillScore = values?.skill || 0;
                const stationScore = values?.station || 0;
                const ontimeScore = values?.ontime || 0;
                const totalScore = stepScore + tasteScore + skillScore + stationScore + ontimeScore;
                const updateBody = {
                    stepScore,
                    tasteScore,
                    skillScore,
                    stationScore,
                    ontimeScore,
                    totalScore,
                    opinionNote: values?.note || "",
                };
                const isUpdate = await updateStudentClassHistoryScore(
                    values?.scheduleClassroomHistoryId,
                    updateBody,
                    controllerRef.current?.signal,
                );
                if (isUpdate) {
                    handleModalForm({ is: false });
                    tableRef.current.onQueryChange();
                }
            } catch (error) {
                console.dir(error);
            }
        },
        [handleModalForm],
    );

    // export excel
    const handleExportExcel = useCallback(async () => {
        try {
            setIsExportLoading(true);
            const queryParams = {
                order: tableOptions?.order || "scheduleClassroomHistoryId",
                direction: tableOptions?.direction || "desc",
                studentId,
            };
            const res = await exportStudentClassHistory(queryParams, controllerRef.current?.signal);
            if (res) {
                const data = res?.map(item => ({
                    "Date/Time": item?.bookingDate ? moment(item?.bookingDate).format("DD/MM/YYYY HH:mm:ss") : "",
                    "Course/Menu": item?.menu || "",
                    Teacher: item?.teacherName || "",
                    Score: `${item?.totalScore || 0}/25`,
                }));
                exportToExcel(data, {
                    fileName: "SCA_class_history",
                    title: `ชื่อนักเรียน : ${studentProfile?.firstname || ""} ${studentProfile?.lastname ||
                        ""} (${studentProfile?.nickname || "-"})`,
                });
            }
            setIsExportLoading(false);
        } catch (error) {
            console.dir(error);
            setIsExportLoading(false);
        }
    }, [tableOptions, studentId, studentProfile]);
    // memo
    const tableColumns = useMemo(() => getColumnsClassHistory({ tableOptions }), [tableOptions]);

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, []);

    return (
        <Fade in>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <div className="t-table-custom student-profile">
                        <MaterialTable
                            tableRef={tableRef}
                            title=""
                            isLoading={isLoading}
                            options={tableOptions}
                            data={fetchData}
                            columns={tableColumns}
                            actions={[
                                {
                                    icon: "edit",
                                    iconProps: { className: "action-edit" },
                                    tooltip: "Edit",
                                    onClick: (event, rowData) => {
                                        handleModalForm({ is: true, data: rowData });
                                    },
                                },
                            ]}
                            components={{
                                Toolbar: props => (
                                    <div className="d-flex justify-content-end align-items-center px-2">
                                        <MTableToolbar {...props} />
                                        <Button
                                            className="btn t-btn-primary"
                                            onClick={handleExportExcel}
                                            disabled={isExportLoading}>
                                            Export Excel
                                        </Button>
                                    </div>
                                ),
                            }}
                        />
                    </div>
                </Grid>
                {/* modal */}
                <Modal show={toggleModalForm} centered size="md" onHide={() => handleModalForm({ is: false })}>
                    <ModalBody>
                        <FormEvaluation
                            onSubmit={updateStudentScore}
                            onCancel={() => handleModalForm({ is: false })}
                            initialValues={scoreData}
                        />
                    </ModalBody>
                </Modal>
            </Grid>
        </Fade>
    );
}
