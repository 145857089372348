import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { toImageUrl } from "../../../utils/format";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import DatepickerApp from "../../componentsUtils/DatePickerApp";
import DropzoneApp from "../../componentsUtils/DropzoneApp";
import SelectApp from "../../componentsUtils/SelectApp";
import TextareaApp from "../../componentsUtils/TextareaApp";
import {
    validationSchemaStudent,
    levelOfExpertise,
    getToKnowFromOption,
    socialMediaPermissionOption,
} from "./helpers/studentManageOption";

export default function StudentForm({ onCancel, onSubmit, initialValuesStudent }) {
    return (
        <Formik initialValues={initialValuesStudent} validationSchema={validationSchemaStudent} onSubmit={onSubmit}>
            {({ values, handleChange, handleSubmit, errors, setFieldValue }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12} className="d-flex flex-column align-items-center">
                        <DropzoneApp
                            src={
                                values?.image?.base64
                                    ? values?.image?.base64
                                    : values?.studentImagePath
                                    ? toImageUrl(values?.studentImagePath)
                                    : ""
                            }
                            onChange={file => {
                                setFieldValue("image", file);
                            }}
                            onRemove={() => {
                                setFieldValue("image", "");
                                setFieldValue("studentImagePath", "");
                            }}
                            styles={{
                                styleImage: { width: "auto", height: 160 },
                            }}
                            noImage={toAbsoluteUrl("/media/users/blank.png")}
                        />
                        <p className="text-muted mt-2"> Allowed file type png , jpg , jpeg.</p>
                    </Grid>
                    {/* first name */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>
                                First name <span className="text-danger">*</span>
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                id="fname"
                                value={values?.fname}
                                onChange={handleChange}
                                error={Boolean(errors.fname)}
                                helperText={errors.fname}
                                placeholder="Firstname"
                            />
                        </FormControl>
                    </Grid>
                    {/* first name */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>
                                Last name <span className="text-danger">*</span>
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                id="lname"
                                value={values?.lname}
                                onChange={handleChange}
                                error={Boolean(errors.lname)}
                                helperText={errors.lname}
                                placeholder="Lastname"
                            />
                        </FormControl>
                    </Grid>
                    {/* nick name */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>Nick name</FormLabel>
                            <TextField
                                variant="outlined"
                                id="nickname"
                                value={values?.nickname}
                                onChange={handleChange}
                                error={Boolean(errors.nickname)}
                                helperText={errors.nickname}
                            />
                        </FormControl>
                    </Grid>
                    {/* class of # */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>Class of #</FormLabel>
                            <TextField
                                variant="outlined"
                                id="classOf"
                                value={values?.classOf}
                                onChange={handleChange}
                                error={Boolean(errors.classOf)}
                                helperText={errors.classOf}
                            />
                        </FormControl>
                    </Grid>
                    {/* email */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>Email</FormLabel>
                            <TextField
                                variant="outlined"
                                type="email"
                                id="email"
                                value={values?.email}
                                onChange={handleChange}
                                error={Boolean(errors.email)}
                                helperText={errors.email}
                                placeholder="Enter email"
                            />
                        </FormControl>
                    </Grid>
                    {/* tel */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>
                                Tel <span className="text-danger">*</span>
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                type="tel"
                                id="tel"
                                value={values?.tel}
                                onChange={handleChange}
                                error={Boolean(errors.tel)}
                                helperText={errors.tel}
                                placeholder="Tel No."
                            />
                        </FormControl>
                    </Grid>
                    {/* LineId */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>Line ID</FormLabel>
                            <TextField
                                variant="outlined"
                                id="lineId"
                                value={values?.lineId}
                                onChange={handleChange}
                                error={Boolean(errors.lineId)}
                                helperText={errors.lineId}
                            />
                        </FormControl>
                    </Grid>

                    {/* birth date */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>Birth Date</FormLabel>
                            <DatepickerApp
                                disableToolbar={false}
                                id="birthday"
                                value={values?.birthday}
                                onChange={date => setFieldValue("birthday", date)}
                                maxDate={new Date()}
                                error={Boolean(errors?.birthday)}
                                helperText={errors?.birthday}
                                placeholder="Select Date"
                            />
                        </FormControl>
                    </Grid>

                    {/* address */}
                    <Grid item xs={12}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>Address</FormLabel>
                            <TextareaApp
                                props={{
                                    textAreaProps: {
                                        id: "address",
                                        value: values?.address,
                                        onChange: handleChange,
                                        rows: 4,
                                    },
                                }}
                                errorMessage={errors?.address}
                            />
                        </FormControl>
                    </Grid>

                    {/* province */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>Province</FormLabel>
                            <TextField
                                variant="outlined"
                                id="province"
                                value={values?.province}
                                onChange={handleChange}
                                error={Boolean(errors.province)}
                                helperText={errors.province}
                            />
                        </FormControl>
                    </Grid>

                    {/* country */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>Country</FormLabel>
                            <TextField
                                variant="outlined"
                                id="country"
                                value={values?.country}
                                onChange={handleChange}
                                error={Boolean(errors.country)}
                                helperText={errors.country}
                            />
                        </FormControl>
                    </Grid>

                    {/* basic */}
                    <Grid item xs={12}>
                        <FormLabel>
                            Level of expertise
                            <span style={{ fontSize: "10px" }} className="col-form-label text-danger">
                                {errors?.expertise}
                            </span>
                        </FormLabel>
                        <FormControl fullWidth className="mb-3 ml-5">
                            <RadioGroup
                                color="primary"
                                name="expertise"
                                value={values?.expertise}
                                onChange={handleChange}>
                                {levelOfExpertise?.map(option => (
                                    <FormControlLabel
                                        key={option?.value}
                                        value={option?.value}
                                        control={<Radio color="primary" />}
                                        label={option?.label}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {/* learning purpose */}
                    <Grid item xs={12}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>Learning Purpose</FormLabel>
                            <TextField
                                variant="outlined"
                                id="learnObjt"
                                value={values?.learnObjt}
                                onChange={handleChange}
                                error={Boolean(errors.learnObjt)}
                                helperText={errors.learnObjt}
                            />
                        </FormControl>
                    </Grid>
                    {/* get to know from */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>Get to know from</FormLabel>
                            <SelectApp
                                size="medium"
                                value={values?.knowChannels}
                                onChange={val => setFieldValue("knowChannels", val)}
                                placeholder="Select"
                                options={getToKnowFromOption}
                                RenderItem={(option, idx) => (
                                    <MenuItem key={`knowChannels${idx}`} value={option?.value}>
                                        {option?.title}
                                    </MenuItem>
                                )}
                                error={Boolean(errors.knowChannels)}
                                helperText={errors.knowChannels}
                            />
                        </FormControl>
                    </Grid>
                    {/* social media permission */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>Social Media Permission</FormLabel>
                            <SelectApp
                                size="medium"
                                value={values?.allowSocial}
                                onChange={val => setFieldValue("allowSocial", val)}
                                placeholder="Select"
                                options={socialMediaPermissionOption}
                                RenderItem={(option, idx) => (
                                    <MenuItem key={`allowSocial${idx}`} value={option?.value}>
                                        {option?.title}
                                    </MenuItem>
                                )}
                                error={Boolean(errors.allowSocial)}
                                helperText={errors.allowSocial}
                            />
                        </FormControl>
                    </Grid>
                    {/* chef shirt size */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>Size of Chef Shirt</FormLabel>
                            <TextField
                                variant="outlined"
                                id="sizeApron"
                                value={values?.sizeApron}
                                onChange={handleChange}
                                error={Boolean(errors.sizeApron)}
                                helperText={errors.sizeApron}
                            />
                        </FormControl>
                    </Grid>
                    {/* student note */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>Student Note</FormLabel>
                            <TextField
                                variant="outlined"
                                id="studentNote"
                                value={values?.studentNote}
                                onChange={handleChange}
                                error={Boolean(errors?.studentNote)}
                                helperText={errors?.studentNote}
                            />
                        </FormControl>
                    </Grid>
                    {/* action */}
                    <Grid item xs={12}>
                        <div className="d-flex justify-content-md-end align-items-center">
                            <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                                <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                            </Button>
                            <Button onClick={handleSubmit} className="btn t-btn-secondary">
                                <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
}
