/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
// import SVG from "react-inlinesvg";
import { checkIsActive } from "../../../../_helpers";
import KTLayoutAsideToggle from "../../../../_assets/js/layout/base/aside-toggle";

export function AsideMenuList({ layoutProps, menu = [] }) {
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url) ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open ` : "";
    };

    const handleCloseAside = async (checkSublv2 = false) => {
        const toggleAside = await KTLayoutAsideToggle?.getToggle();
        if (!checkSublv2) {
            toggleAside.toggleOn();
        }
    };

    return (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            <li className="menu-section ">
                <h4 className="menu-text">{menu[0]?.main?.sidebarMenuName}</h4>
                <i className="menu-icon flaticon-more-v2" />
            </li>
            {menu.length > 0
                ? menu.map((obj, idx) => (
                      <li
                          key={"main" + idx}
                          className={`menu-item ${
                              obj?.sublv2.length > 0 ? "menu-item-submenu" : ""
                          } ${getMenuItemActive(
                              `/${obj?.main?.sidebarMenuPath}/${obj?.sublv1?.sidebarMenuPath}`,
                              obj?.sublv2.length > 0 ? true : false,
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle={obj?.sublv2.length > 0 ? "hover" : ""}>
                          <NavLink
                              onClick={() => handleCloseAside(obj?.sublv2.length > 0)}
                              className={`menu-link ${obj?.sublv2.length > 0 ? "menu-toggle" : ""}`}
                              to={`/${obj?.main?.sidebarMenuPath}/${obj?.sublv1?.sidebarMenuPath}`}>
                              <span className="svg-icon menu-icon">
                                  {/* <SVG src={toAbsoluteUrl(obj.sub_icon)} /> */}
                                  <i className={obj.sublv1?.sidebarMenuIcon}></i>
                              </span>
                              <span className="menu-text">{obj?.sublv1?.sidebarMenuName}</span>
                              {obj?.sublv2.length > 0 ? <i className="menu-arrow" /> : ""}
                          </NavLink>

                          {obj?.sublv2.length > 0 ? (
                              <div className="menu-submenu">
                                  <i className="menu-arrow" />
                                  <ul className="menu-subnav">
                                      <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                          <span className="menu-link">
                                              <span className="menu-text">{obj?.sublv1?.sidebarMenuName}</span>
                                          </span>
                                      </li>
                                      {obj?.sublv2.map((obj_sub, idx_sub) => (
                                          <li
                                              key={"sub_" + idx_sub}
                                              className={`menu-item ${getMenuItemActive(
                                                  `/${obj?.main?.sidebarMenuPath}/${obj?.sublv1?.sidebarMenuPath}/${obj_sub?.sidebarMenuPath}`,
                                                  false,
                                              )}`}
                                              aria-haspopup="true">
                                              <NavLink
                                                  onClick={() => handleCloseAside(false)}
                                                  className="menu-link"
                                                  to={`/${obj?.main?.sidebarMenuPath}/${obj?.sublv1?.sidebarMenuPath}/${obj_sub?.sidebarMenuPath}`}>
                                                  <i className="menu-bullet menu-bullet-dot">
                                                      <span />
                                                  </i>
                                                  <span className="menu-text">{obj_sub?.sidebarMenuName}</span>
                                              </NavLink>
                                          </li>
                                      ))}
                                      {/* begin::2 Level */}

                                      {/* end::2 Level */}
                                  </ul>
                              </div>
                          ) : null}
                      </li>
                  ))
                : ""}
        </ul>
    );
}
