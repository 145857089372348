import { Button, FormControl, FormLabel, Grid, TextField } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { object, string } from "yup";
import { currencyNoSymbol } from "../../../utils/formatCurrency";
import { replaceMoneyWith2Decimal } from "../../../utils/replaceString";

export default function FormPaymentType({
    type,
    paymentMethodId,
    onSubmit,
    onCancel,
    formikValues,
    showPrevPayment = true,
}) {
    const isBalanceForm = type === "Package";
    return (
        <Formik
            enableReinitialize
            validateOnChange
            initialValues={
                isBalanceForm
                    ? {
                          paymentMethodId,
                          type,
                          initialRemaining: formikValues?.remaining || "0.00",
                          remaining: formikValues?.remaining || "",
                          initialBalance: formikValues?.studentBalance || "0.00",
                          balance: formikValues?.studentBalance || "0.00",
                          prevPayment:
                              formikValues?.prevPaymentSelected?.find(item => item?.type === type)?.payment || "",
                          initialPayment:
                              formikValues?.paymentSelected?.find(item => item?.type === type)?.payment || "",
                          payment: formikValues?.paymentSelected?.find(item => item?.type === type)?.payment || "",
                      }
                    : {
                          paymentMethodId,
                          type,
                          initialRemaining: formikValues?.remaining || "0.00",
                          remaining: formikValues?.remaining || "",
                          balance: formikValues?.studentBalance || "0.00",
                          prevPayment:
                              formikValues?.prevPaymentSelected?.find(item => item?.type === type)?.payment || "",
                          initialPayment:
                              formikValues?.paymentSelected?.find(item => item?.type === type)?.payment || "",
                          payment: formikValues?.paymentSelected?.find(item => item?.type === type)?.payment || "",
                      }
            }
            validationSchema={object({
                remaining: string(),
                payment: string()
                    .matches(/^\d*?[.]{0,1}?\d{1,2}$/, { message: "Must be number with 2 digit" })
                    .required("Required."),
            })}
            onSubmit={onSubmit}>
            {({ values, setFieldValue, errors, handleSubmit, setFieldError, isValid }) => {
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div className="mb-3 row align-items-center justify-content-center">
                                <FormLabel className="col-4 mb-0">Remaining Amount : </FormLabel>
                                <FormControl fullWidth className="col-6">
                                    <TextField
                                        name="remaining"
                                        value={currencyNoSymbol(values?.remaining)}
                                        variant="outlined"
                                        size="small"
                                        error={Boolean(errors?.remaining)}
                                        helperText={errors?.remaining}
                                        disabled
                                    />
                                </FormControl>
                            </div>
                        </Grid>
                        {isBalanceForm && (
                            <Grid item xs={12}>
                                <div className="mb-3 row align-items-center justify-content-center">
                                    <FormLabel className="col-4 mb-0">Balance : </FormLabel>
                                    <FormControl fullWidth className="col-6">
                                        <TextField
                                            value={currencyNoSymbol(values?.balance)}
                                            variant="outlined"
                                            size="small"
                                            error={Boolean(errors?.balance)}
                                            helperText={errors?.balance}
                                            disabled
                                        />
                                    </FormControl>
                                </div>
                            </Grid>
                        )}
                        {showPrevPayment && (
                            <Grid item xs={12}>
                                <div className="mb-3 row align-items-center justify-content-center">
                                    <FormLabel className="col-4 mb-0">Previous Payment : </FormLabel>
                                    <FormControl fullWidth className="col-6">
                                        <TextField
                                            name="prevPayment"
                                            value={currencyNoSymbol(values?.prevPayment)}
                                            variant="outlined"
                                            size="small"
                                            error={Boolean(errors?.prevPayment)}
                                            helperText={errors?.prevPayment}
                                            disabled
                                        />
                                    </FormControl>
                                </div>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <div className="mb-3 row align-items-center justify-content-center">
                                <FormLabel className="col-4 mb-0">Payment : </FormLabel>
                                <FormControl fullWidth className="col-6">
                                    <TextField
                                        name="payment"
                                        value={values?.payment}
                                        onChange={async evt => {
                                            const paymentValue = replaceMoneyWith2Decimal(evt?.target?.value);
                                            const initialRemaining = parseFloat(values?.initialRemaining);
                                            const initialPayment = parseFloat(values?.initialPayment || "0.00");
                                            const payment = parseFloat(paymentValue || "0.00");
                                            const remaining = initialRemaining + initialPayment - payment;
                                            await setFieldValue("payment", paymentValue);
                                            await setFieldValue("remaining", remaining);

                                            // has balance form
                                            if (isBalanceForm) {
                                                const initialBalance = parseFloat(values?.initialBalance || "0.00");
                                                const balance = initialBalance + initialPayment - payment;
                                                await setFieldValue("balance", balance);
                                                if (balance < 0) {
                                                    await setFieldError("balance", "Not Enough Balance");
                                                }
                                            }

                                            if (remaining < 0) {
                                                await setFieldError("remaining", "Must greater than or equal 0");
                                            }
                                        }}
                                        variant="outlined"
                                        size="small"
                                        error={Boolean(errors?.payment)}
                                        helperText={errors?.payment}
                                        disabled={
                                            formikValues?.order
                                                ? formikValues?.order?.status === "1" ||
                                                  formikValues?.order?.status === "3"
                                                    ? false
                                                    : true
                                                : false
                                        }
                                    />
                                </FormControl>
                            </div>
                        </Grid>
                        {/* action */}
                        {(!formikValues?.order ||
                            formikValues?.order?.status === "1" ||
                            formikValues?.order?.status === "3") && (
                            <Grid item xs={12}>
                                <div className="d-flex justify-content-end align-items-center">
                                    <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                                        <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                                    </Button>
                                    <Button onClick={isValid ? handleSubmit : () => {}} className="btn t-btn-secondary">
                                        <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                                    </Button>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                );
            }}
        </Formik>
    );
}
