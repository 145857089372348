import React from "react";
import { Grid, Paper } from "@material-ui/core";
import TagList from "../../../components/TagManagement/TagList";

const Tag = () => {
    return (
        <Paper className="p-2 p-md-3 py-3 py-md-5">
            <Grid container spacing={0}>
                <Grid item xs={12} className="mb-3">
                    <h4>Tag Menu</h4>
                </Grid>
                <Grid item xs={12}>
                    <TagList />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default Tag;
