import { Box, Grid, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import MaterialTable from "material-table";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { createCourse, deleteCourseById, getAllCourse, updateCourseById } from "../../../apis/courseApi";
import { uploadImageFile } from "../../../apis/uploadApi";
import { swalCondition } from "../../../utils/swal";
import { getOptionsTableApp } from "../../helpers/useOption";
import FormCourse from "./FormCourse";
import { columnsCourse, initialValuesCourse } from "./helpers/courseOption";
import SearchCourse from "./SearchCourse";
import { getAllTagV2 } from "../../../apis/tagApi";

export default function ContentCourse() {
    // ref
    const tableRef = useRef(null);
    const controllerRef = useRef(null);

    // global redux state
    const { thisMenuPermission } = useSelector(state => {
        const { menuPermission } = state?.mainMenu;
        return {
            thisMenuPermission: menuPermission?.find(menu => menu?.path === window.location.pathname)?.permission,
        };
    });

    // component state
    const [modalForm, setModalForm] = useState(false);
    const [valuesSearch, setValuesSearch] = useState({ status: "", type: "", minprice: "", maxprice: "" });
    const [isLoading, setIsLoading] = useState(false);
    const [option, setOption] = useState({ tag: [] });
    const [isCreateMode, setIsCreateMode] = useState(false);
    const [tableOptions, setTableOptions] = useState({ ...getOptionsTableApp, pageSize: 100 });
    const [initialFormValues, setInitialFormValues] = useState(initialValuesCourse);

    // close modal
    const handleCloseModal = useCallback(() => {
        setModalForm(false);
        setInitialFormValues(initialValuesCourse);
    }, []);

    // open modal create form
    const handleOpenCreateFormModal = useCallback(() => {
        setIsCreateMode(true);
        setInitialFormValues(initialValuesCourse);
        setModalForm(true);
    }, []);

    // open modal edit form
    const handleOpenEditFormModal = useCallback(course => {
        setIsCreateMode(false);
        setModalForm(true);
        setInitialFormValues(currentState => ({
            ...currentState,
            name: course?.courseName,
            price: Number(course?.price),
            limitStd: course?.courseLimitedStudent,
            description: course?.description,
            type: course?.courseType,
            imagePath: course?.imagePath,
            tag: course?.CourseHasTags?.map(courseTag => courseTag?.tagId),
            listMenuName: course?.CourseMenus,
            course,
            durationHr: `${course?.durationHour || ""}`,
            durationMin: `${course?.durationMinute || ""}`,
        }));
    }, []);

    // submit search
    const handleSubmitSearch = useCallback(
        values => {
            setValuesSearch({
                status: values?.status,
                type: values?.type,
                minprice: values?.rangeprice[0] === 0 ? "0" : values?.rangeprice[0],
                maxprice: values?.rangeprice[1] === 0 ? "0" : values?.rangeprice[1],
            });
            tableRef.current.onQueryChange({ search: values?.search, page: 0 });
        },
        [tableRef],
    );

    // fetch data
    const fetchData = useCallback(
        async query => {
            try {
                setIsLoading(true);
                const queryParams = {
                    page: query?.page + 1 || 1,
                    limit: query?.pageSize || 10,
                    search: query?.search || "",
                    order: query?.orderBy?.field || "id",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    type: valuesSearch?.type || "",
                    status: valuesSearch?.status || "",
                    minprice: valuesSearch?.minprice || "",
                    maxprice: valuesSearch?.maxprice || "",
                };
                const res = await getAllCourse(queryParams, controllerRef.current?.signal);
                if (res) {
                    setTableOptions(currentState => ({
                        ...currentState,
                        currentPage: query?.page,
                        pageSize: query?.pageSize || 10,
                        totalData: res?.pagination?.totalData || 0,
                        order: query?.orderBy?.field || "timestamp",
                        direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    }));
                    setIsLoading(false);
                    return {
                        data: res?.data || [],
                        page: query?.page || 0,
                        totalCount: res?.pagination?.totalData || 0,
                    };
                }
                setIsLoading(false);
            } catch (error) {
                console.dir(error);
                setIsLoading(false);
                return {
                    data: [],
                    page: 0,
                    totalCount: 0,
                };
            }
        },
        [valuesSearch],
    );

    // create course logic
    const handleCreateCourse = useCallback(async values => {
        try {
            const createBody = {
                courseName: values?.name,
                courseType: values?.type,
                courseLimitedStudent: values?.limitStd,
                price: values?.price,
                tag: values?.tag,
                durationHour: Number(values?.durationHr),
                durationMinute: Number(values?.durationMin),
                description: values?.description,
                courseMenus: values?.listMenuName?.map(item => item?.id),
            };
            // upload image
            if (values?.image?.file) {
                createBody.imagePath = await uploadImageFile(values?.image?.file, controllerRef.current?.signal);
            }

            const isCreate = await createCourse(createBody, controllerRef.current?.signal);
            if (isCreate) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // edit course logic
    const handleEditCourse = useCallback(async values => {
        try {
            const updateBody = {
                courseName: values?.name,
                courseType: values?.type,
                courseLimitedStudent: values?.limitStd,
                price: values?.price,
                description: values?.description,
                tag: values?.tag,
                durationHour: Number(values?.durationHr),
                durationMinute: Number(values?.durationMin),
                courseMenus: values?.listMenuName?.map(item => ({
                    id: item?.CourseHasMenu?.id,
                    courseMenuId: item?.id,
                })),
                courseMenusDelete: values?.listMenuNameDelete?.map(item => item?.id),
            };
            // upload image
            if (values?.image) {
                if (values?.image?.file) {
                    updateBody.imagePath = await uploadImageFile(values?.image?.file, controllerRef.current?.signal);
                }
            } else {
                updateBody.imagePath = "";
            }

            const isUpdate = await updateCourseById(values?.course?.id, updateBody, controllerRef.current?.signal);
            if (isUpdate) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // change status logic
    const handleChangeStatus = useCallback(async (e, courseId) => {
        try {
            const isUpdateStatus = await updateCourseById(
                courseId,
                { status: e.target?.checked },
                controllerRef.current?.signal,
            );
            if (isUpdateStatus) {
                tableRef.current.onQueryChange();
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // delete course logic
    const handleDeleteCourse = useCallback(async course => {
        try {
            const bool = await swalCondition("Confirm delete ?", course?.courseName, {
                icon: "warning",
            });
            if (bool) {
                const isDelete = await deleteCourseById(course?.id, controllerRef.current?.signal);
                if (isDelete) {
                    tableRef.current.onQueryChange();
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // memo
    const tableColumns = useMemo(() => columnsCourse({ handleChangeStatus, tableOptions }), [
        handleChangeStatus,
        tableOptions,
    ]);

    // fetch option data
    const fetchOption = useCallback(async signal => {
        try {
            const [tag] = await Promise.all([getAllTagV2(signal)]);
            if (tag) {
                setOption(currentState => ({
                    ...currentState,
                    tag: tag?.data,
                }));
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();

        const init = async () => {
            await fetchOption(controllerRef.current?.signal);
        };
        init();
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, [fetchOption]);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className="mb-3 mb-md-5">
                <SearchCourse
                    onSubmit={handleSubmitSearch}
                    handleOpenCreateFormModal={handleOpenCreateFormModal}
                    permission={thisMenuPermission?.create}
                />
            </Grid>

            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        options={tableOptions}
                        components={{ Container: Box }}
                        columns={tableColumns}
                        data={fetchData}
                        actions={[
                            {
                                icon: "edit",
                                iconProps: { className: "action-view" },
                                tooltip: "Edit",
                                onClick: (event, rowData) => handleOpenEditFormModal(rowData),
                                hidden: !thisMenuPermission?.edit,
                            },
                            {
                                icon: "delete",
                                iconProps: { className: "action-delete" },
                                tooltip: "Delete",
                                onClick: (event, rowData) => handleDeleteCourse(rowData),
                                hidden: !thisMenuPermission?.delete,
                            },
                        ]}
                    />
                </div>
            </Grid>

            <Grid item xs={12}>
                <Modal show={modalForm} scrollable centered size="lg" onHide={handleCloseModal}>
                    <Modal.Header>
                        <Modal.Title>{isCreateMode ? "Create" : "Edit"} Course</Modal.Title>
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body>
                        <FormCourse
                            onCancel={handleCloseModal}
                            onSubmit={isCreateMode ? handleCreateCourse : handleEditCourse}
                            initialFormValues={initialFormValues}
                            controllerRef={controllerRef}
                            option={option}
                        />
                    </Modal.Body>
                </Modal>
            </Grid>
        </Grid>
    );
}
