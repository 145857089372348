/* eslint-disable */
import moment from "moment";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { array, boolean, date, object, string } from "yup";
import { CalendarOptions } from "@fullcalendar/react";

export const initialValuesHolidays = {
    type: "",
    titleInput: "",
    teacher: "",
    field_date: [moment().add(1, "day").format("YYYY-MM-DD")],
    description: "",
};

export const validateSchemaCreateHolidays = object({
    type: string().required("Required."),
    titleInput: string().required("Required."),
    teacher: string().required("Required."),
    field_date: array().min(1, "Please select at least 1 days"),
    description: string(),
});

export const dayHeaderDidMount = (arg, valueSelectDate) => {
    const { view, el, date } = arg;
    if (view.type === "timeGridWeek") {
        const dnow = moment(valueSelectDate);
        el.querySelector(".fc-col-header-cell-cushion").innerHTML = `
            <div>
                <div class="h5 ${
                    dnow.format("DD-MM-YYYY") === moment(date).format("DD-MM-YYYY") ? "text-primary" : " "
                }">${moment(date).format("ddd")}</div>
                <small class="${
                    dnow.format("DD-MM-YYYY") === moment(date).format("DD-MM-YYYY") ? "text-primary" : " "
                }">${moment(date).format("DD-MM-YYYY")}</small>
            </div>
            `;
    }
};

export const slotLabelDidMount = arg => {
    const { view, el, date } = arg;
    if (view.type === "timeGridWeek") {
        const tnow = moment();
        const tprev = moment(`${moment().format("YYYY-MM-DD")}T${moment(date).format("HH:mm:ss")}+07:00`);
        const tnext = moment(`${moment().format("YYYY-MM-DD")}T${moment(date).format("HH:mm:ss")}+07:00`).add(
            3599,
            "seconds",
        );

        el.querySelector(".fc-timegrid-slot-label-cushion").innerHTML = `<small class="${
            tnow.isBetween(tprev, tnext) ? "text-danger" : "text-dark"
        }">${moment(date).format("HH:mm")}</small>`;
    }
};

/** @type CalendarOptions */
export const optionScheduleCalendar = {
    viewClassNames: "schedule-view-months-app",
    selectable: true,
    headerToolbar: false,
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
    initialView: "dayGridMonth",
    slotMinTime: "06:00:00",
    slotMaxTime: "20:00:00",
    slotEventOverlap: false,
    dayMaxEvents: 2,
};
