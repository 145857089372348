import { swalCondition } from "../../utils/swal";

/**
 * Start
 * @func apiErrorHandler
 * @desc manage error from api
 * @author izeberg
 * @param {Object} error - error object from try catch
 * @param {string} functionName - ชื่อฟังก์ชันที่เกิด error (getAllUserManagementLogs)
 * @returns {undefined|Object} undefined (axios cancel), error (other error)
 */
export const apiErrorHandler = (error, functionName) => {
    // axios cancel by AbortController
    if (error?.message === "canceled") {
        console.warn(`Function ${functionName} | Error: axios is canceled`);
        return undefined;
    }

    if ([400, 413].includes(error?.response?.status)) {
        swalCondition("Error !!!", `${error?.response?.data?.error?.message || error?.message}`, {
            icon: "error",
            showCancelButton: false,
            confirmButtonText: "OK",
        });
    }

    // other error
    console.error(`Function ${functionName} | Error: ${error?.response?.data?.error?.message || error?.message}`);
    return error;
};
/** End @func apiErrorHandler */
