import { FormHelperText, makeStyles } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import React from "react";
import Select, { components } from "react-select";

const useStyleByClass = makeStyles(() => ({
    containerStyle: {
        position: "relative",
        "& .select2-app.__control": {
            font: "inherit",
            color: "currentColor",
            width: "100%",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            boxShadow: "0 0 0 0 rgba(0, 0, 0, 0.23)",
            minHeight: "1.1876em",
            margin: 0,
            padding: "6px 0 7px",
            minWidth: 0,
            background: "none",
            boxSizing: "content-box",
            animationName: "mui-auto-fill-cancel",
            letterSpacing: "inherit",
        },
        "& .select2-app.__control.sm": {
            font: "inherit",
            color: "currentColor",
            width: "100%",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            boxShadow: "0 0 0 0 rgba(0, 0, 0, 0.23)",
            minHeight: "2.925em",
            margin: 0,
            padding: 0,
            minWidth: 0,
            background: "none",
            boxSizing: "content-box",
            animationName: "mui-auto-fill-cancel",
            letterSpacing: "inherit",
        },
        "& .select2-app.__control.__control--is-disabled": {
            background: "#33333310",
        },
        "& .select2-app.__control.__control--is-focused.__control--menu-is-open": {
            borderColor: "#E4B07B",
            borderWidth: 1,
            boxShadow: "0 0 0 1px #E4B07B",
        },
        "& .select2-app.__control.__control--is-focused.__control--menu-is-open .select2-app.__indicator.__dropdown-indicator": {
            transform: "rotate(180deg)",
        },
        "& .select2-app.__multi-value": {
            borderRadius: 10,
            overflow: "hidden",
        },
        "& .select2-app.__option.__option--is-focused": {
            backgroundColor: "#E4B07B30",
        },
        "& .select2-app.__indicator-separator": {
            display: "none !important",
        },
        "& .select2-app.__placeholder": {
            color: "#b8b8b8",
            fontWeight: "lighter",
        },
        "& .select2-app.__menu": {
            transition: "all 0.15s",
            animation: "fadeDropdown 0.15s ease-in-out",
            boxShadow: "0 0 20px #50505030",
            borderRadius: 2,
            marginTop: 1,
            zIndex: 11,
        },
        "& .select2-app.__control.is-valid": {
            borderColor: "#dc3545",
            borderWidth: 1,
            boxShadow: "0 0 0 0px #dc3545",
        },
        "& .select2-app.__control.is-valid.__control--is-focused.__control--menu-is-open": {
            borderColor: "#dc3545",
            borderWidth: 1,
            boxShadow: "0 0 0 1px #dc3545",
        },
        "& .t-error-app": {
            color: "#dc3545",
            position: "absolute",
        },
        "& .select2-app.__dropdown-indicator": {
            padding: "3px !important",
        },
        "& .select2-app.__clear-indicator": {
            padding: "3px !important",
        },
    },
}));

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowDropDown />
        </components.DropdownIndicator>
    );
};

/**
 * @param props
 * @param error string
 * @param size string "sm" , "md" default "sm"
 * @type Select
 */

export default function SelectSearchMultiApp(props) {
    const classe = useStyleByClass();
    const { classNamePrefix, error, size } = props;
    return (
        <div className={classe.containerStyle}>
            <Select
                isMulti
                menuPosition="fixed"
                {...props}
                components={{ DropdownIndicator }}
                classNamePrefix={`select2-app ${classNamePrefix || " "} ${error ? "is-valid" : ""}  ${
                    size === "md" ? "" : "sm"
                } `}
            />
            {Boolean(error) && <FormHelperText className="t-error-app">{error}</FormHelperText>}
        </div>
    );
}
