import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, FormLabel, Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { GetApp } from "@material-ui/icons";
import { getOptionsTableApp } from "../../helpers/useOption";
import DatePickerApp from "../../componentsUtils/DatePickerApp";
import SelectSearchApp from "../../componentsUtils/SelectSearchApp";
import { getColumnsTablePackageReport } from "./helpers/optionPackageReport";
import { getMenuOption, getReportPackage } from "../../../apis/reportApi";
import { currencyTHB } from "../../../utils/formatCurrency";
import exportToExcel from "../../../utils/exportToExcel";

export default function PackageSummaryContent() {
    // ref
    const tableRef = useRef(null);
    const controllerRef = useRef(null);

    // component state
    const [isLoading, setIsLoading] = useState(false);
    const [dataExcel, setDataExcel] = useState([]);
    const [total, setTotal] = useState(0);
    const [dataTable, setDataTable] = useState([]);
    const [dataFilter, setDataFilter] = useState({
        branch: {},
        package: {},
        student: {},
        date: [new Date(), new Date()],
        expired: null,
    });
    const [options, setOption] = useState({
        isAdmin: false,
        branchId: 0,
        branchOption: [],
        packageOption: [],
        studentOption: [],
        tagOption: [],
    });

    // function filter
    const handleOnchageFilter = useCallback((value, typeField, indexDate = null) => {
        setDataFilter(prevState => {
            const newDataFilter = {};
            if (typeField === "date" && indexDate !== null) {
                if (value) {
                    newDataFilter.date = prevState.date.map((d, idx) => (idx === indexDate ? value : d));
                    if (indexDate === 0) {
                        if (value.getTime() > prevState.date[1].getTime()) {
                            newDataFilter.date = prevState.date.map((d, idx) => (idx === 1 ? value : d));
                        }
                    }
                }
                return { ...prevState, ...newDataFilter };
            }
            newDataFilter[typeField] = value;
            return { ...prevState, ...newDataFilter };
        });
    }, []);

    // export excel
    const handleExportExcel = useCallback(() => {
        if (dataExcel.length < 1) return false;
        exportToExcel(dataExcel, { fileName: "report-package", sumColumn: ["Price", "Credit"] });
    }, [dataExcel]);

    // fetch option
    const fetchOption = useCallback(async () => {
        try {
            const option = await getMenuOption({ type: "3" }, controllerRef?.current?.signal);
            if (option) {
                setOption(option);
                if (!option?.isAdmin) {
                    setDataFilter(state => ({ ...state, branch: { id: option?.branchId } }));
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    const fetch = useCallback(async () => {
        setIsLoading(true);
        try {
            const queryParams = {
                branchId: dataFilter?.branch?.id || "",
                studentId: dataFilter?.student?.id || "",
                startDate: dataFilter?.date[0] || "",
                endDate: dataFilter?.date[1] || "",
                packageId: dataFilter?.package?.id || "",
                expiredDate: dataFilter?.expired || "",
            };
            const res = await getReportPackage(queryParams, controllerRef?.current?.signal);
            if (res) {
                setDataTable(res);
                let totalPrice = 0;
                for (let i = 0; i < res?.length; i++) {
                    totalPrice += Number.isNaN(Number(res[i]?.price)) ? 0 : Number(res[i]?.price);
                }
                setTotal(totalPrice);
                const excel = res?.map(item => ({
                    Branch: item?.branchName || "",
                    "Package Name": item?.packageName || "",
                    "Expired Period": item?.period || "",
                    Student: item?.studentName || "",
                    "Purchased Date": item?.purchasedDate || "",
                    "Expired Date": item?.expiredDate || "",
                    Price: item?.price || 0,
                    Credit: item?.credit || 0,
                }));
                setDataExcel(excel);
            }
        } catch (error) {
            console.dir(error);
        } finally {
            setIsLoading(false);
        }
    }, [dataFilter]);

    useEffect(() => {
        controllerRef.current = new AbortController();
        fetchOption();
        return () => controllerRef.current?.abort();
    }, [fetchOption]);

    useEffect(() => {
        fetch();
    }, [dataFilter, fetch]);

    return (
        <Grid container spacing={0}>
            {/* filter */}
            <Grid item xs={12} className="my-10">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={10}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={2} className="d-md-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Branch :</FormLabel>
                                <div className="flex-fill">
                                    <SelectSearchApp
                                        size="sm"
                                        options={[{ branch_name: "All" }, ...options?.branchOption]}
                                        value={
                                            options?.branchOption?.find(it => it.id === dataFilter?.branch?.id) || ""
                                        }
                                        onChange={value => handleOnchageFilter(value.id ? value : {}, "branch")}
                                        getOptionLabel={option => option?.branch_name}
                                        getOptionValue={option => option?.id}
                                        isDisabled={!options?.isAdmin}
                                        placeholder="All"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3} className="d-md-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Package :</FormLabel>
                                <div className="flex-fill">
                                    <SelectSearchApp
                                        size="sm"
                                        options={[{ package_name: "All" }, ...options?.packageOption]}
                                        value={
                                            options?.packageOption?.find(it => it.id === dataFilter?.package?.id) || ""
                                        }
                                        onChange={value => handleOnchageFilter(value.id ? value : {}, "package")}
                                        getOptionLabel={option => option?.package_name}
                                        getOptionValue={option => option?.id}
                                        placeholder="All"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3} className="d-md-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Student :</FormLabel>
                                <div className="flex-fill">
                                    <SelectSearchApp
                                        size="sm"
                                        options={[{ id: "" }, ...options?.studentOption]}
                                        value={
                                            options?.studentOption?.find(it => it.id === dataFilter?.student?.id) || ""
                                        }
                                        onChange={value => handleOnchageFilter(value.id ? value : {}, "student")}
                                        getOptionLabel={option => {
                                            if (!option?.id) return "All";
                                            return option?.student_firstname && option?.student_lastname
                                                ? `${option?.student_firstname} ${option?.student_lastname} (${option?.student_nickname})`
                                                : "";
                                        }}
                                        getOptionValue={option => option?.id}
                                        placeholder="All"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} className="d-md-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Start Purchased Date : </FormLabel>
                                <div className="d-flex align-items-center justify-content-center flex-fill">
                                    <DatePickerApp
                                        className="flex-fill fixed-h-lg"
                                        size="small"
                                        value={dataFilter?.date[0]}
                                        onChange={date => {
                                            handleOnchageFilter(date, "date", 0);
                                        }}
                                    />
                                    <small className="mx-3">To</small>
                                    <DatePickerApp
                                        className="flex-fill fixed-h-lg"
                                        size="small"
                                        minDate={dataFilter?.date[0]}
                                        value={dataFilter?.date[1]}
                                        onChange={date => {
                                            handleOnchageFilter(date, "date", 1);
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={2} className="d-md-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Expired Date :</FormLabel>
                                <div className="flex-fill">
                                    <DatePickerApp
                                        className="flex-fill w-100 fixed-h-lg"
                                        size="small"
                                        value={dataFilter?.expired}
                                        onChange={date => {
                                            handleOnchageFilter(date, "expired");
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <div className="d-flex align-items-center mt-lg-6">
                                    <Button
                                        size="large"
                                        className="btn t-btn-primary fixed-h-lg flex-lg-grow-0 flex-fill mr-2"
                                        onClick={() => {
                                            setDataFilter({
                                                branch: {},
                                                package: {},
                                                student: {},
                                                date: [new Date(), new Date()],
                                                expired: null,
                                            });
                                            // tableRef.current.onQueryChange({ search: "", page: 0 });
                                        }}>
                                        Clear
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <div className="d-flex align-items-center justify-content-lg-end mt-lg-6">
                            <Button
                                size="large"
                                className={`${dataExcel.length < 1 &&
                                    "disabled"} btn t-btn-primary fixed-h-lg flex-lg-grow-0 flex-fill`}
                                onClick={handleExportExcel}>
                                <GetApp style={{ fontSize: 22 }} />
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            {/* data table */}
            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        options={getOptionsTableApp}
                        components={{ Container: Box }}
                        data={dataTable}
                        columns={getColumnsTablePackageReport()}
                    />
                </div>
                <div className="d-flex justify-content-end px-20 mt-10">
                    <p className="mr-5 h5">Total</p>
                    <p className="h5" style={{ textDecoration: "underline" }}>
                        {currencyTHB(total)}
                    </p>
                </div>
            </Grid>
        </Grid>
    );
}
