import { IconButton, List, ListItem, Menu, MenuItem } from "@material-ui/core";
import { DateRange, MoreVert } from "@material-ui/icons";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { colourNameToHex } from "../../../utils/colourNameToHex";
import { invertColor } from "../../../utils/invertColor";
import { swalCondition } from "../../../utils/swal";
import { deleteScheduleGroupList, deleteSchedulePrivateList } from "../../../apis/scheduleApi";

function MenuComponent({ idx, item, textColor, handleOpenModalSchedule, handleDeleteCard }) {
    const [isToggleActionsCard, setIsToggleActionsCard] = useState(null);

    return (
        <div
            style={{ top: 5, right: 5 }}
            className="position-absolute d-flex justify-content-end align-items-center w-100">
            <IconButton
                onClick={evt => setIsToggleActionsCard(evt?.target || null)}
                size="small"
                style={{ color: textColor }}>
                <MoreVert />
            </IconButton>
            <Menu
                id={`list_classroom_${idx}`}
                open={Boolean(isToggleActionsCard)}
                anchorEl={isToggleActionsCard}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                onClose={() => setIsToggleActionsCard(null)}>
                <MenuItem
                    onClick={() => {
                        setIsToggleActionsCard(null);
                        handleOpenModalSchedule("edit", item);
                    }}>
                    Edit
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setIsToggleActionsCard(null);
                        handleDeleteCard(item);
                    }}>
                    Delete
                </MenuItem>
            </Menu>
        </div>
    );
}

export default function ContentListClassroom({
    data = [],
    valueDate = new Date(),
    handleOpenModalSchedule = () => {},
    forceRenderMonth = () => {},
}) {
    // ref
    const controllerRef = useRef(null);

    // delete classroom card
    const handleDeleteCard = useCallback(
        async item => {
            const bool = await swalCondition("Delete Data", "Do you want to delete?", {
                icon: "warning",
            });
            if (bool) {
                try {
                    if (["0"].includes(item?.typeCode)) {
                        const listIDs = item?.data?.SchedulePrivateLists?.map(
                            list => list?.schedulePrivateListId,
                        )?.join(",");
                        const isDelete = await deleteSchedulePrivateList({ listIDs }, controllerRef.current?.signal);
                        if (isDelete) {
                            forceRenderMonth();
                        }
                    }
                    if (["1", "2"].includes(item?.typeCode)) {
                        const listIDs = item?.data?.ScheduleGroupLists?.map(list => list?.scheduleGroupListId)?.join(
                            ",",
                        );
                        const isDelete = await deleteScheduleGroupList({ listIDs }, controllerRef.current?.signal);
                        if (isDelete) {
                            forceRenderMonth();
                        }
                    }
                } catch (error) {
                    console.dir(error);
                }
            }
        },
        [forceRenderMonth],
    );

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();

        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, []);

    return (
        <div>
            {/* list classroom */}
            <div>
                <div className="d-flex align-items-center justify-content-between mb-5">
                    <div className="d-flex align-items-center">
                        <DateRange />
                        <p className="mb-0 ml-1 h4">Classroom</p>
                    </div>
                    <p className="mb-0 text-muted">
                        {moment(valueDate).format("DDMMYYYY") === moment(new Date()).format("DDMMYYYY")
                            ? "Today"
                            : moment(valueDate).format("ddd, DD MMM YYYY")}
                    </p>
                </div>

                <div>
                    <List className="box-schedule-calendar-app">
                        {data.length > 0 ? (
                            data.map((item, idx) => {
                                const textColor = invertColor(colourNameToHex(item?.color), true);
                                return (
                                    <ListItem
                                        key={`list_classroom_${idx}`}
                                        className="rounded d-block mb-3 card-item-calendar-app position-relative"
                                        style={{
                                            backgroundColor: item?.color,
                                            color: textColor,
                                        }}>
                                        <MenuComponent
                                            idx={idx}
                                            item={item}
                                            textColor={textColor}
                                            handleOpenModalSchedule={handleOpenModalSchedule}
                                            handleDeleteCard={handleDeleteCard}
                                        />
                                        {["0"].includes(item?.typeCode) && (
                                            <>
                                                {item?.paidStatus ? (
                                                    <div
                                                        className="d-flex align-items-center mb-1"
                                                        style={{ color: textColor }}>
                                                        <span className="mr-2 col-4">Paid Status : </span>
                                                        <p className="mb-0 flex-fill">{item?.paidStatus}</p>
                                                    </div>
                                                ) : null}
                                                {item?.bookingStatus ? (
                                                    <div
                                                        className="d-flex align-items-center mb-1"
                                                        style={{ color: textColor }}>
                                                        <span className="mr-2 col-4">Booking Status : </span>
                                                        <p className="mb-0 flex-fill">{item?.bookingStatus}</p>
                                                    </div>
                                                ) : null}
                                            </>
                                        )}
                                        {["1", "2"].includes(item?.typeCode) && (
                                            <>
                                                {item?.roomName ? (
                                                    <div
                                                        className="d-flex align-items-center mb-1"
                                                        style={{ color: textColor }}>
                                                        <span className="mr-2 col-4">Room Name : </span>
                                                        <p className="mb-0 flex-fill">{item?.roomName}</p>
                                                    </div>
                                                ) : null}
                                                {item?.courseName ? (
                                                    <div
                                                        className="d-flex align-items-center mb-1"
                                                        style={{ color: textColor }}>
                                                        <span className="mr-2 col-4">Course Name : </span>
                                                        <p className="mb-0 flex-fill">{item?.courseName}</p>
                                                    </div>
                                                ) : null}
                                            </>
                                        )}
                                        <div className="d-flex align-items-center mb-1" style={{ color: textColor }}>
                                            <span className="mr-2 col-4">Room : </span>
                                            <p className="mb-0 flex-fill">{item?.classroomName}</p>
                                        </div>
                                        <div className="d-flex align-items-center mb-1" style={{ color: textColor }}>
                                            <span className="mr-2 col-4">Time : </span>
                                            <p className="mb-0 flex-fill">{item?.time}</p>
                                        </div>
                                        {item?.student && (
                                            <div
                                                className="d-flex align-items-center mb-1"
                                                style={{ color: textColor }}>
                                                <span className="mr-2 col-4">Student : </span>
                                                <p className="mb-0 flex-fill">{item?.student}</p>
                                            </div>
                                        )}
                                        <div className="d-flex align-items-center mb-1" style={{ color: textColor }}>
                                            <span className="mr-2 col-4">Type : </span>
                                            <p className="mb-0 flex-fill">{item?.type}</p>
                                        </div>
                                        {typeof item?.menu !== "string" && typeof item?.teacher !== "string" ? (
                                            <>
                                                <div
                                                    className="d-flex align-items-center mb-1"
                                                    style={{ color: textColor }}>
                                                    <span className="mr-2 col-4">Menu : </span>
                                                    <p className="mb-0 flex-fill" />
                                                </div>
                                                {item?.menu?.map((menuData, index) => (
                                                    <div key={index} className="d-flex align-items-center">
                                                        <p className="mb-1 flex-fill col-12 ml-5">
                                                            {`${index + 1}. ${menuData} - ${
                                                                item?.teacher[index]
                                                                    ? `${item?.teacher[index]}`
                                                                    : item?.teacher[item?.teacher?.length - 1]
                                                            }`}
                                                        </p>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className="d-flex align-items-center mb-1"
                                                    style={{ color: textColor }}>
                                                    <span className="mr-2 col-4">Menu : </span>
                                                    <p className="mb-0 flex-fill">{item?.menu}</p>
                                                </div>
                                                <div
                                                    className="d-flex align-items-center mb-1"
                                                    style={{ color: textColor }}>
                                                    <span className="mr-2 col-4">Teacher : </span>
                                                    <p className="mb-0 flex-fill">{item?.teacher}</p>
                                                </div>
                                            </>
                                        )}
                                        {item?.type !== "Private" && (
                                            <div
                                                className="d-flex align-items-center mb-1"
                                                style={{ color: textColor }}>
                                                <span className="mr-2 col-4">Student Qty : </span>
                                                <p className="mb-0 flex-fill">{item?.studentQty}</p>
                                            </div>
                                        )}
                                        <div className="d-flex align-items-center mb-1" style={{ color: textColor }}>
                                            <span className="mr-2 col-4">Note : </span>
                                            <p className="mb-0 flex-fill">{item?.note}</p>
                                        </div>
                                    </ListItem>
                                );
                            })
                        ) : (
                            <div className="my-5 text-muted h4 text-center">ไม่พบข้อมูลที่ต้องการ</div>
                        )}
                    </List>
                </div>
            </div>
        </div>
    );
}
