/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { Modal } from "react-bootstrap";
import { CircularProgress, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {
    getValidateSchemaUsers,
    initialValuesUsers,
} from "../../../../../app/components/UsersManagement/helpers/userManageOption";
import FormCreateUser from "../../../../../app/components/UsersManagement/FormCreateUser";
import { toAbsoluteUrl } from "../../../../_helpers";
import { getUserById, getUserMe, getUserOption, updateUserById } from "../../../../../apis/userApi";
import { swalCondition, swalToast } from "../../../../../utils/swal";
import { axiosAuthInstance } from "../../../../../axios/auth.axios";
// import { getUserMe } from "../../../../../apis/userApi";

export function QuickUser() {
    // ref
    const controllerRef = useRef(null);
    const [modalForm, setModalForm] = useState(false);
    const [isLoading, setIsLoading] = useState({ user: false });
    const [initialFormValues, setInitialFormValues] = useState(initialValuesUsers);
    // component state
    const [userMe, setUserMe] = useState({
        id: "",
        firstname: "",
        lastname: "",
        roleName: "",
        branchName: "",
        isAdmin: false,
    });
    const [option, setOption] = useState({ branch: [], role: [] });

    // fetch option data
    const fetchOption = useCallback(async signal => {
        try {
            const userOption = await getUserOption(signal);
            if (userOption) {
                setOption(currentState => ({
                    ...currentState,
                    branch: userOption?.branchOption,
                    role: userOption?.roleOption,
                }));
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // modal
    const handleOpenModal = useCallback(async () => {
        try {
            setIsLoading(prev => ({ ...prev, user: true }));
            const { id } = userMe;
            const response = await getUserById({ id });
            if (response) {
                setInitialFormValues({
                    fname: response?.firstname,
                    lname: response?.lastname,
                    email: response?.email,
                    branchId: response?.branchId || "",
                    roleId: response?.roleId || "",
                    password: "",
                    user: { ...response, id },
                });
                setModalForm(true);
            }
            setIsLoading(prev => ({ ...prev, user: false }));
        } catch (error) {
            console.dir(error);
        }
    }, [userMe]);

    const handleCloseModal = useCallback(() => {
        setModalForm(false);
    }, []);

    // function for update data users
    const handlerForSubmitFormEditUser = useCallback(async values => {
        try {
            const updateBody = {
                roleId: values?.roleId,
                branchId: values?.branchId,
                firstname: values?.fname,
                lastname: values?.lname,
                email: values?.email,
            };
            if (values?.password) {
                updateBody.password = values?.password;
            }
            const isUpdate = await updateUserById(values?.user?.id, updateBody, controllerRef.current?.signal);
            if (isUpdate) {
                setModalForm(false);
                setInitialFormValues(initialValuesUsers);
                swalToast({
                    title: "Update Success",
                    icon: "success",
                    color: "#28a745",
                });
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    const logoutClick = async () => {
        const toggle = document.getElementById("kt_quick_user_toggle");
        const isConfrimed = await swalCondition("", "Are you sure you want to logout ?", {
            icon: "warning",
        });
        if (isConfrimed) {
            if (toggle) {
                toggle.click();
            }
            await axiosAuthInstance.get("web-refresh-access/logout");
            localStorage.clear();
            window.location.reload();
        }
    };

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        const init = async () => {
            const userMeRes = await getUserMe(controllerRef.current?.signal);
            if (userMeRes) {
                setUserMe(userMeRes);
            }
            await fetchOption(controllerRef.current?.signal);
        };
        init();
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, [fetchOption]);

    return (
        <div id="kt_quick_user" className="offcanvas offcanvas-left offcanvas p-10">
            <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                <h3 className="font-weight-bold m-0">
                    User Profile
                    <small className="text-muted font-size-sm ml-2">12 messages</small>
                </h3>
                <a href="#" className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
                    <i className="ki ki-close icon-xs text-muted" />
                </a>
            </div>

            <div className="offcanvas-content pr-5 mr-n5">
                <div className="d-flex align-items-center mt-5">
                    <div className="symbol symbol-100 mr-5">
                        <div
                            className="symbol-label"
                            style={{
                                backgroundImage: `url(${toAbsoluteUrl("/media/logos/logo-sweetcottage.png")})`,
                            }}
                        />
                        {/* <i className="symbol-badge bg-success" /> */}
                    </div>
                    <div className="d-flex flex-column">
                        <a href="#" className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
                            {userMe?.firstname} {userMe?.lastname}
                        </a>
                        {/* <div className="text-muted mt-1">{user.occupation}</div> */}
                        <div className="navi mt-2">
                            {/* <a href="#" className="navi-item">
                                <span className="navi-link p-0 pb-2">
                                    <span className="navi-icon mr-1">
                                        <span className="svg-icon-lg svg-icon-primary">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Communication/Mail-notification.svg",
                                                )}
                                            />
                                        </span>
                                    </span>
                                    
                                </span>
                            </a> */}
                            {/* <span className="navi-text text-muted text-hover-primary"> {"user"} </span> */}
                        </div>
                        {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
                        <button type="button" className="btn btn-light-primary btn-bold" onClick={logoutClick}>
                            Sign out
                        </button>
                    </div>
                </div>

                <div className="separator separator-dashed mt-8 mb-5" />

                <div className="navi navi-spacer-x-0 p-0">
                    <Link to="#" onClick={handleOpenModal} className="navi-item">
                        <div className="navi-link">
                            <div className="symbol symbol-40 bg-light mr-3">
                                <div className="symbol-label">
                                    {isLoading.user ? (
                                        <CircularProgress color="primary" size={30} />
                                    ) : (
                                        <span className="svg-icon svg-icon-md svg-icon-success">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Notification2.svg")} />
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="navi-text">
                                <div className="font-weight-bold">My Profile</div>
                                <div className="text-muted">
                                    Edit password{" "}
                                    {/* <span className="label label-light-danger label-inline font-weight-bold">update</span> */}
                                </div>
                            </div>
                        </div>
                    </Link>

                    <Link to="#" className="navi-item">
                        <div className="navi-link">
                            <div className="symbol symbol-40 bg-light mr-3">
                                <div className="symbol-label">
                                    <span className="svg-icon svg-icon-md svg-icon-warning">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-bar1.svg")} />
                                    </span>
                                </div>
                            </div>
                            <div className="navi-text">
                                <div className="font-weight-bold">Role</div>
                                <div className="text-muted">
                                    {!userMe?.isAdmin ? userMe?.roleName : "Administrator"}
                                </div>
                            </div>
                        </div>
                    </Link>

                    <Link to="#" className="navi-item">
                        <div className="navi-link">
                            <div className="symbol symbol-40 bg-light mr-3">
                                <div className="symbol-label">
                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Selected-file.svg")} />
                                    </span>
                                </div>
                            </div>
                            <div className="navi-text">
                                <div className="font-weight-bold">Branch</div>
                                {/* <div className="text-muted">Logs and notifications</div> */}
                                <span className="label label-light-danger label-inline font-weight-bold">
                                    {!userMe?.isAdmin ? userMe?.branchName : "All Branch"}
                                </span>
                            </div>
                        </div>
                    </Link>

                    {/* <Link to="/user-profile" className="navi-item">
                        <div className="navi-link">
                            <div className="symbol symbol-40 bg-light mr-3">
                                <div className="symbol-label">
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Mail-opened.svg")} />
                                    </span>
                                </div>
                            </div>
                            <div className="navi-text">
                                <div className="font-weight-bold">My Tasks</div>
                                <div className="text-muted">latest tasks and projects</div>
                            </div>
                        </div>
                    </Link> */}
                </div>

                <div className="separator separator-dashed my-7" />

                {/* <div>
                    <h5 className="mb-5">Recent Notifications</h5>

                    <div className="d-flex align-items-center bg-light-warning rounded p-5 gutter-b">
                        <span className="svg-icon svg-icon-warning mr-5">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                                className="svg-icon svg-icon-lg"
                            />
                        </span>

                        <div className="d-flex flex-column flex-grow-1 mr-2">
                            <a
                                href="#"
                                className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">
                                Another purpose persuade
                            </a>
                            <span className="text-muted font-size-sm">Due in 2 Days</span>
                        </div>

                        <span className="font-weight-bolder text-warning py-1 font-size-lg">+28%</span>
                    </div>

                    <div className="d-flex align-items-center bg-light-success rounded p-5 gutter-b">
                        <span className="svg-icon svg-icon-success mr-5">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                className="svg-icon svg-icon-lg"
                            />
                        </span>
                        <div className="d-flex flex-column flex-grow-1 mr-2">
                            <a
                                href="#"
                                className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">
                                Would be to people
                            </a>
                            <span className="text-muted font-size-sm">Due in 2 Days</span>
                        </div>

                        <span className="font-weight-bolder text-success py-1 font-size-lg">+50%</span>
                    </div>

                    <div className="d-flex align-items-center bg-light-danger rounded p-5 gutter-b">
                        <span className="svg-icon svg-icon-danger mr-5">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Communication/Group-chat.svg")}
                                className="svg-icon svg-icon-lg"
                            />
                        </span>
                        <div className="d-flex flex-column flex-grow-1 mr-2">
                            <a
                                href="#"
                                className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1">
                                Purpose would be to persuade
                            </a>
                            <span className="text-muted font-size-sm">Due in 2 Days</span>
                        </div>

                        <span className="font-weight-bolder text-danger py-1 font-size-lg">-27%</span>
                    </div>

                    <div className="d-flex align-items-center bg-light-info rounded p-5">
                        <span className="svg-icon svg-icon-info mr-5">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/General/Attachment2.svg")}
                                className="svg-icon svg-icon-lg"
                            />
                        </span>

                        <div className="d-flex flex-column flex-grow-1 mr-2">
                            <a
                                href="#"
                                className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1">
                                The best product
                            </a>
                            <span className="text-muted font-size-sm">Due in 2 Days</span>
                        </div>

                        <span className="font-weight-bolder text-info py-1 font-size-lg">+8%</span>
                    </div>
                </div> */}
            </div>
            <Modal show={modalForm} scrollable centered onHide={handleCloseModal}>
                <Modal.Header>
                    <Modal.Title>Edit User</Modal.Title>
                    <IconButton onClick={handleCloseModal}>
                        <Close />
                    </IconButton>
                </Modal.Header>
                <Modal.Body>
                    <FormCreateUser
                        onCancel={handleCloseModal}
                        onSubmit={handlerForSubmitFormEditUser}
                        initialFormValues={initialFormValues}
                        validateSchemaUsers={getValidateSchemaUsers(false)}
                        option={option}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
}
