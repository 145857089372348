import React from "react";
import { boolean, object, string } from "yup";
import SwitchApp from "../../../componentsUtils/SwitchApp";

export const columnsTag = props => {
    /** @type Column */
    const columns = [
        {
            field: "id",
            title: "#",
            sorting: false,
            render: obj => (
                <div>{props?.tableOptions?.currentPage * props?.tableOptions?.pageSize + obj.tableData?.id + 1}</div>
            ),
        },
        {
            field: "tagName",
            title: "Tag Name",
            sorting: false,
        },
        {
            field: "status",
            title: "Status",
            sorting: false,
            render: obj => {
                let checkSt = false;
                if (obj?.status === "1") {
                    checkSt = true;
                }
                return <SwitchApp checked={checkSt} onChange={e => props?.handleChangeStatus(e, obj?.tagId)} />;
            },
        },
    ];
    const data = columns?.map(col => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};

export const initialValuesTag = {
    tagName: "",
};

export const initialValuesSearchTag = {
    search: "",
    status: "",
};

export const validateSchemaSearchTag = object({
    search: string(),
    status: boolean(),
});
