export const replaceMoneyInterger = money => {
    return money.replace(/[^0-9]/, "");
};

export const replaceMoneyWith2Decimal = money => {
    return money
        .replace(/[^(0-9|.)]/, "")
        .replace(/([.])[.]+/, "$1")
        .replace(/([.]\d{2})\d+/, "$1");
};
