/* eslint-disable no-useless-escape */
/* eslint-disable  no-unused-vars */
import { Column } from "material-table";
import React from "react";
import { object, string } from "yup";
import SwitchApp from "../../../componentsUtils/SwitchApp";

export const initialValuesClassroom = {
    classroomName: "",
    color: "#000000",
};

export const validationSchema = object({
    classroomName: string().required("Required."),
    color: string().required("Required."),
});

export const columnTableClassroom = props => {
    /** @type Column */
    const columns = [
        {
            field: "id",
            title: "#",
            sorting: false,
            render: obj => props?.tableOptions?.currentPage * props?.tableOptions?.pageSize + obj.tableData?.id + 1,
        },
        {
            field: "classroomName",
            title: "Classroom Name",
            render: obj => (
                <div className="d-flex align-items-center justify-content-center">
                    <p className="mb-0">{obj?.classroomName || ""}</p>
                    <div
                        className="rounded-circle shadow-sm ml-1"
                        style={{ width: 10, height: 10, backgroundColor: obj?.colorHex || "white" }}
                    />
                </div>
            ),
        },
        {
            field: "status",
            title: "Status",
            sorting: false,
            render: obj => <SwitchApp checked={obj?.status} onChange={e => props?.handleChangeStatus(e, obj?.id)} />,
        },
    ];
    const data = columns?.map((col, index) => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};
