import { Grid, Paper } from "@material-ui/core";
import React from "react";
import CreditBalanceStdContent from "../../components/report_credit_balance_std/CreditBalanceStdContent";

export default function CreditBalanceStudent() {
    return (
        <Paper className="p-2 p-lg-3">
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <CreditBalanceStdContent />
                </Grid>
            </Grid>
        </Paper>
    );
}
