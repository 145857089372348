import { Box, FormControl, Grid, InputAdornment, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import MaterialTable from "material-table";
import React, { useCallback, useRef, useState } from "react";
import { getAllLog } from "../../../apis/logApi";
import { getOptionsTableApp } from "../../helpers/useOption";
import { columnsUsersLogs } from "./helpers/userLogsOption";

export default function UserLogsContent() {
    // state or variable
    const [tableOptions, setTableOptions] = useState(getOptionsTableApp);
    const tableRef = useRef(null);
    const [valuesSearch, setValuesSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const controllerRef = useRef(null);

    // function
    const getData = async query => {
        try {
            setIsLoading(true);
            const queryParams = {
                page: query?.page + 1 || 1,
                limit: query?.pageSize || 10,
                search: query?.search || "",
                order: query?.orderBy?.field || "id",
                direction: query?.orderBy?.field ? query?.orderDirection : "desc",
            };

            const res = await getAllLog(queryParams, controllerRef.current?.signal);
            if (res) {
                setTableOptions(currentState => ({
                    ...currentState,
                    currentPage: query?.page,
                    pageSize: query?.pageSize || 10,
                    totalData: res?.pagination?.totalData || 0,
                    order: query?.orderBy?.field || "timestamp",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                }));
                setIsLoading(false);
                return {
                    data: res?.data || [],
                    page: query?.page || 0,
                    totalCount: res?.pagination?.totalData || 0,
                };
            }
            setIsLoading(false);
        } catch (error) {
            console.dir(error);
            setIsLoading(false);
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
    };

    // function
    const handleOnchangeSearch = useCallback(
        evt => {
            const value = evt.target?.value;
            setValuesSearch(value);
            tableRef.current.onQueryChange({ search: value, page: 0 });
        },
        [tableRef],
    );

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className="mb-3 mb-md-5">
                <div className="d-flex align-items-center justify-content-between">
                    <div style={{ flex: 1 }}>
                        <FormControl style={{ height: "100%" }}>
                            <TextField
                                size="small"
                                variant="outlined"
                                value={valuesSearch}
                                onChange={handleOnchangeSearch}
                                placeholder="Search..."
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search className="text-muted" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </div>
                </div>
            </Grid>

            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        isLoading={isLoading}
                        tableRef={tableRef}
                        options={tableOptions}
                        components={{ Container: Box }}
                        columns={columnsUsersLogs({ tableOptions })}
                        data={val => getData(val)}
                    />
                </div>
            </Grid>
        </Grid>
    );
}
