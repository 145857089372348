import { Button, FormControl, FormLabel, Grid, TextField } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import TextareaApp from "../../componentsUtils/TextareaApp";
import { validateSchemaFormPackage } from "./helpers/packageOption";

export default function FormPackage({ onSubmit, onCancel, initialFormValues }) {
    return (
        <Formik initialValues={initialFormValues} validationSchema={validateSchemaFormPackage} onSubmit={onSubmit}>
            {({ values, errors, handleChange, handleSubmit }) => (
                <Grid container spacing={2}>
                    {/* Name package */}
                    <Grid item xs={12}>
                        <FormControl fullWidth className="mb-2">
                            <FormLabel>
                                Name <span className="text-danger">*</span>{" "}
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                id="name_package"
                                value={values.name_package}
                                onChange={handleChange}
                                error={Boolean(errors.name_package)}
                                helperText={errors.name_package}
                                placeholder="Package name"
                            />
                        </FormControl>
                    </Grid>

                    {/* credit */}
                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth className="mb-2">
                            <FormLabel>
                                Credit <span className="text-danger">*</span>{" "}
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                id="credit"
                                value={values.credit}
                                onChange={handleChange}
                                error={Boolean(errors.credit)}
                                helperText={errors.credit}
                                placeholder="Enter credit (THB)"
                            />
                        </FormControl>
                    </Grid>

                    {/* discount
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-2">
                            <FormLabel>
                                Discount <span className="text-danger">*</span>{" "}
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                id="discount"
                                value={values.discount}
                                onChange={handleChange}
                                error={Boolean(errors.discount)}
                                helperText={errors.discount}
                                placeholder="%"
                            />
                        </FormControl>
                    </Grid> */}

                    {/* price */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-2">
                            <FormLabel>
                                Price <span className="text-danger">*</span>{" "}
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                id="price"
                                value={values.price}
                                onChange={handleChange}
                                error={Boolean(errors.price)}
                                helperText={errors.price}
                                placeholder="Enter price (THB)"
                            />
                        </FormControl>
                    </Grid>

                    {/* expired_period */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-2">
                            <FormLabel>
                                Expired Period <span className="text-danger">*</span>{" "}
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                id="expired_period"
                                value={values.expired_period}
                                onChange={handleChange}
                                error={Boolean(errors.expired_period)}
                                helperText={errors.expired_period}
                                placeholder="Months"
                            />
                        </FormControl>
                    </Grid>

                    {/* description */}
                    <Grid item xs={12}>
                        <FormControl fullWidth className="mb-2">
                            <FormLabel>Description</FormLabel>
                            <TextareaApp
                                props={{
                                    textAreaProps: {
                                        rows: 3,
                                        id: "description",
                                        onChange: handleChange,
                                        value: values?.description,
                                        placeholder: "Description",
                                    },
                                }}
                                errorMessage={errors?.description}
                            />
                        </FormControl>
                    </Grid>
                    {/* action */}
                    <Grid item xs={12}>
                        <div className="d-flex justify-content-md-end align-items-center">
                            <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                                <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                            </Button>
                            <Button onClick={handleSubmit} className="btn t-btn-secondary">
                                <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
}
