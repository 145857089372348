import { Grid } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import TeacherProfileContent from "../../components/TeacherProfile/TeacherProfileContent";

export default function TeacherProfile() {
    const { id } = useParams();
    return (
        <div className="p-2 p-lg-3">
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <TeacherProfileContent id={id} />
                </Grid>
            </Grid>
        </div>
    );
}
