import { Grid, Paper } from "@material-ui/core";
import React from "react";
import ContentClassroom from "../../components/ClassroomManagement/ContentClassroom";

export default function ClassroomManagement() {
    return (
        <Paper className="p-2 p-md-3 py-3 py-md-5">
            <Grid container spacing={0}>
                <Grid item xs={12} className="mb-3">
                    <h4>Classroom Management</h4>
                </Grid>
                <Grid item xs={12}>
                    <ContentClassroom />
                </Grid>
            </Grid>
        </Paper>
    );
}
