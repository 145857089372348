import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../_metronic/layout";
import ReceiptPDF from "../components/CreateOrder/ReceiptPDF";
import CreateOrder from "../pages/order_management/CreateOrder";
import OrderTransaction from "../pages/order_management/OrderTransaction";
import ScheduleListFree from "../pages/schedule_list/freeSchedule_list/ScheduleListFree";
import ScheduleListGroup from "../pages/schedule_list/group_list/ScheduleListGroup";
import ScheduleListPrivate from "../pages/schedule_list/private_list/ScheduleListPrivate";
// import { BuilderPage, DashboardPage } from "../pages/base_page";
import ScheduleManagement from "../pages/schedule_management/ScheduleManagement";
import LogActivityStudent from "../pages/students_management/LogActivityStudent";
import StudentProfile from "../pages/students_management/StudentProfile";
import StudentsList from "../pages/students_management/StudentsList";
import BranchManagement from "../pages/system_configuration/BranchManagement";
import ClassroomManagement from "../pages/system_configuration/ClassroomManagement";
import Category from "../pages/system_configuration/course_management/Category";
import Course from "../pages/system_configuration/course_management/Course";
import Menu from "../pages/system_configuration/course_management/Menu";
import PackageManagement from "../pages/system_configuration/PackageManagement";
import RolePermission from "../pages/system_configuration/users_management/RolePermission";
import UserLogsActivity from "../pages/system_configuration/users_management/UserLogsActivity";
import UsersManagement from "../pages/system_configuration/users_management/UsersManagement";
import LogActivityTeacher from "../pages/teacher_management/LogActivityTeacher";
import TeacherManagement from "../pages/teacher_management/TeacherManagement";
import TeacherProfile from "../pages/teacher_management/TeacherProfile";
import Page404 from "../pages/error/Page404";
import MonthlySummary from "../pages/report_management/MonthlySummary";
import Tag from "../pages/system_configuration/course_management/Tag";
import CourseSummary from "../pages/report_management/CourseSummary";
import MenuSummary from "../pages/report_management/MenuSummary";
import PackageSummary from "../pages/report_management/PackageSummary";
import CreditBalanceStudent from "../pages/report_management/CreditBalanceStudent";
import CreditPage from "../pages/credit_management/CreditPage";
import TransactionReport from "../pages/report_management/TransactionReport";

const BasePage = () => {
    const { redirectPath } = useSelector(state => {
        const { redirectPath } = state?.mainMenu;
        return {
            redirectPath,
        };
    });
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {/* start redirect */}
                <Redirect exact from="/" to={redirectPath} />
                {/* end redirect */}

                {/* start system configuration */}
                <ContentRoute exact path="/system_configuration/user_management/users" component={UsersManagement} />
                <ContentRoute
                    exact
                    path="/system_configuration/user_management/role_permission"
                    component={RolePermission}
                />
                <ContentRoute
                    exact
                    path="/system_configuration/user_management/logs_activity"
                    component={UserLogsActivity}
                />
                <ContentRoute exact path="/system_configuration/branch_management" component={BranchManagement} />
                <ContentRoute exact path="/system_configuration/course_management/course" component={Course} />
                <ContentRoute exact path="/system_configuration/course_management/menu" component={Menu} />
                <ContentRoute exact path="/system_configuration/course_management/category" component={Category} />
                <ContentRoute exact path="/system_configuration/course_management/tag" component={Tag} />
                <ContentRoute exact path="/system_configuration/package_management" component={PackageManagement} />
                <ContentRoute exact path="/system_configuration/classroom_management" component={ClassroomManagement} />
                {/* end system configuration */}

                {/* start schedule management */}
                <ContentRoute exact path="/schedule_management/schedule_booking" component={ScheduleManagement} />
                <ContentRoute exact path="/schedule_management/schedule_list/private" component={ScheduleListPrivate} />
                <ContentRoute exact path="/schedule_management/schedule_list/group" component={ScheduleListGroup} />
                <ContentRoute
                    exact
                    path="/schedule_management/schedule_list/free_schedule"
                    component={ScheduleListFree}
                />
                {/* end schedule management */}

                {/* start teacher management */}
                <ContentRoute exact path="/teacher_management/teacher_list" component={TeacherManagement} />
                <ContentRoute exact path="/teacher_management/teacher_list/:id" component={TeacherProfile} />
                <ContentRoute exact path="/teacher_management/logs_activity" component={LogActivityTeacher} />
                {/* end teacher management */}

                {/* start student management */}
                <ContentRoute exact path="/student_management/student_list" component={StudentsList} />
                <ContentRoute exact path="/student_management/student_list/:id" component={StudentProfile} />
                <ContentRoute exact path="/student_management/logs_activity" component={LogActivityStudent} />
                {/* end student management */}

                {/* start order management */}
                <ContentRoute exact path="/order_management/create_order" component={CreateOrder} />
                <ContentRoute exact path="/order_management/order_list" component={OrderTransaction} />
                <ContentRoute exact path="/order_management/pdf" component={ReceiptPDF} />
                {/* end order management */}

                {/* start report management */}
                <ContentRoute exact path="/report-management/Monthly-sales-summary-report" component={MonthlySummary} />
                <ContentRoute exact path="/report-management/course-sales-summary-report" component={CourseSummary} />
                <ContentRoute exact path="/report-management/menu-sales-summary-report" component={MenuSummary} />
                <ContentRoute
                    exact
                    path="/report-management/packages-sales-summary-report"
                    component={PackageSummary}
                />
                <ContentRoute exact path="/report-management/credit-balance-student" component={CreditBalanceStudent} />
                <ContentRoute exact path="/report-management/transaction-report" component={TransactionReport} />
                {/* end report management */}

                {/* start credit management */}
                <ContentRoute exact path="/credit-management" component={CreditPage} />
                {/* end credit management */}

                {/* Error route */}
                <ContentRoute path="*" component={Page404} />
            </Switch>
        </Suspense>
    );
};

export default BasePage;
