import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import ButtonCustom from "./CustomButton";

/**
 * @param {Object} props - react component props.
 * @param {string} props.children  - boostrap modal children.
 * @param {boolean} props.show - open modal status.
 * @param {Function} props.handleClose  - close button function.
 * @param {Function} props.handleSave  - save button function.
 * @param {boolean} props.showCancelButton  - close button status.
 * @param {boolean} props.showSaveButton  - save button status.
 * @param {string} props.closeButtonLabel  - close button label.
 * @param {string} props.saveButtonLabel - save button label.
 * @param {string} props.closeButtonClass  - close button class.
 * @param {string} props.saveButtonClass - save button class.
 * @param {string} props.title  - boostrap modal title.
 * @param {string} props.size - boostrap modal size. ("sm" | "md" | "lg" | "xl")
 * @param {boolean} props.scrollable -  boostrap modal scrollable.
 * @param {boolean} props.isButtonSaveLoading -  save button loading.
 * @returns {jsx} Select Component from react-select lib only use with formik.
 */
const ModalBoostrap = ({
    children,
    show,
    handleClose,
    handleSave,
    showCloseButton,
    showSaveButton,
    closeButtonLabel,
    saveButtonLabel,
    closeButtonClass,
    saveButtonClass,
    title,
    size,
    scrollable,
    isSaveButtonLoading,
}) => {
    return (
        <Modal show={show} onHide={handleClose} size={size} scrollable={scrollable}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                {showCloseButton && (
                    <ButtonCustom label={closeButtonLabel} onClick={handleClose} className={closeButtonClass} />
                )}
                {showSaveButton && (
                    <ButtonCustom
                        label={saveButtonLabel}
                        onClick={handleSave}
                        className={saveButtonClass}
                        isLoading={isSaveButtonLoading}
                    />
                )}
            </Modal.Footer>
        </Modal>
    );
};

ModalBoostrap.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSave: PropTypes.func,
    showCloseButton: PropTypes.bool,
    showSaveButton: PropTypes.bool,
    closeButtonLabel: PropTypes.string,
    saveButtonLabel: PropTypes.string,
    closeButtonClass: PropTypes.string,
    saveButtonClass: PropTypes.string,
    title: PropTypes.string,
    size: PropTypes.string,
    scrollable: PropTypes.bool,
    isSaveButtonLoading: PropTypes.bool,
};

ModalBoostrap.defaultProps = {
    show: false,
    handleClose: () => {},
    handleSave: () => {},
    showCloseButton: false,
    showSaveButton: false,
    closeButtonLabel: "Close",
    saveButtonLabel: "Save",
    closeButtonClass: "",
    saveButtonClass: "",
    title: "",
    size: "md",
    scrollable: false,
    isSaveButtonLoading: false,
};

export default ModalBoostrap;
