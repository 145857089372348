import { Box, Button, Divider, Fade, FormControl, Grid, IconButton } from "@material-ui/core";
import { Delete, Description } from "@material-ui/icons";
import { FieldArray, Formik } from "formik";
import moment from "moment";
import React, { useCallback, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Spinner } from "react-bootstrap";
import { swalCondition } from "../../../utils/swal";
import DatePickerApp from "../../componentsUtils/DatePickerApp";
import SelectSearchApp from "../../componentsUtils/SelectSearchApp";
import TextareaApp from "../../componentsUtils/TextareaApp";
import { initialValuesPrivate, validateSchemaPrivate } from "./helpers/forFormScheduleOption";
import TimeEnterApp from "../../componentsUtils/TimeEnterApp";
import { getOrderListOption, getOrderOption } from "../../../apis/optionsApi";

export default function FormPrivateTab({
    onSubmit,
    onCancel,
    scheduleOption,
    initialFormValues = initialValuesPrivate,
    mode = "create",
}) {
    // ref
    const refForm = useRef(null);

    // component state
    const [isLoading, setIsLoading] = useState(false);

    // onChange student
    const handleChangeStudent = useCallback(
        async value => {
            if (refForm.current !== null) {
                const { setFieldValue } = refForm.current;
                const orders = await getOrderOption({
                    studentId: value?.id,
                });
                await setFieldValue("student", {
                    ...value,
                    Orders: orders || [],
                });
                await setFieldValue("order", "");
                await setFieldValue("bookingNo", "");
                await setFieldValue("listCourseMenu", []);
            }
        },
        [refForm],
    );

    // onChange booking
    const handleChangeBooking = useCallback(
        async value => {
            if (refForm.current !== null) {
                setIsLoading(true);
                const { setValues, values } = refForm.current;
                const orderLists = await getOrderListOption({
                    orderId: value?.orderId,
                });
                const listCourseMenuArray = orderLists?.reduce((result, orderList) => {
                    if (orderList?.Course) {
                        return [
                            ...result,
                            ...orderList?.Course?.CourseMenus?.map(menu => ({
                                bookingDate: orderList?.bookingDate,
                                startTime: orderList?.bookingTime
                                    ? moment(orderList?.bookingTime, "HH:mm:ss").toDate()
                                    : null,
                                toTime:
                                    orderList?.bookingTime &&
                                    (orderList?.Course?.durationHour || orderList?.Course?.durationMinute)
                                        ? moment(orderList?.bookingTime, "HH:mm:ss")
                                              .add(orderList?.Course?.durationHour || 0, "hour")
                                              .add(orderList?.Course?.durationMinute || 0, "minute")
                                              .toDate()
                                        : null,
                                course: orderList?.Course?.courseName,
                                courseId: orderList?.Course?.id,
                                menu: menu?.courseMenuName,
                                menuId: menu?.id,
                                classroom: "",
                                teacher: "",
                                orderListId: orderList?.orderListId,
                                durationHour: orderList?.Course?.durationHour || 0,
                                durationMinute: orderList?.Course?.durationMinute || 0,
                            })),
                        ];
                    }
                    if (orderList?.CourseMenu) {
                        return [
                            ...result,
                            {
                                bookingDate: orderList?.bookingDate,
                                startTime: orderList?.bookingTime
                                    ? moment(orderList?.bookingTime, "HH:mm:ss").toDate()
                                    : null,
                                toTime:
                                    orderList?.bookingTime &&
                                    (orderList?.CourseMenu?.durationHour || orderList?.CourseMenu?.durationMinute)
                                        ? moment(orderList?.bookingTime, "HH:mm:ss")
                                              .add(orderList?.CourseMenu?.durationHour || 0, "hour")
                                              .add(orderList?.CourseMenu?.durationMinute || 0, "minute")
                                              .toDate()
                                        : null,
                                course: "",
                                menu: orderList?.CourseMenu?.courseMenuName,
                                menuId: orderList?.CourseMenu?.id,
                                classroom: "",
                                teacher: "",
                                orderListId: orderList?.orderListId,
                                durationHour: orderList?.CourseMenu?.durationHour || 0,
                                durationMinute: orderList?.CourseMenu?.durationMinute || 0,
                            },
                        ];
                    }
                    return [...result];
                }, []);
                await setValues({
                    ...values,
                    order: value,
                    bookingNo: value?.bookingNo,
                    listCourseMenu: listCourseMenuArray?.length ? listCourseMenuArray : [],
                });
                setIsLoading(false);
            }
        },
        [refForm],
    );

    // filter teacher option
    const filterTeacherOption = useCallback((teacherOption, idx, values) => {
        return teacherOption?.filter(
            item =>
                item?.CourseMenus?.map(menu => menu?.id)?.includes(values?.listCourseMenu[idx]?.menuId) &&
                !item?.Holidays?.some(
                    holiday =>
                        new Date(holiday?.startDate).getTime() >=
                            new Date(moment(values?.listCourseMenu[idx]?.bookingDate).format("YYYY-MM-DD")).getTime() &&
                        new Date(holiday?.startDate).getTime() <=
                            new Date(moment(values?.listCourseMenu[idx]?.bookingDate).format("YYYY-MM-DD")).getTime(),
                ),
        );
    }, []);

    return (
        <Fade in timeout={{ enter: 800 }}>
            <Box>
                <Formik
                    innerRef={refForm}
                    validationSchema={validateSchemaPrivate}
                    initialValues={initialFormValues}
                    onSubmit={values => {
                        onSubmit(values);
                    }}>
                    {({ values, handleSubmit, errors, setFieldValue, handleChange }) => (
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <div className="row align-items-center mb-7">
                                    <div className="col-12 col-md-3 d-md-flex align-items-center mb-5">
                                        <label className="mb-0 mr-2 p-0" style={{ minWidth: "fit-content" }}>
                                            Student <span className="text-danger">*</span>
                                        </label>
                                        <SelectSearchApp
                                            options={scheduleOption?.student}
                                            value={values?.student}
                                            getOptionLabel={option =>
                                                `${option?.studentFirstname || ""} ${option?.studentLastname ||
                                                    ""} (${option?.studentNickname || ""})`
                                            }
                                            getOptionValue={option => option?.id}
                                            onChange={handleChangeStudent}
                                            error={errors?.student}
                                            isDisabled={mode !== "create"}
                                            isLoading={scheduleOption?.student?.length === 0}
                                        />
                                    </div>
                                    <div className="col-12 col-md-3 d-md-flex align-items-center mb-5">
                                        <label className="mb-0 mr-2 p-0" style={{ minWidth: "fit-content" }}>
                                            Booking No. <span className="text-danger">*</span>
                                        </label>
                                        <SelectSearchApp
                                            isDisabled={values?.student === "" || mode !== "create"}
                                            options={values?.student?.Orders}
                                            value={values?.order}
                                            getOptionLabel={option => option?.bookingNo}
                                            getOptionValue={option => option?.bookingNo}
                                            onChange={handleChangeBooking}
                                            error={errors?.bookingNo}
                                        />
                                    </div>
                                </div>
                                <Divider />
                            </Grid>
                            {/* tables List */}
                            <Grid item xs={12}>
                                <h3 className="text-muted d-flex align-items-center mb-3">
                                    <Description fontSize="large" />
                                    Course/Menu
                                </h3>
                                {!isLoading ? (
                                    <div className="mb-5 table-responsive">
                                        <table className="table table-create-schedule">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Booking Date</th>
                                                    <th>Start Time</th>
                                                    <th>To Time</th>
                                                    <th>Course</th>
                                                    <th>Menu</th>
                                                    <th>Classroom</th>
                                                    <th>Teacher</th>
                                                    {mode === "create" ? <th>Actions</th> : null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <FieldArray name="listCourseMenu">
                                                    {({ remove }) => {
                                                        if (values.listCourseMenu.length > 0 && values.listCourseMenu) {
                                                            return values.listCourseMenu.map((row, idx) => {
                                                                const checkValidteArr = (typeKey = "classroom") => {
                                                                    if (errors.listCourseMenu !== undefined) {
                                                                        return (
                                                                            errors?.listCourseMenu[idx] !== undefined &&
                                                                            errors?.listCourseMenu[idx][typeKey]
                                                                        );
                                                                    }
                                                                    return "";
                                                                };
                                                                return (
                                                                    <tr key={`listMenuCourse_${idx}`}>
                                                                        <td className="text-center align-middle">
                                                                            {idx + 1}
                                                                        </td>
                                                                        <td className="align-middle td-date-time">
                                                                            <DatePickerApp
                                                                                value={
                                                                                    values?.listCourseMenu[idx]
                                                                                        ?.bookingDate
                                                                                }
                                                                                onChange={date => {
                                                                                    setFieldValue(
                                                                                        `listCourseMenu.${idx}.bookingDate`,
                                                                                        date,
                                                                                    );
                                                                                    setFieldValue(
                                                                                        `listCourseMenu.${idx}.teacher`,
                                                                                        "",
                                                                                    );
                                                                                }}
                                                                                size="small"
                                                                                error={Boolean(
                                                                                    checkValidteArr("bookingDate"),
                                                                                )}
                                                                                helperText={
                                                                                    errors?.listCourseMenu &&
                                                                                    errors?.listCourseMenu[idx]
                                                                                        ?.bookingDate
                                                                                }
                                                                                placeholder="Choose date."
                                                                            />
                                                                        </td>
                                                                        <td className="align-middle td-date-time time">
                                                                            <TimeEnterApp
                                                                                value={
                                                                                    values?.listCourseMenu[idx]
                                                                                        ?.startTime
                                                                                }
                                                                                onChange={(_evt, val) => {
                                                                                    setFieldValue(
                                                                                        `listCourseMenu.${idx}.startTime`,
                                                                                        val,
                                                                                    );
                                                                                    if (
                                                                                        val &&
                                                                                        (values?.listCourseMenu[idx]
                                                                                            ?.durationHour ||
                                                                                            values?.listCourseMenu[idx]
                                                                                                ?.durationMinute)
                                                                                    ) {
                                                                                        setFieldValue(
                                                                                            `listCourseMenu.${idx}.toTime`,
                                                                                            moment(val)
                                                                                                .add(
                                                                                                    values
                                                                                                        ?.listCourseMenu[
                                                                                                        idx
                                                                                                    ]?.durationHour,
                                                                                                    "hour",
                                                                                                )
                                                                                                .add(
                                                                                                    values
                                                                                                        ?.listCourseMenu[
                                                                                                        idx
                                                                                                    ]?.durationMinute,
                                                                                                    "minute",
                                                                                                )
                                                                                                .toDate(),
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                error={Boolean(
                                                                                    checkValidteArr("startTime"),
                                                                                )}
                                                                                helperText={
                                                                                    errors?.listCourseMenu &&
                                                                                    errors?.listCourseMenu[idx]
                                                                                        ?.startTime
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td className="align-middle td-date-time time">
                                                                            <TimeEnterApp
                                                                                value={
                                                                                    values.listCourseMenu[idx].toTime
                                                                                }
                                                                                onChange={(_evt, val) => {
                                                                                    setFieldValue(
                                                                                        `listCourseMenu.${idx}.toTime`,
                                                                                        val,
                                                                                    );
                                                                                }}
                                                                                error={Boolean(
                                                                                    checkValidteArr("toTime"),
                                                                                )}
                                                                                helperText={
                                                                                    errors?.listCourseMenu &&
                                                                                    errors?.listCourseMenu[idx]?.toTime
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            <div className="text-coursemenu-app text-center">
                                                                                {values?.listCourseMenu[idx]?.course ||
                                                                                    "-"}
                                                                            </div>
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            <div className="text-coursemenu-app text-center">
                                                                                {values?.listCourseMenu[idx]?.menu ||
                                                                                    "-"}
                                                                            </div>
                                                                        </td>
                                                                        <td className="align-middle selected-classroom">
                                                                            <SelectSearchApp
                                                                                value={
                                                                                    values?.listCourseMenu[idx]
                                                                                        ?.classroom
                                                                                }
                                                                                options={scheduleOption?.classroom}
                                                                                getOptionLabel={option =>
                                                                                    option?.classroomName
                                                                                }
                                                                                getOptionValue={option => option?.id}
                                                                                onChange={value => {
                                                                                    setFieldValue(
                                                                                        `listCourseMenu.${idx}.classroom`,
                                                                                        value,
                                                                                    );
                                                                                }}
                                                                                size="sm"
                                                                                error={checkValidteArr("classroom")}
                                                                                placeholder="Classroom."
                                                                            />
                                                                        </td>
                                                                        <td className="align-middle selected-teachers">
                                                                            <SelectSearchApp
                                                                                isDisabled={
                                                                                    values?.listCourseMenu[idx]
                                                                                        ?.classroom === ""
                                                                                }
                                                                                value={
                                                                                    values?.listCourseMenu[idx]?.teacher
                                                                                }
                                                                                options={filterTeacherOption(
                                                                                    scheduleOption?.teacher,
                                                                                    idx,
                                                                                    values,
                                                                                )}
                                                                                getOptionLabel={option =>
                                                                                    `${option?.teacherFirstname ||
                                                                                        ""} ${option?.teacherLastname ||
                                                                                        ""} (${option?.teacherNickname ||
                                                                                        ""})`
                                                                                }
                                                                                getOptionValue={option => option?.id}
                                                                                onChange={value => {
                                                                                    setFieldValue(
                                                                                        `listCourseMenu.${idx}.teacher`,
                                                                                        value,
                                                                                    );
                                                                                }}
                                                                                size="sm"
                                                                                error={checkValidteArr("teacher")}
                                                                                placeholder="Teacher."
                                                                            />
                                                                        </td>
                                                                        {mode === "create" ? (
                                                                            <td className="text-center align-middle">
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        swalCondition(
                                                                                            "Course and Menu",
                                                                                            "Do you want to delete?",
                                                                                            {
                                                                                                icon: "warning",
                                                                                            },
                                                                                        ).then(async bool => {
                                                                                            if (bool) {
                                                                                                if (
                                                                                                    values
                                                                                                        ?.listCourseMenu
                                                                                                        .length < 2
                                                                                                ) {
                                                                                                    setFieldValue(
                                                                                                        "bookingNo",
                                                                                                        "",
                                                                                                    );
                                                                                                }
                                                                                                await remove(idx);
                                                                                            }
                                                                                        });
                                                                                    }}
                                                                                    disabled={mode !== "create"}
                                                                                    className="btn btn-secondary text-danger rounded">
                                                                                    <Delete />
                                                                                </IconButton>
                                                                            </td>
                                                                        ) : null}
                                                                    </tr>
                                                                );
                                                            });
                                                        }
                                                        return (
                                                            <tr>
                                                                <td colSpan={9}>
                                                                    <div className="text-muted text-center h4 py-3">
                                                                        <em>ไม่พบข้อมูล</em>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }}
                                                </FieldArray>
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <div className="d-flex justify-content-center mb-5">
                                        <div>
                                            <Spinner animation="border" variant="primary" />
                                        </div>
                                    </div>
                                )}
                                <Divider />
                            </Grid>
                            {/* textfield */}
                            <Grid item xs={12}>
                                <div className="row flex-column">
                                    <div className="col-12 col-md-8 d-md-flex align-items-center mb-3">
                                        <label className="mb-0 mr-2 col-md-2 p-0">Note:</label>
                                        <FormControl fullWidth>
                                            <TextareaApp
                                                props={{
                                                    textAreaProps: {
                                                        value: values?.note,
                                                        name: "note",
                                                        onChange: handleChange,
                                                        rows: 5,
                                                    },
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </Grid>
                            {/* actions */}
                            <Grid item xs={12}>
                                <Divider className="my-4" />
                                <div className="d-flex justify-content-md-end align-items-center">
                                    <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                                        <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                                    </Button>
                                    <Button type="submit" onClick={handleSubmit} className="btn t-btn-secondary">
                                        <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </Formik>
            </Box>
        </Fade>
    );
}
