/* eslint-disable jsx-a11y/anchor-is-valid */
import { ButtonBase } from "@material-ui/core";
import React from "react";
import { KTUtil } from "../../../../_assets/js/components/util";
// import { Link } from "react-router-dom";
import KTLayoutAsideToggle from "../../../../_assets/js/layout/base/aside-toggle";

export function BreadCrumbs({ items }) {
  if (!items || !items.length) {
    return "";
  }
  const hadlerToggleAside = async () => {
    if (KTUtil.find(document.getElementById("kt_aside"), ".aside-secondary .aside-workspace")) {
      await KTUtil.scrollDestroy(KTUtil.find(document.getElementById("kt_aside"), ".aside-secondary .aside-workspace"));
    }
    const toggleAside = await KTLayoutAsideToggle?.getToggle();
    toggleAside.toggleOff()
  }
  return (
    <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0">
      {/* <Link className="breadcrumb-item" to="/dashboard">
        Dashboard
      </Link> */}
      {items.map((item, index) => (
        <li className="breadcrumb-item" key={index}>
          <ButtonBase
            className="text-muted "
            onClick={hadlerToggleAside}
            // to={{ pathname: item.pathname }}
          >
            {item.title}
          </ButtonBase>
          {/* <Link
            className="text-muted "
            onClick={hadlerToggleAside}
            // to={{ pathname: item.pathname }}
          >
            {item.title}
          </Link> */}
        </li>
      ))}
    </ul>
  );
}
