/* eslint-disable */
import React from "react";
import { Column } from "material-table";
import { convertDateTimeFromApi } from "../../../../utils/format";
import moment from "moment";

export const getColumnsTeacherClassHistory = props => {
    /** @type Column */
    const columns = [
        {
            field: "scheduleClassroomHistoryId",
            title: "#",
            sorting: false,
            render: obj => (
                <div>{props?.tableOptions?.currentPage * props?.tableOptions?.pageSize + obj?.tableData?.id + 1}</div>
            ),
        },
        {
            field: "bookingDate",
            title: "Date/Time",
            render: obj => <div>{convertDateTimeFromApi(obj?.bookingDate)}</div>,
        },
        {
            field: "CourseMenu.courseMenuName",
            title: "Course/Menu",
            render: obj => <div>{obj?.menu}</div>,
        },
        {
            field: "Student.studentFirstname",
            title: "Student",
            render: obj => <div>{obj?.studentName ? obj?.studentName : "-"}</div>,
        },
    ];
    const data = columns?.map(col => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};

export const getColumnsTeacherMenus = props => {
    /** @type Column */
    const columns = [
        {
            field: "id",
            title: "#",
            sorting: false,
            render: obj => (
                <div>{props?.tableOptions?.currentPage * props?.tableOptions?.pageSize + obj.tableData?.id + 1}</div>
            ),
        },
        {
            field: "CourseMenu.courseMenuName",
            title: "Course/Menu",
            render: obj => <div>{obj?.menu}</div>,
        },
    ];
    const data = columns?.map(col => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};

export const getColumnsTeacherDayOff = props => {
    /** @type Column */
    const columns = [
        {
            field: "id",
            title: "#",
            sorting: false,
            render: obj => (
                <div>{props?.tableOptions?.currentPage * props?.tableOptions?.pageSize + obj.tableData?.id + 1}</div>
            ),
        },
        {
            field: "startDate",
            title: "Date",
            render: obj => (
                <div>
                    {obj?.startDate === obj?.endDate
                        ? convertDateTimeFromApi(obj?.startDate)
                        : `${convertDateTimeFromApi(obj?.startDate)} - ${convertDateTimeFromApi(obj?.endDate)}`}
                </div>
            ),
        },
        {
            field: "title",
            title: "Description",
        },
    ];
    const data = columns?.map(col => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};
