import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "log";

const GET_ALL_LOGS = `${prefix}/get-all`;
const GET_ALL_LOG_TEACHER = `${prefix}/get-all-log-teacher`;
const GET_ALL_LOG_STUDENT = `${prefix}/get-all-log-student`;

/**
 * @func getAllLog
 * @desc get all Log data
 * @author kop_ter
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} logs array
 */

export const getAllLog = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_ALL_LOGS, {
            params: queryParams,
            signal,
        });

        if (status === 200) {
            return data;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllLog");
        if (err) {
            throw error;
        }
        return undefined;
    }
};
/** End @func getAllLog */

/**
 * @func getStudentLog
 * @desc get all Log Student data
 * @author kop_ter
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} logs array
 */
export const getStudentLog = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_ALL_LOG_STUDENT, {
            params: queryParams,
            signal,
        });

        if (status === 200) {
            return data;
        }
    } catch (error) {
        const err = apiErrorHandler(error, "getStudentLog");
        if (err) {
            throw error;
        }
        return undefined;
    }
};
/** End @func getStudentLog */

/**
 * @func getTeacherLog
 * @desc get all Log Teacher data
 * @author kop_ter
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} logs array
 */
export const getTeacherLog = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_ALL_LOG_TEACHER, {
            params: queryParams,
            signal,
        });

        if (status === 200) {
            return data;
        }
    } catch (error) {
        const err = apiErrorHandler(error, "getTeacherLog");
        if (err) {
            throw error;
        }

        return undefined;
    }
};
/** End @func getTeacherLog */
