/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { Layout } from "../../_metronic/layout";
import AuthPage from "./AuthPage";
import BasePage from "./BasePage";
import ErrorPage from "../pages/error/ErrorPage";

export function Routes() {
    const isAuthorized = localStorage.getItem("authToken");
    return (
        <Switch>
            {!isAuthorized ? (
                /* Render auth page when user at `/auth` and not authorized. */
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                /* Otherwise redirect to root page (`/`) */
                <Redirect from="/auth" to="/" />
            )}

            <Route path="/error" component={ErrorPage} />

            {!isAuthorized ? (
                /* Redirect to `/auth` when user is not authorized */
                <Redirect to="/auth/login" />
            ) : (
                <Layout>
                    <BasePage />
                </Layout>
            )}
        </Switch>
    );
}
