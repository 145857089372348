/* eslint-disable  no-unused-vars */
import { TextField } from "@material-ui/core";
import moment from "moment";
import { Autocomplete } from "@material-ui/lab";
import React, { useCallback } from "react";
import optionsTime from "../../utils/optionsTime";

const options = optionsTime(15, "06:00", "20:00");
/**
 * @author Nakharin
 * @param onChange Function
 * @param value String e.g. 2023-05-08T00:30:00+07:00
 * @param error Boolean
 * @param helperText String
 * @returns string Date: e.g. 2023-05-08T00:30:00+07:00 | null
 */
export default function TimeEnterApp({
    onChange = (event, value) => {},
    value = moment(options[0].date).format(),
    error = false,
    helperText = "",
}) {
    const [msgError, setMsgError] = React.useState("");

    const handleOnchange = useCallback(
        (_evt, val) => {
            setMsgError("");
            onChange(_evt, val !== null ? moment(val, "HH:mm").format() : null);
        },
        [onChange],
    );

    const handleOnchangeText = useCallback(
        evt => {
            /* eslint prefer-regex-literals: "error" */
            const testStr = new RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/u); // prepare a regex object
            const val = evt.target.value;

            if (val === "" || !val) {
                setMsgError("");
                onChange(evt, null);
                return false;
            }
            if (!testStr.test(val)) {
                setMsgError("Please enter time e.g. 08:00");
                onChange(evt, null);
                return false;
            }
            if (testStr.test(val)) {
                setMsgError("");
                onChange(evt, moment(val, "HH:mm").format());
            }
        },
        [onChange],
    );
    return (
        <div className="position-relative w-100">
            <Autocomplete
                fullWidth
                freeSolo
                value={value ? moment(value).format("HH:mm") : null}
                options={options.map(option => option?.timeText || "")}
                onChange={handleOnchange}
                renderInput={params => (
                    <TextField
                        error={error || Boolean(msgError) ? true : false}
                        helperText={helperText ? helperText : msgError || ""}
                        {...params}
                        variant="outlined"
                        size="small"
                        onChange={handleOnchangeText}
                        InputProps={{
                            ...params.InputProps,
                            // startAdornment: (
                            //     <InputAdornment position="start">
                            //         <AccessTime fontSize="small" />
                            //     </InputAdornment>
                            // ),
                        }}
                    />
                )}
            />
        </div>
    );
}
