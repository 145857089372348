/* eslint-disable  no-unused-vars */
import { Column } from "material-table";
import React from "react";
import { object, string } from "yup";
import SwitchApp from "../../../componentsUtils/SwitchApp";

export const tableColumnsCategory = props => {
    /** @type Column */
    const columns = [
        {
            field: "id",
            title: "#",
            sorting: false,
            render: obj => (
                <div>{props?.tableOptions?.currentPage * props?.tableOptions?.pageSize + obj.tableData?.id + 1}</div>
                // <div>{obj?.id}</div>
            ),
        },
        {
            field: "categoryName",
            title: "Skill Level",
        },
        {
            field: "status",
            title: "Status",
            sorting: false,
            render: obj => (
                <SwitchApp
                    checked={obj?.status === "1"}
                    onChange={e =>
                        obj.status === "1"
                            ? props?.handleChangeStatus("0", obj?.id)
                            : props?.handleChangeStatus("1", obj?.id)
                    }
                />
            ),
        },
    ];
    const data = columns?.map((col, index) => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};

export const initialValuesCategory = {
    name: "",
    description: "",
};

export const validateSchemaCategory = object({
    name: string()
        .max(150)
        .required("Required."),
    description: string().max(255),
});
