import React from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { Formik } from "formik";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { replaceMoneyInterger } from "../../../utils/replaceString";
import { validationSchemaCredit } from "./helpers/options";
import { currencyNoSymbol } from "../../../utils/formatCurrency";
import DatePickerApp from "../../componentsUtils/DatePickerApp";

export default function FormCredit({ onSubmit = () => {}, onCancel = () => {}, initialFormValues = {} }) {
    return (
        <Formik
            enableReinitialize
            initialValues={initialFormValues}
            validationSchema={validationSchemaCredit}
            onSubmit={onSubmit}>
            {({ values, handleSubmit, errors, setFieldValue }) => (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center" className="mb-3">
                            <Grid
                                item
                                xs={12}
                                md={5}
                                className="text-center"
                                style={{ backgroundColor: "#e4b07b", color: "#fff" }}>
                                Credit Balance (Old)
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={7}
                                className="text-center"
                                style={{ backgroundColor: "#b6a68b", color: "#fff" }}>
                                Credit Balance (New)
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems="center" className="mb-5">
                            <Grid item xs={12} md={5}>
                                <p className="mb-0 text-center">{currencyNoSymbol(values?.balance || "0.00")}</p>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    value={values?.newBalance}
                                    name="newBalance"
                                    onChange={async evt => {
                                        const newBalance = replaceMoneyInterger(evt?.target?.value);
                                        await setFieldValue("newBalance", newBalance);
                                    }}
                                    error={Boolean(errors?.newBalance)}
                                    helperText={errors?.newBalance}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems="center" className="mb-3">
                            <Grid
                                item
                                xs={12}
                                md={5}
                                className="text-center"
                                style={{ backgroundColor: "#e4b07b", color: "#fff" }}>
                                Expired Date (Old)
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={7}
                                className="text-center"
                                style={{ backgroundColor: "#b6a68b", color: "#fff" }}>
                                Expired Date (New)
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems="center" className="mb-5">
                            <Grid item xs={12} md={5}>
                                <p className="mb-0 text-center">
                                    {values?.balanceExpired ? moment(values?.balanceExpired).format("DD/MM/YYYY") : "-"}
                                </p>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <DatePickerApp
                                    fullWidth
                                    value={values?.newBalanceExpired}
                                    onChange={date => {
                                        setFieldValue("newBalanceExpired", date);
                                    }}
                                    size="small"
                                    error={Boolean(errors?.newBalanceExpired)}
                                    helperText={errors?.newBalanceExpired && errors?.newBalanceExpired}
                                    placeholder="Choose date."
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* action */}
                    <Grid item xs={12}>
                        <div className="d-flex justify-content-end align-items-center">
                            <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                                <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                            </Button>
                            <Button onClick={handleSubmit} className="btn t-btn-secondary">
                                <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
}
