import { axiosAuthInstance } from "./auth.axios";

export default function setupAxios(axios, _store) {
    axios.interceptors.request.use(
        config => {
            const authToken = localStorage.getItem("authToken");
            if (authToken) {
                config.headers.Authorization = `Bearer ${authToken}`;
            }
            if (config.url === "upload/upload-image" || config.url === "upload/upload-file-image") {
                config.baseURL = `${process.env.REACT_APP_UPLOAD_HOST}/${process.env.REACT_APP_API_PATH}/`;
            } else {
                config.baseURL = `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_PATH}/`;
            }
            return config;
        },
        err => Promise.reject(err),
    );

    axios.interceptors.response.use(
        response => {
            return response;
        },
        async error => {
            const originalRequest = error.config;
            if (error?.response?.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                const { status, data } = await axiosAuthInstance.get("web-refresh-access/web-refresh");
                if (status === 200) {
                    localStorage.setItem("authToken", data?.access_token);
                    const authToken = localStorage.getItem("authToken");
                    if (authToken) {
                        axios.defaults.headers.Authorization = `Bearer ${authToken}`;
                    }
                    return axios(originalRequest);
                }
            }
            return Promise.reject(error);
        },
    );
}
