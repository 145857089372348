import { Button, Grid, TextField, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Formik } from "formik";
import React, { useCallback, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import { currencyNoSymbol, currencyTHB } from "../../../utils/formatCurrency";
import { validationSchemaRecieve } from "./helpers/orderTransactionOption";
import FormPaymentType from "../CreateOrder/FormPaymentType";

export default function FormPayment({
    onSubmit = () => {},
    onCancel = () => {},
    initialFormValues = {},
    paymentOption = [],
}) {
    // ref
    const formikRef = useRef(null);

    // component state
    const [modalPayment, setModalPayment] = useState({ isShow: false, type: "", paymentMethodId: 0 });

    // open payment modal
    const handleOpenModalPayment = useCallback(payMethod => {
        setModalPayment({ isShow: true, type: payMethod?.type, paymentMethodId: payMethod?.paymentMethodId });
    }, []);

    // close payment modal
    const handleCloseModalPayment = useCallback(() => {
        setModalPayment({ isShow: false, type: "", paymentMethodId: 0 });
    }, []);

    // submit pay by different method
    const handleSubmitPayment = useCallback(
        (values, { setFieldError }) => {
            const formikValues = formikRef.current?.values;
            const setFieldValue = formikRef.current?.setFieldValue;
            const remaining = parseFloat(values?.remaining || "0.00");
            const paid = parseFloat(formikValues?.paid || "0.00");
            const initialPayment = parseFloat(values?.initialPayment || "0.00");
            const payment = parseFloat(values?.payment || "0.00");
            const balance = parseFloat(values?.balance || "0.00");

            if (balance < 0) {
                setFieldError("payment", "Not Enough Balance");
                return;
            }

            // check payment selected
            const findPaymentSelected = formikValues?.paymentSelected?.find(item => item?.type === values?.type);
            if (!findPaymentSelected) {
                setFieldValue("paymentSelected", [
                    ...formikValues?.paymentSelected,
                    { type: values?.type, paymentMethodId: values?.paymentMethodId, payment },
                ]);
            } else {
                setFieldValue(
                    "paymentSelected",
                    formikValues?.paymentSelected?.map(item =>
                        item?.type === values?.type ? { ...item, payment } : item,
                    ),
                );
                // check zero payment
                if (payment === 0) {
                    setFieldValue(
                        "paymentSelected",
                        formikValues?.paymentSelected?.filter(item => item?.type !== values?.type),
                    );
                }
            }

            setModalPayment(prev => ({ ...prev, isShow: false }));
            setFieldValue("paid", paid - initialPayment + payment);
            setFieldValue("remaining", remaining);
            setFieldValue("studentBalance", values?.balance);
        },
        [formikRef],
    );

    return (
        <Formik
            enableReinitialize
            innerRef={formikRef}
            initialValues={initialFormValues}
            validationSchema={validationSchemaRecieve}
            onSubmit={onSubmit}>
            {({ values, handleSubmit, handleChange, errors }) => (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center" className="mb-3">
                            <Grid item xs={12} md={4}>
                                <p className="mb-0">Total Amount</p>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <p className="mb-0">{currencyTHB(values?.totalAmount)}</p>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} alignItems="center" justify="space-between" className="mb-3">
                            {paymentOption.map((payMethod, idx) => (
                                <Grid item key={`payment_${idx}`} xs={4}>
                                    <Button
                                        onClick={() => handleOpenModalPayment(payMethod)}
                                        className={`btn w-100 ${
                                            values?.paymentSelected?.find(item => item?.type === payMethod?.type)
                                                ? "t-btn-primary"
                                                : "t-btn-primary-outline"
                                        }`}>
                                        {payMethod?.name}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container spacing={2} alignItems="center" className="mb-3">
                            <Grid item xs={12} md={4}>
                                <p className="mb-0">Paid</p>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    value={currencyNoSymbol(values?.paid)}
                                    name="paid"
                                    onChange={handleChange}
                                    error={Boolean(errors?.paid)}
                                    helperText={errors?.paid}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems="center" className="mb-3">
                            <Grid item xs={12} md={4}>
                                <p className="mb-0">Remaining</p>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    value={currencyNoSymbol(values?.remaining)}
                                    name="remaining"
                                    onChange={handleChange}
                                    error={Boolean(errors?.remaining)}
                                    helperText={errors?.remaining}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* action */}
                    {!values?.order || values?.order?.status === "1" || values?.order?.status === "3" ? (
                        <Grid item xs={12}>
                            <div className="d-flex justify-content-md-end align-items-center">
                                <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                                    <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                                </Button>
                                <Button onClick={handleSubmit} className="btn t-btn-secondary">
                                    <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                                </Button>
                            </div>
                        </Grid>
                    ) : null}
                    {/* payment modal */}
                    <Modal show={modalPayment?.isShow} centered scrollable onHide={handleCloseModalPayment}>
                        <Modal.Header>
                            <Modal.Title>{modalPayment?.type}</Modal.Title>
                            <IconButton onClick={handleCloseModalPayment}>
                                <Close />
                            </IconButton>
                        </Modal.Header>
                        <Modal.Body>
                            <FormPaymentType
                                paymentMethodId={modalPayment?.paymentMethodId}
                                type={modalPayment?.type}
                                onCancel={handleCloseModalPayment}
                                onSubmit={handleSubmitPayment}
                                formikValues={values}
                            />
                        </Modal.Body>
                    </Modal>
                </Grid>
            )}
        </Formik>
    );
}
