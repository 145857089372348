import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "course-category";

const GET_ALL_CATEGORY_URL = `${prefix}/get-all`;
const CREATE_CATEGORY_URL = `${prefix}/create`;
const UPDATE_CATEGORY_BY_ID_URL = `${prefix}/update-by-id`;
const DELETE_CATEGORY_BY_ID_URL = `${prefix}/delete-by-id`;

/**
 * @func getAllCourseCategory
 * @desc get all category data
 * @author kop_ter
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} category array
 */
export const getAllCourseCategory = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_ALL_CATEGORY_URL, {
            params: queryParams,
            signal,
        });

        if (status === 200) {
            return data;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllCourseCategory");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getAllCourseCategory */

export const createCourseCategory = async (body, signal) => {
    try {
        const { status } = await axios.post(CREATE_CATEGORY_URL, body, { signal });

        if (status === 201) {
            return true;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "createCourseCategory");
        if (err) throw err;
        return undefined;
    }
};

export const updateCourseCategory = async (id, body, signal) => {
    try {
        const { status } = await axios.put(`${UPDATE_CATEGORY_BY_ID_URL}/${id}`, body, { signal });

        if (status === 200) {
            return true;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "updateCourseCategory");
        if (err) throw err;
        return undefined;
    }
};

export const deleteCourseCategory = async (id, signal) => {
    try {
        const { status } = await axios.delete(`${DELETE_CATEGORY_BY_ID_URL}/${id}`, { signal });

        if (status === 200) {
            return true;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "deleteCourseCategory");
        if (err) throw err;
        return undefined;
    }
};
