import { Grid, Paper } from "@material-ui/core";
import React from "react";
import ContentBranch from "../../components/BranchManagement/ContentBranch";

export default function BranchManagement() {
    return (
        <Paper className="p-2 p-md-3 py-3 py-md-5">
            <Grid container spacing={0}>
                <Grid item xs={12} className="mb-3">
                    <h4>Branch Management</h4>
                </Grid>
                <Grid item xs={12}>
                    <ContentBranch />
                </Grid>
            </Grid>
        </Paper>
    );
}
