import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, FormLabel, Grid, IconButton } from "@material-ui/core";
import moment from "moment";
import MaterialTable from "material-table";
import { Modal } from "react-bootstrap";
import { Close } from "@material-ui/icons";
import { getColumnsTableCredit, initialValuesCredit } from "./helpers/options";
import SelectSearchApp from "../../componentsUtils/SelectSearchApp";
import DatePickerApp from "../../componentsUtils/DatePickerApp";
import { getOptionsTableApp } from "../../helpers/useOption";
import { getCreditOption, getStudentCredit, updateStudentCreditById } from "../../../apis/creditApi";
import FormCredit from "./FormCredit";
import { swalCondition } from "../../../utils/swal";

export default function CreditContent() {
    // ref
    const controllerRef = useRef(null);
    const tableRef = useRef(null);

    // global redux state
    const { thisMenuPermission } = useSelector(state => {
        const { menuPermission } = state?.mainMenu;
        return {
            thisMenuPermission: menuPermission?.find(menu => menu?.path === window.location.pathname)?.permission,
        };
    });

    // component state
    const [isLoading, setIsLoading] = useState(false);
    const [tableOptions, setTableOptions] = useState({ ...getOptionsTableApp, pageSize: 100 });
    const [dataFilter, setDataFilter] = useState({
        student: {},
        startDate: null,
        endDate: null,
    });
    const [filterError, setFilterError] = useState({
        startDate: "",
        endDate: "",
    });
    const [options, setOption] = useState({
        studentOption: [],
    });
    const [modalCredit, setModalCredit] = useState(false);
    const [initialCreditFormValues, setInitialCreditFormValues] = useState(initialValuesCredit);

    // change expired date filter
    const handleChangeExpiredDateFilter = useCallback((value, typeField) => {
        if (value) {
            const timestamp = moment(value).unix();
            if (Number.isNaN(timestamp) || timestamp < 0) {
                setFilterError(prevState => ({
                    ...prevState,
                    [typeField]: "Invalid Date",
                }));
                return;
            }
        }
        setDataFilter(prevState => ({ ...prevState, [typeField]: moment(value).format("YYYY-MM-DD") }));
        setFilterError(prevState => ({
            ...prevState,
            [typeField]: "",
        }));
    }, []);

    // clear filter
    const handleClearFilter = useCallback(() => {
        setDataFilter({
            student: {},
            startDate: null,
            endDate: null,
        });
        setFilterError({
            startDate: "",
            endDate: "",
        });
    }, []);

    // open credit modal
    const handleOpenCreditModal = useCallback(student => {
        setInitialCreditFormValues({
            balance: student?.balance || 0,
            balanceExpired: student?.balanceExpired || null,
            newBalance: student?.balance || 0,
            newBalanceExpired: student?.balanceExpired || null,
            student,
        });
        setModalCredit(true);
    }, []);

    // close credit modal
    const handleCloseCreditModal = useCallback(() => {
        setModalCredit(false);
    }, []);

    // update student credit
    const handleUpdateStudentCredit = useCallback(async values => {
        try {
            const isConfirmed = await swalCondition("Confirm credit?", "", {
                icon: "warning",
            });
            if (isConfirmed) {
                const isSuccess = await updateStudentCreditById(
                    values?.student?.id,
                    {
                        balance: values?.newBalance,
                        balanceExpired: values?.newBalanceExpired,
                    },
                    controllerRef.current?.signal,
                );
                if (isSuccess) {
                    tableRef.current.onQueryChange();
                    setModalCredit(false);
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // fetch option
    const fetchOption = useCallback(async () => {
        try {
            const option = await getCreditOption(controllerRef?.current?.signal);
            if (option) {
                setOption({
                    studentOption: option?.studentOption,
                });
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // fetch data
    const fetchData = useCallback(
        async query => {
            try {
                setIsLoading(true);
                const queryParams = {
                    page: query?.page + 1 || 1,
                    limit: query?.pageSize || 10,
                    order: query?.orderBy?.field || "id",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    studentId: dataFilter?.student?.id,
                    startDate: dataFilter?.startDate ? moment(dataFilter?.startDate).format("YYYY-MM-DD") : undefined,
                    endDate: dataFilter?.endDate ? moment(dataFilter?.endDate).format("YYYY-MM-DD") : undefined,
                };
                const res = await getStudentCredit(queryParams, controllerRef?.current?.signal);
                if (res) {
                    setTableOptions(currentState => ({
                        ...currentState,
                        currentPage: query?.page,
                        pageSize: query?.pageSize || 10,
                        totalData: res?.pagination?.totalData || 0,
                        order: query?.orderBy?.field || "timestamp",
                        direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    }));
                    setIsLoading(false);
                    return {
                        data: res?.data || [],
                        page: query?.page || 0,
                        totalCount: res?.pagination?.totalData || 0,
                    };
                }
                setIsLoading(false);
            } catch (error) {
                console.dir(error);
                setIsLoading(false);
                return {
                    data: [],
                    page: 0,
                    totalCount: 0,
                };
            }
        },
        [dataFilter],
    );

    // memo
    const tableColumns = useMemo(() => getColumnsTableCredit(), []);

    useEffect(() => {
        if (dataFilter.startDate && dataFilter.endDate && dataFilter.startDate > dataFilter.endDate) {
            setFilterError(prevState => ({
                ...prevState,
                endDate: `Date should be after ${moment(dataFilter.startDate).format("DD/MM/YYYY")}`,
            }));
            return;
        }
        tableRef.current.onQueryChange({ page: 0 });
    }, [dataFilter]);

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        fetchOption();
        return () => {
            controllerRef.current.abort();
        };
    }, [fetchOption]);

    return (
        <Grid container spacing={0}>
            {/* filter */}
            <Grid item xs={12} className="my-10">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={11}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3} className="d-md-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Student :</FormLabel>
                                <div className="flex-fill">
                                    <SelectSearchApp
                                        size="sm"
                                        options={[{ id: "" }, ...options?.studentOption]}
                                        value={dataFilter?.student}
                                        onChange={value =>
                                            setDataFilter(prevState => ({
                                                ...prevState,
                                                student: value.id ? value : {},
                                            }))
                                        }
                                        getOptionLabel={option => {
                                            if (!option?.id) return "All";
                                            return option?.studentFirstname && option?.studentLastname
                                                ? `${option?.studentFirstname} ${option?.studentLastname} (${option?.studentNickname})`
                                                : "";
                                        }}
                                        getOptionValue={option => option?.id}
                                        placeholder="All"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} className="d-md-block align-items-center">
                                <FormLabel className="mb-md-0 mr-1">Expired Date : </FormLabel>
                                <div className="d-flex align-items-center justify-content-center flex-fill">
                                    <DatePickerApp
                                        className="flex-fill fixed-h-lg"
                                        size="small"
                                        value={dataFilter?.startDate}
                                        onChange={date => {
                                            handleChangeExpiredDateFilter(date, "startDate");
                                        }}
                                        error={Boolean(filterError.startDate)}
                                        helperText={filterError.startDate}
                                    />
                                    <small className="mx-3">To</small>
                                    <DatePickerApp
                                        className="flex-fill fixed-h-lg"
                                        size="small"
                                        value={dataFilter?.endDate}
                                        minDate={dataFilter?.startDate || undefined}
                                        onChange={date => {
                                            handleChangeExpiredDateFilter(date, "endDate");
                                        }}
                                        error={Boolean(filterError.endDate)}
                                        helperText={filterError.endDate}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <div className="d-flex align-items-center mt-lg-6">
                                    <Button
                                        size="large"
                                        className="btn t-btn-primary fixed-h-lg flex-lg-grow-0 flex-fill mr-2"
                                        onClick={handleClearFilter}>
                                        Clear
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* data table */}
            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        options={tableOptions}
                        data={fetchData}
                        columns={tableColumns}
                        components={{
                            Container: Box,
                        }}
                        actions={[
                            {
                                icon: "edit",
                                iconProps: { className: "action-edit" },
                                tooltip: "Edit",
                                onClick: (event, rowData) => handleOpenCreditModal(rowData),
                                hidden: !thisMenuPermission?.edit,
                            },
                        ]}
                    />
                </div>
            </Grid>
            {/* modal */}
            <Grid item xs={12}>
                <Modal show={modalCredit} scrollable centered onHide={handleCloseCreditModal}>
                    <Modal.Header>
                        <Modal.Title>
                            Edit Student Credit
                            <div
                                className="font-weight-lighter"
                                style={{
                                    fontSize: 12,
                                }}>{`${initialCreditFormValues?.student?.studentFirstname} ${initialCreditFormValues?.student?.studentLastname} (${initialCreditFormValues?.student?.studentNickname})`}</div>
                        </Modal.Title>
                        <IconButton onClick={handleCloseCreditModal}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body>
                        <FormCredit
                            onCancel={handleCloseCreditModal}
                            onSubmit={handleUpdateStudentCredit}
                            initialFormValues={initialCreditFormValues}
                        />
                    </Modal.Body>
                </Modal>
            </Grid>
        </Grid>
    );
}
