/* eslint-disable */
import { Column } from "material-table";
import moment from "moment";
import { convertScheduleDate } from "../../../../utils/format";

export const columnsGroup = () => {
    /** @type Column */
    const columns = [
        {
            title: "Booking Date",
            field: "bookingStartDate",
            render: row => convertScheduleDate(row?.bookingStartDate),
        },
        {
            title: "Start Time",
            field: "bookingStartDate",
            render: row => (row?.bookingStartDate ? moment(row?.bookingStartDate).format("HH:mm") : ""),
        },
        {
            title: "End Time",
            field: "bookingEndDate",
            render: row => (row?.bookingEndDate ? moment(row?.bookingEndDate).format("HH:mm") : ""),
        },
        {
            title: "Student",
            field: "ScheduleGroupListHasStudents.Student.studentFirstname",
            render: row =>
                `${row?.ScheduleGroupListHasStudents?.Student?.studentFirstname || ""} ${row
                    ?.ScheduleGroupListHasStudents?.Student?.studentLastname || ""}`,
        },
        {
            title: "Course",
            field: "ScheduleGroup.Course.courseName",
        },
        {
            title: "Menu",
            field: "ScheduleGroupListHasMenus.CourseMenu.courseMenuName",
        },
        {
            title: "Teacher",
            field: "ScheduleGroupListHasMenus.Teacher.teacherFirstname",
            render: row =>
                `${row?.ScheduleGroupListHasMenus?.Teacher?.teacherFirstname || ""} ${row?.ScheduleGroupListHasMenus
                    ?.Teacher?.teacherLastname || ""}`,
        },
        {
            title: "Classroom",
            field: "Classroom.classroomName",
        },
        {
            title: "Room Name",
            field: "ScheduleGroup.roomName",
        },
        {
            title: "Note",
            field: "ScheduleGroup.note",
        },
    ];
    const data = columns?.map((col, index) => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};
