import { Button, Grid, IconButton, Paper, Tab, Tabs } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getStudentById, updateStudentById } from "../../../apis/studentApi";
import { uploadImageFile } from "../../../apis/uploadApi";
import { useForceRender } from "../../../utils/customHook";
import { toImageUrl } from "../../../utils/format";
import { currencyTHB } from "../../../utils/formatCurrency";
import StudentForm from "../StudentsManagement/StudentForm";
import ContentClassHistory from "./ContentClassHistory";
import ContentOrderHistory from "./ContentOrderHistory";
import ContentAbsence from "./ContentAbsence";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import ContentNote from "./ContentNote";
import { initialStudentProfileFormValues } from "./helpers/studentOption";
import { getBasicOfBakingName, getSocialPermissionName, getToKnowFromName } from "./helpers/optionName";

export default function StudentProfileContent({ id }) {
    const [render, forceRender] = useForceRender();

    // global redux state
    const { thisMenuPermission } = useSelector(state => {
        const { menuPermission } = state?.mainMenu;
        return {
            thisMenuPermission: menuPermission?.find(menu => window.location.pathname.includes(menu?.path))?.permission,
        };
    });

    // component state
    const [studentProfile, setStudentProfile] = useState({
        firstname: "",
        lastname: "",
        nickname: "",
        classOf: "",
        email: "",
        tel: "",
        lineId: "",
        birthDate: "",
        address: "",
        province: "",
        country: "",
        levelOfExpertise: "",
        sizeOfChefShirt: "",
        studentNote: "",
        learningPurpose: "",
        getToKnowFrom: "",
        socialMediaPermission: "",
        imagePath: "",
        balance: "",
        balanceExpired: "",
        note: "",
        registerDate: "",
    });
    const [studentData, setStudentData] = useState({});
    const [modalForm, setModalForm] = useState(false);
    const [valueTabs, setValueTabs] = useState("class_history");
    const controllerRef = useRef(null);
    const [initialValuesStudent, setInitialValuesStudent] = useState(initialStudentProfileFormValues);

    // for function change tab
    const handleChangeTab = useCallback((_, value) => {
        setValueTabs(value);
    }, []);

    // function cotrol modal
    const handleCloseModal = useCallback(() => {
        setModalForm(false);
        setInitialValuesStudent(initialStudentProfileFormValues);
    }, [setInitialValuesStudent]);

    // open edit form modal
    const handleOpenEditFormModal = useCallback(async () => {
        setModalForm(true);
        setInitialValuesStudent(currentState => ({
            ...currentState,
            fname: studentData?.studentFirstname || "",
            lname: studentData?.studentLastname || "",
            nickname: studentData?.studentNickname || "",
            classOf: studentData?.studentClassof || "",
            email: studentData?.studentEmail || "",
            tel: studentData?.studentTel || "",
            lineId: studentData?.studentLineid || "",
            birthday: studentData?.studentBirthday || new Date(),
            address: studentData?.studentAddress || "",
            province: studentData?.studentProvince || "",
            country: studentData?.studentCountry || "",
            expertise: studentData?.studentExpertise || "",
            sizeApron: studentData?.studentSizeshirt || "",
            studentNote: studentData?.studentNote || "",
            learnObjt: studentData?.objective || "",
            knowChannels: studentData?.funnel || "",
            allowSocial: studentData?.social || "",
            studentImagePath: studentData?.studentImagePath || "",
        }));
    }, [studentData, setInitialValuesStudent]);

    // update student logic
    const handleUpdateStudent = useCallback(
        async values => {
            try {
                const updateBody = {
                    studentFirstname: values?.fname,
                    studentLastname: values?.lname,
                    studentNickname: values?.nickname,
                    studentClassof: values?.classOf,
                    studentEmail: values?.email,
                    studentTel: values?.tel,
                    studentLineid: values?.lineId,
                    studentBirthday: values?.birthday,
                    studentAddress: values?.address,
                    studentProvince: values?.province,
                    studentCountry: values?.country,
                    studentExpertise: values?.expertise,
                    studentSizeshirt: values?.sizeApron,
                    studentNote: values?.studentNote,
                    objective: values?.learnObjt,
                    funnel: values?.knowChannels,
                    social: values?.allowSocial,
                };

                // upload image
                if (values?.image) {
                    if (values?.image?.file) {
                        updateBody.studentImagePath = await uploadImageFile(
                            values?.image?.file,
                            controllerRef.current?.signal,
                        );
                    }
                } else {
                    updateBody.studentImagePath = "";
                }

                const isUpdate = await updateStudentById(id, updateBody, controllerRef.current?.signal);
                if (isUpdate) {
                    setModalForm(false);
                    forceRender();
                }
            } catch (error) {
                console.dir(error);
            }
        },
        [id, forceRender],
    );

    // fetch data
    const fetchData = useCallback(async id => {
        try {
            const res = await getStudentById(id, controllerRef.current?.signal);
            if (res) {
                setStudentProfile(currentState => ({
                    ...currentState,
                    imagePath: res?.studentImagePath || "",
                    firstname: res?.studentFirstname || "-",
                    lastname: res?.studentLastname || "-",
                    nickname: res?.studentNickname || "-",
                    classOf: res?.studentClassof || "-",
                    email: res?.studentEmail || "-",
                    tel: res?.studentTel || "-",
                    lineId: res?.studentLineid || "-",
                    birthDate: res?.studentBirthday ? moment(res?.studentBirthday).format("DD/MM/YYYY") : "-",
                    address: res?.studentAddress || "-",
                    province: res?.studentProvince || "-",
                    country: res?.studentCountry || "-",
                    levelOfExpertise: res?.studentExpertise ? getBasicOfBakingName(res?.studentExpertise) : "-",
                    sizeOfChefShirt: res?.studentSizeshirt || "-",
                    studentNote: res?.studentNote || "-",
                    learningPurpose: res?.objective || "-",
                    getToKnowFrom: res?.funnel ? getToKnowFromName(res?.funnel) : "-",
                    socialMediaPermission: res?.social ? getSocialPermissionName(res?.social) : "-",
                    balance: currencyTHB(
                        res?.balance && res?.balanceExpired
                            ? new Date(res?.balanceExpired) > new Date()
                                ? res?.balance
                                : ""
                            : "",
                    ),
                    balanceExpired: res?.balanceExpired ? moment(res?.balanceExpired).format("DD/MM/YYYY") : "-",
                    note: res?.note,
                    registerDate: res?.createdAt ? moment(res?.createdAt).format("DD/MM/YYYY") : "-",
                }));
                setStudentData(res);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        fetchData(id);
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, [id, fetchData, render]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
                <Paper className="p-3">
                    <div className="d-flex justify-content-center my-5">
                        <img
                            className="img-profile-student"
                            src={
                                studentProfile?.imagePath
                                    ? toImageUrl(studentProfile?.imagePath)
                                    : toAbsoluteUrl("/media/users/blank.png")
                            }
                            alt="profile"
                        />
                    </div>
                    <div className="mb-4 d-sm-flex">
                        <div className="w-50">
                            <div className="mb-2">First name</div>
                            <div className="h6 text-muted">{studentProfile?.firstname}</div>
                        </div>
                        <div className="w-50">
                            <div className="mb-2">Last name</div>
                            <div className="h6 text-muted">{studentProfile?.lastname}</div>
                        </div>
                    </div>
                    <div className="mb-4 d-sm-flex">
                        <div className="w-50">
                            <div className="mb-2">Nick name</div>
                            <div className="h6 text-muted">{studentProfile?.nickname}</div>
                        </div>
                        <div className="w-50">
                            <div className="mb-2">Class of #</div>
                            <div className="h6 text-muted">{studentProfile?.classOf}</div>
                        </div>
                    </div>
                    <div className="mb-4 d-sm-flex">
                        <div className="w-100">
                            <div className="mb-2">Email</div>
                            <div className="h6 text-muted">{studentProfile?.email}</div>
                        </div>
                    </div>
                    <div className="mb-4 d-sm-flex">
                        <div className="w-100">
                            <div className="mb-2">Tel</div>
                            <div className="h6 text-muted">{studentProfile?.tel}</div>
                        </div>
                    </div>
                    <div className="mb-4 d-sm-flex">
                        <div className="w-50">
                            <div className="mb-2">Line ID</div>
                            <div className="h6 text-muted">{studentProfile?.lineId}</div>
                        </div>
                        <div className="w-50">
                            <div className="mb-2">Birth Date</div>
                            <div className="h6 text-muted">{studentProfile?.birthDate}</div>
                        </div>
                    </div>
                    <div className="mb-4 d-sm-flex">
                        <div className="w-100">
                            <div className="mb-2">Address</div>
                            <div className="h6 text-muted">{studentProfile?.address}</div>
                        </div>
                    </div>
                    <div className="mb-4 d-sm-flex">
                        <div className="w-50">
                            <div className="mb-2">Province</div>
                            <div className="h6 text-muted">{studentProfile?.province}</div>
                        </div>
                        <div className="w-50">
                            <div className="mb-2">Country</div>
                            <div className="h6 text-muted">{studentProfile?.country}</div>
                        </div>
                    </div>
                    <div className="mb-4 d-sm-flex">
                        <div className="w-100">
                            <div className="mb-2">Level of expertise</div>
                            <div className="h6 text-muted">{studentProfile?.levelOfExpertise}</div>
                        </div>
                    </div>
                    <div className="mb-4 d-sm-flex">
                        <div className="w-100">
                            <div className="mb-2">Learning Purpose</div>
                            <div className="h6 text-muted">{studentProfile?.learningPurpose}</div>
                        </div>
                    </div>
                    <div className="mb-4 d-sm-flex">
                        <div className="w-50">
                            <div className="mb-2">Get to know from</div>
                            <div className="h6 text-muted">{studentProfile?.getToKnowFrom}</div>
                        </div>
                        <div className="w-50">
                            <div className="mb-2">Social Media Permission</div>
                            <div className="h6 text-muted">{studentProfile?.socialMediaPermission}</div>
                        </div>
                    </div>
                    <div className="mb-4 d-sm-flex">
                        <div className="w-50">
                            <div className="mb-2">Size of Chef Shirt</div>
                            <div className="h6 text-muted">{studentProfile?.sizeOfChefShirt}</div>
                        </div>
                        <div className="w-50">
                            <div className="mb-2">Student Note</div>
                            <div className="h6 text-muted">{studentProfile?.studentNote}</div>
                        </div>
                    </div>
                    <div className="mb-4 d-sm-flex">
                        <div className="w-50">
                            <div className="mb-2">Register Date</div>
                            <div className="h6 text-muted">{studentProfile?.registerDate}</div>
                        </div>
                    </div>
                    {thisMenuPermission?.edit && (
                        <div className="d-flex justify-content-center mb-3">
                            <Button onClick={handleOpenEditFormModal} className="col-12 col-lg-4 btn t-btn-primary">
                                Edit Profile
                            </Button>
                        </div>
                    )}
                    <hr />
                    <div className="mb-4">
                        <div className="mb-2">Balance</div>
                        <div className="h6 text-muted">{studentProfile?.balance}</div>
                    </div>
                    <div className="mb-4">
                        <div className="mb-2">
                            Expire:
                            <span className="text-muted ml-2">{studentProfile?.balanceExpired}</span>
                        </div>
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={12} lg={8}>
                <Paper className="p-3">
                    <div className="mb-3">
                        <Tabs value={valueTabs} onChange={handleChangeTab} indicatorColor="primary" textColor="primary">
                            <Tab label="class History" value="class_history" />
                            <Tab label="Order History" value="order_history" />
                            <Tab label="Absence" value="absence" />
                            <Tab label="Note" value="note" />
                        </Tabs>
                    </div>
                    <div>
                        {valueTabs === "class_history" && (
                            <ContentClassHistory studentProfile={studentProfile} studentId={id} />
                        )}
                        {valueTabs === "order_history" && <ContentOrderHistory studentId={id} />}
                        {valueTabs === "absence" && <ContentAbsence studentId={id} />}
                        {valueTabs === "note" && (
                            <ContentNote
                                controllerRef={controllerRef}
                                studentId={id}
                                setRender={forceRender}
                                note={studentProfile?.note}
                            />
                        )}
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                {/* modal */}
                <Modal show={modalForm} centered scrollable onHide={handleCloseModal}>
                    <Modal.Header>
                        <Modal.Title>Edit Student</Modal.Title>
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body>
                        <StudentForm
                            onCancel={handleCloseModal}
                            onSubmit={handleUpdateStudent}
                            initialValuesStudent={initialValuesStudent}
                        />
                    </Modal.Body>
                </Modal>
            </Grid>
        </Grid>
    );
}
