import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "credit";

const GET_STUDENT_CREDIT_URL = `${prefix}/student`;
const GET_CREDIT_OPTION_URL = `${prefix}/option`;
const UPDATE_STUDENT_CREDIT_BY_ID_URL = `${prefix}/student`;

/**
 * @func getStudentCredit
 * @desc get student credit
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} student credit array
 */
export const getStudentCredit = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_STUDENT_CREDIT_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getStudentCredit");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getStudentCredit */

/**
 * @func getCreditOption
 * @desc get credit option
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} option array
 */
export const getCreditOption = async signal => {
    try {
        const { data, status } = await axios.get(GET_CREDIT_OPTION_URL, {
            signal,
        });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getCreditOption");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getCreditOption */

/**
 * @func updateStudentCreditById
 * @desc update student credit by id
 * @author izeberg
 * @param {number} id - stduent id
 * @param {Object} body - update student credit body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} update status
 */
export const updateStudentCreditById = async (id, body, signal) => {
    try {
        const { status } = await axios.patch(`${UPDATE_STUDENT_CREDIT_BY_ID_URL}/${id}`, body, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "updateStudentCreditById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func updateStudentCreditById */
