import { createSlice } from "@reduxjs/toolkit";

const initialMenuState = {
    allMenuFilter: [],
    mainMenu: [],
    subMemuLV1: [],
    subMemuLV2: [],
    isLoad: false,
    menuForRolePermssion: [],
    menuPermission: [],
    redirectPath: "",
};

export const mainMenuSlice = createSlice({
    name: "main_menu",
    initialState: initialMenuState,
    reducers: {
        setMenuFilter: (state, action) => {
            state.allMenuFilter = action?.payload;
        },
        setMainMenu: (state, action) => {
            state.mainMenu = action?.payload;
        },
        setSubMemuLV1: (state, action) => {
            state.subMemuLV1 = action?.payload;
        },
        setSubMemuLV2: (state, action) => {
            state.subMemuLV2 = action?.payload;
        },
        setIsLoad: (state, action) => {
            state.isLoad = action?.payload;
        },
        setMenuForRolePermission: (state, action) => {
            state.menuForRolePermssion = action?.payload;
        },
        setMenuPermission: (state, action) => {
            state.menuPermission = action?.payload;
        },
        setRedirectPath: (state, action) => {
            state.redirectPath = action.payload;
        },
    },
});

export const mainMenuReducers = mainMenuSlice.reducer;
export const mainMenuActions = mainMenuSlice.actions;
