import React from "react";
import PropTypes from "prop-types";

/**
 * @param {Object} props - react component props.
 * @param {string} props.label - button label.
 * @param {Function} props.onClick - click event function.
 * @param {string} props.className - custom class.
 * @param {boolean} props.disabled - disabled status.
 * @param {boolean} props.isLoading - disabled status.
 * @returns {jsx} Create Button Component.
 */
const CustomButton = ({ label, onClick, className, disabled, isLoading }) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className={`btn ${className || "btn-primary"}`}
            disabled={disabled || isLoading}>
            {isLoading ? "Loading..." : label}
        </button>
    );
};

CustomButton.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
};

CustomButton.defaultProps = {
    label: "",
    onClick: () => {},
    className: "",
    disabled: false,
    isLoading: false,
};

export default CustomButton;
