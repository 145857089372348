import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "role";

const GET_ALL_ROLE_URL = `${prefix}/all`;
const CREATE_ROLE_URL = `${prefix}/create`;
const UPDATE_ROLE_BY_ID_URL = `${prefix}/update-by-id`;
const DELETE_ROLE_BY_ID_URL = `${prefix}/delete-by-id`;

/**
 * @func getAllRole
 * @desc get all role data
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} role array
 */
export const getAllRole = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_ALL_ROLE_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllRole");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getAllRole */

/**
 * @func createRole
 * @desc create new role
 * @author izeberg
 * @param {Object} body - create role body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} create status
 */
export const createRole = async (body, signal) => {
    try {
        const { status } = await axios.post(CREATE_ROLE_URL, body, { signal });
        if (status === 201) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "createRole");
        if (err) throw err;
        return undefined;
    }
};
/** End @func createRole */

/**
 * @func updateRoleById
 * @desc update role by id
 * @author izeberg
 * @param {number} id - role id
 * @param {Object} body - update role body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} update status
 */
export const updateRoleById = async (id, body, signal) => {
    try {
        const { status } = await axios.put(`${UPDATE_ROLE_BY_ID_URL}/${id}`, body, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "updateRoleById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func updateRoleById */

/**
 * @func deleteRoleById
 * @desc delete role by id
 * @author izeberg
 * @param {number} id - role id
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} delete status
 */
export const deleteRoleById = async (id, signal) => {
    try {
        const { status } = await axios.delete(`${DELETE_ROLE_BY_ID_URL}/${id}`, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "deleteRoleById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func deleteRoleById */
