import React from "react";
import MaterialTable from "material-table";

const PreviewImport = ({ columns, data, fileName, author }) => {
    const tableOptions = {
        actionsColumnIndex: -1,
        paging: true,
        pageSize: 10, // make initial page size
        emptyRowsWhenPaging: false, // To avoid of having empty rows
        pageSizeOptions: [5, 10, 20, 30], // rows selection options
    };

    columns.unshift({
        title: "No.",
        field: "no",
        render: rowData => rowData.tableData.id + 1,
        customSort: (a, b) => a?.tableData?.id - b?.tableData?.id,
    });

    return (
        <div>
            <div>
                <div>
                    <label>File Name :</label>
                    <label className="ml-1">
                        <b>{fileName}</b>
                    </label>
                </div>
                <div>
                    <label>Total :</label>
                    <label className="ml-1">
                        <b>{data?.length}</b> rows
                    </label>
                </div>
                <div>
                    <label>Author :</label>
                    <label className="ml-1">
                        <b>{author}</b>
                    </label>
                </div>
            </div>
            <MaterialTable title="" columns={columns} data={data} options={tableOptions} />
        </div>
    );
};

export default PreviewImport;
