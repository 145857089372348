/* eslint no-unused-vars: */
import React from "react";
import moment from "moment";
import { Column } from "material-table";
import { capitalize } from "lodash";
import { currencyTHB } from "../../../../utils/formatCurrency";
import { getOrdinalSuffix } from "../../../../utils/number";

export const getColumnsTableTransactionReport = () => {
    /** @type Column */
    const column = [
        {
            field: "bookingNo",
            title: "Booking ID",
            cellStyle: { textAlign: "center" },
        },
        {
            field: "studentName",
            title: "Student",
            cellStyle: { textAlign: "center" },
        },
        {
            field: "bookingDate",
            title: "Date of booking",
            cellStyle: { textAlign: "left" },
            render: obj => {
                return obj?.Order?.OrderLists?.filter(item => item?.bookingDate)
                    ?.map(item => (item?.bookingDate ? moment(item?.bookingDate).format("DD/MM/YYYY") : ""))
                    ?.join(", ");
            },
        },
        {
            field: "name",
            title: "Product",
            cellStyle: { textAlign: "left" },
            render: obj => {
                return obj?.Order?.OrderLists?.map(item => item?.name)?.join(", ");
            },
        },
        {
            field: "saleTotal",
            title: "Sale Total",
            cellStyle: { textAlign: "center" },
            render: obj => <div>{currencyTHB(obj?.saleTotal)}</div>,
        },
        {
            field: "transactionDate",
            title: "Date & Time of Transaction",
            cellStyle: { textAlign: "center" },
        },
        {
            field: "orderHasPaymentId",
            title: "Transaction ID",
            cellStyle: { textAlign: "center" },
        },
        {
            field: "payment",
            title: "Transaction Amount",
            cellStyle: { textAlign: "center" },
            render: obj => <div>{currencyTHB(obj?.payment)}</div>,
        },
        {
            field: "package",
            title: "Package",
            cellStyle: { textAlign: "center" },
            render: obj => <div>{currencyTHB(obj?.package)}</div>,
        },
        {
            field: "scb",
            title: "SCB",
            cellStyle: { textAlign: "center" },
            render: obj => <div>{currencyTHB(obj?.scb)}</div>,
        },
        {
            field: "kbank",
            title: "K Bank",
            cellStyle: { textAlign: "center" },
            render: obj => <div>{currencyTHB(obj?.kbank)}</div>,
        },
        {
            field: "bay",
            title: "BAY",
            cellStyle: { textAlign: "center" },
            render: obj => <div>{currencyTHB(obj?.bay)}</div>,
        },
        {
            field: "credit",
            title: "Credit card",
            cellStyle: { textAlign: "center" },
            render: obj => <div>{currencyTHB(obj?.credit)}</div>,
        },
        {
            field: "kshop",
            title: "Kshop",
            cellStyle: { textAlign: "center" },
            render: obj => <div>{currencyTHB(obj?.kshop)}</div>,
        },
        {
            field: "installment",
            title: "ผ่อนผ่านบัตร",
            cellStyle: { textAlign: "center" },
            render: obj => <div>{currencyTHB(obj?.installment)}</div>,
        },
        {
            field: "ttb",
            title: "TTB",
            cellStyle: { textAlign: "center" },
            render: obj => <div>{currencyTHB(obj?.ttb)}</div>,
        },
        {
            field: "paypal",
            title: "PAYPAL",
            cellStyle: { textAlign: "center" },
            render: obj => <div>{currencyTHB(obj?.paypal)}</div>,
        },
        {
            field: "uob",
            title: "UOB",
            cellStyle: { textAlign: "center" },
            render: obj => <div>{currencyTHB(obj?.uob)}</div>,
        },
        {
            field: "transfer",
            title: "Transfer",
            cellStyle: { textAlign: "center" },
            render: obj => <div>{currencyTHB(obj?.transfer)}</div>,
        },
        {
            field: "other",
            title: "Other",
            cellStyle: { textAlign: "center" },
            render: obj => <div>{currencyTHB(obj?.other)}</div>,
        },
        {
            field: "status",
            title: "Status",
            cellStyle: { textAlign: "center" },
            render: obj => (
                <div>
                    {Number(obj?.status)
                        ? `${obj?.status}${getOrdinalSuffix(Number(obj?.status))} Payment`
                        : capitalize(obj?.status)}
                </div>
            ),
        },
        {
            field: "user",
            title: "User",
            cellStyle: { textAlign: "center" },
        },
    ];
    return column;
};
