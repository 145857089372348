import { Box, Button, FormControl, FormLabel, Grid, InputAdornment, TextField } from "@material-ui/core";
import { GetApp, Search } from "@material-ui/icons";
import MaterialTable from "material-table";
import React, { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";

import DatePickerApp from "../../componentsUtils/DatePickerApp";
// import SelectSearchApp from "../../componentsUtils/SelectSearchApp";
import { getOptionsTableApp } from "../../helpers/useOption";
import { columnsPrivate } from "./helpers/privateOption";
import { getAllScheduleListPrivate, getAllScheduleListPrivateForExcel } from "../../../apis/scheduleApi";
import { convertDateTimeFromApi } from "../../../utils/format";
import exportToExcel from "../../../utils/exportToExcel";

export default function ContentPrivate() {
    // component state
    const tableRef = useRef(null);
    const controllerRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [tableOptions, setTableOptions] = useState({ ...getOptionsTableApp, pageSize: 100 });
    const [valuesSearch, setValuesSearch] = useState("");
    const [isExport, setIsExport] = useState(false);
    const [valuesTeacher, setValuesSearchTeacher] = useState("");
    const [valuesStudent, setValuesSearchStudent] = useState("");
    const [valuesFilter, setValuesFilter] = useState({
        start_d: new Date(),
        end_d: new Date(),
        student: null,
    });

    // export excel
    const handleExportExcel = useCallback(async () => {
        setIsExport(true);
        try {
            const body = {
                start: valuesFilter?.start_d,
                end: valuesFilter?.end_d,
                student: valuesStudent,
                search: valuesSearch,
                teacher: valuesTeacher,
            };
            const res = await getAllScheduleListPrivateForExcel(body, controllerRef?.current?.signal);
            if (res) {
                const data = res.map(row => ({
                    "Booking Date": row?.bookingStartDate ? convertDateTimeFromApi(row?.bookingStartDate) : "",
                    "Start Time": row?.bookingStartDate ? moment(row?.bookingStartDate).format("HH:mm") : "",
                    "End Time": row?.bookingEndDate ? moment(row?.bookingEndDate).format("HH:mm") : "",
                    Student: `${row?.SchedulePrivate?.Student?.studentFirstname || ""} ${row?.SchedulePrivate?.Student
                        ?.studentLastname || ""}`,
                    "Booking No.": row?.SchedulePrivate?.Order?.bookingNo || "",
                    Course: row?.Course?.courseName || "",
                    Menu: row?.CourseMenu?.courseMenuName || "",
                    Teacher: row?.Teacher?.teacherFirstname ? `ครู${row?.Teacher?.teacherFirstname}` : "",
                    Classroom: row?.Classroom?.classroomName || "",
                    Note: row?.SchedulePrivate?.note || "",
                }));
                if (!data?.length) return false;
                exportToExcel(data, { fileName: "schedule-private-list" });
            }
        } catch (error) {
            console.dir(error);
        } finally {
            setIsExport(false);
        }
    }, [valuesTeacher, valuesStudent, valuesSearch, valuesFilter]);

    // onChangeFilter
    const handlerInputFilter = useCallback(
        (value, type = "") => {
            setValuesFilter(prev => ({ ...prev, [type]: value }));
            if (tableRef?.current !== null) {
                tableRef.current.onQueryChange({ page: 0 });
            }
        },
        [tableRef],
    );

    // search input
    const handleOnchangeSearch = useCallback(
        evt => {
            const value = evt.target?.value;
            setValuesSearch(value);
            if (tableRef?.current !== null) {
                tableRef.current.onQueryChange({ search: value, page: 0 });
            }
        },
        [tableRef],
    );
    // search input
    const handleOnchangeSearchTeacher = useCallback(
        evt => {
            const value = evt.target?.value;
            setValuesSearchTeacher(value);
            if (tableRef?.current !== null) {
                tableRef.current.onQueryChange({ teacher: value, page: 0 });
            }
        },
        [tableRef],
    );

    // search input
    const handleOnchangeSearchStudent = useCallback(
        evt => {
            const value = evt.target?.value;
            setValuesSearchStudent(value);
            if (tableRef?.current !== null) {
                tableRef.current.onQueryChange({ user: value, page: 0 });
            }
        },
        [tableRef],
    );

    // clear filter
    const handleClearInputFilter = useCallback(() => {
        setValuesFilter({
            start_d: new Date(),
            end_d: new Date(),
            student: null,
        });
        setValuesSearch("");
        setValuesSearchTeacher("");
        setValuesSearchStudent("");
        if (tableRef?.current !== null) {
            tableRef.current.onQueryChange({ search: "", page: 0 });
        }
    }, []);

    // fetch data table
    const fetchData = useCallback(
        async query => {
            try {
                setIsLoading(true);
                const queryParams = {
                    page: query?.page + 1 || 1,
                    limit: query?.pageSize || 10,
                    search: query?.search || "",
                    teacher: valuesTeacher || "",
                    user: valuesStudent || "",
                    order: query?.orderBy?.field || "schedulePrivateId",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                };
                if (valuesFilter?.student !== null) {
                    queryParams.studentId = valuesFilter?.student?.id;
                }
                if (valuesFilter?.start_d !== null) {
                    queryParams.startDate = moment(valuesFilter?.start_d).format("YYYY-MM-DD");
                }
                if (valuesFilter?.end_d !== null) {
                    queryParams.endDate = moment(valuesFilter?.end_d).format("YYYY-MM-DD");
                }
                const response = await getAllScheduleListPrivate(queryParams, controllerRef.current?.signal);
                if (response) {
                    // // set option
                    // setOptionStudent(
                    //     response?.option?.optionStd.length > 0
                    //         ? response?.option?.optionStd.map(row => row?.Student)
                    //         : [],
                    // );
                    setTableOptions(currentState => ({
                        ...currentState,
                        currentPage: query?.page,
                        pageSize: query?.pageSize || 10,
                        totalData: response?.pagination?.totalData || 0,
                        order: query?.orderBy?.field || "scheduleGroupId",
                        direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    }));
                    setIsLoading(false);
                    return {
                        data: response?.data || [],
                        page: query?.page || 0,
                        totalCount: response?.pagination?.totalData || 0,
                    };
                }
                setIsLoading(false);
            } catch (error) {
                console.dir(error);
                setIsLoading(false);
                return {
                    data: [],
                    page: 0,
                    totalCount: 0,
                };
            }
        },
        [valuesFilter, valuesTeacher, valuesStudent],
    );

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        // to avoid memory leaked.

        return () => {
            controllerRef.current.abort();
        };
    }, [controllerRef]);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className="mb-3 mb-lg-5">
                <div className="row mb-3 mb-lg-5">
                    <div className="col-12 col-md-8 col-xl-9">
                        <div className="row">
                            <div className="col-12 col-md-4 col-xl-2 mb-3 d-flex align-items-center">
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        value={valuesSearch}
                                        onChange={handleOnchangeSearch}
                                        placeholder="Search..."
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search className="text-muted" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div className="col-12 col-md-4 col-xl-3 mb-3 d-flex align-items-center">
                                {/* <FormLabel className="mr-1 mb-0">Student</FormLabel> */}
                                <div className="flex-fill">
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        value={valuesStudent}
                                        onChange={handleOnchangeSearchStudent}
                                        placeholder="Search Student..."
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search className="text-muted" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {/* <SelectSearchApp
                                size="sm"
                                value={valuesFilter.student}
                                options={optionStudent}
                                getOptionLabel={option => `${option?.studentFirstname} ${option?.studentLastname}`}
                                getOptionValue={option => option?.id}
                                onChange={value => handlerInputFilter(value, "student")}
                            /> */}
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-xl-3 mb-3 d-flex align-items-center">
                                <div className="flex-fill">
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        value={valuesTeacher}
                                        onChange={handleOnchangeSearchTeacher}
                                        placeholder="Search Teacher..."
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search className="text-muted" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-xl-4 mb-3 d-flex align-items-center">
                                <FormLabel className="mr-1 mb-0">Booking Date</FormLabel>
                                <div className="flex-fill">
                                    <DatePickerApp
                                        fullWidth
                                        size="small"
                                        value={valuesFilter.start_d}
                                        onChange={date => {
                                            if (date > valuesFilter?.end_d) {
                                                handlerInputFilter(date, "end_d");
                                            }
                                            handlerInputFilter(date, "start_d");
                                        }}
                                    />
                                </div>
                                <small className="mx-2">To</small>
                                <div className="flex-fill">
                                    <DatePickerApp
                                        fullWidth
                                        size="small"
                                        value={valuesFilter.end_d}
                                        minDate={valuesFilter.start_d}
                                        onChange={date => handlerInputFilter(date, "end_d")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-3">
                        <div className="w-100 d-flex flex-column flex-xl-row align-items-center justify-content-between">
                            <Button
                                onClick={handleClearInputFilter}
                                size="large"
                                className="btn btn-lg t-btn-primary mb-3 mb-xl-0 mr-xl-2 flex-grow-0">
                                Clear
                            </Button>
                            <Button
                                size="large"
                                disabled={isExport}
                                className="btn t-btn-primary flex-grow-0"
                                onClick={handleExportExcel}>
                                {isExport ? "Loading..." : <GetApp style={{ fontSize: 22 }} />}
                            </Button>
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className="t-table-custom w-100">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        options={tableOptions}
                        components={{ Container: Box }}
                        data={fetchData}
                        columns={columnsPrivate()}
                    />
                </div>
            </Grid>
        </Grid>
    );
}
