import { Box, Button, Divider, Fade, FormControl, Grid, IconButton, TextField } from "@material-ui/core";
import { Add, Delete, Description, Refresh } from "@material-ui/icons";
import { FieldArray, Formik } from "formik";
import moment from "moment";
import React, { useCallback, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { swalCondition } from "../../../utils/swal";
import DatePickerApp from "../../componentsUtils/DatePickerApp";
import SelectSearchApp from "../../componentsUtils/SelectSearchApp";
import SelectSearchMultiApp from "../../componentsUtils/SelectSearchMultiApp";
import TextareaApp from "../../componentsUtils/TextareaApp";
import { initialValuesGroup, validateSchemaGroup } from "./helpers/forFormScheduleOption";
import TimeEnterApp from "../../componentsUtils/TimeEnterApp";

const checkValidteArrMenuTeacher = ({ typeKey = "menu", idx = 0, idxMenu = 0, errors, keyError = "" }) => {
    if (errors[keyError] !== undefined) {
        if (errors[keyError][idx] !== undefined) {
            if (errors[keyError][idx].menuAndTeacher !== undefined) {
                return (
                    errors[keyError][idx].menuAndTeacher[idxMenu] !== undefined &&
                    errors[keyError][idx].menuAndTeacher[idxMenu][typeKey]
                );
            }
        }
        return "";
    }
    return "";
};

export default function FormGroupTab({
    onSubmit,
    onCancel,
    scheduleOption,
    initialFormValues = initialValuesGroup,
    mode = "create",
}) {
    // state or variable
    const refForm = useRef(null);

    // select course
    const handleChangeCourse = useCallback(
        async valObj => {
            if (refForm.current !== null) {
                const { setFieldValue } = refForm.current;
                await setFieldValue("course", valObj);
                await setFieldValue("limitstudent", valObj?.courseLimitedStudent || "0");
                await setFieldValue("listCourseMenu", []);
                await setFieldValue("note", "");
                await setFieldValue("roomname", "");
                await setFieldValue("durationHour", valObj?.durationHour || 0);
                await setFieldValue("durationMinute", valObj?.durationMinute || 0);
            }
        },
        [refForm],
    );

    // add schedule group list
    const handleClickForAddRowTable = useCallback(async () => {
        if (refForm.current !== null) {
            const { setValues, values } = refForm.current;
            const setting = {
                ...values,
                listCourseMenu: [
                    ...values?.listCourseMenu,
                    {
                        bookingDate: null,
                        startTime: null,
                        toTime: null,
                        classroom: "",
                        students: [],
                        menuAndTeacher: [{ menu: "", teacher: "" }],
                        // durationHour: orderList?.Course?.durationHour || 0,
                        // durationMinute: orderList?.Course?.durationMinute || 0,
                    },
                ],
            };
            await setValues(setting);
        }
    }, [refForm]);

    // select student
    const handleChangeStudentsMulti = useCallback(
        async (newValue, targetField) => {
            if (refForm.current !== null) {
                const { setFieldValue } = refForm.current;
                await setFieldValue(targetField, newValue);
            }
        },
        [refForm],
    );

    // onChange menu and teacher
    const handleChangeMenuAndTeacherList = useCallback(
        async (newValue, targetField) => {
            if (refForm.current !== null) {
                const { setFieldValue } = refForm.current;
                await setFieldValue(targetField, newValue);
                if (targetField?.match(/\[|\]/)) {
                    const fieldSplit = targetField?.split(/\[|\]/);
                    if (
                        fieldSplit[0] === "listCourseMenu" &&
                        fieldSplit[2] === ".menuAndTeacher" &&
                        fieldSplit[4] === ".menu"
                    ) {
                        // when select room for reset teacher
                        const parentIndex = fieldSplit[1];
                        const index = fieldSplit[3];
                        await setFieldValue(`listCourseMenu[${parentIndex}].menuAndTeacher[${index}].teacher`, "");
                    }
                }
            }
        },
        [refForm],
    );

    // filter student option
    const filterStudentOption = useCallback((studentOption, values) => {
        const studentAbsent = values?.absence?.filter(s => s?.student?.id)?.map(item => item?.student?.id);
        return studentOption?.filter(
            student =>
                student?.Orders?.length &&
                student?.Orders?.filter(order =>
                    order?.OrderLists?.some(orderList => orderList?.course === values?.course?.id),
                )?.length &&
                !studentAbsent?.includes(student?.id),
        );
    }, []);

    // filter student option
    const filterStudentAbsentOption = useCallback((studentOption, values) => {
        const studentStillLearn = values?.listCourseMenu[0]?.students?.map(s => s?.id);
        return studentOption?.filter(student => {
            const checkStudent = values?.absence[0]?.student !== "" ? true : false;
            const studentID = checkStudent
                ? values?.absence
                      .filter(ab => ab?.student && ab?.student?.id) // filter out objects that don't have an id property
                      .map(ab => ab?.student?.id) // map remaining objects to their id property
                : true;
            return checkStudent
                ? student?.Orders?.length &&
                      student?.Orders?.filter(order =>
                          order?.OrderLists?.some(orderList => orderList?.course === values?.course?.id),
                      )?.length &&
                      Array.isArray(studentID) &&
                      !studentID?.includes(student?.id) &&
                      studentStillLearn &&
                      !studentStillLearn?.includes(student?.id)
                : student?.Orders?.length &&
                      student?.Orders?.filter(order =>
                          order?.OrderLists?.some(orderList => orderList?.course === values?.course?.id),
                      )?.length &&
                      studentStillLearn &&
                      !studentStillLearn?.includes(student?.id);
        });
    }, []);

    // filter teacher option
    const filterTeacherOption = useCallback((teacherOption, idx, idxMenu, values) => {
        return teacherOption?.filter(
            item =>
                item?.CourseMenus?.map(menu => menu?.id)?.includes(
                    values?.listCourseMenu[idx]?.menuAndTeacher[idxMenu]?.menu?.id,
                ) &&
                !item?.Holidays?.some(
                    holiday =>
                        new Date(holiday?.startDate).getTime() >=
                            new Date(moment(values?.listCourseMenu[idx]?.bookingDate).format("YYYY-MM-DD")).getTime() &&
                        new Date(holiday?.startDate).getTime() <=
                            new Date(moment(values?.listCourseMenu[idx]?.bookingDate).format("YYYY-MM-DD")).getTime(),
                ),
        );
    }, []);

    // filter teacher option
    const filterMenuOption = useCallback((options, values, idx) => {
        const menuSelect = values?.listCourseMenu[idx]?.menuAndTeacher
            ?.filter(mt => mt?.menu?.id)
            ?.map(mt => mt?.menu?.id);
        return options?.filter(option => option && !menuSelect?.includes(option?.id));
    }, []);

    return (
        <Fade in timeout={{ enter: 800 }}>
            <Box>
                <Formik
                    onSubmit={onSubmit}
                    innerRef={refForm}
                    validationSchema={validateSchemaGroup}
                    initialValues={initialFormValues}>
                    {({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <div className="row mb-7">
                                    <div className="col-12 col-md-4 mb-5">
                                        <div className="d-flex align-items-center w-100">
                                            <label className="mb-0 mr-2 p-0" style={{ minWidth: "fit-content" }}>
                                                Course <span className="text-danger">*</span>
                                            </label>
                                            <div className="flex-fill mr-3">
                                                <SelectSearchApp
                                                    options={scheduleOption?.course?.filter(
                                                        item => item?.courseType === "2",
                                                    )}
                                                    getOptionLabel={option => option?.courseName}
                                                    getOptionValue={option => option?.id}
                                                    value={values?.course}
                                                    onChange={handleChangeCourse}
                                                    error={errors?.course}
                                                    isDisabled={mode !== "create"}
                                                />
                                            </div>
                                            {mode === "create" ? (
                                                <div>
                                                    <IconButton
                                                        disabled={values.course === ""}
                                                        onClick={handleClickForAddRowTable}
                                                        className="rounded btn btn-primary">
                                                        <Add />
                                                    </IconButton>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2 d-flex align-items-center mb-5">
                                        <label className="mb-0 mr-2 p-0" style={{ minWidth: "fit-content" }}>
                                            Limit Std.
                                        </label>
                                        <TextField
                                            className="flex-fill"
                                            variant="outlined"
                                            size="medium"
                                            value={values?.limitstudent || "0"}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <Divider />
                            </Grid>
                            {/* table list */}
                            <Grid item xs={12}>
                                <h3 className="text-muted d-flex align-items-center mb-3">
                                    <Description fontSize="large" />
                                    Course/Menu
                                </h3>
                                <div className="mb-5 table-responsive">
                                    <table className="table table-create-schedule">
                                        <thead>
                                            <tr className="text-center">
                                                <th>#</th>
                                                <th>Booking Date</th>
                                                <th>Start Time</th>
                                                <th>To Time</th>
                                                <th>Classroom</th>
                                                <th>Students</th>
                                                <th>Menu</th>
                                                <th>Teacher</th>
                                                {mode === "create" ? <th>Actions</th> : null}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <FieldArray name="listCourseMenu">
                                                {({ remove }) => {
                                                    if (values.listCourseMenu.length > 0 && values.listCourseMenu) {
                                                        return values.listCourseMenu.map((row, idx) => {
                                                            const checkValidteArr = (typeKey = "students") => {
                                                                if (errors.listCourseMenu !== undefined) {
                                                                    return (
                                                                        errors?.listCourseMenu[idx] !== undefined &&
                                                                        errors?.listCourseMenu[idx][typeKey]
                                                                    );
                                                                }
                                                                return "";
                                                            };
                                                            return (
                                                                <tr key={`listMenuCourse_${idx}`}>
                                                                    <td className="text-center align-middle">
                                                                        {idx + 1}
                                                                    </td>
                                                                    <td className="align-middle td-date-time">
                                                                        <DatePickerApp
                                                                            value={
                                                                                values?.listCourseMenu[idx]?.bookingDate
                                                                            }
                                                                            onChange={date => {
                                                                                setFieldValue(
                                                                                    `listCourseMenu.${idx}.bookingDate`,
                                                                                    date,
                                                                                );
                                                                            }}
                                                                            size="small"
                                                                            error={Boolean(
                                                                                checkValidteArr("bookingDate"),
                                                                            )}
                                                                            helperText={
                                                                                errors?.listCourseMenu &&
                                                                                errors?.listCourseMenu[idx]?.bookingDate
                                                                            }
                                                                            placeholder="Choose date."
                                                                        />
                                                                    </td>
                                                                    <td className="align-middle td-date-time time">
                                                                        <TimeEnterApp
                                                                            value={
                                                                                values?.listCourseMenu[idx]?.startTime
                                                                            }
                                                                            onChange={(_evt, val) => {
                                                                                setFieldValue(
                                                                                    `listCourseMenu.${idx}.startTime`,
                                                                                    val,
                                                                                );
                                                                                if (
                                                                                    val &&
                                                                                    (values?.durationHour ||
                                                                                        values?.durationMinute)
                                                                                ) {
                                                                                    setFieldValue(
                                                                                        `listCourseMenu.${idx}.toTime`,
                                                                                        moment(val)
                                                                                            .add(
                                                                                                values?.durationHour,
                                                                                                "hour",
                                                                                            )
                                                                                            .add(
                                                                                                values?.durationMinute,
                                                                                                "minute",
                                                                                            )
                                                                                            .toDate(),
                                                                                    );
                                                                                }
                                                                            }}
                                                                            error={Boolean(
                                                                                checkValidteArr("startTime"),
                                                                            )}
                                                                            helperText={
                                                                                errors?.listCourseMenu &&
                                                                                errors?.listCourseMenu[idx]?.startTime
                                                                            }
                                                                        />
                                                                    </td>
                                                                    <td className="align-middle td-date-time time">
                                                                        <TimeEnterApp
                                                                            value={values.listCourseMenu[idx].toTime}
                                                                            onChange={(_evt, val) => {
                                                                                setFieldValue(
                                                                                    `listCourseMenu.${idx}.toTime`,
                                                                                    val,
                                                                                );
                                                                            }}
                                                                            error={Boolean(checkValidteArr("toTime"))}
                                                                            helperText={
                                                                                errors?.listCourseMenu &&
                                                                                errors?.listCourseMenu[idx]?.toTime
                                                                            }
                                                                        />
                                                                    </td>
                                                                    <td className="align-middle selected-classroom">
                                                                        <SelectSearchApp
                                                                            value={
                                                                                values?.listCourseMenu[idx]?.classroom
                                                                            }
                                                                            options={scheduleOption?.classroom}
                                                                            getOptionLabel={option =>
                                                                                option?.classroomName
                                                                            }
                                                                            getOptionValue={option => option?.id}
                                                                            onChange={value => {
                                                                                setFieldValue(
                                                                                    `listCourseMenu.${idx}.classroom`,
                                                                                    value,
                                                                                );
                                                                            }}
                                                                            size="sm"
                                                                            className="w-100"
                                                                            error={checkValidteArr("classroom")}
                                                                            placeholder="Classroom."
                                                                        />
                                                                    </td>
                                                                    <td className="align-middle selected-students multi">
                                                                        <SelectSearchMultiApp
                                                                            isDisabled={row.classroom === ""}
                                                                            options={filterStudentOption(
                                                                                scheduleOption?.student,
                                                                                values,
                                                                            )}
                                                                            value={
                                                                                values?.listCourseMenu[idx]?.students
                                                                            }
                                                                            getOptionLabel={option =>
                                                                                `${option?.studentFirstname ||
                                                                                    ""} ${option?.studentLastname ||
                                                                                    ""} (${option?.studentNickname ||
                                                                                    ""})`
                                                                            }
                                                                            getOptionValue={option => option?.id}
                                                                            onChange={value => {
                                                                                handleChangeStudentsMulti(
                                                                                    value,
                                                                                    `listCourseMenu.${idx}.students`,
                                                                                );
                                                                            }}
                                                                            size="sm"
                                                                            className="w-100"
                                                                            error={checkValidteArr("students")}
                                                                            placeholder="Students."
                                                                        />
                                                                    </td>
                                                                    <td className="align-middle selected-menu">
                                                                        <FieldArray
                                                                            name={`listCourseMenu[${idx}].menuAndTeacher`}
                                                                            render={() => (
                                                                                <div>
                                                                                    {row.menuAndTeacher?.length > 0 &&
                                                                                        row.menuAndTeacher.map(
                                                                                            (rowMenu, idxMenu) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        className={`${row
                                                                                                            .menuAndTeacher
                                                                                                            ?.length >
                                                                                                            1 &&
                                                                                                            "mb-6"}`}
                                                                                                        key={`$course_${idxMenu}`}>
                                                                                                        <SelectSearchApp
                                                                                                            options={filterMenuOption(
                                                                                                                values
                                                                                                                    ?.course
                                                                                                                    ?.CourseMenus,
                                                                                                                values,
                                                                                                                idx,
                                                                                                            )}
                                                                                                            getOptionLabel={option =>
                                                                                                                option?.courseMenuName
                                                                                                            }
                                                                                                            getOptionValue={option =>
                                                                                                                option?.id
                                                                                                            }
                                                                                                            value={
                                                                                                                values
                                                                                                                    ?.listCourseMenu[
                                                                                                                    idx
                                                                                                                ]
                                                                                                                    ?.menuAndTeacher[
                                                                                                                    idxMenu
                                                                                                                ]?.menu
                                                                                                            }
                                                                                                            onChange={newVal => {
                                                                                                                handleChangeMenuAndTeacherList(
                                                                                                                    newVal,
                                                                                                                    `listCourseMenu[${idx}].menuAndTeacher[${idxMenu}].menu`,
                                                                                                                );
                                                                                                            }}
                                                                                                            size="sm"
                                                                                                            error={checkValidteArrMenuTeacher(
                                                                                                                {
                                                                                                                    typeKey:
                                                                                                                        "menu",
                                                                                                                    errors,
                                                                                                                    keyError:
                                                                                                                        "listCourseMenu",
                                                                                                                    idx,
                                                                                                                    idxMenu,
                                                                                                                },
                                                                                                            )}
                                                                                                            placeholder="Menu."
                                                                                                        />
                                                                                                    </div>
                                                                                                );
                                                                                            },
                                                                                        )}
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </td>
                                                                    <td className="align-middle selected-teachers">
                                                                        <FieldArray
                                                                            name={`listCourseMenu[${idx}].menuAndTeacher`}
                                                                            render={({ remove, push }) => (
                                                                                <div>
                                                                                    {row.menuAndTeacher?.length > 0 &&
                                                                                        row.menuAndTeacher.map(
                                                                                            (rowMenu, idxMenu) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        className={`${row
                                                                                                            .menuAndTeacher
                                                                                                            ?.length >
                                                                                                            1 &&
                                                                                                            "mb-6"} d-flex align-items-center`}
                                                                                                        key={`teacher_${idxMenu}`}>
                                                                                                        <div className="flex-fill">
                                                                                                            <SelectSearchApp
                                                                                                                isDisabled={
                                                                                                                    rowMenu?.menu ===
                                                                                                                    ""
                                                                                                                }
                                                                                                                options={filterTeacherOption(
                                                                                                                    scheduleOption?.teacher,
                                                                                                                    idx,
                                                                                                                    idxMenu,
                                                                                                                    values,
                                                                                                                )}
                                                                                                                getOptionLabel={option =>
                                                                                                                    `${option?.teacherFirstname ||
                                                                                                                        ""} ${option?.teacherLastname ||
                                                                                                                        ""} (${option?.teacherNickname ||
                                                                                                                        ""})`
                                                                                                                }
                                                                                                                getOptionValue={option =>
                                                                                                                    option?.id
                                                                                                                }
                                                                                                                value={
                                                                                                                    values
                                                                                                                        ?.listCourseMenu[
                                                                                                                        idx
                                                                                                                    ]
                                                                                                                        ?.menuAndTeacher[
                                                                                                                        idxMenu
                                                                                                                    ]
                                                                                                                        ?.teacher
                                                                                                                }
                                                                                                                onChange={newVal => {
                                                                                                                    handleChangeMenuAndTeacherList(
                                                                                                                        newVal,
                                                                                                                        `listCourseMenu[${idx}].menuAndTeacher[${idxMenu}].teacher`,
                                                                                                                    );
                                                                                                                }}
                                                                                                                size="sm"
                                                                                                                error={checkValidteArrMenuTeacher(
                                                                                                                    {
                                                                                                                        typeKey:
                                                                                                                            "teacher",
                                                                                                                        errors,
                                                                                                                        keyError:
                                                                                                                            "listCourseMenu",
                                                                                                                        idx,
                                                                                                                        idxMenu,
                                                                                                                    },
                                                                                                                )}
                                                                                                                placeholder="Teacher."
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="ml-3">
                                                                                                            <Button
                                                                                                                onClick={() => {
                                                                                                                    if (
                                                                                                                        idxMenu <
                                                                                                                        1
                                                                                                                    ) {
                                                                                                                        push(
                                                                                                                            {
                                                                                                                                menu:
                                                                                                                                    "",
                                                                                                                                teacher:
                                                                                                                                    "",
                                                                                                                            },
                                                                                                                        );
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    remove(
                                                                                                                        idxMenu,
                                                                                                                    );
                                                                                                                }}
                                                                                                                style={{
                                                                                                                    width: 50,
                                                                                                                }}
                                                                                                                className={`btn ${
                                                                                                                    idxMenu >
                                                                                                                    0
                                                                                                                        ? "btn-danger"
                                                                                                                        : "btn-primary"
                                                                                                                }`}>
                                                                                                                {idxMenu >
                                                                                                                0 ? (
                                                                                                                    <Delete />
                                                                                                                ) : (
                                                                                                                    <Add />
                                                                                                                )}
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                );
                                                                                            },
                                                                                        )}
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </td>
                                                                    {mode === "create" ? (
                                                                        <td className="text-center align-middle">
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    swalCondition(
                                                                                        "Course and Menu",
                                                                                        "Do you want to delete?",
                                                                                        {
                                                                                            icon: "warning",
                                                                                        },
                                                                                    ).then(async bool => {
                                                                                        if (bool) {
                                                                                            if (
                                                                                                values?.listCourseMenu
                                                                                                    .length < 2
                                                                                            ) {
                                                                                                setFieldValue(
                                                                                                    "course",
                                                                                                    "",
                                                                                                );
                                                                                            }
                                                                                            await remove(idx);
                                                                                        }
                                                                                    });
                                                                                }}
                                                                                disabled={mode !== "create"}
                                                                                className="btn btn-secondary text-danger rounded">
                                                                                <Delete />
                                                                            </IconButton>
                                                                        </td>
                                                                    ) : null}
                                                                </tr>
                                                            );
                                                        });
                                                    }
                                                    return (
                                                        <tr>
                                                            <td colSpan={9}>
                                                                <div className="text-muted text-center h4 py-3">
                                                                    <em>ไม่พบข้อมูล</em>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                }}
                                            </FieldArray>
                                        </tbody>
                                    </table>
                                </div>
                                <Divider />
                            </Grid>
                            {/* textfield */}
                            <Grid item xs={12}>
                                <div className="row flex-column">
                                    <div className="col-12 col-md-8 d-md-flex align-items-center mb-7">
                                        <label className="mb-0 mr-2 col-md-2 p-0">Room Name:</label>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            value={values.roomname}
                                            name="roomname"
                                            onChange={handleChange}
                                            error={Boolean(errors?.roomname)}
                                            helperText={errors?.roomname}
                                        />
                                    </div>
                                    <div className="col-12 col-md-8 d-md-flex align-items-center mb-3">
                                        <label className="mb-0 mr-2 col-md-2 p-0">Note:</label>
                                        <FormControl fullWidth>
                                            <TextareaApp
                                                props={{
                                                    textAreaProps: {
                                                        value: values?.note,
                                                        name: "note",
                                                        onChange: handleChange,
                                                        rows: 5,
                                                    },
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </Grid>
                            {/* for edit mode Absence */}
                            {mode === "edit" && (
                                <Grid item xs={12}>
                                    <FieldArray name="absence">
                                        {({ remove, push }) => (
                                            <div className="row">
                                                <div className="col-12 col-md-8 d-md-flex align-items-center mb-3">
                                                    <label className="mb-0 mr-2 col-md-2 p-0">Absence :</label>
                                                    <div className="d-flex flex-column flex-fill">
                                                        {values.absence.length > 0 &&
                                                            values.absence.map((value, index) => (
                                                                <div
                                                                    key={`absence_${index}`}
                                                                    className="row align-items-center mb-3 fadeIn animated">
                                                                    <div className="col-12 col-sm-5">
                                                                        <SelectSearchApp
                                                                            size="sm"
                                                                            options={filterStudentAbsentOption(
                                                                                scheduleOption?.student,
                                                                                values,
                                                                            )}
                                                                            getOptionLabel={option =>
                                                                                `${option?.studentFirstname ||
                                                                                    ""} ${option?.studentLastname ||
                                                                                    ""} (${option?.studentNickname ||
                                                                                    ""})`
                                                                            }
                                                                            getOptionValue={option => option?.id}
                                                                            value={value?.student || ""}
                                                                            onChange={newVal => {
                                                                                setFieldValue(
                                                                                    `absence.${index}.student`,
                                                                                    newVal,
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-12 col-sm-5">
                                                                        <TextField
                                                                            fullWidth
                                                                            size="small"
                                                                            variant="outlined"
                                                                            placeholder="Note"
                                                                            value={value.note}
                                                                            name={`absence.${index}.note`}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </div>
                                                                    <div className="col-12 col-sm-2 d-flex align-items-center justify-content-center">
                                                                        <Button
                                                                            // size="small"
                                                                            onClick={() => {
                                                                                if (index === 0) {
                                                                                    push({ student: "", note: "" });
                                                                                    return;
                                                                                }
                                                                                remove(index);
                                                                            }}
                                                                            className={`btn mr-1 ${
                                                                                index === 0
                                                                                    ? "btn-primary"
                                                                                    : "btn-danger"
                                                                            } text-center`}>
                                                                            {index === 0 ? <Add /> : <Delete />}
                                                                        </Button>
                                                                        {index === 0 &&
                                                                        values?.absence
                                                                            ?.filter(s => s?.student?.id)
                                                                            ?.map(item => item?.student?.id)?.length ? (
                                                                            <Button
                                                                                // size="small"
                                                                                onClick={() =>
                                                                                    setFieldValue("absence", [
                                                                                        { student: "", note: "" },
                                                                                    ])
                                                                                }
                                                                                className="text-center btn btn-warning ml-1">
                                                                                <Refresh />
                                                                            </Button>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </FieldArray>
                                </Grid>
                            )}
                            {/* actions */}
                            <Grid item xs={12}>
                                <Divider className="my-4" />
                                <div className="d-flex justify-content-md-end align-items-center">
                                    <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                                        <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                                    </Button>
                                    <Button type="submit" onClick={handleSubmit} className="btn t-btn-secondary">
                                        <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </Formik>
            </Box>
        </Fade>
    );
}
