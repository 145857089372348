/* eslint no-unused-vars: */
import React from "react";
import { Column } from "material-table";
import moment from "moment";
import { currencyTHB } from "../../../../utils/formatCurrency";

export const getColumnsTableReport = ({ handleClickViewOrder }) => {
    /** @type Column */
    const column = [
        {
            field: "date",
            title: "Order Date",
            render: rowData => moment(rowData?.date, "YYYY-MM-DD").format("DD/MM/YYYY"),
        },
        {
            field: "name",
            title: "Branch",
        },
        {
            field: "totalTransaction",
            title: "Transaction",
            render: rowData => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleClickViewOrder(rowData, "transaction")}>
                        <strong>{rowData?.totalTransaction}</strong>
                    </div>
                );
            },
        },
        {
            field: "sales",
            title: "Sales Total",
            render: rowData => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleClickViewOrder(rowData, "sales")}>
                        <strong>{currencyTHB(rowData?.sales)}</strong>
                    </div>
                );
            },
        },
        {
            field: "package",
            title: "Package",
            render: rowData => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleClickViewOrder(rowData, "package")}>
                        <strong>{currencyTHB(rowData?.package)}</strong>
                    </div>
                );
            },
        },
        {
            field: "scb",
            title: "SCB",
            render: rowData => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleClickViewOrder(rowData, "scb")}>
                        <strong>{currencyTHB(rowData?.scb)}</strong>
                    </div>
                );
            },
        },
        {
            field: "kbank",
            title: "K Bank",
            render: rowData => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleClickViewOrder(rowData, "kbank")}>
                        <strong>{currencyTHB(rowData?.kbank)}</strong>
                    </div>
                );
            },
        },
        {
            field: "bay",
            title: "BAY",
            render: rowData => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleClickViewOrder(rowData, "bay")}>
                        <strong>{currencyTHB(rowData?.bay)}</strong>
                    </div>
                );
            },
        },
        {
            field: "credit",
            title: "Credit card",
            render: rowData => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleClickViewOrder(rowData, "credit")}>
                        <strong>{currencyTHB(rowData?.credit)}</strong>
                    </div>
                );
            },
        },
        {
            field: "kshop",
            title: "Kshop",
            render: rowData => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleClickViewOrder(rowData, "kshop")}>
                        <strong>{currencyTHB(rowData?.kshop)}</strong>
                    </div>
                );
            },
        },
        {
            field: "installment",
            title: "ผ่อนผ่านบัตร",
            render: rowData => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleClickViewOrder(rowData, "installment")}>
                        <strong>{currencyTHB(rowData?.installment)}</strong>
                    </div>
                );
            },
        },
        {
            field: "ttb",
            title: "TTB",
            render: rowData => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleClickViewOrder(rowData, "ttb")}>
                        <strong>{currencyTHB(rowData?.ttb)}</strong>
                    </div>
                );
            },
        },
        {
            field: "paypal",
            title: "PAYPAL",
            render: rowData => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleClickViewOrder(rowData, "paypal")}>
                        <strong>{currencyTHB(rowData?.paypal)}</strong>
                    </div>
                );
            },
        },
        {
            field: "uob",
            title: "UOB",
            render: rowData => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleClickViewOrder(rowData, "uob")}>
                        <strong>{currencyTHB(rowData?.uob)}</strong>
                    </div>
                );
            },
        },
        {
            field: "cash",
            title: "Cash",
            render: rowData => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleClickViewOrder(rowData, "cash")}>
                        <strong>{currencyTHB(rowData?.cash)}</strong>
                    </div>
                );
            },
        },
        {
            field: "transfer",
            title: "Transfer",
            render: rowData => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleClickViewOrder(rowData, "transfer")}>
                        <strong>{currencyTHB(rowData?.transfer)}</strong>
                    </div>
                );
            },
        },
        {
            field: "other",
            title: "Other",
            render: rowData => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleClickViewOrder(rowData, "other")}>
                        <strong>{currencyTHB(rowData?.other)}</strong>
                    </div>
                );
            },
        },
    ];
    const data = column?.map((col, index) => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};
