import { Grid, Tab, Tabs } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import FormFreeScheduleTab from "./FormFreeScheduleTab";
import FormGroupTab from "./FormGroupTab";
import FormPrivateTab from "./FormPrivateTab";
import { initialValuesFreeSchedule, initialValuesGroup, initialValuesPrivate } from "./helpers/forFormScheduleOption";

export default function TabsSchedule({
    onSubmit,
    onCancel,
    scheduleOption,
    initialTab = "private",
    mode = "create",
    scheduleData = null,
}) {
    // state or variable
    const [valueTabs, setValueTabs] = useState(initialTab);

    // function onchange tabs
    const handleOnchangeTabs = useCallback((_evt, value) => {
        setValueTabs(value);
    }, []);

    // submit
    const handelSubmit = useCallback(
        values => {
            onSubmit({ values, tab: valueTabs });
        },
        [valueTabs, onSubmit],
    );

    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Tabs
                    value={valueTabs}
                    onChange={handleOnchangeTabs}
                    indicatorColor="primary"
                    className="tabs-create-schedule border-bottom">
                    <Tab
                        label="Private"
                        value="private"
                        disabled={mode !== "create" && valueTabs !== "private"}
                        hidden={mode !== "create" && valueTabs !== "private"}
                    />
                    <Tab
                        label="Group"
                        value="group"
                        disabled={mode !== "create" && valueTabs !== "group"}
                        hidden={mode !== "create" && valueTabs !== "group"}
                    />
                    <Tab
                        label="Free Schedule"
                        value="free_schedule"
                        disabled={mode !== "create" && valueTabs !== "free_schedule"}
                        hidden={mode !== "create" && valueTabs !== "free_schedule"}
                    />
                </Tabs>
            </Grid>
            <Grid item xs={12}>
                <div className="py-5">
                    {valueTabs === "private" && (
                        <FormPrivateTab
                            onSubmit={handelSubmit}
                            onCancel={onCancel}
                            scheduleOption={scheduleOption}
                            initialFormValues={scheduleData || initialValuesPrivate}
                            mode={mode}
                        />
                    )}
                    {valueTabs === "group" && (
                        <FormGroupTab
                            onSubmit={handelSubmit}
                            onCancel={onCancel}
                            scheduleOption={scheduleOption}
                            initialFormValues={scheduleData || initialValuesGroup}
                            mode={mode}
                        />
                    )}
                    {valueTabs === "free_schedule" && (
                        <FormFreeScheduleTab
                            onSubmit={handelSubmit}
                            onCancel={onCancel}
                            scheduleOption={scheduleOption}
                            initialFormValues={scheduleData || initialValuesFreeSchedule}
                            mode={mode}
                        />
                    )}
                </div>
            </Grid>
        </Grid>
    );
}
