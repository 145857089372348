import {
    Button,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Formik } from "formik";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import SelectApp from "../../componentsUtils/SelectApp";

export default function FormCreateUser({
    onCancel = () => {},
    onSubmit = () => {},
    initialFormValues = {},
    validateSchemaUsers = {},
    option = {},
}) {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <Formik initialValues={initialFormValues} validationSchema={validateSchemaUsers} onSubmit={onSubmit}>
            {({ errors, handleChange, handleSubmit, values, setFieldValue }) => (
                <Grid container spacing={2}>
                    {/* fname */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>
                                First name <span className="text-danger">*</span>
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                id="fname"
                                placeholder="First name"
                                value={values.fname}
                                onChange={handleChange}
                                error={Boolean(errors.fname)}
                                helperText={errors.fname}
                            />
                        </FormControl>
                    </Grid>
                    {/* lname */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>
                                Last name <span className="text-danger">*</span>
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                id="lname"
                                placeholder="Last name"
                                value={values.lname}
                                onChange={handleChange}
                                error={Boolean(errors.lname)}
                                helperText={errors.lname}
                            />
                        </FormControl>
                    </Grid>
                    {/* Email */}
                    <Grid item xs={12}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>
                                Email <span className="text-danger">*</span>
                            </FormLabel>
                            <TextField
                                type="email"
                                variant="outlined"
                                id="email"
                                placeholder="Enter email"
                                value={values.email}
                                onChange={handleChange}
                                error={Boolean(errors.email)}
                                helperText={errors.email}
                            />
                        </FormControl>
                    </Grid>
                    {/* Password */}
                    <Grid item xs={12}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>
                                Password <span className="text-danger">*</span>
                            </FormLabel>
                            <TextField
                                type={showPassword ? "text" : "password"}
                                variant="outlined"
                                id="password"
                                value={values.password}
                                placeholder="Password"
                                onChange={handleChange}
                                error={Boolean(errors.password)}
                                helperText={errors.password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(prev => !prev)}>
                                                {" "}
                                                {showPassword ? <VisibilityOff /> : <Visibility />}{" "}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>
                    {/* Branch */}
                    {!values?.user?.isAdmin ? (
                        <Grid item xs={12}>
                            <FormControl fullWidth className="mb-3">
                                <FormLabel>
                                    Branch <span className="text-danger">*</span>
                                </FormLabel>
                                <SelectApp
                                    size="medium"
                                    value={values.branchId}
                                    onChange={val => setFieldValue("branchId", val)}
                                    placeholder="Select Branch"
                                    options={option?.branch}
                                    RenderItem={option => (
                                        <MenuItem key={`b${option?.id}`} value={option?.id}>
                                            {" "}
                                            {option?.branchName}{" "}
                                        </MenuItem>
                                    )}
                                    error={Boolean(errors.branchId)}
                                    helperText={errors.branchId}
                                />
                            </FormControl>
                        </Grid>
                    ) : null}
                    {/* role */}
                    {!values?.user?.isAdmin ? (
                        <Grid item xs={12}>
                            <FormControl fullWidth className="mb-3">
                                <FormLabel>
                                    Role <span className="text-danger">*</span>
                                </FormLabel>
                                <SelectApp
                                    size="medium"
                                    value={values.roleId}
                                    onChange={val => setFieldValue("roleId", val)}
                                    placeholder="Select Role"
                                    options={option?.role}
                                    RenderItem={option => (
                                        <MenuItem key={`b${option?.id}`} value={option?.id}>
                                            {" "}
                                            {option?.roleName}{" "}
                                        </MenuItem>
                                    )}
                                    error={Boolean(errors.roleId)}
                                    helperText={errors.roleId}
                                />
                            </FormControl>
                            {/* <FormLabel>
                            Role <span className="text-danger">*</span>
                        </FormLabel>
                        <FormControl fullWidth className="mb-3">
                            <RadioGroup
                                aria-label="Role"
                                name="roleId"
                                value={values.roleId}
                                onChange={handleChange}
                                style={{ flexDirection: "row" }}>
                                {option?.role?.map(item => (
                                    <FormControlLabel
                                        key={item?.id}
                                        value={`${item?.id}`}
                                        control={<Radio />}
                                        label={item?.roleName}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl> */}
                        </Grid>
                    ) : null}

                    {/* action */}
                    <Grid item xs={12}>
                        <div className="d-flex justify-content-md-end align-items-center">
                            <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                                <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                            </Button>
                            <Button onClick={handleSubmit} className="btn t-btn-secondary">
                                <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
}
