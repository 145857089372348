/* eslint-disable */
import React from "react";
import { Column } from "material-table";
import { Image } from "react-bootstrap";
import { array, object, string } from "yup";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { currencyTHB } from "../../../../utils/formatCurrency";
import SwitchApp from "../../../componentsUtils/SwitchApp";
import { toImageUrl } from "../../../../utils/format";

export const columnsMenu = props => {
    /** @type Column */
    const columns = [
        {
            field: "id",
            title: "#",
            sorting: false,
            render: obj => (
                <div>{props?.tableOptions?.currentPage * props?.tableOptions?.pageSize + obj.tableData?.id + 1}</div>
            ),
        },
        {
            field: "imagePath",
            title: "Image",
            sorting: false,
            render: obj => (
                <div className="d-flex align-items-center justify-content-center">
                    <Image
                        src={
                            obj?.imagePath
                                ? toImageUrl(obj?.imagePath)
                                : toAbsoluteUrl("/media/assets/images/noImage/non-menu.png")
                        }
                        className="image-products-app"
                        onError={e =>
                            e.target.setAttribute("src", toAbsoluteUrl("/media/assets/images/noImage/non-menu.png"))
                        }
                        alt={obj?.imagePath}
                    />
                </div>
            ),
        },
        {
            field: "courseMenuName",
            title: "Menu name",
            render: obj => <div>{obj.courseMenuName}</div>,
        },
        {
            field: "price",
            title: "Price",
            render: obj => <div>{currencyTHB(obj.price)}</div>,
        },
        {
            field: "status",
            title: "Status",
            sorting: false,

            render: obj =>
                !obj?.isAdmin ? (
                    <SwitchApp checked={obj?.status} onChange={e => props?.handleChangeStatus(e, obj?.id)} />
                ) : null,
        },
    ];
    const data = columns?.map((col, index) => ({
        ...col,
        cellStyle: { textAlign: "center" },
    }));
    return data;
};

export const initialValuesSearchMenu = {
    search: "",
    status: "",
    rangeprice: [0, 100000],
};

export const validateSchemaSearchMenu = object({
    search: string(),
    status: string(),
    rangeprice: array(),
});

export const initialValuesFormMenu = {
    name: "",
    price: "",
    category: "",
    description: "",
    tag: [],
    durationHr: "",
    durationMin: "",
    image: {
        id: "",
        name: "",
        base64: "",
    },
};

export const validateSchemaFormMenu = object({
    name: string()
        .max(255)
        .required("Required."),
    category: string().required("Required."),
    price: string()
        .matches(/^[0-9]+$/g, "Number only.")
        .max(10)
        .required("Required."),
    description: string().max(255),
    tag: array(),
    durationHr: string(),
    durationMin: string(),
    image: object({
        id: "",
        name: "",
        base64: "",
    }),
});
