import { Button, Grid, IconButton, InputAdornment, MenuItem, TextField } from "@material-ui/core";
import { Add, Search } from "@material-ui/icons";
import { Formik } from "formik";
import React from "react";
import { currencyTHB } from "../../../utils/formatCurrency";
import SelectApp from "../../componentsUtils/SelectApp";
import SliderApp from "../../componentsUtils/SliderApp";
import { initialValuesSearchMenu } from "./helpers/menuOption";

export default function SearchMenu({ onSubmit, handleOpenCreateFormModal, permission }) {
    return (
        <Formik initialValues={initialValuesSearchMenu} onSubmit={onSubmit}>
            {({ values, errors, setFieldValue, handleChange, handleSubmit }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={9} lg={8}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={4}>
                                <div className="d-flex align-items-center pr-lg-4">
                                    <div style={{ flex: 1 }}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            id="search"
                                            size="small"
                                            value={values.search}
                                            onChange={handleChange}
                                            error={Boolean(errors.search)}
                                            helperText={errors.search}
                                            placeholder="Search..."
                                            className="fixed-h-lg"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconButton disabled>
                                                            <Search style={{ color: "#808080" }} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <div className="d-lg-flex align-items-center pr-lg-4">
                                    <label className="mb-0 mr-4">Status : </label>
                                    <div className="flex-fill">
                                        <SelectApp
                                            value={values.status}
                                            onChange={value => setFieldValue("status", value)}
                                            options={[
                                                { value: "0", title: "Inactive" },
                                                { value: "1", title: "Active" },
                                            ]}
                                            selectClass="fixed-h-lg"
                                            placeholder="All"
                                            RenderItem={(option, idx) => (
                                                <MenuItem key={`type_${idx}`} value={option.value}>
                                                    {" "}
                                                    {option.title}{" "}
                                                </MenuItem>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <div className="d-lg-flex align-items-center mt-3 mt-md-0 h-100 pr-lg-4">
                                    <label className="mb-0 mr-5">Range price :</label>
                                    <div className="position-relative mt-8 mt-lg-0 flex-fill">
                                        <SliderApp
                                            value={values?.rangeprice}
                                            onChange={(evt, value) => {
                                                setFieldValue("rangeprice", value);
                                            }}
                                            max={100000}
                                            min={0}
                                            valueLabelDisplay="off"
                                            valueLabelFormat={(value, _idx) => {
                                                return currencyTHB(value);
                                            }}
                                            step={1000}
                                        />
                                        <div
                                            style={{ bottom: -5 }}
                                            className="position-absolute w-100 d-flex align-items-center justify-content-between justify-content-sm-center justify-content-lg-between">
                                            <p className="mb-0">{currencyTHB(values?.rangeprice[0] || 0)}</p>
                                            <small className="mx-2">-</small>
                                            <p className="mb-0">{currencyTHB(values?.rangeprice[1] || 0)}</p>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} lg={4}>
                        <div className="d-flex flex-row flex-md-column flex-xl-row align-content-stretch justify-content-md-end">
                            <Button
                                onClick={handleSubmit}
                                size="large"
                                className="btn btn-lg t-btn-primary fixed-h-lg mr-4 mr-md-0 mr-xl-4 mb-0 mb-md-4 mb-xl-0 flex-fill flex-md-grow-0">
                                <Search className="mr-3" /> Search
                            </Button>
                            {permission && (
                                <Button
                                    onClick={handleOpenCreateFormModal}
                                    size="large"
                                    className="fixed-h-lg btn btn-lg t-btn-primary flex-fill flex-xl-grow-0 fixed-h-lg">
                                    <Add className="mr-3" /> Create Menu
                                </Button>
                            )}
                        </div>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
}
