import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "course-menu";

const GET_ALL_COURSE_MENU_URL = `${prefix}/get-all`;
const GET_COURSE_MENU_OPTION = `${prefix}/get-course-menu-option`;
const CREATE_COURSE_MENU_URL = `${prefix}/create`;
const IMPORT = `${prefix}/import`;
const UPDATE_COURSE_MENU_BY_ID_URL = `${prefix}/update-by-id`;
const DELETE_COURSE_MENU_BY_ID_URL = `${prefix}/delete-by-id`;

/**
 * @func getAllCourseMenu
 * @desc get all course menu data
 * @author kop_ter
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} course array
 */
export const getAllCourseMenu = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_ALL_COURSE_MENU_URL, {
            params: queryParams,
            signal,
        });

        if (status === 200) {
            return data;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllCourseMenu");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getAllCourse */

/**
 * @func getCourseMenuOption
 * @desc get all course menu option
 * @author kop_ter
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} category array
 */
export const getCourseMenuOption = async signal => {
    try {
        const { data, status } = await axios.get(GET_COURSE_MENU_OPTION, { signal });

        if (status === 200) {
            return data;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getCourseMenuOption");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getCourseMenuOption */

/**
 * @func createCourseMenu
 * @desc create new course menu
 * @author kop_ter
 * @param {Object} body - create course menu body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} create status
 */
export const createCourseMenu = async (body, signal) => {
    try {
        const { status } = await axios.post(CREATE_COURSE_MENU_URL, body, { signal });

        if (status === 201) {
            return true;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "createCourseMenu");
        if (err) throw err;
        return undefined;
    }
};
/** End @func createCourseMenu */

/**
 * @func importMenu
 * @desc import course menu
 * @author march
 * @param {Object} body - import menu body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {data|boolean|undefined}
 */
export const importMenu = async (body, signal) => {
    try {
        const { status, data } = await axios.post(IMPORT, body, { signal });

        if (status === 201) {
            return data?.data;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "createCourseMenu");
        if (err) throw err;
        return undefined;
    }
};
/** End @func importMenu */

/**
 * @func updateCourseMenu
 * @desc update new course menu
 * @author kop_ter
 * @param {Object} body - update course menu body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} update status
 */
export const updateCourseMenu = async (id, body, signal) => {
    try {
        const { status } = await axios.put(`${UPDATE_COURSE_MENU_BY_ID_URL}/${id}`, body, { signal });

        if (status === 200) {
            return true;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "updateCourseMenu");
        if (err) throw err;
        return undefined;
    }
};
/** End @func updateCourseMenu */

/**
 * @func deleteCourseMenu
 * @desc delete course menu
 * @author kop_ter
 * @param {Object} body - delete course menu body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} delete status
 */
export const deleteCourseMenu = async (id, signal) => {
    try {
        const { status } = await axios.delete(`${DELETE_COURSE_MENU_BY_ID_URL}/${id}`, { signal });

        if (status === 200) {
            return true;
        }

        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "deleteCourseMenu");
        if (err) throw err;
        return undefined;
    }
};
/** End @func deleteCourseMenu */
