import {
    Box,
    Button,
    ButtonGroup,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import { AccountBalanceWallet, Close, DeleteOutline, Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { FieldArray, Formik } from "formik";
import { useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { uniqueId } from "lodash";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { currencyNoSymbol, currencyTHB } from "../../../utils/formatCurrency";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import DatePickerApp from "../../componentsUtils/DatePickerApp";
import SelectApp from "../../componentsUtils/SelectApp";
import TextareaApp from "../../componentsUtils/TextareaApp";
import StudentForm from "../StudentsManagement/StudentForm";
import { initialValuesCreateOrder, initialValuesStudent, validateSchemaCreateOrder } from "./helpers/CreateOrderOption";
import FormPaymentType from "./FormPaymentType";
import { createOrder, getOrderBookingNo, getOrderOption } from "../../../apis/orderApi";
import { toImageUrl } from "../../../utils/format";
import { createStudent, getStudentById } from "../../../apis/studentApi";
import { uploadImage } from "../../../apis/uploadApi";
import { useForceRender } from "../../../utils/customHook";
import { swalCondition } from "../../../utils/swal";
import { replaceMoneyInterger } from "../../../utils/replaceString";
import TimeEnterApp from "../../componentsUtils/TimeEnterApp";

export default function CreateOrderContent() {
    const [render, forceRender] = useForceRender();

    // ref
    const controllerRef = useRef(null);
    const formikRef = useRef(null);
    const history = useHistory();

    // global redux state
    const { thisMenuPermission } = useSelector(state => {
        const { menuPermission } = state?.mainMenu;
        return {
            thisMenuPermission: menuPermission?.find(menu => menu?.path === window.location.pathname)?.permission,
        };
    });

    // component state
    const [isCreateOrder, setIsCreateOrder] = useState(false);
    const [modalForm, setModalForm] = useState(false);
    const [modalPayment, setModalPayment] = useState({ isShow: false, type: "", paymentMethodId: 0 });
    const [searchProductsCard, setSearchProductsCard] = useState("");
    const [valueNewItem, setValueNewItem] = useState({ itemName: "", itemQty: "", itemPrice: "" });
    const [option, setOption] = useState({
        branch: [],
        student: [],
        course: [],
        courseMenu: [],
        package: [],
        payment: [],
    });
    const [tabChangePackageCourseMenu, setTabChangePackageCourseMenu] = useState({
        name: "course",
        label: "Course",
        data: option.course,
    });
    const [toggleDiscount, setToggleDiscount] = useState("thb");

    // change tab course/menu/package
    const handleChangeTabs = useCallback(
        tabName => {
            let label = "";
            if (tabName === "course") {
                label = "Course";
            }
            if (tabName === "courseMenu") {
                label = "Menu";
            }
            if (tabName === "package") {
                label = "Package";
            }
            setTabChangePackageCourseMenu({ name: tabName, label, data: option[tabName] });
        },
        [option],
    );

    // set order list and calculate price
    const handleCalculateOrderList = useCallback(
        courseSelected => {
            const values = formikRef.current?.values;
            const setFieldValue = formikRef.current?.setFieldValue;
            const totalPrice = courseSelected.reduce((partialSum, a) => partialSum + parseFloat(a?.price), 0);
            setFieldValue("courseSelected", courseSelected);
            setFieldValue("amount", courseSelected?.length);
            setFieldValue("discount", totalPrice !== 0 ? values?.discount : 0);
            setFieldValue("total", totalPrice || "");
            setFieldValue("grandTotal", totalPrice !== 0 ? totalPrice - values?.discount : 0);
            setFieldValue("remaining", totalPrice !== 0 ? totalPrice - values?.discount - values?.paid : 0);
        },
        [formikRef],
    );

    // calculate price
    const handleSetActiveCard = useCallback(
        (item, isActive) => {
            // course tab (set active selected card)
            if (tabChangePackageCourseMenu.name === "course") {
                setTabChangePackageCourseMenu(currentState => ({
                    ...currentState,
                    data: currentState?.data?.map(course =>
                        course?.id === item?.id ? { ...course, selected: isActive } : course,
                    ),
                }));
            }

            // menu tab (set active selected card)
            if (tabChangePackageCourseMenu.name === "courseMenu") {
                setTabChangePackageCourseMenu(currentState => ({
                    ...currentState,
                    data: currentState?.data?.map(courseMenu =>
                        courseMenu?.id === item?.id ? { ...courseMenu, selected: isActive } : courseMenu,
                    ),
                }));
            }

            // package tab (set active selected card)
            if (tabChangePackageCourseMenu.name === "package") {
                setTabChangePackageCourseMenu(currentState => ({
                    ...currentState,
                    data: currentState?.data?.map(pkg => (pkg?.id === item?.id ? { ...pkg, selected: isActive } : pkg)),
                }));
            }

            setOption(currentState => ({
                ...currentState,
                course: currentState?.course?.map(course =>
                    course?.id === item?.id ? { ...course, selected: isActive } : course,
                ),
                courseMenu: currentState?.courseMenu?.map(courseMenu =>
                    courseMenu?.id === item?.id ? { ...courseMenu, selected: isActive } : courseMenu,
                ),
                package: currentState?.package?.map(pkg =>
                    pkg?.id === item?.id ? { ...pkg, selected: isActive } : pkg,
                ),
            }));
        },
        [tabChangePackageCourseMenu.name],
    );

    // calculate discount
    const handleCalculateDiscount = useCallback(async () => {
        const values = formikRef.current?.values;
        const setFieldValue = formikRef.current?.setFieldValue;
        const total = parseFloat(values?.total || "0.00");
        const remaining = parseFloat(values?.remaining || "0.00");
        const discount = parseFloat(values?.discount || "0.00");
        let discountTemp = parseFloat(values?.discountTemp || "0.00");
        if (toggleDiscount === "percent") {
            discountTemp = (discountTemp * total) / 100;
        }
        const validationRemaining = remaining + discount - discountTemp;
        if (validationRemaining < 0) {
            await swalCondition("Error!!!", "Remaining Amount cannot less than zero.", {
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "OK",
            });
            return;
        }
        setFieldValue("discountTemp", "");
        setFieldValue("discount", discountTemp);
        setFieldValue("grandTotal", total - discountTemp);
        setFieldValue("remaining", remaining + discount - discountTemp);
    }, [formikRef, toggleDiscount]);

    // calculate discount
    const handleSetDiscount = useCallback(
        evt => {
            const values = formikRef.current?.values;
            const setFieldValue = formikRef.current?.setFieldValue;
            const total = parseFloat(values?.total || "0.00");
            const value = replaceMoneyInterger(evt?.target?.value);
            let discountTemp = Number.isNaN(parseFloat(value)) ? "" : parseFloat(value);
            if (toggleDiscount === "thb" && discountTemp > total) {
                discountTemp = total;
            }
            if (toggleDiscount === "percent" && discountTemp > 100) {
                discountTemp = 100;
            }
            setFieldValue("discountTemp", discountTemp);
        },
        [formikRef, toggleDiscount],
    );

    // click item card
    const handleClickItemCard = useCallback(
        ({ values, item }) => {
            // set selected card
            handleSetActiveCard(item, true);

            // set order list
            handleCalculateOrderList([
                ...values.courseSelected,
                {
                    ...item,
                    bookingDate: null,
                    bookingTime: null,
                    selected: !item?.selected,
                    quantity: 1,
                    itemPrice: item?.price,
                    price: item?.price * 1,
                    tab: tabChangePackageCourseMenu.name,
                },
            ]);
        },
        [handleCalculateOrderList, handleSetActiveCard, tabChangePackageCourseMenu.name],
    );

    // add custom item
    const handleAddCustomItem = useCallback(
        ({ values }) => {
            if (valueNewItem?.itemName === "" || valueNewItem?.itemPrice === "" || valueNewItem?.itemQty === "") {
                return false;
            }
            const courseSelected = [
                ...values?.courseSelected,
                {
                    bookingDate: null,
                    bookingTime: null,
                    id: uniqueId("newItem_"),
                    img: "",
                    quantity: valueNewItem?.itemQty,
                    itemName: valueNewItem?.itemName,
                    itemPrice: valueNewItem?.itemPrice,
                    price: valueNewItem?.itemPrice * valueNewItem?.itemQty,
                    tab: "",
                },
            ];
            handleCalculateOrderList(courseSelected);
            setValueNewItem({ itemName: "", itemQty: "", itemPrice: "" });
        },
        [valueNewItem, handleCalculateOrderList],
    );

    // delete order list
    const handleDeleteOrderList = useCallback(
        ({ values, item, itemIdx }) => {
            const courseSelected = values.courseSelected.filter((row, index) => index !== itemIdx);
            const findSelected = courseSelected?.find(row => row?.id === item?.id);
            handleSetActiveCard(item, findSelected ? true : false);
            handleCalculateOrderList(courseSelected);
        },
        [handleCalculateOrderList, handleSetActiveCard],
    );

    // open payment modal
    const handleOpenModalPayment = useCallback(payMethod => {
        setModalPayment({ isShow: true, type: payMethod?.type, paymentMethodId: payMethod?.paymentMethodId });
    }, []);

    // close payment modal
    const handleCloseModalPayment = useCallback(() => {
        setModalPayment({ isShow: false, type: "", paymentMethodId: 0 });
    }, []);

    // for setstate new item
    const handleOnchangeAddCustomItem = useCallback((name, evt) => {
        const { value } = evt?.target;
        if (name === "name") {
            setValueNewItem(prev => ({ ...prev, itemName: value }));
        }
        if (name === "qty") {
            setValueNewItem(prev => ({ ...prev, itemQty: replaceMoneyInterger(value) }));
        }
        if (name === "price") {
            setValueNewItem(prev => ({ ...prev, itemPrice: replaceMoneyInterger(value) }));
        }
    }, []);

    // search course, menu, package input
    const filterSearch = useCallback(evt => {
        // Declare variables
        const input = evt?.target;
        const filter = input.value.toUpperCase();
        const content = document.getElementById("container-card-createOrder");
        const item = content.querySelectorAll(".col-create-order");

        // Loop through all list items, and hide those who don't match the search query
        for (let i = 0; i < item.length; i += 1) {
            const name = item[i].querySelectorAll(".name-products")[0];
            const txtValue = name.textContent || name.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                item[i].style.display = "";
            } else {
                item[i].style.display = "none";
            }
        }
    }, []);

    // close student modal
    const handleCloseModal = useCallback(() => {
        setModalForm(false);
    }, []);

    // open student modal
    const handleOpenModal = useCallback(() => {
        setModalForm(true);
    }, []);

    // create student logic
    const handleCreateStudent = useCallback(
        async values => {
            try {
                let imagePath = "";
                if (values?.base64) {
                    imagePath = await uploadImage(values?.base64, controllerRef.current?.signal);
                }
                const createBody = {
                    studentFirstname: values?.fname,
                    studentLastname: values?.lname,
                    studentNickname: values?.nickname,
                    studentClassof: values?.classOf,
                    studentEmail: values?.email,
                    studentTel: values?.tel,
                    studentLineid: values?.lineId,
                    studentBirthday: values?.birthday,
                    studentAddress: values?.address,
                    studentProvince: values?.province,
                    studentCountry: values?.country,
                    studentExpertise: values?.expertise,
                    studentSizeshirt: values?.sizeApron,
                    studentNote: values?.studentNote,
                    objective: values?.learnObjt,
                    funnel: values?.knowChannels,
                    social: values?.allowSocial,
                };
                const isCreate = await createStudent(
                    { ...createBody, studentImagePath: imagePath },
                    controllerRef.current?.signal,
                );
                if (isCreate) {
                    setModalForm(false);
                    forceRender();
                }
            } catch (error) {
                console.dir(error);
            }
        },
        [forceRender],
    );

    // fetch option data
    const fetchOption = useCallback(async signal => {
        try {
            const orderOption = await getOrderOption(signal);
            if (orderOption) {
                const opt = {
                    branch: orderOption?.branchOption,
                    student: orderOption?.studentOption,
                    course: orderOption?.courseOption?.map(item => ({
                        ...item,
                        selected: false,
                        itemName: item?.courseName,
                        tab: "course",
                    })),
                    courseMenu: orderOption?.courseMenuOption?.map(item => ({
                        ...item,
                        selected: false,
                        itemName: item?.courseMenuName,
                        tab: "courseMenu",
                    })),
                    package: orderOption?.packageOption?.map(item => ({
                        ...item,
                        selected: false,
                        itemName: item?.packageName,
                        tab: "package",
                    })),
                    payment: orderOption?.paymentMethodOption?.map(item => ({
                        paymentMethodId: item?.paymentMethodId,
                        type: item?.name,
                        name: item?.name,
                    })),
                };
                return opt;
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // fetch booking no
    const fetchOrderBookingNo = useCallback(async (branchId, signal) => {
        try {
            const bookingNo = await getOrderBookingNo(branchId, signal);
            if (bookingNo) {
                formikRef.current.setFieldValue("bookingNo", bookingNo);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // change branch
    const handleChangeBranch = useCallback(
        async value => {
            formikRef.current.setFieldValue("branchId", value);
            await fetchOrderBookingNo(value);
        },
        [formikRef, fetchOrderBookingNo],
    );

    // change student
    const handleChangeStudent = useCallback(
        async (evt, value) => {
            try {
                const student = await getStudentById(value?.id, controllerRef.current?.signal);
                if (student) {
                    await formikRef.current.setFieldValue("studentObj", student || "");
                    if (value?.balance && student?.balanceExpired) {
                        if (new Date(student?.balanceExpired) > new Date()) {
                            await formikRef.current.setFieldValue("initialStudentBalance", student?.balance || "");
                            await formikRef.current.setFieldValue("studentBalance", student?.balance || "");
                        }
                    } else {
                        await formikRef.current.setFieldValue("initialStudentBalance", "");
                        await formikRef.current.setFieldValue("studentBalance", "");
                    }
                }
            } catch (error) {
                console.dir(error);
            }
        },
        [formikRef],
    );

    // submit payment by different method
    const handleSubmitPayment = useCallback(
        async (values, { setFieldError }) => {
            const formikValues = formikRef.current?.values;
            const setFieldValue = formikRef.current?.setFieldValue;
            const remaining = parseFloat(values?.remaining || "0.00");
            const paid = parseFloat(formikValues?.paid || "0.00");
            const initialPayment = parseFloat(values?.initialPayment || "0.00");
            const payment = parseFloat(values?.payment || "0.00");
            const balance = parseFloat(values?.balance || "0.00");

            if (remaining < 0) {
                await swalCondition("Error!!!", "Remaining cannot less than zero.", {
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "OK",
                });
                return;
            }

            // check balance
            if (balance < 0) {
                setFieldError("payment", "Not Enough Balance");
                return;
            }

            // check payment selected
            const findPaymentSelected = formikValues?.paymentSelected?.find(item => item?.type === values?.type);
            if (!findPaymentSelected) {
                if (payment > 0) {
                    setFieldValue("paymentSelected", [
                        ...formikValues?.paymentSelected,
                        { type: values?.type, paymentMethodId: values?.paymentMethodId, payment },
                    ]);
                }
            } else {
                setFieldValue(
                    "paymentSelected",
                    formikValues?.paymentSelected?.map(item =>
                        item?.type === values?.type ? { ...item, payment } : item,
                    ),
                );
                // check zero payment
                if (payment === 0) {
                    setFieldValue(
                        "paymentSelected",
                        formikValues?.paymentSelected?.filter(item => item?.type !== values?.type),
                    );
                }
            }

            setFieldValue("paid", paid - initialPayment + payment);
            setFieldValue("remaining", remaining);
            setFieldValue("studentBalance", values?.balance);
            setModalPayment(prev => ({ ...prev, isShow: false }));
        },
        [formikRef],
    );

    // create order logic
    const handleCreateOrder = useCallback(
        async values => {
            setIsCreateOrder(true);
            try {
                if (!values?.courseSelected?.length) {
                    await swalCondition("Error!!!", "Please select at least 1 item.", {
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                    return;
                }
                const createBody = {
                    date: values?.orderDate,
                    branchId: parseInt(values?.branchId),
                    studentId: values?.studentObj?.id,
                    remark: values?.remark,
                    amount: values?.amount,
                    discount: values?.discount,
                    total: values?.total,
                    grandTotal: values?.grandTotal,
                    paid: values?.paid,
                    remaining: values?.remaining,
                    status:
                        !values?.paymentSelected?.length || values?.paid === 0
                            ? "3"
                            : values?.remaining > 0
                            ? "1"
                            : "0",
                    orderList: values?.courseSelected?.map(item => ({
                        bookingDate: item?.bookingDate,
                        bookingTime: item?.bookingTime ? moment(item?.bookingTime).format("HH:mm") : null,
                        name: item?.itemName,
                        course: item?.tab === "course" ? item?.id : null,
                        menu: item?.tab === "courseMenu" ? item?.id : null,
                        package: item?.tab === "package" ? item?.id : null,
                        quantity: item?.quantity,
                        price: item?.itemPrice,
                    })),
                    orderHasPayment: values?.paymentSelected,
                };
                const isCreate = await createOrder(createBody, controllerRef.current?.signal);
                if (isCreate) {
                    const isConfirm = await swalCondition("Success", "Create Order Successful.", {
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                    if (isConfirm) {
                        history.push("/order_management/order_list");
                    }
                    window.location.reload();
                }
                setIsCreateOrder(false);
            } catch (error) {
                console.dir(error);
                setIsCreateOrder(false);
            }
        },
        [history],
    );

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        const init = async () => {
            const branchId = localStorage.getItem("currentBranchId") || null;
            const orderOption = await fetchOption(controllerRef.current?.signal);
            if (branchId) {
                fetchOrderBookingNo(branchId, controllerRef?.current?.signal);
            }
            if (orderOption) {
                setOption(currentState => ({
                    ...currentState,
                    ...orderOption,
                }));
                setTabChangePackageCourseMenu(currentState => ({
                    ...currentState,
                    data: orderOption?.course,
                }));
            }
        };
        init();
        return () => {
            controllerRef.current.abort();
        };
    }, [fetchOption, fetchOrderBookingNo]);

    // re-render
    useEffect(() => {
        const init = async () => {
            const orderOption = await fetchOption(controllerRef.current?.signal);
            if (orderOption) {
                setOption(currentState => ({
                    ...currentState,
                    student: orderOption?.student,
                }));
            }
        };
        init();
    }, [render, fetchOption]);

    return (
        <div>
            <Formik
                innerRef={formikRef}
                initialValues={initialValuesCreateOrder}
                validationSchema={validateSchemaCreateOrder}
                onSubmit={handleCreateOrder}>
                {({ values, setFieldValue, handleChange, handleSubmit, errors }) => {
                    const hasPackage = values?.courseSelected?.length
                        ? values?.courseSelected?.find(item => ["package"]?.includes(item?.tab))
                            ? true
                            : false
                        : false;
                    const hasCourseMenu = values?.courseSelected?.length
                        ? values?.courseSelected?.find(item => ["course", "courseMenu"]?.includes(item?.tab))
                            ? true
                            : false
                        : false;
                    const itemDisabled =
                        (hasPackage && ["course", "courseMenu"]?.includes(tabChangePackageCourseMenu.name)) ||
                        (hasCourseMenu && ["package"]?.includes(tabChangePackageCourseMenu.name));
                    return (
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={4}>
                                {/* == start Order Information */}
                                <Paper className="p-3" elevation={0} style={{ marginBottom: 22 }}>
                                    <h5 className="mb-4">Order Information</h5>
                                    <div className="row align-items-center" style={{ marginBottom: 22 }}>
                                        <div className="col-4">
                                            <FormLabel>Booking No.</FormLabel>
                                        </div>
                                        <div className="col-8">
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                value={values?.bookingNo}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center" style={{ marginBottom: 22 }}>
                                        <div className="col-4">
                                            <FormLabel>Date</FormLabel>
                                        </div>
                                        <div className="col-8">
                                            <DatePickerApp
                                                fullWidth
                                                size="small"
                                                value={values.orderDate}
                                                onChange={_date => {
                                                    setFieldValue("orderDate", moment(_date).format("YYYY-MM-DD"));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center" style={{ marginBottom: 22 }}>
                                        <div className="col-4">
                                            <FormLabel>
                                                Branch <span className="text-danger">*</span>
                                            </FormLabel>
                                        </div>
                                        <div className="col-8">
                                            <SelectApp
                                                options={option.branch}
                                                value={values?.branchId}
                                                onChange={handleChangeBranch}
                                                error={Boolean(errors?.branchId)}
                                                helperText={errors?.branchId}
                                                RenderItem={obj => (
                                                    <MenuItem key={`branch_${obj?.id}`} value={obj?.id}>
                                                        {obj?.branchName}{" "}
                                                    </MenuItem>
                                                )}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </Paper>
                                {/* == end Order Information */}

                                {/* == start student Information */}
                                <Paper className="p-3" elevation={0} style={{ marginBottom: 22 }}>
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <h5 className="mb-0">Student Information</h5>
                                        <Button onClick={handleOpenModal} className="btn t-btn-primary">
                                            + Create
                                        </Button>
                                    </div>
                                    <div className="row align-items-center" style={{ marginBottom: 22 }}>
                                        <div className="col-4">
                                            <FormLabel>
                                                Student <span className="text-danger">*</span>
                                            </FormLabel>
                                        </div>
                                        <div className="col-8">
                                            <Autocomplete
                                                fullWidth
                                                size="small"
                                                options={option.student}
                                                value={values?.studentObj || null}
                                                getOptionLabel={option =>
                                                    `${option?.studentFirstname || ""} ${option?.studentLastname ||
                                                        ""} (${option?.studentNickname || ""})`
                                                }
                                                getOptionSelected={(option, value) => option?.id === value?.id}
                                                onChange={handleChangeStudent}
                                                renderInput={params => (
                                                    <TextField
                                                        error={Boolean(errors?.studentObj)}
                                                        helperText={errors?.studentObj}
                                                        key={params?.id}
                                                        {...params}
                                                        label="Search"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center" style={{ marginBottom: 22 }}>
                                        <div className="col-12">
                                            <FormLabel>Balance</FormLabel>
                                        </div>
                                        <div className="col-12">
                                            <div className="d-flex align-items-center">
                                                <AccountBalanceWallet
                                                    style={{ color: "#6c7293", width: 30, height: 30 }}
                                                />
                                                <div className="ml-2">
                                                    <h5 className="mb-0" style={{ color: "#e4b07b" }}>
                                                        {currencyTHB(values?.initialStudentBalance)}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                                {/* == end student Information */}

                                {/* == start remark */}
                                <Paper className="p-3" elevation={0} style={{ marginBottom: 22 }}>
                                    <div className="row align-items-center" style={{ marginBottom: 22 }}>
                                        <div className="col-12">
                                            <FormLabel>Remark</FormLabel>
                                        </div>
                                        <div className="col-12">
                                            <TextareaApp
                                                props={{
                                                    textAreaProps: {
                                                        rows: 5,
                                                        name: "remark",
                                                        value: values.remark,
                                                        onChange: handleChange,
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Paper>
                                {/* == end remark */}
                            </Grid>

                            <Grid item xs={12} lg={8}>
                                {/* == start course menu package */}
                                <Paper className="p-3" elevation={0} style={{ marginBottom: 22 }}>
                                    <div className="d-flex align-items-center">
                                        <Button
                                            onClick={() => handleChangeTabs("course")}
                                            className={`col-3 btn ${
                                                tabChangePackageCourseMenu?.name === "course"
                                                    ? "t-btn-primary"
                                                    : "btn-light"
                                            }`}
                                            disabled={hasPackage}>
                                            Course
                                        </Button>
                                        <Button
                                            onClick={() => handleChangeTabs("courseMenu")}
                                            className={`col-3 btn mx-3 ${
                                                tabChangePackageCourseMenu?.name === "courseMenu"
                                                    ? "t-btn-primary"
                                                    : "btn-light"
                                            }`}
                                            disabled={hasPackage}>
                                            Menu
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                handleChangeTabs("package");
                                            }}
                                            className={`col-3 btn ${
                                                tabChangePackageCourseMenu?.name === "package"
                                                    ? "t-btn-primary"
                                                    : "btn-light"
                                            }`}
                                            disabled={hasCourseMenu}>
                                            Package
                                        </Button>
                                    </div>
                                    <div className="my-4">
                                        <FormControl fullWidth>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                placeholder={`Search ${tabChangePackageCourseMenu.label}`}
                                                value={searchProductsCard}
                                                onChange={evt => {
                                                    setSearchProductsCard(evt?.target?.value);
                                                    filterSearch(evt);
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Search className="text-muted" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                    </div>

                                    {/* content item */}
                                    <Grid
                                        component={Box}
                                        id="container-card-createOrder"
                                        container
                                        spacing={2}
                                        style={{ maxHeight: 190 * 2, overflowY: "auto" }}>
                                        {tabChangePackageCourseMenu.data?.length > 0 &&
                                            tabChangePackageCourseMenu?.data.map((row, idx) => (
                                                <Grid
                                                    key={`item_${idx}`}
                                                    item
                                                    xs={6}
                                                    lg={3}
                                                    className="col-create-order">
                                                    <Box
                                                        onClick={
                                                            itemDisabled
                                                                ? () => {}
                                                                : () => handleClickItemCard({ values, item: row })
                                                        }
                                                        className={`rounded border overflow-hidden card-products-app ${
                                                            row?.selected ? "active" : ""
                                                        } ${itemDisabled ? "bg-gray-100" : ""} `}>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <img
                                                                src={
                                                                    row?.imagePath
                                                                        ? toImageUrl(row?.imagePath)
                                                                        : toAbsoluteUrl(
                                                                              "/media/assets/images/noImage/no-photo.png",
                                                                          )
                                                                }
                                                                className="img-fluid"
                                                                style={{ height: 100 }}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="p-2">
                                                            <Typography className="text-lines-2 mb-2 name-products">
                                                                {row?.itemName}
                                                            </Typography>
                                                            <Typography className="text-primary-app">
                                                                {currencyTHB(row?.price)}
                                                            </Typography>
                                                        </div>
                                                    </Box>
                                                </Grid>
                                            ))}
                                    </Grid>
                                    <hr className="my-4" />
                                    {/* add new course */}
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                value={valueNewItem?.itemName}
                                                onChange={evt => handleOnchangeAddCustomItem("name", evt)}
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                placeholder="Item"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                value={valueNewItem?.itemQty}
                                                onChange={evt => handleOnchangeAddCustomItem("qty", evt)}
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                placeholder="Qty"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                value={valueNewItem?.itemPrice}
                                                onChange={evt => handleOnchangeAddCustomItem("price", evt)}
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                placeholder="Price"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Button
                                                size="large"
                                                onClick={() => handleAddCustomItem({ values })}
                                                className="w-100 btn t-btn-primary">
                                                Add
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                {/* == end course menu package */}

                                {/* == start table selected */}
                                <Paper className="p-3" elevation={0} style={{ marginBottom: 22 }}>
                                    <FieldArray name="courseSelected">
                                        {() => (
                                            <div className="table-responsive">
                                                <table className="table table-borderless table-items-order-app">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Booking Date</th>
                                                            <th>Booking Time</th>
                                                            <th>Item</th>
                                                            <th>Qty.</th>
                                                            <th>Price</th>
                                                            <th>{"  "}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {values?.courseSelected.length > 0 &&
                                                            values?.courseSelected?.map((item, idx) => (
                                                                <tr key={`item_products_${idx}`}>
                                                                    <td>{idx + 1}</td>
                                                                    <td>
                                                                        <DatePickerApp
                                                                            style={{ maxWidth: 150 }}
                                                                            size="small"
                                                                            value={
                                                                                values?.courseSelected[idx]?.bookingDate
                                                                            }
                                                                            onChange={date =>
                                                                                setFieldValue(
                                                                                    `courseSelected.${idx}.bookingDate`,
                                                                                    moment(date).format("YYYY-MM-DD"),
                                                                                )
                                                                            }
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <TimeEnterApp
                                                                            value={
                                                                                values?.courseSelected[idx]?.bookingTime
                                                                            }
                                                                            onChange={(_evt, val) => {
                                                                                setFieldValue(
                                                                                    `courseSelected.${idx}.bookingTime`,
                                                                                    val,
                                                                                );
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td>{item?.itemName}</td>
                                                                    <td>{item?.quantity}</td>
                                                                    <td>
                                                                        {" "}
                                                                        {currencyTHB(
                                                                            item?.itemPrice * item?.quantity,
                                                                        )}{" "}
                                                                    </td>
                                                                    <td>
                                                                        <Button
                                                                            onClick={() =>
                                                                                handleDeleteOrderList({
                                                                                    values,
                                                                                    item,
                                                                                    itemIdx: idx,
                                                                                })
                                                                            }
                                                                            className="btn btn-sm btn-danger"
                                                                            size="small">
                                                                            <DeleteOutline />
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </FieldArray>

                                    <hr className="my-4" />

                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={5}>
                                            <FormLabel>Discount</FormLabel>
                                            <div className="d-flex align-items-center" style={{ marginBottom: 22 }}>
                                                <TextField
                                                    className="flex-grow-1 mr-2"
                                                    variant="outlined"
                                                    size="small"
                                                    name="discountTemp"
                                                    onChange={e => handleSetDiscount(e)}
                                                    value={values?.discountTemp}
                                                    placeholder={
                                                        toggleDiscount === "thb"
                                                            ? "THB"
                                                            : toggleDiscount === "percent"
                                                            ? "%"
                                                            : ""
                                                    }
                                                    disabled={values?.total === ""}
                                                />
                                                <ButtonGroup
                                                    size="large"
                                                    color="primary"
                                                    aria-label="outlined primary button group"
                                                    className="mr-2"
                                                    disabled={values?.total === ""}>
                                                    <Button
                                                        className={`btn ${
                                                            toggleDiscount === "thb"
                                                                ? "t-btn-primary"
                                                                : "t-btn-primary-outline"
                                                        }  w-50`}
                                                        onClick={() => setToggleDiscount("thb")}>
                                                        THB
                                                    </Button>
                                                    <Button
                                                        className={`btn ${
                                                            toggleDiscount === "percent"
                                                                ? "t-btn-primary"
                                                                : "t-btn-primary-outline"
                                                        } w-50`}
                                                        onClick={() => setToggleDiscount("percent")}>
                                                        %
                                                    </Button>
                                                </ButtonGroup>
                                                <Button
                                                    size="large"
                                                    onClick={handleCalculateDiscount}
                                                    className="btn t-btn-primary flex-grow-0"
                                                    disabled={values?.discountTemp === "" || values?.total === ""}>
                                                    OK
                                                </Button>
                                            </div>
                                            <div className="row align-items-center" style={{ marginBottom: 22 }}>
                                                <div className="col-8">
                                                    <FormLabel>Amount</FormLabel>
                                                </div>
                                                <div className="col-4">
                                                    <div className="text-right">{values?.amount || 0}</div>
                                                </div>
                                            </div>
                                            <div className="row align-items-center" style={{ marginBottom: 22 }}>
                                                <div className="col-8">
                                                    <FormLabel>Discount</FormLabel>
                                                </div>
                                                <div className="col-4">
                                                    <div className="text-right">
                                                        {currencyNoSymbol(values?.discount)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row align-items-center" style={{ marginBottom: 22 }}>
                                                <div className="col-8">
                                                    <FormLabel>Total</FormLabel>
                                                </div>
                                                <div className="col-4">
                                                    <div className="text-right">{currencyNoSymbol(values?.total)}</div>
                                                </div>
                                            </div>
                                            <div className="row align-items-center" style={{ marginBottom: 22 }}>
                                                <div className="col-8">
                                                    <FormLabel style={{ fontWeight: "1000" }}>Grand Total</FormLabel>
                                                </div>
                                                <div className="col-4">
                                                    <div className="text-right" style={{ fontWeight: "1000" }}>
                                                        {currencyNoSymbol(values?.grandTotal)}
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <p>Payment</p>
                                            <Grid container spacing={1} style={{ marginBottom: 22 }}>
                                                {option.payment.map((payMethod, idx) => (
                                                    <Grid item className="flex-fill" key={`payment_${idx}`}>
                                                        <Button
                                                            onClick={() => handleOpenModalPayment(payMethod)}
                                                            size="small"
                                                            className={`btn w-100 ${
                                                                values?.paymentSelected?.find(
                                                                    item => item?.type === payMethod?.type,
                                                                )
                                                                    ? "t-btn-primary"
                                                                    : "t-btn-primary-outline"
                                                            }`}
                                                            disabled={
                                                                values?.grandTotal
                                                                    ? payMethod?.type === "Package"
                                                                        ? hasPackage
                                                                        : false
                                                                    : true
                                                            }>
                                                            {payMethod?.name}
                                                        </Button>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            <div className="row align-items-center" style={{ marginBottom: 22 }}>
                                                <div className="col-4">
                                                    <FormLabel>Paid</FormLabel>
                                                </div>
                                                <div className="col-8">
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                        value={currencyNoSymbol(values?.paid)}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center" style={{ marginBottom: 22 }}>
                                                <div className="col-4">
                                                    <FormLabel>Remaining</FormLabel>
                                                </div>
                                                <div className="col-8">
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                        value={currencyNoSymbol(values?.remaining)}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </Grid>

                                        {/* action */}
                                        {thisMenuPermission?.create && (
                                            <Grid item xs={12}>
                                                <div className="d-flex justify-content-end">
                                                    <Button
                                                        size="large"
                                                        onClick={handleSubmit}
                                                        className="btn t-btn-primary col-12 col-sm-6 col-lg-3 px-0"
                                                        disabled={
                                                            (hasPackage && (values?.remaining > 0 ? true : false)) ||
                                                            isCreateOrder
                                                        }>
                                                        Submit
                                                    </Button>
                                                </div>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Paper>
                                {/* == end table selected */}
                            </Grid>
                        </Grid>
                    );
                }}
            </Formik>
            {/* student modal */}
            <Modal show={modalForm} centered scrollable onHide={handleCloseModal}>
                <Modal.Header>
                    <Modal.Title>Create Student</Modal.Title>
                    <IconButton onClick={handleCloseModal}>
                        <Close />
                    </IconButton>
                </Modal.Header>
                <Modal.Body>
                    <StudentForm
                        onCancel={handleCloseModal}
                        onSubmit={handleCreateStudent}
                        initialValuesStudent={initialValuesStudent}
                    />
                </Modal.Body>
            </Modal>
            {/* payment modal */}
            <Modal show={modalPayment?.isShow} centered scrollable onHide={handleCloseModalPayment}>
                <Modal.Header>
                    <Modal.Title>{modalPayment?.type}</Modal.Title>
                    <IconButton onClick={handleCloseModalPayment}>
                        <Close />
                    </IconButton>
                </Modal.Header>
                <Modal.Body>
                    <FormPaymentType
                        paymentMethodId={modalPayment?.paymentMethodId}
                        type={modalPayment?.type}
                        onCancel={handleCloseModalPayment}
                        onSubmit={handleSubmitPayment}
                        formikValues={formikRef.current?.values}
                        showPrevPayment={false}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
}
