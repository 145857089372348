import { Box, Grid, IconButton } from "@material-ui/core";
import { Close, RemoveRedEye } from "@material-ui/icons";
import MaterialTable from "material-table";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    createTeacher,
    deleteTeacherById,
    getAllTeacher,
    updateTeacherById,
    getAllTeacherForExcel,
} from "../../../apis/teacherApi";
import { uploadImageFile } from "../../../apis/uploadApi";
import { swalCondition } from "../../../utils/swal";
import FormCreate from "./FormCreate";
import { columnTableTeachers } from "./helpers/TeacherManageOption";
import TeacherSearchForm from "./TeacherSearchForm";
import exportToExcel from "../../../utils/exportToExcel";
import { getOptionsTableApp } from "../../helpers/useOption";

export default function TeacherManageTable() {
    // ref
    const tableRef = useRef(null);
    const controllerRef = useRef(null);

    // navigate or router
    const history = useHistory();

    // global redux state
    const { thisMenuPermission } = useSelector(state => {
        const { menuPermission } = state?.mainMenu;
        return {
            thisMenuPermission: menuPermission?.find(menu => menu?.path === window.location.pathname)?.permission,
        };
    });

    // component state
    const [searchData, setSearchData] = useState({});
    const [excelFilter, setExcelFilter] = useState({
        search: "",
        menu: "",
        status: "",
    });
    const [modalForm, setModalForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tableOptions, setTableOptions] = useState(getOptionsTableApp);
    const [isCreateMode, setIsCreateMode] = useState(false);
    const [initialValuesTeacher, setInitialValuesTeacher] = useState({
        fname: "",
        lname: "",
        birthday: new Date(),
        nickname: "",
        email: "",
        tel: "",
        lineId: "",
        status: "",
        id: "",
        image: {
            id: "",
            name: "",
            base64: "",
        },
        courseMenus: [],
    });
    const [isExport, setIsExport] = useState(false);

    // open create form modal
    const handleOpenCreateFormModal = useCallback(() => {
        setModalForm(true);
        setIsCreateMode(true);
    }, []);

    // close create form modal
    const handleCloseCreateFormModal = useCallback(() => {
        setModalForm(false);
        setInitialValuesTeacher({
            fname: "",
            lname: "",
            birthday: new Date(),
            nickname: "",
            email: "",
            tel: "",
            lineId: "",
            status: "",
            id: "",
            teacherImagePath: "",
            courseMenus: [],
        });
    }, []);

    // delete Teacher
    const handleDeleteTeacher = useCallback(async value => {
        try {
            const bool = await swalCondition(
                "Confirm delete ?",
                `${value?.teacherFirstname} ${value?.teacherLastname}`,
                {
                    icon: "warning",
                },
            );
            if (bool) {
                const isDelete = await deleteTeacherById(value?.id, controllerRef.current?.signal);
                if (isDelete) {
                    tableRef.current.onQueryChange();
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // create teacher logic
    const handleCreateTeacher = useCallback(async values => {
        try {
            const createBody = {
                teacherFirstname: values?.fname,
                teacherLastname: values?.lname,
                teacherBirthday: values?.birthday || "",
                teacherNickname: values?.nickname || "",
                teacherEmail: values?.email || null,
                teacherTel: values?.tel || "",
                teacherLineid: values?.lineId || "",
                courseMenus: values?.courseMenus || [],
            };

            if (values?.image?.file) {
                createBody.teacherImagePath = await uploadImageFile(values?.image?.file, controllerRef.current?.signal);
            }

            const isCreate = await createTeacher(createBody, controllerRef.current?.signal);
            if (isCreate) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // edit teacher logic
    const handleEditTeacher = useCallback(async values => {
        try {
            const updateBody = {
                teacherFirstname: values?.fname,
                teacherLastname: values?.lname,
                teacherBirthday: values?.birthday,
                teacherNickname: values?.nickname,
                teacherEmail: values?.email,
                teacherTel: values?.tel,
                teacherLineid: values?.lineId,
                courseMenus: values?.courseMenus?.map(item => ({
                    id: item?.CourseMenuMapping?.id,
                    courseMenuId: item,
                })),
            };

            // upload image
            if (values?.image) {
                if (values?.image?.file) {
                    updateBody.teacherImagePath = await uploadImageFile(
                        values?.image?.file,
                        controllerRef.current?.signal,
                    );
                }
            } else {
                updateBody.teacherImagePath = "";
            }

            const isUpdateTeacher = await updateTeacherById(values?.id, updateBody, controllerRef.current?.signal);
            if (isUpdateTeacher) {
                tableRef.current.onQueryChange();
                setModalForm(false);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // fetch data
    const fetchData = useCallback(
        async query => {
            try {
                setIsLoading(true);
                const queryParams = {
                    page: query?.page + 1 || 1,
                    limit: query?.pageSize || 10,
                    order: query?.orderBy?.field || "id",
                    direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    status: searchData?.status || "",
                    menu: searchData?.menu?.id || "",
                    search: searchData?.search || "",
                };
                const res = await getAllTeacher(queryParams, controllerRef.current?.signal);
                if (res) {
                    setTableOptions(currentState => ({
                        ...currentState,
                        currentPage: query?.page,
                        pageSize: query?.pageSize || 10,
                        totalData: res?.pagination?.totalData || 0,
                        order: query?.orderBy?.field || "timestamp",
                        direction: query?.orderBy?.field ? query?.orderDirection : "desc",
                    }));
                    setIsLoading(false);
                    return {
                        data: res?.data || [],
                        page: query?.page || 0,
                        totalCount: res?.pagination?.totalData || 0,
                    };
                }
                setIsLoading(false);
            } catch (error) {
                console.dir(error);
                setIsLoading(false);
                return {
                    data: [],
                    page: 0,
                    totalCount: 0,
                };
            }
        },
        [searchData],
    );

    // export excel
    const handleExportExcel = useCallback(async () => {
        setIsExport(true);
        const body = {
            status: excelFilter?.status,
            menu: excelFilter?.menu,
            search: excelFilter?.search,
        };
        try {
            const res = await getAllTeacherForExcel(body, controllerRef.current?.signal);
            if (res) {
                const data = res?.map(item => ({
                    Firstname: item?.teacherFirstname || "",
                    Lastname: item?.teacherLastname || "",
                    Nickname: item?.teacherNickname || "",
                    Email: item?.teacherEmail || "",
                    Tel: item?.teacherTel || "",
                    Line: item?.teacherLineid || "",
                    Status: item?.status || "",
                }));
                if (!data?.length) return false;
                exportToExcel(data, { fileName: "TeacherList" });
            }
        } catch (error) {
            console.dir(error);
        } finally {
            setIsExport(false);
        }
    }, [excelFilter]);

    // change status
    const handleChangeStatus = useCallback(async data => {
        const stutus = data?.obj?.status;
        const isCreate = await updateTeacherById(
            data?.obj?.id,
            { status: stutus === "0" ? "1" : "0" },
            controllerRef.current?.signal,
        );
        if (isCreate) {
            tableRef.current.onQueryChange();
        }
    }, []);

    // memo
    const tableColumns = useMemo(() => columnTableTeachers({ tableOptions, handleChangeStatus }), [
        handleChangeStatus,
        tableOptions,
    ]);

    // run only first render
    useEffect(() => {
        if (searchData.status !== undefined) {
            tableRef.current.onQueryChange({ page: 0 });
        }
        controllerRef.current = new AbortController();
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, [searchData]);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className="mb-3">
                <TeacherSearchForm
                    setExcelFilter={setExcelFilter}
                    onSubmit={values => {
                        setSearchData(values);
                    }}
                    {...{ isExport, handleExportExcel, handleOpenCreateFormModal, thisMenuPermission }}
                />
            </Grid>
            <Grid item xs={12}>
                <div className="t-table-custom">
                    <MaterialTable
                        tableRef={tableRef}
                        isLoading={isLoading}
                        data={fetchData}
                        columns={tableColumns}
                        options={tableOptions}
                        components={{ Container: Box }}
                        title=""
                        actions={[
                            {
                                icon: () => <RemoveRedEye />,
                                iconProps: { className: "action-view" },
                                tooltip: "View",
                                onClick: (_evt, d) => {
                                    // logic....
                                    history.push(`/teacher_management/teacher_list/${d.id}`);
                                },
                            },
                            {
                                icon: "delete",
                                iconProps: { className: "action-delete" },
                                tooltip: "Delete",
                                onClick: (event, rowData) => handleDeleteTeacher(rowData),
                                hidden: !thisMenuPermission?.delete,
                            },
                        ]}
                    />
                </div>
            </Grid>
            {/* modal */}
            <Grid item xs={12}>
                <Modal show={modalForm} scrollable centered onHide={handleCloseCreateFormModal}>
                    <Modal.Header>
                        <ModalTitle>{isCreateMode ? "Create" : "Edit"} Teacher</ModalTitle>
                        <IconButton onClick={handleCloseCreateFormModal}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <ModalBody>
                        <FormCreate
                            onCancel={handleCloseCreateFormModal}
                            onSubmit={isCreateMode ? handleCreateTeacher : handleEditTeacher}
                            initialValuesTeacher={initialValuesTeacher}
                        />
                    </ModalBody>
                </Modal>
            </Grid>
        </Grid>
    );
}
