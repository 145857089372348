import { Button, Grid, IconButton } from "@material-ui/core";
import { Add, Close, GetApp } from "@material-ui/icons";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { createHolidays, getHolidaysByMonth, getHolidaysOption, updateHolidaysById } from "../../../apis/holidaysApi";
import {
    createScheduleGroup,
    createSchedulePrivate,
    getScheduleListByMonth,
    getScheduleListByWeek,
    updateSchedulePrivate,
    getScheduleOption,
    updateScheduleGroup,
    exportSchedule,
} from "../../../apis/scheduleApi";
import { useForceRender } from "../../../utils/customHook";
import ContentCalendar from "./ContentCalendar";
import FormHolidays from "./FormHolidays";
import TabsSchedule from "./TabsSchedule";
import PrintCalendar from "./PrintCalendar";
import { getStatusBooking, getStatusPaid } from "../OrderTransaction/helpers/orderTransactionOption";
import exportExcelShedule from "../../../utils/exportExcelShedule";
// import html2Image from "../../../utils/html2Image";

export default function ContentSchedule() {
    const [renderMonth, forceRenderMonth] = useForceRender();
    const [renderWeek, forceRenderWeek] = useForceRender();

    // ref
    const controllerRef = useRef(null);

    // component state
    const [holidayEvents, setHolidayEvents] = useState([]);
    const [scheduleEventsMonth, setScheduleEventsMonth] = useState([]);
    const [scheduleEventsWeek, setScheduleEventsWeek] = useState([]);
    const [modalFormSchedule, setModalFormSchedule] = useState(false);
    const [targetHoliday, setTargetHoliday] = useState([]);
    const [modalFormHolidays, setModalFormHolidays] = useState({ isOpen: false, mode: "Create" });
    const [agumentsModalPrintCalendar, setAgumentsModalPrintCalendar] = useState({
        isOpen: false,
        element: null,
        listClassroom: [],
        dateSelected: null,
        base64Img: "",
        autoPrint: true,
    });
    const [holidaysOption, setHolidaysOption] = useState({
        teacher: [],
    });
    const [scheduleOption, setScheduleOption] = useState({
        student: [],
        classroom: [],
        teacher: [],
        course: [],
    });
    const [calendarType, setCalendarType] = useState("dayGridMonth");
    const [startDate, setStartDate] = useState(
        moment()
            .startOf("month")
            .subtract(1, "month")
            .format("YYYY-MM-DD"),
    );
    const [endDate, setEndDate] = useState(
        moment()
            .endOf("month")
            .add(1, "month")
            .format("YYYY-MM-DD"),
    );
    const [scheduleExcelFilter, setScheduleExcelFilter] = useState({
        startDate: moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
        endDate: moment()
            .endOf("month")
            .format("YYYY-MM-DD"),
    });
    const [initialScheduleFormTab, setInitialScheduleFormTab] = useState("private");
    const [initialScheduleFormMode, setInitialScheduleFormMode] = useState("create");
    const [scheduleData, setScheduleData] = useState(null);
    const [filterTeacher, setFilterTeacher] = useState([]);

    // close schedule modal form
    const handleCloseModalSchedule = useCallback(() => {
        setModalFormSchedule(false);
    }, []);

    // update schedule logic
    const handleUpdateSchedule = useCallback(
        async ({ values, tab }) => {
            try {
                // create tab private
                if (tab === "private") {
                    const updateSchedulePrivateBody = {
                        schedulePrivateId: values?.schedulePrivateId || null,
                        studentId: values?.student?.id || "",
                        orderId: values?.order?.orderId || "",
                        note: values?.note || "",
                        schedulePrivateList:
                            values?.listCourseMenu?.map(item => ({
                                schedulePrivateId: item?.schedulePrivateId,
                                schedulePrivateListId: item?.schedulePrivateListId || null,
                                bookingStartDate:
                                    item?.bookingDate && item?.startTime
                                        ? new Date(
                                              `${moment(item?.bookingDate).format("YYYY-MM-DD")} ${moment(
                                                  item?.startTime,
                                              ).format("HH:mm")}`,
                                          )
                                        : "",
                                bookingEndDate:
                                    item?.bookingDate && item?.toTime
                                        ? new Date(
                                              `${moment(item?.bookingDate).format("YYYY-MM-DD")} ${moment(
                                                  item?.toTime,
                                              ).format("HH:mm")}`,
                                          )
                                        : "",
                                courseId: item?.courseId || "",
                                menuId: item?.menuId || "",
                                classroomId: item?.classroom?.id || "",
                                teacherId: item?.teacher?.id || "",
                            })) || [],
                    };
                    const isUpdate = await updateSchedulePrivate(
                        updateSchedulePrivateBody,
                        controllerRef.current?.signal,
                    );
                    if (isUpdate) {
                        handleCloseModalSchedule();
                        if (calendarType === "dayGridMonth") {
                            forceRenderMonth();
                        }
                        if (calendarType === "timeGridWeek") {
                            forceRenderWeek();
                        }
                    }
                }

                // create tab group
                if (tab === "group") {
                    const updateScheduleGroupBody = {
                        scheduleGroupId: values?.scheduleGroupId || null,
                        scheduleGroupType: "1",
                        courseId: values?.course?.id || "",
                        roomName: values?.roomname || "",
                        note: values?.note || "",
                        scheduleGroupList:
                            values?.listCourseMenu?.map(item => ({
                                scheduleGroupId: values?.scheduleGroupId || null,
                                scheduleGroupListId: item?.scheduleGroupListId || null,
                                bookingStartDate:
                                    item?.bookingDate && item?.startTime
                                        ? new Date(
                                              `${moment(item?.bookingDate).format("YYYY-MM-DD")} ${moment(
                                                  item?.startTime,
                                              ).format("HH:mm")}`,
                                          )
                                        : "",
                                bookingEndDate:
                                    item?.bookingDate && item?.toTime
                                        ? new Date(
                                              `${moment(item?.bookingDate).format("YYYY-MM-DD")} ${moment(
                                                  item?.toTime,
                                              ).format("HH:mm")}`,
                                          )
                                        : "",
                                classroomId: item?.classroom?.id || "",
                                studentList: item?.students?.map(student => student?.id) || [],
                                menuList:
                                    item?.menuAndTeacher?.map(item2 => ({
                                        menuId: item2?.menu?.id || "",
                                        teacherId: item2?.teacher?.id || "",
                                    })) || [],
                                type: 1,
                                absent: values?.absence
                                    ?.filter(ab => ab?.student?.id)
                                    ?.map(ab => ({ studentId: ab?.student?.id, note: ab?.note })),
                            })) || [],
                    };
                    const isUpdate = await updateScheduleGroup(updateScheduleGroupBody, controllerRef.current?.signal);
                    if (isUpdate) {
                        handleCloseModalSchedule();
                        if (calendarType === "dayGridMonth") {
                            forceRenderMonth();
                        }
                        if (calendarType === "timeGridWeek") {
                            forceRenderWeek();
                        }
                    }
                }

                // create tab free_schedule
                if (tab === "free_schedule") {
                    const updateScheduleFreeScheduleBody = {
                        scheduleGroupId: values?.scheduleGroupId || null,
                        scheduleGroupType: "0",
                        courseId: values?.course?.id || "",
                        roomName: values?.roomname || "",
                        note: values?.note || "",
                        scheduleGroupList:
                            values?.listCourseMenu?.map(item => ({
                                scheduleGroupId: values?.scheduleGroupId || null,
                                scheduleGroupListId: item?.scheduleGroupListId || null,
                                bookingStartDate:
                                    item?.bookingDate && item?.startTime
                                        ? new Date(
                                              `${moment(item?.bookingDate).format("YYYY-MM-DD")} ${moment(
                                                  item?.startTime,
                                              ).format("HH:mm")}`,
                                          )
                                        : "",
                                bookingEndDate:
                                    item?.bookingDate && item?.toTime
                                        ? new Date(
                                              `${moment(item?.bookingDate).format("YYYY-MM-DD")} ${moment(
                                                  item?.toTime,
                                              ).format("HH:mm")}`,
                                          )
                                        : "",
                                classroomId: item?.classroom?.id || "",
                                studentList: item?.students?.map(student => student?.id) || [],
                                menuList:
                                    item?.menuAndTeacher?.map(item2 => ({
                                        menuId: item2?.menu?.id || "",
                                        teacherId: item2?.teacher?.id || "",
                                    })) || [],
                                type: 2,
                                absent: values?.absence
                                    ?.filter(ab => ab?.student?.id)
                                    ?.map(ab => ({ studentId: ab?.student?.id, note: ab?.note })),
                            })) || [],
                    };
                    const isUpdate = await updateScheduleGroup(
                        updateScheduleFreeScheduleBody,
                        controllerRef.current?.signal,
                    );
                    if (isUpdate) {
                        handleCloseModalSchedule();
                        if (calendarType === "dayGridMonth") {
                            forceRenderMonth();
                        }
                        if (calendarType === "timeGridWeek") {
                            forceRenderWeek();
                        }
                    }
                }
            } catch (error) {
                console.dir(error);
            }
        },
        [handleCloseModalSchedule, forceRenderMonth, forceRenderWeek, calendarType],
    );

    // close holidays modal form
    const handleCloseModalHolidays = useCallback(() => {
        setTargetHoliday([]);
        setModalFormHolidays({ isOpen: false, mode: "Create" });
    }, []);

    // open holidays modal form
    const handleOpenModalHolidays = useCallback(() => {
        setModalFormHolidays({ isOpen: true, mode: "Create" });
    }, []);

    // open handleOpenModalExportCalandarPDF modal Print
    const handleOpenModalExportCalandarPDF = useCallback(async () => {
        // const base64Img = await html2Image(agumentsModalPrintCalendar.element);
        const listClassroom = scheduleEventsMonth.filter(
            item =>
                moment(item?.date).format("YYYY-MM-DD") ===
                moment(agumentsModalPrintCalendar.dateSelected).format("YYYY-MM-DD"),
        );
        setAgumentsModalPrintCalendar(prev => ({ ...prev, isOpen: true, listClassroom }));
    }, [agumentsModalPrintCalendar, scheduleEventsMonth]);

    // handleExportCalandarExcel for export excel
    const handleExportCalandarExcel = useCallback(async () => {
        try {
            const queryParams = {
                startDate: scheduleExcelFilter?.startDate,
                endDate: scheduleExcelFilter?.endDate,
            };
            if (filterTeacher.length) {
                queryParams.teacher = filterTeacher.join(",");
            }
            const res = await exportSchedule(queryParams, controllerRef.current?.signal);
            if (res) {
                await exportExcelShedule(res, { groupBy: "classroomId", fileName: "SCA_schedule", sliceSchedule: 6 });
            }
        } catch (error) {
            console.dir(error);
        }
    }, [scheduleExcelFilter, filterTeacher]);

    // submit holidays logic
    const handleSubmitHolidays = useCallback(
        async value => {
            try {
                const body = {
                    id: value?.id,
                    title: value?.titleInput || "",
                    teacherId: value?.teacher?.id || "",
                    typeHolidays: value?.type || "",
                    fieldDate: value?.field_date || [],
                    descriptionHolidays: value?.description || "",
                };
                if (targetHoliday?.length) {
                    const isUpdate = await updateHolidaysById(body, controllerRef.current?.signal);
                    if (isUpdate) {
                        handleCloseModalHolidays();
                        return forceRenderMonth();
                    }
                }
                const isCreate = await createHolidays(body, controllerRef.current?.signal);
                if (isCreate) {
                    handleCloseModalHolidays();
                    forceRenderMonth();
                }
            } catch (error) {
                console.dir(error);
            }
        },
        [handleCloseModalHolidays, forceRenderMonth, targetHoliday],
    );

    // create schedule logic
    const handleCreateSchedule = useCallback(
        async ({ values, tab }) => {
            try {
                // create tab private
                if (tab === "private") {
                    const createSchedulePrivateBody = {
                        studentId: values?.student?.id || "",
                        orderId: values?.order?.orderId || "",
                        note: values?.note || "",
                        absence: [{ student: "", note: "" }],
                        schedulePrivateList:
                            values?.listCourseMenu?.map(item => ({
                                bookingStartDate:
                                    item?.bookingDate && item?.startTime
                                        ? new Date(
                                              `${moment(item?.bookingDate).format("YYYY-MM-DD")} ${moment(
                                                  item?.startTime,
                                              ).format("HH:mm")}`,
                                          )
                                        : "",
                                bookingEndDate:
                                    item?.bookingDate && item?.toTime
                                        ? new Date(
                                              `${moment(item?.bookingDate).format("YYYY-MM-DD")} ${moment(
                                                  item?.toTime,
                                              ).format("HH:mm")}`,
                                          )
                                        : "",
                                courseId: item?.courseId || "",
                                menuId: item?.menuId || "",
                                classroomId: item?.classroom?.id || "",
                                teacherId: item?.teacher?.id || "",
                                orderListId: item?.orderListId || "",
                            })) || [],
                    };
                    const isCreateSchedulePrivate = await createSchedulePrivate(
                        createSchedulePrivateBody,
                        controllerRef.current?.signal,
                    );
                    if (isCreateSchedulePrivate) {
                        handleCloseModalSchedule();
                        if (calendarType === "dayGridMonth") {
                            forceRenderMonth();
                        }
                        if (calendarType === "timeGridWeek") {
                            forceRenderWeek();
                        }
                    }
                }

                // create tab group
                if (tab === "group") {
                    const createScheduleGroupBody = {
                        scheduleGroupType: "1",
                        courseId: values?.course?.id || "",
                        roomName: values?.roomname || "",
                        note: values?.note || "",
                        absence: [{ student: "", note: "" }],
                        scheduleGroupList:
                            values?.listCourseMenu?.map(item => ({
                                bookingStartDate:
                                    item?.bookingDate && item?.startTime
                                        ? new Date(
                                              `${moment(item?.bookingDate).format("YYYY-MM-DD")} ${moment(
                                                  item?.startTime,
                                              ).format("HH:mm")}`,
                                          )
                                        : "",
                                bookingEndDate:
                                    item?.bookingDate && item?.toTime
                                        ? new Date(
                                              `${moment(item?.bookingDate).format("YYYY-MM-DD")} ${moment(
                                                  item?.toTime,
                                              ).format("HH:mm")}`,
                                          )
                                        : "",
                                classroomId: item?.classroom?.id || "",
                                studentList: item?.students?.map(student => student?.id) || [],
                                menuList:
                                    item?.menuAndTeacher?.map(item2 => ({
                                        menuId: item2?.menu?.id || "",
                                        teacherId: item2?.teacher?.id || "",
                                    })) || [],
                            })) || [],
                    };
                    const isCreateScheduleGroup = await createScheduleGroup(
                        createScheduleGroupBody,
                        controllerRef.current?.signal,
                    );
                    if (isCreateScheduleGroup) {
                        handleCloseModalSchedule();
                        if (calendarType === "dayGridMonth") {
                            forceRenderMonth();
                        }
                        if (calendarType === "timeGridWeek") {
                            forceRenderWeek();
                        }
                    }
                }

                // create tab free_schedule
                if (tab === "free_schedule") {
                    const createScheduleFreeScheduleBody = {
                        scheduleGroupType: "0",
                        courseId: values?.course?.id || "",
                        roomName: values?.roomname || "",
                        note: values?.note || "",
                        absence: [{ student: "", note: "" }],
                        scheduleGroupList:
                            values?.listCourseMenu?.map(item => ({
                                bookingStartDate:
                                    item?.bookingDate && item?.startTime
                                        ? new Date(
                                              `${moment(item?.bookingDate).format("YYYY-MM-DD")} ${moment(
                                                  item?.startTime,
                                              ).format("HH:mm")}`,
                                          )
                                        : "",
                                bookingEndDate:
                                    item?.bookingDate && item?.toTime
                                        ? new Date(
                                              `${moment(item?.bookingDate).format("YYYY-MM-DD")} ${moment(
                                                  item?.toTime,
                                              ).format("HH:mm")}`,
                                          )
                                        : "",
                                classroomId: item?.classroom?.id || "",
                                studentList: item?.students?.map(student => student?.id) || [],
                                menuList:
                                    item?.menuAndTeacher?.map(item2 => ({
                                        menuId: item2?.menu?.id || "",
                                        teacherId: item2?.teacher?.id || "",
                                    })) || [],
                            })) || [],
                    };
                    const isCreateScheduleFreeSchedule = await createScheduleGroup(
                        createScheduleFreeScheduleBody,
                        controllerRef.current?.signal,
                    );
                    if (isCreateScheduleFreeSchedule) {
                        handleCloseModalSchedule();
                        if (calendarType === "dayGridMonth") {
                            forceRenderMonth();
                        }
                        if (calendarType === "timeGridWeek") {
                            forceRenderWeek();
                        }
                    }
                }
            } catch (error) {
                console.dir(error);
            }
        },
        [handleCloseModalSchedule, forceRenderMonth, forceRenderWeek, calendarType],
    );

    // fetch holidays option data
    const fetchHolidaysOption = useCallback(async signal => {
        try {
            const holidaysOption = await getHolidaysOption(signal);
            if (holidaysOption) {
                const opt = {
                    teacher: holidaysOption?.teacherOption,
                };
                setHolidaysOption(currentState => ({
                    ...currentState,
                    ...opt,
                }));
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // fetch schedule option data
    const fetchScheduleOption = useCallback(async signal => {
        try {
            const scheduleOption = await getScheduleOption(signal);
            if (scheduleOption) {
                const opt = {
                    student: scheduleOption?.studentOption,
                    classroom: scheduleOption?.classroomOption,
                    teacher: scheduleOption?.teacherOption,
                    course: scheduleOption?.courseOption,
                };
                setScheduleOption(currentState => ({
                    ...currentState,
                    ...opt,
                }));
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // fetch holidays
    const fetchHolidays = useCallback(
        async signal => {
            try {
                const queryParams = {
                    startDate,
                    endDate,
                };
                if (filterTeacher.length) {
                    queryParams.teacher = filterTeacher.join(",");
                }
                const holidays = await getHolidaysByMonth(queryParams, signal);
                if (holidays) {
                    setHolidayEvents(
                        [...holidays?.schoolHolidays, ...holidays?.teacherHolidays]?.map(item => {
                            return {
                                id: item?.id,
                                title:
                                    item?.typeHolidays === "1"
                                        ? `${item?.Teacher?.teacherFirstname} ${item?.Teacher?.teacherLastname} ${item?.title}`
                                        : item?.title,
                                titleInput: item?.title,
                                start: new Date(item?.startDate),
                                end: new Date(item?.endDate),
                                display: "block",
                                className: ["schedule-holidays-app"],
                                color: item?.typeHolidays === "0" ? "#dc3545" : "#73c2fb",
                                textColor: "#fff",
                                createdAt: item?.createdAt,
                                updatedAt: item?.updatedAt,
                                typeHolidays: item?.typeHolidays,
                                descriptionHolidays: item?.descriptionHolidays,
                                exStart: item?.startDate,
                                teacher: item?.Teacher,
                                exEnd: item?.endDate,
                                data: item,
                            };
                        }),
                    );
                }
            } catch (error) {
                console.dir(error);
            }
        },
        [startDate, endDate, filterTeacher],
    );

    // fetch schedule month
    const fetchScheduleMonth = useCallback(
        async signal => {
            try {
                const queryParams = {
                    startDate,
                    endDate,
                };
                if (filterTeacher.length) {
                    queryParams.teacher = filterTeacher.join(",");
                }
                const schedule = await getScheduleListByMonth(queryParams, signal);
                if (schedule) {
                    const schedulePrivateMonth = schedule?.schedulePrivateClassroomCards?.map(item => {
                        if (item?.bookingMenu?.includes(",")) {
                            const menuList = item?.bookingMenu?.split(",")?.map(menu => menu?.trim());
                            item.bookingMenu = menuList;
                        }
                        if (item?.bookingTeacher?.includes(",")) {
                            const teacherList = item?.bookingTeacher?.split(",")?.map(teacher => teacher?.trim());
                            item.bookingTeacher = teacherList;
                        }
                        return {
                            customTitle: `${item?.classroomName}`,
                            date: new Date(item?.bookingDate),
                            color: item?.colorHex,
                            classroomName: item?.classroomName,
                            time: item?.bookingTime,
                            student: item?.bookingStudent,
                            type: item?.bookingType,
                            typeCode: item?.bookingTypeCode,
                            menu: item?.bookingMenu,
                            teacher: item?.bookingTeacher,
                            note: item?.bookingNote,
                            paidStatus: getStatusPaid({ status: item?.orderPaidStatus }),
                            bookingStatus: getStatusBooking({ status: item?.orderBookingStatus }),
                            data: item,
                        };
                    });
                    const scheduleGroupMonth = schedule?.scheduleGroupClassroomCards?.map(item => {
                        if (item?.bookingMenu?.includes(",")) {
                            const menuList = item?.bookingMenu?.split(",")?.map(menu => menu?.trim());
                            item.bookingMenu = menuList;
                        }
                        if (item?.bookingTeacher?.includes(",")) {
                            const teacherList = item?.bookingTeacher?.split(",")?.map(teacher => teacher?.trim());
                            item.bookingTeacher = teacherList;
                        }
                        return {
                            customTitle: `${item?.classroomName}`,
                            date: new Date(item?.bookingDate),
                            color: item?.colorHex,
                            roomName: item?.bookingGroupName,
                            classroomName: item?.classroomName,
                            time: item?.bookingTime,
                            student: item?.bookingStudent,
                            type: item?.bookingType,
                            typeCode: item?.bookingTypeCode,
                            menu: item?.bookingMenu,
                            teacher: item?.bookingTeacher,
                            note: item?.bookingNote,
                            studentQty: item?.ScheduleGroupLists[0]?.ScheduleGroupListHasStudents?.length,
                            courseName: item?.bookingCourseName,
                            data: item,
                        };
                    });
                    const freeScheduleMonth = schedule?.freeScheduleClassroomCards?.map(item => {
                        if (item?.bookingMenu?.includes(",")) {
                            const menuList = item?.bookingMenu?.split(",")?.map(menu => menu?.trim());
                            item.bookingMenu = menuList;
                        }
                        if (item?.bookingTeacher?.includes(",")) {
                            const teacherList = item?.bookingTeacher?.split(",")?.map(teacher => teacher?.trim());
                            item.bookingTeacher = teacherList;
                        }
                        return {
                            customTitle: `${item?.classroomName}`,
                            date: new Date(item?.bookingDate),
                            color: item?.colorHex,
                            roomName: item?.bookingGroupName,
                            classroomName: item?.classroomName,
                            time: item?.bookingTime,
                            student: item?.bookingStudent,
                            type: item?.bookingType,
                            typeCode: item?.bookingTypeCode,
                            menu: item?.bookingMenu,
                            teacher: item?.bookingTeacher,
                            studentQty: item?.ScheduleGroupLists[0]?.ScheduleGroupListHasStudents?.length,
                            note: item?.bookingNote,
                            courseName: item?.bookingCourseName,
                            data: item,
                        };
                    });
                    setScheduleEventsMonth([...schedulePrivateMonth, ...scheduleGroupMonth, ...freeScheduleMonth]);
                }
            } catch (error) {
                console.dir(error);
            }
        },
        [startDate, endDate, filterTeacher],
    );

    // fetch schedule week
    const fetchScheduleWeek = useCallback(
        async signal => {
            try {
                const queryParams = {
                    startDate,
                    endDate,
                };
                if (filterTeacher.length) {
                    queryParams.teacher = filterTeacher.join(",");
                }
                const schedule = await getScheduleListByWeek(queryParams, signal);
                if (schedule) {
                    const schedulePrivateWeek = schedule?.schedulePrivateList?.map(item => {
                        return {
                            start: new Date(item?.bookingStartDate),
                            end: new Date(item?.bookingEndDate),
                            color: item?.bookingClassroomColor,
                            classroomName: item?.bookingClassroom,
                            time: item?.bookingTime,
                            data: item,
                        };
                    });
                    const scheduleGroupWeek = schedule?.scheduleGroupList?.map(item => {
                        return {
                            start: new Date(item?.bookingStartDate),
                            end: new Date(item?.bookingEndDate),
                            color: item?.bookingClassroomColor,
                            classroomName: item?.bookingClassroom,
                            time: item?.bookingTime,
                            data: item,
                        };
                    });
                    setScheduleEventsWeek([...schedulePrivateWeek, ...scheduleGroupWeek]);
                }
            } catch (error) {
                console.dir(error);
            }
        },
        [startDate, endDate, filterTeacher],
    );

    // open schedule modal form
    const handleOpenModalSchedule = useCallback(
        (mode, item) => {
            fetchScheduleOption(controllerRef.current?.signal);
            setModalFormSchedule(true);
            // schedule form create mode
            if (mode === "create") {
                setInitialScheduleFormTab("private");
                setInitialScheduleFormMode("create");
                setScheduleData(null);
                return;
            }
            // schedule form edit mode
            if (mode === "edit") {
                const data = item?.data;
                // private
                if (item?.typeCode === "0") {
                    const scheduelPrivate = data?.SchedulePrivateLists[0]?.SchedulePrivate;
                    setInitialScheduleFormTab("private");
                    setInitialScheduleFormMode("edit");
                    setScheduleData({
                        schedulePrivateId: scheduelPrivate?.schedulePrivateId,
                        student: scheduelPrivate?.Student,
                        order: scheduelPrivate?.Order,
                        bookingNo: scheduelPrivate?.Order?.bookingNo,
                        note: scheduelPrivate?.note,
                        absence: [{ student: "", note: "" }],
                        listCourseMenu: data?.SchedulePrivateLists?.map(item => ({
                            schedulePrivateId: scheduelPrivate?.schedulePrivateId,
                            schedulePrivateListId: item?.schedulePrivateListId,
                            bookingDate: item?.bookingStartDate,
                            startTime: item?.bookingStartDate,
                            toTime: item?.bookingEndDate,
                            course: item?.Course?.courseName,
                            courseId: item?.Course?.id,
                            menu: item?.CourseMenu?.courseMenuName,
                            menuId: item?.CourseMenu?.id,
                            classroom: item?.Classroom,
                            teacher: item?.Teacher,
                        })),
                    });
                }
                // group
                if (item?.typeCode === "1") {
                    const scheduleGroup = data?.ScheduleGroupLists[0]?.ScheduleGroup;
                    const absent = data?.ScheduleGroupLists[0]?.StudentAbsents;
                    setInitialScheduleFormTab("group");
                    setInitialScheduleFormMode("edit");
                    setScheduleData({
                        scheduleGroupId: scheduleGroup?.scheduleGroupId,
                        course: scheduleGroup?.Course,
                        limitstudent: scheduleGroup?.Course?.courseLimitedStudent || 0,
                        roomname: scheduleGroup?.roomName,
                        note: scheduleGroup?.note,
                        absence: absent?.length
                            ? absent?.map(ab => {
                                  const student = scheduleOption?.student?.find(s => s?.id === ab?.studentId);
                                  return {
                                      student: {
                                          id: ab?.studentId,
                                          studentFirstname: student?.studentFirstname,
                                          studentLastname: student?.studentLastname,
                                          studentNickname: student?.studentNickname,
                                      },
                                      note: ab?.note,
                                  };
                              })
                            : [{ student: "", note: "" }],
                        listCourseMenu: data?.ScheduleGroupLists?.map(item => ({
                            scheduleGroupId: scheduleGroup?.scheduleGroupId,
                            scheduleGroupListId: item?.scheduleGroupListId,
                            bookingDate: item?.bookingStartDate,
                            startTime: item?.bookingStartDate,
                            toTime: item?.bookingEndDate,
                            classroom: item?.Classroom,
                            students: item?.ScheduleGroupListHasStudents?.map(student => student?.Student),
                            menuAndTeacher: item?.ScheduleGroupListHasMenus?.map(menu => ({
                                scheduleGroupListHasMenuId: menu?.scheduleGroupListHasMenuId,
                                menu: menu?.CourseMenu,
                                teacher: menu?.Teacher,
                            })),
                            scheduleGroupListHasStudents: item?.ScheduleGroupListHasStudents,
                            scheduleGroupListHasMenus: item?.ScheduleGroupListHasMenus,
                        })),
                    });
                }
                // free schedule
                if (item?.typeCode === "2") {
                    const scheduleGroup = data?.ScheduleGroupLists[0]?.ScheduleGroup;
                    const absent = data?.ScheduleGroupLists[0]?.StudentAbsents;
                    setInitialScheduleFormTab("free_schedule");
                    setInitialScheduleFormMode("edit");
                    setScheduleData({
                        scheduleGroupId: scheduleGroup?.scheduleGroupId,
                        course: scheduleGroup?.Course,
                        limitstudent: scheduleGroup?.Course?.courseLimitedStudent || 0,
                        roomname: scheduleGroup?.roomName,
                        note: scheduleGroup?.note,
                        absence: absent?.length
                            ? absent?.map(ab => {
                                  const student = scheduleOption?.student?.find(s => s?.id === ab?.studentId);
                                  return {
                                      student: {
                                          id: ab?.studentId,
                                          studentFirstname: student?.studentFirstname,
                                          studentLastname: student?.studentLastname,
                                          studentNickname: student?.studentNickname,
                                      },
                                      note: ab?.note,
                                  };
                              })
                            : [{ student: "", note: "" }],
                        listCourseMenu: data?.ScheduleGroupLists?.map(item => ({
                            scheduleGroupId: scheduleGroup?.scheduleGroupId,
                            scheduleGroupListId: item?.scheduleGroupListId,
                            bookingDate: item?.bookingStartDate,
                            startTime: item?.bookingStartDate,
                            toTime: item?.bookingEndDate,
                            classroom: item?.Classroom,
                            students: item?.ScheduleGroupListHasStudents?.map(student => student?.Student),
                            menuAndTeacher: item?.ScheduleGroupListHasMenus?.map(menu => ({
                                scheduleGroupListHasMenuId: menu?.scheduleGroupListHasMenuId,
                                menu: menu?.CourseMenu,
                                teacher: menu?.Teacher,
                            })),
                            absent: item?.StudentAbsents,
                        })),
                    });
                }
            }
        },
        [scheduleOption, fetchScheduleOption],
    );

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        const init = async () => {
            Promise.all([fetchHolidaysOption(controllerRef.current?.signal)]);
        };
        init();
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // re-render month
    useEffect(() => {
        if (calendarType === "dayGridMonth") {
            const initCalendarEvent = async () => {
                const signal = controllerRef.current?.signal;
                Promise.all([fetchScheduleMonth(signal), fetchHolidays(signal)]);
            };
            initCalendarEvent();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderMonth]);

    // re-render week
    useEffect(() => {
        if (calendarType === "timeGridWeek") {
            const initCalendarEvent = async () => {
                const signal = controllerRef.current?.signal;
                Promise.all([fetchScheduleWeek(signal), fetchScheduleMonth(signal)]);
            };
            initCalendarEvent();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderWeek]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <div>
                    <div className="mb-5">
                        <div className="d-flex justify-content-end">
                            {typeof handleExportCalandarExcel === "function" ? (
                                <Button size="large" className="t-btn-primary mr-3" onClick={handleExportCalandarExcel}>
                                    <GetApp />
                                    <span className="ml-1">Excel</span>
                                </Button>
                            ) : null}
                            <Button
                                size="large"
                                className="t-btn-primary mr-3"
                                onClick={handleOpenModalExportCalandarPDF}>
                                <GetApp />
                            </Button>
                            <Button size="large" className="t-btn-primary mr-3" onClick={handleOpenModalHolidays}>
                                <Add /> Add Holidays
                            </Button>
                            <Button
                                size="large"
                                className="t-btn-primary"
                                onClick={() => handleOpenModalSchedule("create", {})}>
                                <Add /> Create Schedule
                            </Button>
                        </div>
                    </div>
                    <ContentCalendar
                        scheduleOption={scheduleOption}
                        calendarType={calendarType}
                        setCalendarType={setCalendarType}
                        holidayEvents={holidayEvents}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        setScheduleExcelFilter={setScheduleExcelFilter}
                        handleOpenModalSchedule={handleOpenModalSchedule}
                        scheduleEventsMonth={scheduleEventsMonth}
                        scheduleEventsWeek={scheduleEventsWeek}
                        forceRenderMonth={forceRenderMonth}
                        forceRenderWeek={forceRenderWeek}
                        setModalFormHolidays={setModalFormHolidays}
                        setTargetHoliday={setTargetHoliday}
                        stateAgumentsModalPrintCalendar={{ agumentsModalPrintCalendar, setAgumentsModalPrintCalendar }}
                        filterTeacher={filterTeacher}
                        setFilterTeacher={setFilterTeacher}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <Modal show={modalFormHolidays?.isOpen} scrollable centered onHide={handleCloseModalHolidays}>
                    <Modal.Header>
                        <Modal.Title>{modalFormHolidays?.mode || "Create"} Holidays</Modal.Title>
                        <IconButton onClick={handleCloseModalHolidays}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body>
                        <FormHolidays
                            onCancel={handleCloseModalHolidays}
                            onSubmit={handleSubmitHolidays}
                            teacherOption={holidaysOption.teacher}
                            modalFormHolidays={modalFormHolidays}
                            targetHoliday={targetHoliday}
                            setTargetHoliday={setTargetHoliday}
                        />
                    </Modal.Body>
                </Modal>
                <Modal
                    show={modalFormSchedule}
                    centered
                    dialogClassName="dialog-form-schedule"
                    size="xl"
                    onHide={handleCloseModalSchedule}>
                    <Modal.Header>
                        <Modal.Title>{initialScheduleFormMode === "create" ? "Create" : "Edit"} Schedule</Modal.Title>
                        <IconButton onClick={handleCloseModalSchedule}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body>
                        <TabsSchedule
                            onCancel={handleCloseModalSchedule}
                            onSubmit={!scheduleData ? handleCreateSchedule : handleUpdateSchedule}
                            scheduleOption={scheduleOption}
                            initialTab={initialScheduleFormTab}
                            mode={initialScheduleFormMode}
                            scheduleData={scheduleData}
                        />
                    </Modal.Body>
                </Modal>
                <Modal
                    show={agumentsModalPrintCalendar.isOpen}
                    centered
                    size="xl"
                    onHide={() => setAgumentsModalPrintCalendar(prev => ({ ...prev, isOpen: false }))}>
                    <Modal.Header>
                        <Modal.Title />
                        <IconButton onClick={() => setAgumentsModalPrintCalendar({ isOpen: false, data: {} })}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body>
                        <PrintCalendar {...agumentsModalPrintCalendar} autoPrint={false} />
                    </Modal.Body>
                </Modal>
            </Grid>
        </Grid>
    );
}
