import { Button, FormControl, FormLabel, Grid, TextField } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getOptionsTeacher } from "../../../apis/teacherApi";
import { toImageUrl } from "../../../utils/format";
// import { toImageUrl } from "../../../utils/format";
import DatepickerApp from "../../componentsUtils/DatePickerApp";
import DropzoneApp from "../../componentsUtils/DropzoneApp";
import SelectSearchMultiApp from "../../componentsUtils/SelectSearchMultiApp";
import { validationSchema } from "./helpers/TeacherManageOption";

export default function FormCreate({ onCancel, onSubmit, initialValuesTeacher }) {
    const [options, setOptions] = useState([]);
    useEffect(() => {
        const fetchOption = async () => {
            const res = await getOptionsTeacher();
            setOptions(res?.courseMenuOption);
        };
        if (options?.length < 1) {
            fetchOption();
        }
    }, [options]);
    return (
        <Formik
            enableReinitialize
            initialValues={initialValuesTeacher}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {({ values, handleChange, handleSubmit, errors, setFieldValue }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12} className="d-flex flex-column align-items-center justify-content-center">
                        <DropzoneApp
                            // onChange={file => {
                            //     setFieldValue("base64", file?.base64);
                            // }}
                            // onRemove={file => {
                            //     setFieldValue("base64", file?.base64);
                            //     setFieldValue("imagePath", "");
                            // }}
                            onChange={file => {
                                setFieldValue("image", file);
                            }}
                            onRemove={() => {
                                setFieldValue("image", "");
                                setFieldValue("teacherImagePath", "");
                            }}
                            src={
                                values?.image?.base64
                                    ? values?.image?.base64
                                    : values?.teacherImagePath
                                    ? toImageUrl(values?.teacherImagePath)
                                    : ""
                            }
                            styles={{ styleImage: { width: 160, height: 160 } }}
                        />
                        <p className="text-muted mt-2"> Allowed file type png , jpg , jpeg.</p>
                    </Grid>
                    {/* first name */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>
                                First name <span className="text-danger">*</span>
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                id="fname"
                                value={values.fname}
                                onChange={handleChange}
                                error={Boolean(errors.fname)}
                                helperText={errors.fname}
                                placeholder="Firstname"
                            />
                        </FormControl>
                    </Grid>
                    {/* first name */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>
                                Last name <span className="text-danger">*</span>
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                id="lname"
                                value={values.lname}
                                onChange={handleChange}
                                error={Boolean(errors.lname)}
                                helperText={errors.lname}
                                placeholder="Lastname"
                            />
                        </FormControl>
                    </Grid>
                    {/* nick name */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>
                                Birth day
                                {/* <span className="text-danger">*</span> */}
                            </FormLabel>
                            <DatepickerApp
                                disableToolbar={false}
                                id="birthday"
                                value={values?.birthday}
                                onChange={date => setFieldValue("birthday", date)}
                                error={Boolean(errors?.birthday)}
                                helperText={errors?.birthday}
                            />
                        </FormControl>
                    </Grid>
                    {/* nick name */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>
                                Nick name
                                {/* <span className="text-danger">*</span> */}
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                id="nickname"
                                value={values.nickname}
                                onChange={handleChange}
                                error={Boolean(errors.nickname)}
                                helperText={errors.nickname}
                                placeholder="Nickname"
                            />
                        </FormControl>
                    </Grid>
                    {/* email */}
                    <Grid item xs={12}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>
                                Email
                                {/* <span className="text-danger">*</span> */}
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                type="email"
                                id="email"
                                value={values.email}
                                onChange={handleChange}
                                error={Boolean(errors.email)}
                                helperText={errors.email}
                                placeholder="Enter email that this teacher use for login."
                            />
                        </FormControl>
                    </Grid>
                    {/* tel */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>
                                Tel
                                {/* <span className="text-danger">*</span> */}
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                type="tel"
                                id="tel"
                                value={values.tel}
                                onChange={handleChange}
                                error={Boolean(errors.tel)}
                                helperText={errors.tel}
                                placeholder="Tel No."
                            />
                        </FormControl>
                    </Grid>
                    {/* LineId */}
                    <Grid item xs={12} md={6} className="mb-3">
                        <FormControl fullWidth>
                            <FormLabel>
                                Line ID
                                {/* <span className="text-danger">*</span> */}
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                id="lineId"
                                value={values.lineId}
                                onChange={handleChange}
                                error={Boolean(errors.lineId)}
                                helperText={errors.lineId}
                                placeholder="ID"
                            />
                        </FormControl>
                    </Grid>
                    {/* Course */}
                    <Grid item xs={12}>
                        <FormControl fullWidth className="mb-3">
                            <FormLabel>
                                Menus
                                {/* <span className="text-danger">*</span> */}
                            </FormLabel>
                            <SelectSearchMultiApp
                                size="md"
                                options={options}
                                getOptionLabel={opt => opt?.courseMenuName || "-"}
                                getOptionValue={opt => opt?.id}
                                value={options.filter(valR => values?.courseMenus.includes(valR.id))}
                                onChange={value => {
                                    setFieldValue(
                                        "courseMenus",
                                        value.map(rv => rv.id),
                                    );
                                }}
                                error={errors?.courseMenus}
                                isDisabled={options.length < 1}
                                placeholder="Select"
                            />
                        </FormControl>
                    </Grid>
                    {/* action */}
                    <Grid item xs={12}>
                        <div className="d-flex justify-content-md-end align-items-center">
                            <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                                <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                            </Button>
                            <Button onClick={handleSubmit} className="btn t-btn-secondary">
                                <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
}
