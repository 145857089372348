import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
    user: JSON.parse(localStorage.getItem("user")),
    authToken: "",
};

export const userSlice = createSlice({
    name: "user",
    initialState: initialUserState,
    reducers: {
        setUser: (state, action) => {
            const { user } = action.payload;
            state.user = user;
        },
        setAuthToken: (state, action) => {
            const { authToken } = action.payload;
            state.authToken = authToken;
        },
    },
});

export const userReducers = userSlice.reducer;
export const userActions = userSlice.actions;
