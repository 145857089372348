import moment from "moment";

/**
 * @author Nakharin
 * @description 2023
 * @param {number} step default 30
 * @param {string} start default 08:00
 * @param {string} end default 17:00
 * @returns optionTimeValueType []
 */
export default (step = 30, start = "08:00", end = "17:00") => {
    const timeStart = moment(start, "HH:mm");
    const timeEnd = moment(end, "HH:mm");
    const limit = timeEnd.diff(timeStart, "minutes") / step;
    if (limit < 1) {
        alert("Error Time start > end.");
        return [];
    }
    let n = 0;
    const timeArrayObject = [];
    do {
        timeArrayObject.push({
            unix: timeStart.unix(),
            date: timeStart.toDate(),
            timeText: timeStart.format("HH:mm"),
        });
        timeStart.add(step, "minutes");
        if (timeEnd.isSame(moment(timeStart).add(-step, "minutes"))) {
            break;
        }
        n++;
    } while (n <= limit);
    return timeArrayObject;
};
