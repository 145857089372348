import React from "react";
import { Grid, Paper } from "@material-ui/core";
import CreditContent from "../../components/CreditManagement/CreditContent";

export default function CreditPage() {
    return (
        <Paper className="p-2 p-lg-3'">
            <Grid container spacing={0}>
                <Grid item xs={12} lg={12}>
                    <CreditContent />
                </Grid>
            </Grid>
        </Paper>
    );
}
