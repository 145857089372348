import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import store, { persistor } from "./redux/store";
import App from "./app/App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "react-datepicker/dist/react-datepicker.css";
import "./app/styles/main.scss";
import "./app/styles/custom.scss";
import "./app/styles/override.scss";
import "./app/styles/responsive.scss";
// Datepicker
import { MetronicLayoutProvider, MetronicSplashScreenProvider, MetronicSubheaderProvider } from "./_metronic/layout";
import { MetronicI18nProvider } from "./_metronic/i18n";
import setupAxios from "./axios/setupAxios";

setupAxios(axios, store);

ReactDOM.render(
    <MetronicI18nProvider>
        <MetronicLayoutProvider>
            <MetronicSubheaderProvider>
                <MetronicSplashScreenProvider>
                    <App store={store} persistor={persistor} />
                </MetronicSplashScreenProvider>
            </MetronicSubheaderProvider>
        </MetronicLayoutProvider>
    </MetronicI18nProvider>,
    document.getElementById("root"),
);
