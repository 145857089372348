import { Button, Divider, FormControl, Grid, TextField } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { number, object, string } from "yup";
import TextareaApp from "../../componentsUtils/TextareaApp";

export default function FormEvaluation({ onSubmit, onCancel, initialValues }) {
    return (
        <div>
            <h4>ประเมินผลการเรียนนักเรียน</h4>
            <Divider className="my-5" />
            <Formik
                initialValues={initialValues}
                validationSchema={object({
                    step: number().max(5),
                    taste: number().max(5),
                    skill: number().max(5),
                    station: number().max(5),
                    ontime: number().max(5),
                    note: string(),
                })}
                onSubmit={onSubmit}>
                {({ handleSubmit, values, handleChange, errors }) => (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <p className="mb-3">คะแนนที่ได้รับจากการเรียนครั้งนี้ (ข้อละ 5 : รวม 25 คะแนน)</p>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="d-flex align-items-center">
                                <p className="mb-0 mr-3 col-5 px-0">ขั้นตอนการทำ :</p>
                                <div className="flex-fill">
                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            InputProps={{ inputProps: { min: 0, max: 5, className: "text-center" } }}
                                            inputMode="decimal"
                                            name="step"
                                            value={values?.step}
                                            onChange={handleChange}
                                            error={Boolean(errors?.step)}
                                            helperText={errors?.step}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="d-flex align-items-center">
                                <p className="mb-0 mr-3 col-5 px-0">รสชาติถูกต้อง :</p>
                                <div className="flex-fill">
                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            InputProps={{ inputProps: { min: 0, max: 5, className: "text-center" } }}
                                            inputMode="decimal"
                                            name="taste"
                                            value={values?.taste}
                                            onChange={handleChange}
                                            error={Boolean(errors?.taste)}
                                            helperText={errors?.taste}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="d-flex align-items-center">
                                <p className="mb-0 mr-3 col-5 px-0">ฝีมือปราณีต :</p>
                                <div className="flex-fill">
                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            InputProps={{ inputProps: { min: 0, max: 5, className: "text-center" } }}
                                            inputMode="decimal"
                                            name="skill"
                                            value={values?.skill}
                                            onChange={handleChange}
                                            error={Boolean(errors?.skill)}
                                            helperText={errors?.skill}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="d-flex align-items-center">
                                <p className="mb-0 mr-3 col-5 px-0">สเตชั่นสะอาด :</p>
                                <div className="flex-fill">
                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            InputProps={{ inputProps: { min: 0, max: 5, className: "text-center" } }}
                                            inputMode="decimal"
                                            name="station"
                                            value={values?.station}
                                            onChange={handleChange}
                                            error={Boolean(errors?.station)}
                                            helperText={errors?.station}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="d-flex align-items-center">
                                <p className="mb-0 mr-3 col-5 px-0">ทำทันเวลา :</p>
                                <div className="flex-fill">
                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            InputProps={{ inputProps: { min: 0, max: 5, className: "text-center" } }}
                                            inputMode="decimal"
                                            name="ontime"
                                            value={values?.ontime}
                                            onChange={handleChange}
                                            error={Boolean(errors?.ontime)}
                                            helperText={errors?.ontime}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="d-flex align-items-center">
                                <p className="mb-0 mr-3 col-5 px-0">ความคิดเห็นเพิ่มเติม :</p>
                                <div className="flex-fill">
                                    <FormControl fullWidth>
                                        <TextareaApp
                                            props={{
                                                textAreaProps: {
                                                    name: "note",
                                                    rows: 5,
                                                    value: values?.note,
                                                    onChange: handleChange,
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </Grid>
                        {/* action */}
                        <Grid item xs={12}>
                            <Divider className="my-5" />
                            <div className="d-flex justify-content-md-end align-items-center">
                                <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                                    <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                                </Button>
                                <Button onClick={handleSubmit} className="btn t-btn-secondary">
                                    <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </div>
    );
}
