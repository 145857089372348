import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    Button,
    ButtonGroup,
    Fade,
    Grid,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    MenuList,
    Popover,
} from "@material-ui/core";
import FullCalendar from "@fullcalendar/react";
import { ArrowLeft, ArrowRight, Close, KeyboardArrowDownRounded, KeyboardArrowUpRounded } from "@material-ui/icons";
import { Modal } from "react-bootstrap";
import moment from "moment";
import DatepickerApp from "../../componentsUtils/DatePickerApp";
import ContentListClassroom from "./ContentListClassroom";
import { dayHeaderDidMount, optionScheduleCalendar, slotLabelDidMount } from "./helpers/scheduleOption";
import RenderEventCalendar from "./RenderEventCalendar";
import ViewScheduleDetail from "./ViewScheduleDetail";
import { useOnClickOutside } from "../../../utils/useOnClickOutside";
import { swalCondition } from "../../../utils/swal";
import { deleteScheduleGroupList, deleteSchedulePrivateList } from "../../../apis/scheduleApi";
import { deleteHolidaysById } from "../../../apis/holidaysApi";
import SelectSearchMultiApp from "../../componentsUtils/SelectSearchMultiApp";

const useStyles = makeStyles(() => ({
    noBorder: {
        "& ~ fieldset": {
            display: "none",
        },
    },
}));

export default function ContentCalendar({
    calendarType = "dayGridMonth",
    setCalendarType = () => {},
    holidayEvents = [],
    setStartDate = () => {},
    setEndDate = () => {},
    setScheduleExcelFilter = () => {},
    handleOpenModalSchedule = () => {},
    scheduleEventsMonth = [],
    scheduleEventsWeek = [],
    forceRenderMonth = () => {},
    forceRenderWeek = () => {},
    setModalFormHolidays = () => {},
    setTargetHoliday = () => {},
    scheduleOption,
    stateAgumentsModalPrintCalendar = { agumentsModalPrintCalendar: null, setAgumentsModalPrintCalendar: () => {} },
    filterTeacher = [],
    setFilterTeacher = () => {},
}) {
    // ref
    const refFullCalendar = useRef(null);
    const containerCalendarRef = useRef(null);
    const controllerRef = useRef(null);
    const refInputDatePickMonthsYears = useRef(null);

    // component state
    const classes = useStyles();
    const [isTogglePopOver, setIsTogglePopOver] = useState({
        positionEl: { left: 0, top: 0 },
        data: {},
        type: 0,
    });
    const [selectedDateValue, setSelectedDateValue] = useState(new Date());
    const [toggleShowPickDate, setToggleShowPickDate] = useState(false);
    const [calendarDateTitle, setCalendarDateTitle] = useState(new Date());
    const [modalViewDetail, setModalViewDetail] = useState(false);

    // filter state
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const [filterTeacherTemp, setFilterTeacherTemp] = useState([]);

    useOnClickOutside(refInputDatePickMonthsYears, event => {
        if (!refInputDatePickMonthsYears.current.contains(event?.target)) {
            setToggleShowPickDate(false);
        }
    });

    // set calendar unit
    const handleSetCalendarUnit = useCallback(
        (currentDate, calendarType) => {
            if (calendarType === "dayGridMonth") {
                setStartDate(
                    moment(currentDate)
                        .startOf("month")
                        .subtract(1, "month")
                        .format("YYYY-MM-DD"),
                );
                setEndDate(
                    moment(currentDate)
                        .endOf("month")
                        .add(1, "month")
                        .format("YYYY-MM-DD"),
                );
                setScheduleExcelFilter({
                    startDate: moment(currentDate)
                        .startOf("month")
                        .format("YYYY-MM-DD"),
                    endDate: moment(currentDate)
                        .endOf("month")
                        .format("YYYY-MM-DD"),
                });
            }
            if (calendarType === "timeGridWeek") {
                setStartDate(
                    moment(currentDate)
                        .startOf("week")
                        .format("YYYY-MM-DD"),
                );
                setEndDate(
                    moment(currentDate)
                        .endOf("week")
                        .format("YYYY-MM-DD"),
                );
                setScheduleExcelFilter({
                    startDate: moment(currentDate)
                        .startOf("week")
                        .format("YYYY-MM-DD"),
                    endDate: moment(currentDate)
                        .endOf("week")
                        .format("YYYY-MM-DD"),
                });
            }
        },
        [setStartDate, setEndDate, setScheduleExcelFilter],
    );

    // handle change calendar type (month or week)
    const handleChangeCalendarType = useCallback(
        type => {
            const calendarApi = refFullCalendar.current?.getApi();
            setCalendarType(type);
            calendarApi.changeView(type);
        },
        [setCalendarType],
    );

    // click to next or prev (month or week)
    const handleClickPrevNextCalendar = useCallback(
        (type = "next") => {
            const calendarApi = refFullCalendar.current?.getApi();
            if (type === "next") {
                calendarApi.next();
            } else if (type === "prev") {
                calendarApi.prev();
            }
            setCalendarDateTitle(calendarApi?.currentDataManager?.data?.currentDate);
            const currentDate = calendarApi?.currentDataManager?.data?.currentDate;
            handleSetCalendarUnit(currentDate, calendarType);
            setSelectedDateValue(currentDate);
        },
        [refFullCalendar, calendarType, handleSetCalendarUnit],
    );

    // click calendar date
    const handleClickCalendarDate = useCallback(args => {
        const { date } = args;
        setSelectedDateValue(date);
    }, []);

    // click calendar event
    const handleClickCalendarEvent = useCallback(
        arg => {
            const { view, event, jsEvent } = arg;
            const { data } = event?.extendedProps;
            if (view?.type === "timeGridWeek") {
                setIsTogglePopOver({
                    positionEl: { left: jsEvent?.clientX || 0, top: jsEvent?.clientY || 0 },
                    data,
                    type: 2,
                });
            } else if (view?.type === "dayGridMonth") {
                const targetID = event?.id;
                if (targetID && typeof targetID === "string" && !Number.isNaN(Number(targetID))) {
                    const targetSelectData = holidayEvents?.find(item => item?.id === Number(targetID));
                    const allWholeData = holidayEvents?.filter(item => item?.updatedAt === targetSelectData?.updatedAt);
                    setIsTogglePopOver({
                        positionEl: { left: jsEvent?.clientX || 0, top: jsEvent?.clientY || 0 },
                        data: allWholeData,
                        type: 1,
                    });
                }
            }
        },
        [holidayEvents],
    );

    // change date calendar
    const handlePickDateSchedule = useCallback(
        date => {
            if (refFullCalendar?.current !== null) {
                const calendarApi = refFullCalendar.current?.getApi();
                calendarApi.gotoDate(date);
            }
            handleSetCalendarUnit(date, calendarType);
            setSelectedDateValue(date);
        },
        [refFullCalendar, calendarType, handleSetCalendarUnit],
    );

    // open modal schedule popover
    const handleOpenModalSchedulePopover = useCallback(() => {
        setIsTogglePopOver(currentState => ({ ...currentState, positionEl: { left: 0, top: 0 } }));
        setModalViewDetail(true);
    }, []);

    // open modal holiday popover
    const handleOpenModalHolidayPopover = useCallback(() => {
        setTargetHoliday(currentState => [...currentState, ...isTogglePopOver?.data]);
        setIsTogglePopOver(currentState => ({ ...currentState, positionEl: { left: 0, top: 0 } }));
        setModalFormHolidays({ isOpen: true, mode: "Edit" });
    }, [isTogglePopOver, setModalFormHolidays, setTargetHoliday]);

    // close holiday popover
    const handleClosePopOverWeek = useCallback(() => {
        setIsTogglePopOver(currentState => ({
            ...currentState,
            positionEl: { left: 0, top: 0 },
        }));
        setTimeout(() => {
            setIsTogglePopOver(currentState => ({
                ...currentState,
                positionEl: { left: 0, top: 0 },
                type: 0,
            }));
        }, 300);
        setModalFormHolidays({ isOpen: false, mode: "Create" });
    }, [setModalFormHolidays]);

    // close modal view detail
    const handleCloseModalViewDetail = useCallback(() => {
        setModalViewDetail(false);
    }, []);

    // delete calendar event
    const handleDeleteEvent = useCallback(async () => {
        setIsTogglePopOver(currentState => ({ ...currentState, positionEl: { left: 0, top: 0 } }));
        const bool = await swalCondition("Delete Schedule?", "Do you want to delete?", {
            icon: "warning",
        });
        if (bool) {
            try {
                if (isTogglePopOver?.type === 1) {
                    const body = isTogglePopOver?.data?.map(item => item?.id);
                    const isDelete = await deleteHolidaysById(body, controllerRef.current?.signal);
                    if (isDelete) {
                        forceRenderWeek();
                        forceRenderMonth();
                    }
                }
                if (["0"].includes(isTogglePopOver?.data?.bookingTypeCode)) {
                    const listIDs = `${isTogglePopOver?.data?.schedulePrivateListId}`;
                    const isDelete = await deleteSchedulePrivateList({ listIDs }, controllerRef.current?.signal);
                    if (isDelete) {
                        forceRenderWeek();
                        forceRenderMonth();
                    }
                    if (["1", "2"].includes(isTogglePopOver?.data?.bookingTypeCode)) {
                        const listIDs = `${isTogglePopOver?.data?.scheduleGroupListId}`;
                        const isDelete = await deleteScheduleGroupList({ listIDs }, controllerRef.current?.signal);
                        if (isDelete) {
                            forceRenderWeek();
                            forceRenderMonth();
                        }
                    }
                }
            } catch (error) {
                console.dir(error);
            }
        }
    }, [isTogglePopOver, forceRenderWeek, forceRenderMonth]);

    // open filter popover
    const handleOpenFilter = useCallback(event => {
        setAnchorEl(event.currentTarget);
    }, []);

    // close filter popover
    const handleCloseFilter = useCallback(() => {
        setAnchorEl(null);
        setFilterTeacherTemp(filterTeacher);
        if (calendarType === "dayGridMonth") {
            forceRenderMonth();
        }
        if (calendarType === "timeGridWeek") {
            forceRenderWeek();
        }
    }, [filterTeacher, setFilterTeacherTemp, calendarType, forceRenderMonth, forceRenderWeek]);

    // apply filter
    const handleApplyFilter = useCallback(() => {
        setFilterTeacher(filterTeacherTemp);
        if (calendarType === "dayGridMonth") {
            forceRenderMonth();
        }
        if (calendarType === "timeGridWeek") {
            forceRenderWeek();
        }
    }, [calendarType, forceRenderMonth, forceRenderWeek, filterTeacherTemp, setFilterTeacher]);

    // reset filter
    const handleResetFilter = useCallback(() => {
        setFilterTeacher([]);
        setFilterTeacherTemp([]);
        if (calendarType === "dayGridMonth") {
            forceRenderMonth();
        }
        if (calendarType === "timeGridWeek") {
            forceRenderWeek();
        }
    }, [calendarType, forceRenderMonth, forceRenderWeek, setFilterTeacher, setFilterTeacherTemp]);

    useEffect(() => {
        if (refFullCalendar?.current !== null) {
            stateAgumentsModalPrintCalendar.setAgumentsModalPrintCalendar(prev => ({
                ...prev,
                element: containerCalendarRef.current,
                dateSelected: selectedDateValue,
            }));
            const calendarApi = refFullCalendar.current?.getApi();
            handleSetCalendarUnit(selectedDateValue, calendarType);
            calendarApi.gotoDate(moment(selectedDateValue).toDate());
            if (calendarType === "dayGridMonth") {
                calendarApi.select(moment(selectedDateValue).toDate());
                forceRenderMonth();
            }
            if (calendarType === "timeGridWeek") {
                forceRenderWeek();
            }
            setCalendarDateTitle(selectedDateValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDateValue, calendarType]);

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, []);

    return (
        <Fade in>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={8}>
                    <div className="bg-white" ref={containerCalendarRef}>
                        <div className="row justify-content-between mb-3">
                            <div className="col-6 col-md-3 order-1 order-md-0">
                                <ButtonGroup variant="outlined" aria-label="outlined button group">
                                    <Button
                                        onClick={() => handleChangeCalendarType("dayGridMonth")}
                                        className={`border ${calendarType === "dayGridMonth" ? "btn btn-light" : ""}`}>
                                        Months
                                    </Button>
                                    <Button
                                        onClick={() => handleChangeCalendarType("timeGridWeek")}
                                        className={`border ${calendarType === "timeGridWeek" ? "btn btn-light" : ""}`}>
                                        Weeks
                                    </Button>
                                </ButtonGroup>
                            </div>
                            <div className="col-12 col-md-7 order-0 order-md-1 d-flex justify-content-center">
                                <div ref={refInputDatePickMonthsYears} className="col-6 col-md-6">
                                    <div
                                        className="h3 d-flex justify-content-center align-items-center"
                                        onClick={() => {
                                            setToggleShowPickDate(prev => !prev);
                                        }}>
                                        <div className="flex-grow-1 text-center">
                                            {moment(calendarDateTitle).format("MMMM yyyy")}
                                        </div>
                                        <div>
                                            {!toggleShowPickDate ? (
                                                <KeyboardArrowDownRounded />
                                            ) : (
                                                <KeyboardArrowUpRounded />
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-none">
                                        <DatepickerApp
                                            open={toggleShowPickDate}
                                            size="small"
                                            value={selectedDateValue}
                                            views={["month", "year"]}
                                            format="MMMM yyyy"
                                            InputAdornmentProps={{ style: { display: "none" } }}
                                            inputProps={{
                                                className: `text-center h5 ${classes.noBorder}`,
                                            }}
                                            onChange={handlePickDateSchedule}
                                            onYearChange={() => {
                                                setToggleShowPickDate(false);
                                            }}
                                            PopoverProps={{
                                                anchorEl: refInputDatePickMonthsYears.current,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-2 d-flex justify-content-end order-2">
                                <div>
                                    <Button
                                        aria-describedby={id}
                                        onClick={handleOpenFilter}
                                        className="btn border mr-2">
                                        Filter
                                    </Button>
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleCloseFilter}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center",
                                        }}>
                                        <div className="p-5" style={{ width: 300 }}>
                                            <div className="row">
                                                <div className="col-12">
                                                    <h6>Filter</h6>
                                                    <hr />
                                                </div>
                                                <div className="col-12">
                                                    <div className="row justify-content-center">
                                                        <div className="col-10">
                                                            <h6>Teacher</h6>
                                                            <SelectSearchMultiApp
                                                                size="md"
                                                                options={scheduleOption?.teacher}
                                                                getOptionLabel={opt =>
                                                                    `${opt?.teacherFirstname} ${opt?.teacherLastname}`
                                                                }
                                                                getOptionValue={opt => opt?.id}
                                                                value={scheduleOption?.teacher.filter(value =>
                                                                    filterTeacherTemp.includes(value.id),
                                                                )}
                                                                onChange={value => {
                                                                    setFilterTeacherTemp(value.map(d => d?.id));
                                                                }}
                                                                isDisabled={scheduleOption?.teacher?.length < 1}
                                                                placeholder="Select"
                                                            />
                                                        </div>
                                                    </div>

                                                    <hr />
                                                </div>
                                                <div className="col-12">
                                                    <div className="row w-100">
                                                        <div className="col-6 d-flex justify-content-end">
                                                            <Button
                                                                onClick={handleResetFilter}
                                                                style={{ minWidth: 100 }}
                                                                className={`border ${
                                                                    calendarType === "timeGridWeek"
                                                                        ? "btn btn-light"
                                                                        : ""
                                                                }`}>
                                                                Reset
                                                            </Button>
                                                        </div>
                                                        <div className="col-6">
                                                            <Button
                                                                onClick={handleApplyFilter}
                                                                style={{ minWidth: 100 }}
                                                                className="t-btn-primary">
                                                                Apply
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Popover>
                                </div>
                                <ButtonGroup variant="outlined" aria-label="outlined button group">
                                    <Button onClick={() => handleClickPrevNextCalendar("prev")} className="border">
                                        <ArrowLeft />
                                    </Button>
                                    <Button onClick={() => handleClickPrevNextCalendar("next")} className="border">
                                        <ArrowRight />
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>

                        {/* calendar */}
                        <div className="schedule-view-months-app">
                            <FullCalendar
                                {...optionScheduleCalendar}
                                ref={refFullCalendar}
                                events={
                                    calendarType === "dayGridMonth"
                                        ? [...scheduleEventsMonth, ...holidayEvents]
                                        : scheduleEventsWeek
                                }
                                dayHeaderDidMount={arg => dayHeaderDidMount(arg, selectedDateValue)}
                                slotLabelDidMount={slotLabelDidMount}
                                dateClick={handleClickCalendarDate}
                                eventDidMount={RenderEventCalendar}
                                eventClick={handleClickCalendarEvent}
                                allDaySlot={false}
                                unselectAuto={false}
                            />
                        </div>
                    </div>

                    {/* start event popover */}
                    <Menu
                        open={Boolean(isTogglePopOver?.positionEl?.left !== 0)}
                        anchorPosition={isTogglePopOver?.positionEl}
                        anchorReference="anchorPosition"
                        onClose={handleClosePopOverWeek}>
                        <MenuList style={{ minWidth: 150 }} className="px-3">
                            <MenuItem
                                onClick={
                                    isTogglePopOver?.type === 1
                                        ? handleOpenModalHolidayPopover
                                        : handleOpenModalSchedulePopover
                                }>
                                {isTogglePopOver?.type === 1 ? "Edit" : "View"}
                            </MenuItem>
                            <MenuItem onClick={handleDeleteEvent}>Delete</MenuItem>
                        </MenuList>
                    </Menu>
                    {/* end event popover */}
                </Grid>
                <Grid item xs={12} lg={4}>
                    <ContentListClassroom
                        data={scheduleEventsMonth?.filter(
                            item =>
                                moment(item?.date).format("YYYY-MM-DD") ===
                                moment(selectedDateValue).format("YYYY-MM-DD"),
                        )}
                        valueDate={selectedDateValue}
                        handleOpenModalSchedule={handleOpenModalSchedule}
                        forceRenderMonth={forceRenderMonth}
                    />
                </Grid>

                {/* Modal */}
                <Grid item xs={12}>
                    <Modal show={modalViewDetail} centered scrollable size="xl" onHide={handleCloseModalViewDetail}>
                        <Modal.Header>
                            <Modal.Title>View</Modal.Title>
                            <IconButton onClick={handleCloseModalViewDetail}>
                                <Close />
                            </IconButton>
                        </Modal.Header>
                        <Modal.Body>
                            <ViewScheduleDetail scheduleDetail={isTogglePopOver?.data} />
                        </Modal.Body>
                    </Modal>
                </Grid>
                {/* End Modal */}
            </Grid>
        </Fade>
    );
}
