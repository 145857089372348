import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "classroom";

const GET_ALL_CLASSROOM_URL = `${prefix}/all`;
const CREATE_CLASSROOM_URL = `${prefix}/create`;
const UPDATE_CLASSROOM_BY_ID_URL = `${prefix}/update-by-id`;
const DELETE_CLASSROOM_BY_ID_URL = `${prefix}/delete-by-id`;

/**
 * @func getAllClassroom
 * @desc get all classroom data
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} classroom array
 */
export const getAllClassroom = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_ALL_CLASSROOM_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllClassroom");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getAllClassroom */

/**
 * @func createClassroom
 * @desc create new classroom
 * @author izeberg
 * @param {Object} body - create classroom body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} create status
 */
export const createClassroom = async (body, signal) => {
    try {
        const { status } = await axios.post(CREATE_CLASSROOM_URL, body, { signal });
        if (status === 201) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "createClassroom");
        if (err) throw err;
        return undefined;
    }
};
/** End @func createClassroom */

/**
 * @func updateClassroomById
 * @desc update classroom by id
 * @author izeberg
 * @param {number} id - classroom id
 * @param {Object} body - update classroom body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} update status
 */
export const updateClassroomById = async (id, body, signal) => {
    try {
        const { status } = await axios.put(`${UPDATE_CLASSROOM_BY_ID_URL}/${id}`, body, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "updateClassroomById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func updateClassroomById */

/**
 * @func deleteClassroomById
 * @desc delete classroom by id
 * @author izeberg
 * @param {number} id - classroom id
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} delete status
 */
export const deleteClassroomById = async (id, signal) => {
    try {
        const { status } = await axios.delete(`${DELETE_CLASSROOM_BY_ID_URL}/${id}`, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "deleteClassroomById");
        if (err) throw err;
        return undefined;
    }
};
/** End @func deleteClassroomById */
