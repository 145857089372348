import axios from "axios";
import { apiErrorHandler } from "./helpers/handler";

const prefix = "schedule";

const GET_ALL_SCHEDULE_PRIVATE_URL = `${prefix}/all-schedule-private`;
const GET_ALL_SCHEDULE_PRIVATE_URL_EXCEL = `${prefix}/all-schedule-private-export`;
const GET_SCHEDULE_GROUP_BY_TYPE_URL = `${prefix}/get-schedule-group-by-type`;
const GET_SCHEDULE_GROUP_BY_TYPE_URL_EXCEL = `${prefix}/get-schedule-group-by-type-export`;
const GET_SCHEDULE_BY_MONTH_URL = `${prefix}/get-by-month`;
const GET_SCHEDULE_BY_WEEK_URL = `${prefix}/get-by-week`;
const EXPORT_SCHEDULE_URL = `${prefix}/export`;
const GET_SCHEDULE_OPTION_URL = `${prefix}/get-option`;
const CREATE_SCHEDULE_PRIVATE_URL = `${prefix}/create-schedule-private`;
const CREATE_SCHEDULE_GROUP_URL = `${prefix}/create-schedule-group`;
const DELETE_SCHEDULE_PRIVATE_LIST_URL = `${prefix}/delete-schedule-private-list`;
const DELETE_SCHEDULE_GROUP_LIST_URL = `${prefix}/delete-schedule-group-list`;
const UPDATE_SCHEDULE_PRIVATE_URL = `${prefix}/update-schedule-private`;
const UPDATE_SCHEDULE_GROUP_URL = `${prefix}/update-schedule-group`;

/**
 * @func getAllScheduleListPrivate
 * @desc get all schedule private data
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} schedule private array
 */
export const getAllScheduleListPrivate = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_ALL_SCHEDULE_PRIVATE_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllSchedulePrivate");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getAllSchedulePrivate */

/**
 * @func getAllScheduleListPrivate
 * @desc get all schedule private data
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} schedule private array
 */
export const getAllScheduleListPrivateForExcel = async (body, signal) => {
    try {
        const { data, status } = await axios.post(GET_ALL_SCHEDULE_PRIVATE_URL_EXCEL, body, {
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getAllSchedulePrivate");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getAllSchedulePrivate */

/**
 * @func getScheduleGroupByType
 * @desc get schedule group by type data
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} schedule group array
 */
export const getScheduleGroupByType = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_SCHEDULE_GROUP_BY_TYPE_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getScheduleGroupByType");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getScheduleGroupByType */

/**
 * @func getScheduleGroupByType
 * @desc get schedule group by type data
 * @author march
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} schedule group array
 */
export const getScheduleGroupByTypeForExcel = async (body, signal) => {
    try {
        const { data, status } = await axios.post(GET_SCHEDULE_GROUP_BY_TYPE_URL_EXCEL, body, {
            signal,
        });
        if (status === 200) {
            return data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getScheduleGroupByType");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getScheduleGroupByType */

/**
 * @func getScheduleListByMonth
 * @desc get schedule list by month
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} schedule list by month
 */
export const getScheduleListByMonth = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_SCHEDULE_BY_MONTH_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getScheduleListByMonth");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getScheduleListByMonth */

/**
 * @func getScheduleListByWeek
 * @desc get schedule list by week
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} schedule list by week
 */
export const getScheduleListByWeek = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(GET_SCHEDULE_BY_WEEK_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getScheduleListByWeek");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getScheduleListByWeek */

/**
 * @func exportSchedule
 * @desc export schedule
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Array} schedule
 */
export const exportSchedule = async (queryParams, signal) => {
    try {
        const { data, status } = await axios.get(EXPORT_SCHEDULE_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "exportSchedule");
        if (err) throw err;
        return undefined;
    }
};
/** End @func exportSchedule */

/**
 * @func getScheduleOption
 * @desc get schedule option
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {Object} schedule option
 */
export const getScheduleOption = async signal => {
    try {
        const { data, status } = await axios.get(GET_SCHEDULE_OPTION_URL, {
            signal,
        });
        if (status === 200) {
            return data?.data;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "getScheduleOption");
        if (err) throw err;
        return undefined;
    }
};
/** End @func getScheduleOption */

/**
 * @func createSchedulePrivate
 * @desc create new schedule private
 * @author izeberg
 * @param {Object} body - create schedule private body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} create status
 */
export const createSchedulePrivate = async (body, signal) => {
    try {
        const { status } = await axios.post(CREATE_SCHEDULE_PRIVATE_URL, body, { signal });
        if (status === 201) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "createSchedulePrivate");
        if (err) throw err;
        return undefined;
    }
};
/** End @func createSchedulePrivate */

/**
 * @func createScheduleGroup
 * @desc create new schedule group
 * @author izeberg
 * @param {Object} body - create schedule group body
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} create status
 */
export const createScheduleGroup = async (body, signal) => {
    try {
        const { status } = await axios.post(CREATE_SCHEDULE_GROUP_URL, body, { signal });
        if (status === 201) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "createScheduleGroup");
        if (err) throw err;
        return undefined;
    }
};
/** End @func createScheduleGroup */

/**
 * @func deleteSchedulePrivateList
 * @desc delete schedule private list
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} delete status
 */
export const deleteSchedulePrivateList = async (queryParams, signal) => {
    try {
        const { status } = await axios.delete(DELETE_SCHEDULE_PRIVATE_LIST_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "deleteSchedulePrivateList");
        if (err) throw err;
        return undefined;
    }
};
/** End @func deleteSchedulePrivateList */

/**
 * @func deleteScheduleGroupList
 * @desc delete schedule group list
 * @author izeberg
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} delete status
 */
export const deleteScheduleGroupList = async (queryParams, signal) => {
    try {
        const { status } = await axios.delete(DELETE_SCHEDULE_GROUP_LIST_URL, {
            params: queryParams,
            signal,
        });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "deleteScheduleGroupList");
        if (err) throw err;
        return undefined;
    }
};
/** End @func deleteScheduleGroupList */

/**
 * @func updateSchedulePrivate
 * @desc update schedule private list
 * @author march
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} update schedule
 */
export const updateSchedulePrivate = async (body, signal) => {
    try {
        const { status } = await axios.put(UPDATE_SCHEDULE_PRIVATE_URL, body, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "updateSchedulePrivate");
        if (err) throw err;
        return undefined;
    }
};
/** End @func updateSchedulePrivate */

/**
 * @func updateScheduleGroup
 * @desc update schedule private list
 * @author march
 * @param {AbortSignal} signal - AbortController Signal
 * @returns {boolean|undefined} update schedule
 */
export const updateScheduleGroup = async (body, signal) => {
    try {
        const { status } = await axios.put(UPDATE_SCHEDULE_GROUP_URL, body, { signal });
        if (status === 200) {
            return true;
        }
        return undefined;
    } catch (error) {
        const err = apiErrorHandler(error, "updateScheduleGroup");
        if (err) throw err;
        return undefined;
    }
};
/** End @func updateScheduleGroup */
