import React, { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Button, List, ListItem } from "@material-ui/core";
import moment from "moment";

import { invertColor } from "../../../utils/invertColor";
import { colourNameToHex } from "../../../utils/colourNameToHex";

// set page
const paginate = 4;

export default function PrintCalendar({
    // base64Img = "",
    dateSelected = new Date(),
    listClassroom = [],
    onError = () => {},
    onAfterPrint = () => {},
    onBeforePrint = () => {},
    autoPrint = true,
}) {
    const refContentPage = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => refContentPage?.current,
        documentTitle: `sweetcottage_${new Date().getTime()}.pdf`,
        bodyClass: "p-20 print-custom h-100 w-100",
        onAfterPrint: () => {
            onAfterPrint();
        },
        onBeforePrint: () => {
            onBeforePrint();
        },
        onPrintError: onError,
        removeAfterPrint: true,
    });

    useEffect(() => {
        if (refContentPage.current !== null && autoPrint) {
            handlePrint();
        }
    }, [autoPrint, refContentPage, handlePrint]);

    return (
        <>
            <div ref={refContentPage}>
                {[...Array(Math.ceil(listClassroom.length / paginate) || 1).keys()].map(numPage => {
                    return (
                        <div key={`list_${numPage}`} className={`row p-20 ${numPage > 0 ? "pages" : ""}`}>
                            <div className="col-12 mb-10">
                                <h2 className="text-black-50 text-right">
                                    {moment(dateSelected || new Date()).format("ddd, DD MMM YYYY")}
                                </h2>
                            </div>
                            {/* <div className="col-12 mb-20 box-image">
                                <img src={base64Img || ""} className="img-fluid w-100" alt="calendar" />
                            </div> */}
                            <div className="col-12">
                                <div className="box-show-list">
                                    <List className="d-flex flex-wrap" style={{ marginLeft: -24, marginRight: -24 }}>
                                        {listClassroom.length > 0
                                            ? listClassroom
                                                  .slice(numPage * paginate, (numPage + 1) * paginate)
                                                  .map((item, idx) => {
                                                      const textColor = invertColor(colourNameToHex(item?.color), true);
                                                      return (
                                                          <ListItem key={`list_classroom_${idx}`} className="w-50 px-6">
                                                              <div
                                                                  className="rounded w-100 mb-3 card-item-calendar-app position-relative p-5"
                                                                  style={{
                                                                      backgroundColor: item?.color,
                                                                      color: textColor,
                                                                  }}>
                                                                  {item?.roomName !== null && (
                                                                      <div
                                                                          className="d-flex align-items-center mb-1"
                                                                          style={{ color: textColor }}>
                                                                          <span className="mr-2 col-4">
                                                                              Room Name :{" "}
                                                                          </span>
                                                                          <p className="mb-0 flex-fill">
                                                                              {item?.roomName}
                                                                          </p>
                                                                      </div>
                                                                  )}
                                                                  <div
                                                                      className="d-flex align-items-center mb-1"
                                                                      style={{ color: textColor }}>
                                                                      <span className="mr-2 col-4">Room : </span>
                                                                      <p className="mb-0 flex-fill">
                                                                          {item?.classroomName}
                                                                      </p>
                                                                  </div>
                                                                  <div
                                                                      className="d-flex align-items-center mb-1"
                                                                      style={{ color: textColor }}>
                                                                      <span className="mr-2 col-4">Time : </span>
                                                                      <p className="mb-0 flex-fill">{item?.time}</p>
                                                                  </div>
                                                                  {item?.student && (
                                                                      <div
                                                                          className="d-flex align-items-center mb-1"
                                                                          style={{ color: textColor }}>
                                                                          <span className="mr-2 col-4">Student : </span>
                                                                          <p className="mb-0 flex-fill">
                                                                              {item?.student}
                                                                          </p>
                                                                      </div>
                                                                  )}
                                                                  <div
                                                                      className="d-flex align-items-center mb-1"
                                                                      style={{ color: textColor }}>
                                                                      <span className="mr-2 col-4">Type : </span>
                                                                      <p className="mb-0 flex-fill">{item?.type}</p>
                                                                  </div>
                                                                  {typeof item?.menu !== "string" &&
                                                                  typeof item?.teacher !== "string" ? (
                                                                      <>
                                                                          <div
                                                                              className="d-flex align-items-center mb-1"
                                                                              style={{ color: textColor }}>
                                                                              <span className="mr-2 col-4">
                                                                                  Menu :{" "}
                                                                              </span>
                                                                              <p className="mb-0 flex-fill" />
                                                                          </div>
                                                                          {item?.menu?.map((menuData, index) => (
                                                                              <div
                                                                                  key={index}
                                                                                  className="d-flex align-items-center">
                                                                                  <p className="mb-1 flex-fill col-12 ml-5">
                                                                                      {`${index + 1}. ${menuData} - ${
                                                                                          item?.teacher[index]
                                                                                              ? `${item?.teacher[index]}`
                                                                                              : item?.teacher[
                                                                                                    item?.teacher
                                                                                                        ?.length - 1
                                                                                                ]
                                                                                      }`}
                                                                                  </p>
                                                                              </div>
                                                                          ))}
                                                                      </>
                                                                  ) : (
                                                                      <>
                                                                          <div
                                                                              className="d-flex align-items-center mb-1"
                                                                              style={{ color: textColor }}>
                                                                              <span className="mr-2 col-4">
                                                                                  Menu :{" "}
                                                                              </span>
                                                                              <p className="mb-0 flex-fill">
                                                                                  {item?.menu}
                                                                              </p>
                                                                          </div>
                                                                          <div
                                                                              className="d-flex align-items-center mb-1"
                                                                              style={{ color: textColor }}>
                                                                              <span className="mr-2 col-4">
                                                                                  Teacher :{" "}
                                                                              </span>
                                                                              <p className="mb-0 flex-fill">
                                                                                  {item?.teacher}
                                                                              </p>
                                                                          </div>
                                                                      </>
                                                                  )}
                                                                  {item?.type !== "Private" && (
                                                                      <div
                                                                          className="d-flex align-items-center mb-1"
                                                                          style={{ color: textColor }}>
                                                                          <span className="mr-2 col-4">
                                                                              Student Qty :{" "}
                                                                          </span>
                                                                          <p className="mb-0 flex-fill">
                                                                              {item?.studentQty}
                                                                          </p>
                                                                      </div>
                                                                  )}
                                                                  <div
                                                                      className="d-flex align-items-center mb-1"
                                                                      style={{ color: textColor }}>
                                                                      <span className="mr-2 col-4">Note : </span>
                                                                      <p className="mb-0 flex-fill">{item?.note}</p>
                                                                  </div>
                                                              </div>
                                                          </ListItem>
                                                      );
                                                  })
                                            : null}
                                    </List>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="card-footer">
                <div className="d-flex justify-content-end">
                    <Button
                        onClick={handlePrint}
                        size="large"
                        className="btn mr-1 flex-fill flex-md-grow-0 t-btn-primary px-5">
                        Print
                    </Button>
                </div>
            </div>
        </>
    );
}
