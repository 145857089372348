import moment from "moment";

/**
 * Start
 * @func toImageUrl
 * @desc concat imagePath with api host
 * @author izeberg
 * @param {string} imagePath - ชื่อไฟล์รูป (*.jpg))
 * @returns {string} http://localhost:8080/assets/images/{{folderPath}}/{{imagePath}}
 */
export const toImageUrl = imagePath => {
    return `${process.env.REACT_APP_UPLOAD_HOST}/${process.env.REACT_APP_IMAGE_PATH}/${imagePath}`;
};
/** End @func toImageUrl */

/**
 * Start
 * @func convertDateTimeFromApi
 * @desc convert date time to thailand format
 * @author march
 * @param {string} dateTime - วันและเวลา (2023-03-05) (2023-04-03 16:46:59) (2023-04-10T12:59:47+07:00) (2023-03-16T15:56:51.782+07:00) (2022-08-19T00:00:00+07:00)
 * @returns {string} 25-08-2566 12:55
 */
export const convertDateTimeFromApi = inputTime => {
    if (typeof inputTime !== "string") {
        return "";
    }
    const dateTime = new Date(inputTime);
    if (Number.isNaN(dateTime)) {
        return "";
    }
    const hasTime = inputTime?.includes(":");
    const dateOptions = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    };
    const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
    };

    // thai version
    // const date = dateTime
    //     ?.toLocaleDateString("th-TH-u-ca-buddhist", dateOptions)
    //     ?.replace(/พ\.ศ\. /, "") // remove "พ.ศ." and its space
    //     ?.replace(/\//g, "-"); // replace slashes with dashes globally

    const date = dateTime?.toLocaleDateString("en-GB", dateOptions);
    const time = hasTime ? dateTime?.toLocaleTimeString("en-GB", timeOptions) : "";
    return time === "00:00:00" ? date : `${date} ${time}`;
};
/* End @func convertDateTimeFromApi */

/**
 * Start
 * @func convertScheduleDate
 * @desc convert date time to thailand format
 * @author march
 * @param {string} dateTime - วันและเวลา (2023-03-05) (2023-04-03 16:46:59) (2023-04-10T12:59:47+07:00) (2023-03-16T15:56:51.782+07:00) (2022-08-19T00:00:00+07:00)
 * @returns {string} 25/08/2023
 */
export const convertScheduleDate = inputTime => {
    if (typeof inputTime !== "string") {
        return "";
    }
    const momentDate = moment.utc(inputTime);

    // Format the date as "DD/MM/YYYY"
    const formattedDate = momentDate.format("DD/MM/YYYY");
    return formattedDate || "";
};

/* End @func convertScheduleDates */
