import { Avatar, Box, Grid, ListItem, ListItemText } from "@material-ui/core";
import { AccountBox, InsertDriveFileOutlined } from "@material-ui/icons";
import MaterialTable from "material-table";
import React from "react";
import { toImageUrl } from "../../../utils/format";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { getOptionsTableApp } from "../../helpers/useOption";

export default function ViewScheduleDetail({ scheduleDetail = {} }) {
    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <div className="row mb-4">
                    <div className="col-12 col-md-5">
                        <div>Room Name</div>
                    </div>
                    <div className="col-12 col-md-7">
                        <div>{scheduleDetail?.bookingRoomName}</div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12 col-md-5">
                        <div>Room Type</div>
                    </div>
                    <div className="col-12 col-md-7">
                        <div>{scheduleDetail?.bookingType}</div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12 col-md-5">
                        <div>Room</div>
                    </div>
                    <div className="col-12 col-md-7">
                        <div>{scheduleDetail?.bookingClassroom}</div>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-12 col-md-5">
                        <div>Date & Time</div>
                    </div>
                    <div className="col-12 col-md-7">
                        <div>
                            {scheduleDetail?.bookingDate} {scheduleDetail?.bookingTime}
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-12">
                        <div className="h4 mb-3 text-black-50">
                            <AccountBox style={{ width: 30, height: 30 }} /> Students
                        </div>
                    </div>
                    <div className="col-12">
                        {(scheduleDetail?.ScheduleGroupListHasStudents || [scheduleDetail?.SchedulePrivate])?.map(
                            (item, idx) => (
                                <div key={`list_student_view_${idx}`} className="d-flex align-items-center mb-3">
                                    <div style={{ flex: 1 }}>
                                        <ListItem className="border rounded">
                                            <Avatar
                                                src={
                                                    item?.Student?.studentImagePath
                                                        ? toImageUrl(item?.Student?.studentImagePath)
                                                        : toAbsoluteUrl("../..//media/users/blank.png")
                                                }
                                                alt="user"
                                                className="mr-2"
                                            />
                                            <ListItemText
                                                primary={`${item?.Student?.studentFirstname || ""} ${item?.Student
                                                    ?.studentLastname || ""} (${item?.Student?.studentNickname || ""})`}
                                            />
                                        </ListItem>
                                    </div>
                                    {/* <Button size="large" className='btn btn-danger ml-3 h-100 py-3'><Close/></Button> */}
                                </div>
                            ),
                        )}
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className="row">
                    <div className="col-12">
                        <div className="h4 mb-3 text-black-50">
                            <InsertDriveFileOutlined style={{ width: 30, height: 30 }} /> Course/Menu
                        </div>
                        <div className="col-12">
                            <div className="t-table-custom table-responsive">
                                <MaterialTable
                                    components={{ Container: Box }}
                                    data={(
                                        scheduleDetail?.ScheduleGroupListHasMenus || [
                                            {
                                                CourseMenu: scheduleDetail?.CourseMenu,
                                                Teacher: scheduleDetail?.Teacher,
                                            },
                                        ]
                                    )?.map(item => ({
                                        name: item?.CourseMenu?.courseMenuName,
                                        teacher: `${item?.Teacher?.teacherFirstname || ""} ${item?.Teacher
                                            ?.teacherLastname || ""} (${item?.Teacher?.teacherNickname || ""})`,
                                    }))}
                                    options={{ ...getOptionsTableApp, paging: false }}
                                    columns={[
                                        {
                                            field: "index",
                                            title: "#",
                                            sorting: false,
                                            render: data => {
                                                return data?.tableData?.id + 1;
                                            },
                                        },
                                        { field: "name", title: "Menu" },
                                        { field: "teacher", title: "Teacher" },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}
