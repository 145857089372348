/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import objectPath from "object-path";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Skeleton } from "@material-ui/lab";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
// import { AsideSearch } from "./AsideSearch";
import { AsideMenu } from "./aside-menu/AsideMenu";
// import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuickUserToggler";
import { Brand } from "../brand/Brand";
import { KTUtil } from "../../../_assets/js/components/util";
import { swalCondition } from "../../../../utils/swal";
import KTLayoutAsideToggle from "../../../_assets/js/layout/base/aside-toggle";
import { mainMenuActions } from "../../../../redux/slices/mainMenuSlice";
import { getAllSideMenu, getSideMenuByPermission } from "../../../../apis/sideMenu";
import { useOnClickOutside } from "../../../../utils/useOnClickOutside";
import { axiosAuthInstance } from "../../../../axios/auth.axios";

export function Aside() {
    const uiService = useHtmlClassService();
    const location = useLocation();
    const history = useHistory();
    const { mainMenu, subMemuLV1, subMemuLV2, isLoad } = useSelector(state => state.mainMenu);
    const dispatch = useDispatch();

    const refKtAside = useRef(null);
    useOnClickOutside(refKtAside, async event => {
        const toggleAside = await KTLayoutAsideToggle?.getToggle();
        if (toggleAside) {
            if (toggleAside.getState() === "on") return false;
            if (
                !refKtAside.current.contains(event.target) &&
                !document.getElementById("kt_quick_user").contains(event?.target)
            ) {
                toggleAside.toggleOn(); // off aside
            }
        }
    });

    const asideWorkspace = KTUtil.find(document.getElementById("kt_aside"), ".aside-secondary .aside-workspace");
    // const btnToggleAsideSecondary = useCallback(() =>
    //     KTUtil.find(document.getElementById("kt_aside"), "#kt_aside_toggle"),
    // );
    const layoutProps = useMemo(() => {
        return {
            asideClassesFromConfig: uiService.getClasses("aside", true),
            asideSecondaryDisplay: objectPath.get(uiService.config, "aside.secondary.display"),
            asideSelfMinimizeToggle: objectPath.get(uiService.config, "aside.self.minimize.toggle"),
            extrasSearchDisplay: objectPath.get(uiService.config, "extras.search.display"),
            extrasNotificationsDisplay: objectPath.get(uiService.config, "extras.notifications.display"),
            extrasQuickActionsDisplay: objectPath.get(uiService.config, "extras.quick-actions.display"),
            extrasQuickPanelDisplay: objectPath.get(uiService.config, "extras.quick-panel.display"),
            extrasLanguagesDisplay: objectPath.get(uiService.config, "extras.languages.display"),
            extrasUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
        };
    }, [uiService]);

    const tabs = {
        tabId1: "kt_aside_tab_1",
        tabId2: "kt_aside_tab_2",
    };

    // ref
    const controllerRef = useRef(null);

    // const [activeTab, setActiveTab] = useState(tabs.tabId2);
    const [activeMainMenu, setActiveMainMenu] = useState([]);

    const handleTabChange = async obj => {
        // console.log(subMemuLV2);
        // console.log(obj);
        // console.log(mainMenu, subMemuLV1, subMemuLV2);
        const newSubLv1 = subMemuLV1.filter(row => row?.sidebarMenuMainIndex === obj?.sidebarMenuMainIndex);
        const arraylistMenu = newSubLv1.map(row => {
            return {
                main: obj,
                sublv1: row,
                sublv2: subMemuLV2.filter(
                    row2 =>
                        row2?.sidebarMenuSubIndex === row?.sidebarMenuSubIndex &&
                        row2?.sidebarMenuMainIndex === row?.sidebarMenuMainIndex,
                ),
            };
        });
        const toggleAside = await KTLayoutAsideToggle?.getToggle();
        // const asideWorkspace = KTUtil.find(
        //     document.getElementById("kt_aside"),
        //     ".aside-secondary .aside-workspace",
        // );
        if (asideWorkspace) {
            KTUtil.scrollDestroy(asideWorkspace);
        }
        if (arraylistMenu.length < 1) {
            setActiveMainMenu(arraylistMenu);
            toggleAside.toggleOn(); // off aside
            history.replace(`/${obj?.sidebarMenuPath}`);
            KTUtil.addClass(KTUtil.find(document.getElementById("kt_aside"), "#kt_aside_toggle"), "d-none");
        } else {
            setActiveMainMenu(arraylistMenu);
            toggleAside.toggleOff(); // On aside
            KTUtil.removeClass(KTUtil.find(document.getElementById("kt_aside"), "#kt_aside_toggle"), "d-none");
        }
    };

    const fetchAllMenu = (fetchmainMenu = [], fetchsubMemuLV1 = [], fetchsubMemuLV2 = []) => {
        // console.log(fetchmainMenu, fetchsubMemuLV1, fetchsubMemuLV2);
        let newArrMenu = [];
        newArrMenu = fetchmainMenu.map(rowMain => {
            return {
                main: rowMain,
                subMenu1: fetchsubMemuLV1.filter(
                    rowSub1 => rowSub1?.sidebarMenuMainIndex === rowMain?.sidebarMenuMainIndex,
                ),
                subMenu2: fetchsubMemuLV2.filter(
                    rowSub2 => rowSub2.sidebarMenuMainIndex === rowMain?.sidebarMenuMainIndex,
                ),
            };
        });
        return newArrMenu;
    };

    const setDismountPath = async (obj, subLv1, subLv2) => {
        const newSubLv1 = subLv1.filter(row => row?.sidebarMenuMainIndex === obj?.sidebarMenuMainIndex);
        const arraylistMenu = newSubLv1.map(row => {
            return {
                main: obj,
                sublv1: row,
                sublv2: subLv2.filter(
                    row2 =>
                        row2?.sidebarMenuSubIndex === row?.sidebarMenuSubIndex &&
                        row2?.sidebarMenuMainIndex === obj?.sidebarMenuMainIndex,
                ),
            };
        });
        setActiveMainMenu(arraylistMenu);
    };

    const handlerToggleAside = () => {
        if (KTUtil.find(document.getElementById("kt_aside"), ".aside-secondary .aside-workspace")) {
            KTUtil.scrollDestroy(KTUtil.find(document.getElementById("kt_aside"), ".aside-secondary .aside-workspace"));
        }
    };

    const setMenuPermission = useCallback(
        menuRes => {
            const mainMenus = menuRes?.mainMenus?.map(main => ({
                path: `/${main?.sidebarMenuPath}`,
                permission: main?.permission,
            }));
            const sub1Menus = menuRes?.subMenus?.map(sub1 => ({
                path: `/${
                    menuRes?.mainMenus?.find(main => main?.sidebarMenuMainIndex === sub1?.sidebarMenuMainIndex)
                        ?.sidebarMenuPath
                }/${sub1?.sidebarMenuPath}`,
                permission: sub1?.permission,
            }));
            const sub2Menus = menuRes?.sub2Menus?.map(sub2 => ({
                path: `/${
                    menuRes?.mainMenus?.find(main => main?.sidebarMenuMainIndex === sub2.sidebarMenuMainIndex)
                        ?.sidebarMenuPath
                }/${
                    menuRes?.subMenus?.find(
                        sub1 =>
                            sub1?.sidebarMenuMainIndex === sub2.sidebarMenuMainIndex &&
                            sub1?.sidebarMenuSubIndex === sub2.sidebarMenuSubIndex,
                    )?.sidebarMenuPath
                }/${sub2?.sidebarMenuPath}`,
                permission: sub2?.permission,
            }));
            dispatch(mainMenuActions.setMenuPermission([...mainMenus, ...sub1Menus, ...sub2Menus]));
        },
        [dispatch],
    );

    useEffect(() => {
        KTUtil.addClass(KTUtil.find(document.getElementById("kt_aside"), "#kt_aside_toggle"), "d-none");

        controllerRef.current = new AbortController();

        const fetchMenu = async () => {
            const res = await getSideMenuByPermission(controllerRef.current?.signal);
            if (res) {
                setMenuPermission(res);
                dispatch(mainMenuActions.setMenuFilter(fetchAllMenu(res?.mainMenus, res?.subMenus, res?.sub2Menus)));
                dispatch(mainMenuActions.setMainMenu(res?.mainMenus));
                dispatch(mainMenuActions.setSubMemuLV1(res?.subMenus));
                dispatch(mainMenuActions.setSubMemuLV2(res?.sub2Menus));
                dispatch(mainMenuActions.setIsLoad(true));
                const mainPath = res?.mainMenus[0];
                const subPath = res?.subMenus?.find(
                    sub =>
                        sub?.sidebarMenuMainIndex === mainPath?.sidebarMenuMainIndex && sub?.sidebarMenuSub2Index === 0,
                );
                const path = res?.sub2Menus?.find(
                    path =>
                        path?.sidebarMenuSubIndex === subPath?.sidebarMenuSubIndex && path?.sidebarMenuSub2Index === 0,
                );
                const redirectPath = `/${mainPath?.sidebarMenuPath}${
                    subPath?.sidebarMenuPath ? `/${subPath?.sidebarMenuPath}` : ""
                }${path?.sidebarMenuPath ? `/${path?.sidebarMenuPath}` : ""}`;
                dispatch(mainMenuActions.setRedirectPath(redirectPath));
            }
        };

        const fetchMenuForRolePermission = async () => {
            const res = await getAllSideMenu(controllerRef.current?.signal);
            if (res) {
                dispatch(
                    mainMenuActions.setMenuForRolePermission(
                        fetchAllMenu(res?.mainMenus, res?.subMenus, res?.sub2Menus),
                    ),
                );
            }
        };

        fetchMenu();
        fetchMenuForRolePermission();

        return () => {
            controllerRef.current.abort();
        };
    }, [dispatch, setMenuPermission]);

    useEffect(() => {
        if (isLoad) {
            const arrayPath = location.pathname.split("/"); // sidebarMenuPath
            const objMainMenu = mainMenu.filter(row => row.sidebarMenuPath === arrayPath[1]);
            setDismountPath(objMainMenu[0], subMemuLV1, subMemuLV2);
            if (arrayPath.length > 2) {
                KTUtil.removeClass(KTUtil.find(document.getElementById("kt_aside"), "#kt_aside_toggle"), "d-none");
            }
        }
    }, [isLoad, location.pathname, mainMenu, subMemuLV1, subMemuLV2]);

    return (
        <>
            {/* begin::Aside */}
            <div
                id="kt_aside"
                ref={refKtAside}
                className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}>
                {/* begin::Primary */}
                <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
                    <Brand />
                    {/* begin::Nav Wrapper */}
                    <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull">
                        {/* begin::Nav */}
                        {mainMenu.length > 0 ? (
                            <ul
                                className="list-unstyled flex-column d-flex"
                                style={{ flex: "1 1 auto" }}
                                role="tablist">
                                {/* begin::Item */}
                                {mainMenu.map((obj, idx) => (
                                    <li
                                        key={`main_${idx}`}
                                        className="nav-item mb-3"
                                        data-toggle="tooltip"
                                        data-placement="rigth"
                                        data-container="body"
                                        data-boundary="window"
                                        title={obj?.sidebarMenuName}>
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={<Tooltip id="latest-project">{obj?.sidebarMenuName}</Tooltip>}>
                                            <a
                                                // href="#"
                                                className="nav-link btn btn-icon btn-clean btn-lg"
                                                onClick={() => handleTabChange(obj)}
                                                role="tab">
                                                <span className="svg-icon svg-icon-lg">
                                                    {/* <SVG src={toAbsoluteUrl(`${obj?.sidebarMenuIcon}`)} /> */}
                                                    <i className={obj?.sidebarMenuIcon} />
                                                </span>
                                            </a>
                                        </OverlayTrigger>
                                    </li>
                                ))}
                                {/* end::Item */}
                            </ul>
                        ) : (
                            <Skeleton width="calc(1.5em + 1.65rem + 2px)" height="100%" />
                        )}

                        {/* end::Nav */}

                        {/* begin::Auth-Logout */}
                        <ul className="list-unstyled flex-column" role="tablist">
                            {/* begin::Item */}
                            <li>{layoutProps.extrasUserDisplay && <QuickUserToggler />}</li>
                            {/* end::Item */}
                            {/* begin::Item */}
                            <li
                                className="nav-item mb-3"
                                data-toggle="tooltip"
                                data-placement="rigth"
                                data-container="body"
                                data-boundary="window"
                                title="Latest Project">
                                <OverlayTrigger
                                    placement="right"
                                    overlay={<Tooltip id="latest-project">Logout</Tooltip>}>
                                    <a
                                        className="nav-link btn btn-icon btn-clean btn-lg"
                                        role="tab"
                                        onClick={async () => {
                                            const isConfrimed = await swalCondition(
                                                "",
                                                "Are you sure you want to logout ?",
                                                {
                                                    icon: "warning",
                                                },
                                            );
                                            if (isConfrimed) {
                                                await axiosAuthInstance.get("web-refresh-access/logout");
                                                localStorage.clear();
                                                window.location.reload();
                                            }
                                        }}>
                                        <span className="svg-icon svg-icon-lg">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Sign-out.svg")} />
                                        </span>
                                    </a>
                                </OverlayTrigger>
                            </li>
                            {/* end::Item */}
                        </ul>
                        {/* end::Auth-Logout */}
                    </div>
                    {/* end::Nav Wrapper */}

                    {/* begin::Footer */}
                    <div className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
                        {/* begin::Aside Toggle */}
                        {layoutProps.asideSecondaryDisplay && layoutProps.asideSelfMinimizeToggle && (
                            <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip id="toggle-aside">Toggle Aside</Tooltip>}>
                                <span
                                    onClick={handlerToggleAside}
                                    className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
                                    id="kt_aside_toggle">
                                    <i className="ki ki-bold-arrow-back icon-sm" />
                                </span>
                            </OverlayTrigger>
                        )}
                        {/* end::Aside Toggle */}

                        {/* begin::Search */}
                        {/* {layoutProps.extrasSearchDisplay && (
                              <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip id="toggle-search">Quick Search</Tooltip>}>
                                  <a href="#" className="btn btn-icon btn-clean btn-lg mb-1" id="kt_quick_search_toggle">
                                      <span className="svg-icon svg-icon-lg">
                                          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
                                      </span>
                                  </a>
                              </OverlayTrigger>
                          )} */}
                        {/* end::Search */}

                        {/* begin::Notifications */}
                        {/* {layoutProps.extrasNotificationsDisplay && (
                              <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip id="toggle-notifications">Notifications</Tooltip>}>
                                  <a
                                      href="#"
                                      className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
                                      id="kt_quick_notifications_toggle"
                                      data-placement="right"
                                      data-container="body"
                                      data-boundary="window">
                                      <span className="svg-icon svg-icon-lg">
                                          <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                                      </span>
                                  </a>
                              </OverlayTrigger>
                          )} */}
                        {/* end::Notifications */}

                        {/* begin::Quick Actions */}
                        {/* {layoutProps.extrasQuickActionsDisplay && (
                              <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip id="toggle-quick-actions">Quick Actions</Tooltip>}>
                                  <a href="#" className="btn btn-icon btn-clean btn-lg mb-1" id="kt_quick_actions_toggle">
                                      <span className="svg-icon svg-icon-lg">
                                          <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")} />
                                      </span>
                                  </a>
                              </OverlayTrigger>
                          )} */}
                        {/* end::Quick Actions */}

                        {/* begin::Quick Panel */}
                        {/* {layoutProps.extrasQuickPanelDisplay && (
                              <OverlayTrigger
                                  placement="right"
                                  overlay={<Tooltip id="toggle-quick-panel">Quick Panel</Tooltip>}>
                                  <a
                                      href="#"
                                      className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
                                      id="kt_quick_panel_toggle"
                                      data-placement="right"
                                      data-container="body"
                                      data-boundary="window">
                                      <span className="svg-icon svg-icon-lg">
                                          <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")} />
                                      </span>
                                      <span className="label label-sm label-light-danger label-rounded font-weight-bolder position-absolute top-0 right-0 mt-1 mr-1">
                                          3
                                      </span>
                                  </a>
                              </OverlayTrigger>
                          )} */}
                        {/* end::Quick Panel */}

                        {/* begin::Languages */}
                        {/* {layoutProps.extrasLanguagesDisplay && <LanguageSelectorDropdown />} */}
                        {/* end::Languages */}

                        {/* begin::User */}
                        {/* {layoutProps.extrasUserDisplay && <QuickUserToggler />} */}
                        {/* end::User */}
                    </div>
                    {/* end::Footer */}
                </div>
                {/* end::Primary */}

                {layoutProps.asideSecondaryDisplay && (
                    <>
                        {/* begin::Secondary */}
                        <div className="aside-secondary d-flex flex-row-fluid">
                            {/* begin::Workspace */}
                            <div className="aside-workspace scroll scroll-push my-2">
                                <div className="tab-content">
                                    {/* <AsideSearch isActive={activeTab === tabs.tabId1} /> */}
                                    {/* <AsideMenu isActive={activeTab === tabs.tabId2} listMenu={activeMainMenu} /> */}
                                    <AsideMenu isActive={tabs.tabId2 === "kt_aside_tab_2"} listMenu={activeMainMenu} />
                                </div>
                            </div>
                            {/* end::Workspace */}
                        </div>
                        {/* end::Secondary */}
                    </>
                )}
            </div>
            {/* end::Aside */}
        </>
    );
}
