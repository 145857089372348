import { FormHelperText } from "@material-ui/core";
import React from "react";

// export default function TextareaApp(/** @type React.TextareaHTMLAttributes */ props) {
export default function TextareaApp({
    errorMessage,
    props = {
        /** @type React.TextareaHTMLAttributes */
        textAreaProps: {},
    },
}) {
    return (
        <div>
            <textarea
                {...props?.textAreaProps}
                className={`form-control form-control-textarea ${Boolean(errorMessage) ? "in-valid" : ""} ${
                    props?.textAreaProps?.className ? props?.textAreaProps?.className : ""
                }`}
            />
            {Boolean(errorMessage) ? <FormHelperText className="text-danger"> {errorMessage} </FormHelperText> : null}
        </div>
    );
}
