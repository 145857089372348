import { Button, FormLabel, Grid } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { updateStudentNoteById } from "../../../apis/studentApi";
import TextareaApp from "../../componentsUtils/TextareaApp";
import { swalCondition } from "../../../utils/swal";

export default function ContentNote({ studentId, controllerRef, setRender, note }) {
    // variable or state
    const [valueNote, setValueNote] = useState(note || "");

    // change note
    const handleChangeNote = useCallback(event => {
        setValueNote(event.target.value);
    }, []);

    // submit note
    const handleOnSubmit = useCallback(async () => {
        try {
            const res = await updateStudentNoteById(studentId, { note: valueNote }, controllerRef?.current?.signal);
            if (res) {
                const isConfirm = await swalCondition("Success", "Update Note Successful.", {
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonText: "OK",
                });
                if (isConfirm) {
                    setRender();
                }
            }
        } catch (error) {
            console.dir(error);
        }
    }, [valueNote, studentId, controllerRef, setRender]);

    return (
        <Grid container spacing={2} className="my-10">
            <Grid item xs={12} sm={8}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={2} md={2}>
                        <FormLabel>Note :</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={10} md={8}>
                        <TextareaApp
                            props={{
                                textAreaProps: {
                                    value: valueNote,
                                    rows: 5,
                                    onChange: handleChangeNote,
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <div className="h-100 d-flex align-items-end justify-content-sm-end">
                    <Button onClick={handleOnSubmit} className="btn t-btn-secondary">
                        Submit
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
}
