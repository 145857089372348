export const getBasicOfBakingName = value => {
    if (value === "1") {
        return "Never bake before";
    }
    if (value === "2") {
        return "Beginner";
    }
    if (value === "3") {
        return "Intermediate";
    }
    if (value === "4") {
        return "Expert";
    }
    return "";
};

export const getToKnowFromName = value => {
    if (value === "1") {
        return "Facebook";
    }
    if (value === "2") {
        return "Google";
    }
    if (value === "3") {
        return "Line";
    }
    if (value === "4") {
        return "Tiktok";
    }
    if (value === "5") {
        return "Youtube";
    }
    if (value === "6") {
        return "เพื่อนแนะนำ";
    }
    if (value === "7") {
        return "อื่นๆ";
    }
    return "";
};

export const getSocialPermissionName = value => {
    if (value === "0") {
        return "No";
    }
    if (value === "1") {
        return "Yes";
    }
    return "";
};
