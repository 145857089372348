import { Box, Button, FormControl, FormHelperText, FormLabel, Grid, MenuItem, TextField } from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Spinner } from "react-bootstrap";
import { searchCourseMenu } from "../../../apis/courseApi";
import { toImageUrl } from "../../../utils/format";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import DropzoneApp from "../../componentsUtils/DropzoneApp";
import SelectApp from "../../componentsUtils/SelectApp";
import TextareaApp from "../../componentsUtils/TextareaApp";
import { validateSchemaCourse } from "./helpers/courseOption";
import SelectSearchMultiApp from "../../componentsUtils/SelectSearchMultiApp";

export default function FormCourse({ onCancel, onSubmit, initialFormValues, controllerRef, option }) {
    // component state
    const [courseMenuList, setCourseMenuList] = useState([]);
    const [courseMenuDelete, setCourseMenuDelete] = useState([]);
    const [searchIndex, setSearchIndex] = useState(0);

    // onChange course menu name
    const handleChangeCourseMenuName = useCallback(
        async ({ evt, idx, setFieldValue }) => {
            try {
                const queryParams = {
                    courseMenuName: evt.target.value || "",
                };
                await setFieldValue(`listMenuName.${idx}.courseMenuName`, evt.target.value);
                if (queryParams.courseMenuName) {
                    const courseMenuList = await searchCourseMenu(queryParams, controllerRef.current?.signal);
                    if (courseMenuList) {
                        setSearchIndex(idx);
                        setCourseMenuList(courseMenuList);
                    }
                } else {
                    setSearchIndex(idx);
                    setCourseMenuList([]);
                }
            } catch (error) {
                console.dir(error);
            }
        },
        [controllerRef],
    );

    // select course menu
    const handleSelectCourseMenu = useCallback(async ({ courseMenu, idx, setFieldValue }) => {
        await setFieldValue(`listMenuName.${idx}.id`, courseMenu?.id);
        await setFieldValue(`listMenuName.${idx}.courseMenuName`, courseMenu?.courseMenuName);
        setCourseMenuList([]);
        setSearchIndex(0);
    }, []);

    // delete course menu
    const handleDeleteCourseMenu = useCallback(
        async ({ courseMenu, setFieldValue }) => {
            setCourseMenuDelete(currentState => [
                ...currentState,
                { id: courseMenu?.id, courseMenuName: courseMenu?.courseMenuName },
            ]);
            await setFieldValue("listMenuNameDelete", [
                ...courseMenuDelete,
                { id: courseMenu?.id, courseMenuName: courseMenu?.courseMenuName },
            ]);
        },
        [courseMenuDelete],
    );

    return (
        <Formik initialValues={initialFormValues} validationSchema={validateSchemaCourse} onSubmit={onSubmit}>
            {({ values, handleChange, errors, handleSubmit, setFieldValue, isSubmitting }) => (
                <Grid container spacing={2}>
                    {/* name */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-4">
                            <FormLabel>
                                Name <span className="text-danger">*</span>{" "}
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                id="name"
                                value={values.name}
                                onChange={handleChange}
                                error={Boolean(errors?.name)}
                                helperText={errors?.name}
                                placeholder="Course Name"
                            />
                        </FormControl>
                    </Grid>
                    {/* type */}
                    <Grid item xs={6} md={3}>
                        <FormControl fullWidth className="mb-4">
                            <FormLabel>
                                Type <span className="text-danger">*</span>{" "}
                            </FormLabel>
                            <SelectApp
                                size="medium"
                                value={values.type}
                                onChange={value => setFieldValue("type", value)}
                                options={[
                                    { title: "Free Schedule", value: "1" },
                                    { title: "Group", value: "2" },
                                ]}
                                placeholder="Select Type"
                                error={Boolean(errors?.type)}
                                helperText={errors?.type}
                                RenderItem={(option, idx) => (
                                    <MenuItem key={`type_${idx}`} value={option.value}>
                                        {" "}
                                        {option.title}{" "}
                                    </MenuItem>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    {/* limit */}
                    <Grid item xs={6} md={3}>
                        <FormControl fullWidth className="mb-4">
                            <FormLabel>
                                Limit students <span className="text-danger">*</span>{" "}
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                id="limitStd"
                                value={values.limitStd}
                                onChange={handleChange}
                                error={Boolean(errors.limitStd)}
                                helperText={errors.limitStd}
                                placeholder="Limit students"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            {/* price */}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel>
                                        Price <span className="text-danger">*</span>{" "}
                                    </FormLabel>
                                    <TextField
                                        variant="outlined"
                                        id="price"
                                        value={values.price}
                                        onChange={handleChange}
                                        error={Boolean(errors.price)}
                                        helperText={errors.price}
                                        placeholder="Enter price (THB)"
                                    />
                                </FormControl>
                            </Grid>
                            {/* image */}
                            <Grid item xs={12}>
                                <div>
                                    <FormLabel>Image</FormLabel>
                                    <DropzoneApp
                                        src={
                                            values?.image?.base64
                                                ? values?.image?.base64
                                                : values?.imagePath
                                                ? toImageUrl(values?.imagePath)
                                                : ""
                                        }
                                        onChange={file => {
                                            setFieldValue("image", file);
                                        }}
                                        onRemove={() => {
                                            setFieldValue("image", "");
                                            setFieldValue("imagePath", "");
                                        }}
                                        styles={{
                                            styleImage: { width: 160, height: 160 },
                                        }}
                                        noImage={toAbsoluteUrl("/media/assets/images/noImage/no-photo.png")}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* description */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-4">
                            <FormLabel>Description </FormLabel>
                            <TextareaApp
                                props={{
                                    textAreaProps: {
                                        id: "description",
                                        value: values.description,
                                        onChange: handleChange,
                                        placeholder: "Description",
                                        rows: 10,
                                    },
                                }}
                                errorMessage={errors.description}
                            />
                        </FormControl>
                    </Grid>
                    {/* duration */}
                    <Grid item container xs={12} md={3}>
                        <Grid item xs={8} className="d-flex justify-content-center align-items-center">
                            <SelectApp
                                options={[
                                    { label: "1", value: "1" },
                                    { label: "2", value: "2" },
                                    { label: "3", value: "3" },
                                    { label: "4", value: "4" },
                                    { label: "5", value: "5" },
                                    { label: "6", value: "6" },
                                    { label: "7", value: "7" },
                                    { label: "8", value: "8" },
                                    { label: "9", value: "9" },
                                    { label: "10", value: "10" },
                                    { label: "11", value: "11" },
                                    { label: "12", value: "12" },
                                ]}
                                size="medium"
                                value={values?.durationHr}
                                onChange={value => setFieldValue("durationHr", value)}
                                error={Boolean(errors?.durationHr)}
                                helperText={errors?.durationHr}
                                placeholder="Duration of Hours"
                                RenderItem={option => (
                                    <MenuItem key={`category_${option?.value}`} value={option?.value}>
                                        {option?.label}
                                    </MenuItem>
                                )}
                            />{" "}
                        </Grid>
                        <Grid item xs={4} className="d-flex justify-content-center align-items-center">
                            <Box>Hours</Box>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={3}>
                        <Grid item xs={8} className="d-flex justify-content-center align-items-center">
                            <SelectApp
                                options={[
                                    { label: "15", value: "15" },
                                    { label: "30", value: "30" },
                                    { label: "45", value: "45" },
                                ]}
                                size="medium"
                                value={values?.durationMin}
                                onChange={value => setFieldValue("durationMin", value)}
                                error={Boolean(errors?.durationMin)}
                                helperText={errors?.durationMin}
                                placeholder="Duration of Minutes"
                                RenderItem={option => (
                                    <MenuItem key={`category_${option?.value}`} value={option?.value}>
                                        {option?.label}
                                    </MenuItem>
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} className="d-flex justify-content-center align-items-center">
                            <Box>Minutes</Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {null}
                    </Grid>
                    {/* tag */}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth className="mb-4">
                            <FormLabel>Tag </FormLabel>
                            <SelectSearchMultiApp
                                size="md"
                                options={option?.tag}
                                getOptionLabel={opt => opt?.tagName || "-"}
                                getOptionValue={opt => opt?.tagId}
                                value={option?.tag?.filter(valR => values?.tag.includes(valR.tagId))}
                                onChange={value => {
                                    setFieldValue(
                                        "tag",
                                        value.map(rv => rv.tagId),
                                    );
                                }}
                                error={errors?.tag}
                                isDisabled={option?.tag?.length < 1}
                                placeholder="Select"
                            />
                        </FormControl>
                    </Grid>
                    {/* listMenuName */}
                    <Grid item xs={12}>
                        <Form>
                            <FieldArray name="listMenuName">
                                {({ remove, push }) => (
                                    <>
                                        <div className="t-table-custom table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Menu name</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {values.listMenuName.length > 0 &&
                                                        values.listMenuName.map((menuname, idx) => (
                                                            <tr key={`tr_${idx}`}>
                                                                <td className="align-middle">{idx + 1}</td>
                                                                <td className="align-middle py-4">
                                                                    <FormControl
                                                                        fullWidth
                                                                        className="position-relative">
                                                                        <Field
                                                                            type="text"
                                                                            name={`listMenuName.${idx}.courseMenuName`}
                                                                            component={TextField}
                                                                            variant="outlined"
                                                                            size="small"
                                                                            value={menuname?.courseMenuName || ""}
                                                                            onChange={evt =>
                                                                                handleChangeCourseMenuName({
                                                                                    evt,
                                                                                    idx,
                                                                                    setFieldValue,
                                                                                })
                                                                            }
                                                                            placeholder="Search menu"
                                                                        />
                                                                        <ErrorMessage
                                                                            name={`listMenuName.${idx}.courseMenuName`}
                                                                            component={FormHelperText}
                                                                            className="field-error text-danger position-absolute"
                                                                            style={{ bottom: -18 }}
                                                                        />
                                                                    </FormControl>
                                                                    {searchIndex === idx && courseMenuList?.length ? (
                                                                        <div className="search-course-menu-container">
                                                                            {courseMenuList?.map(courseMenu => (
                                                                                <div
                                                                                    key={courseMenu?.id}
                                                                                    className="search-course-menu-item"
                                                                                    onClick={() =>
                                                                                        handleSelectCourseMenu({
                                                                                            courseMenu,
                                                                                            idx,
                                                                                            setFieldValue,
                                                                                        })
                                                                                    }>
                                                                                    {courseMenu?.courseMenuName}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ) : null}
                                                                </td>
                                                                <td className="align-middle">
                                                                    <button
                                                                        type="button"
                                                                        onClick={async () => {
                                                                            await handleDeleteCourseMenu({
                                                                                courseMenu: menuname,
                                                                                setFieldValue,
                                                                            });
                                                                            remove(idx);
                                                                        }}
                                                                        className="btn btn-danger p-2 h-100">
                                                                        <Close />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                onClick={() => push("")}
                                                className="t-btn-primary p-2 rounded">
                                                <Add />
                                            </button>
                                        </div>
                                    </>
                                )}
                            </FieldArray>
                        </Form>
                    </Grid>
                    {/* action */}
                    <Grid item xs={12}>
                        <div className="d-flex justify-content-md-end align-items-center">
                            <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                                <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                            </Button>
                            <Button disabled={isSubmitting} onClick={handleSubmit} className="btn t-btn-secondary">
                                {isSubmitting ? (
                                    <Spinner animation="border" variant="primary" />
                                ) : (
                                    <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                                )}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
}
