import React from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { Formik } from "formik";
import { FormattedMessage } from "react-intl";
import { number, object, string } from "yup";
import { replaceMoneyInterger } from "../../../utils/replaceString";
import TextareaApp from "../../componentsUtils/TextareaApp";

export default function FormRefund({ onSubmit = () => {}, onCancel = () => {}, initialFormValues = {} }) {
    return (
        <Formik
            enableReinitialize
            initialValues={initialFormValues}
            validationSchema={object({
                refundValue: number()
                    .max(
                        initialFormValues.refundValue,
                        `Must be less than or equal to ${initialFormValues.refundValue}`,
                    )
                    .required("Required!"),
                refundNote: string().required("Required!"),
            })}
            onSubmit={onSubmit}>
            {({ values, handleSubmit, handleChange, errors, setFieldValue }) => (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center" className="mb-3">
                            <Grid item xs={12} md={4}>
                                <p className="mb-0">Add refund value</p>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    value={values?.refundValue}
                                    name="refundValue"
                                    onChange={async evt => {
                                        const refundValue = replaceMoneyInterger(evt?.target?.value);
                                        await setFieldValue("refundValue", refundValue);
                                    }}
                                    error={Boolean(errors?.refundValue)}
                                    helperText={errors?.refundValue}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center" className="mb-3">
                            <Grid item xs={12} md={4}>
                                <p className="mb-0">Add refund note</p>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <TextareaApp
                                    props={{
                                        textAreaProps: {
                                            id: "refundNote",
                                            value: values?.refundNote,
                                            rows: 5,
                                            onChange: handleChange,
                                        },
                                    }}
                                    errorMessage={errors?.refundNote}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* action */}
                    <Grid item xs={12}>
                        <div className="d-flex justify-content-end align-items-center">
                            <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                                <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                            </Button>
                            <Button onClick={handleSubmit} className="btn t-btn-secondary">
                                <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
}
