import { Box, Button, FormControl, FormLabel, Grid, MenuItem, TextField } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { toImageUrl } from "../../../utils/format";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import DropzoneApp from "../../componentsUtils/DropzoneApp";
import SelectApp from "../../componentsUtils/SelectApp";
import TextareaApp from "../../componentsUtils/TextareaApp";
import { validateSchemaFormMenu } from "./helpers/menuOption";
import SelectSearchMultiApp from "../../componentsUtils/SelectSearchMultiApp";

export default function FormMenu({ onCancel, onSubmit, option, initialFormValues }) {
    return (
        <Formik initialValues={initialFormValues} validationSchema={validateSchemaFormMenu} onSubmit={onSubmit}>
            {({ values, handleChange, handleSubmit, setFieldValue, errors }) => (
                <div className="px-3">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth className="mb-4">
                                <FormLabel>
                                    Name <span className="text-danger">*</span>{" "}
                                </FormLabel>
                                <TextField
                                    variant="outlined"
                                    id="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    error={Boolean(errors.name)}
                                    helperText={errors.name}
                                    placeholder="Menu Name"
                                />
                            </FormControl>
                        </Grid>
                        {/* price */}
                        <Grid item xs={12}>
                            <FormControl fullWidth className="mb-4">
                                <FormLabel>
                                    Price <span className="text-danger">*</span>{" "}
                                </FormLabel>
                                <TextField
                                    variant="outlined"
                                    id="price"
                                    value={values.price}
                                    onChange={handleChange}
                                    error={Boolean(errors.price)}
                                    helperText={errors.price}
                                    placeholder="Enter price (THB)"
                                />
                            </FormControl>
                        </Grid>
                        {/* skill level */}
                        <Grid item xs={12}>
                            <FormControl fullWidth className="mb-4">
                                <FormLabel>
                                    Skill Level <span className="text-danger">*</span>{" "}
                                </FormLabel>
                                <SelectApp
                                    options={option.category}
                                    size="medium"
                                    value={values?.category}
                                    onChange={value => setFieldValue("category", value)}
                                    error={Boolean(errors?.category)}
                                    helperText={errors?.category}
                                    placeholder="Please selete skill level"
                                    RenderItem={option => (
                                        <MenuItem key={`category_${option?.id}`} value={option?.id}>
                                            {option?.categoryName}
                                        </MenuItem>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        {/* duration */}
                        <Grid item container xs={6}>
                            <Grid item xs={8} className="d-flex justify-content-center align-items-center">
                                <SelectApp
                                    options={[
                                        { label: "1", value: "1" },
                                        { label: "2", value: "2" },
                                        { label: "3", value: "3" },
                                        { label: "4", value: "4" },
                                        { label: "5", value: "5" },
                                        { label: "6", value: "6" },
                                        { label: "7", value: "7" },
                                        { label: "8", value: "8" },
                                        { label: "9", value: "9" },
                                        { label: "10", value: "10" },
                                        { label: "11", value: "11" },
                                        { label: "12", value: "12" },
                                    ]}
                                    size="medium"
                                    value={values?.durationHr}
                                    onChange={value => setFieldValue("durationHr", value)}
                                    error={Boolean(errors?.durationHr)}
                                    helperText={errors?.durationHr}
                                    placeholder="Duration of Hours"
                                    RenderItem={option => (
                                        <MenuItem key={`category_${option?.value}`} value={option?.value}>
                                            {option?.label}
                                        </MenuItem>
                                    )}
                                />{" "}
                            </Grid>
                            <Grid item xs={4} className="d-flex justify-content-center align-items-center">
                                <Box>Hours</Box>
                            </Grid>
                        </Grid>
                        <Grid item container xs={6}>
                            <Grid item xs={8} className="d-flex justify-content-center align-items-center">
                                <SelectApp
                                    options={[
                                        { label: "15", value: "15" },
                                        { label: "30", value: "30" },
                                        { label: "45", value: "45" },
                                    ]}
                                    size="medium"
                                    value={values?.durationMin}
                                    onChange={value => setFieldValue("durationMin", value)}
                                    error={Boolean(errors?.durationMin)}
                                    helperText={errors?.durationMin}
                                    placeholder="Duration of Minutes"
                                    RenderItem={option => (
                                        <MenuItem key={`category_${option?.value}`} value={option?.value}>
                                            {option?.label}
                                        </MenuItem>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4} className="d-flex justify-content-center align-items-center">
                                <Box>Minute</Box>
                            </Grid>
                        </Grid>
                        {/* tag */}
                        <Grid item xs={12}>
                            <FormControl fullWidth className="mb-3">
                                <FormLabel>Tag</FormLabel>
                                <SelectSearchMultiApp
                                    size="md"
                                    options={option?.tag}
                                    getOptionLabel={opt => opt?.tagName || "-"}
                                    getOptionValue={opt => opt?.tagId}
                                    value={option?.tag?.filter(valR => values?.tag.includes(valR.tagId))}
                                    onChange={value => {
                                        setFieldValue(
                                            "tag",
                                            value.map(rv => rv.tagId),
                                        );
                                    }}
                                    error={errors?.tag}
                                    isDisabled={option?.tag?.length < 1}
                                    placeholder="Select"
                                />
                            </FormControl>
                        </Grid>
                        {/* description */}
                        <Grid item xs={12}>
                            <FormControl fullWidth className="mb-4">
                                <FormLabel>Description </FormLabel>
                                <TextareaApp
                                    props={{
                                        textAreaProps: {
                                            id: "description",
                                            value: values.description,
                                            onChange: handleChange,
                                            placeholder: "Description",
                                            rows: 4,
                                        },
                                    }}
                                    errorMessage={errors.description}
                                />
                            </FormControl>
                        </Grid>
                        {/* image */}
                        <Grid item xs={12}>
                            <div>
                                <FormLabel>Image</FormLabel>
                                <DropzoneApp
                                    src={
                                        values?.image?.base64
                                            ? values?.image?.base64
                                            : values?.imagePath
                                            ? toImageUrl(values?.imagePath)
                                            : toAbsoluteUrl("/media/assets/images/noImage/no-photo.png")
                                    }
                                    onChange={file => {
                                        setFieldValue("image", file);
                                    }}
                                    onRemove={() => {
                                        setFieldValue("image", "");
                                        setFieldValue("imagePath", "");
                                    }}
                                    styles={{
                                        styleImage: { width: 160, height: 160 },
                                    }}
                                    uploadBotton
                                    noImage={toAbsoluteUrl("/media/assets/images/noImage/no-photo.png")}
                                    className={{
                                        classPreview: "d-flex justify-content-center",
                                    }}
                                />
                            </div>
                        </Grid>
                        {/* action */}
                        <Grid item xs={12}>
                            <div className="d-flex justify-content-md-end align-items-center">
                                <Button onClick={onCancel} className="btn t-btn-gray mr-2">
                                    <FormattedMessage id="BTN.ACTOIN.CANCEL" />
                                </Button>
                                <Button onClick={handleSubmit} className="btn t-btn-secondary">
                                    <FormattedMessage id="BTN.ACTOIN.SUBMIT" />
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            )}
        </Formik>
    );
}
