import { Button, Grid, IconButton, Paper, Tab, Tabs } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getTeacherById, updateTeacherById } from "../../../apis/teacherApi";
import { uploadImageFile } from "../../../apis/uploadApi";
import { useForceRender } from "../../../utils/customHook";
import { toImageUrl } from "../../../utils/format";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import FormCreate from "../TeacherManagement/FormCreate";
import TeacherClassHistory from "./TeacherClassHistory";
import TeacherDayOff from "./TeacherDayOff";
import TeachersMenu from "./TeachersMenu";

export default function TeacherProfileContent(props) {
    const { id } = props;
    const [render, forceRender] = useForceRender();

    // ref
    const controllerRef = useRef(null);

    // global redux state
    const { thisMenuPermission } = useSelector(state => {
        const { menuPermission } = state?.mainMenu;
        return {
            thisMenuPermission: menuPermission?.find(menu => window.location.pathname.includes(menu?.path))?.permission,
        };
    });

    // component state
    const [modalForm, setModalForm] = useState(false);
    const [valueTabs, setValueTabs] = useState("class_history");
    const [initialValuesTeacher, setInitialValuesTeacher] = useState({
        fname: "",
        lname: "",
        birthday: new Date(),
        nickname: "",
        email: "",
        tel: "",
        lineId: "",
        status: "",
        id: "",
        teacherImagePath: "",
        courseMenus: [],
    });
    const [teacherProfile, setTeacherProfile] = useState({});

    // open modal
    const handleOpenModal = useCallback(async () => {
        setModalForm(true);
        setInitialValuesTeacher(currentState => ({
            ...currentState,
            fname: teacherProfile?.teacherFirstname || "",
            lname: teacherProfile?.teacherLastname || "",
            birthday: teacherProfile?.teacherBirthday || new Date(),
            nickname: teacherProfile?.teacherNickname || "",
            email: teacherProfile?.teacherEmail || "",
            tel: teacherProfile?.teacherTel || "",
            lineId: teacherProfile?.teacherLineid || "",
            status: teacherProfile?.status || "",
            id: teacherProfile?.id || "",
            teacherImagePath: teacherProfile?.teacherImagePath || "",
            courseMenus: teacherProfile?.CourseMenus?.map(item => item?.id) || [],
        }));
    }, [teacherProfile]);

    // close modal
    const handleCloseModal = useCallback(() => {
        setModalForm(false);
    }, []);

    // for function change tab
    const handleChangeTab = useCallback((_, value) => {
        setValueTabs(value);
    }, []);

    // fetch data
    const fetchData = useCallback(async id => {
        try {
            const res = await getTeacherById(id, controllerRef.current?.signal);
            if (res) {
                setTeacherProfile(res);
            }
        } catch (error) {
            console.dir(error);
        }
    }, []);

    // edit Teacher logic
    const handleEditTeacher = useCallback(
        async values => {
            try {
                const updateBody = {
                    teacherFirstname: values?.fname,
                    teacherLastname: values?.lname,
                    teacherBirthday: values?.birthday,
                    teacherNickname: values?.nickname,
                    teacherEmail: values?.email,
                    teacherTel: values?.tel,
                    teacherLineid: values?.lineId,
                    courseMenus: values?.courseMenus?.map(item => ({
                        id: item?.CourseMenuMapping?.id,
                        courseMenuId: item,
                    })),
                };

                // upload image
                if (values?.image) {
                    if (values?.image?.file) {
                        updateBody.teacherImagePath = await uploadImageFile(
                            values?.image?.file,
                            controllerRef.current?.signal,
                        );
                    }
                } else {
                    updateBody.teacherImagePath = values?.image;
                }

                const isUpdateTeacherProfile = await updateTeacherById(
                    values?.id,
                    updateBody,
                    controllerRef.current?.signal,
                );
                if (isUpdateTeacherProfile) {
                    forceRender();
                    setModalForm(false);
                }
            } catch (error) {
                console.dir(error);
            }
        },
        [forceRender],
    );

    // run only first render
    useEffect(() => {
        controllerRef.current = new AbortController();
        fetchData(id);
        // to avoid memory leaked.
        return () => {
            controllerRef.current.abort();
        };
    }, [id, render, fetchData]);

    // useEffect(() => {
    //     fetchData(id);
    // }, [id, render, fetchData]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
                <Paper className="p-3 pb-10">
                    <div className="d-flex justify-content-center my-3">
                        <img
                            className="img-profile-student"
                            src={
                                teacherProfile?.teacherImagePath
                                    ? toImageUrl(teacherProfile?.teacherImagePath)
                                    : toAbsoluteUrl("/media/users/blank.png")
                            }
                            alt="profile"
                        />
                    </div>
                    <div className="mb-3">
                        <div className="mb-2">Name</div>
                        <div className="h6 text-muted">
                            {teacherProfile?.teacherFirstname} {teacherProfile?.teacherLastname}
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="mb-2">Email</div>
                        <div className="h6 text-muted">{teacherProfile?.teacherEmail}</div>
                    </div>
                    <div className="mb-3">
                        <div className="mb-2">Tel</div>
                        <div className="h6 text-muted">{teacherProfile?.teacherTel}</div>
                    </div>
                    <div className="mb-4">
                        <div className="mb-2">Line ID</div>
                        <div className="h6 text-muted">{teacherProfile?.teacherLineid}</div>
                    </div>
                    {thisMenuPermission?.edit && (
                        <div className="d-flex justify-content-center mb-3">
                            <Button onClick={handleOpenModal} className="col-12 col-lg-4 btn t-btn-primary">
                                Edit Profile
                            </Button>
                        </div>
                    )}
                    <hr />
                </Paper>
            </Grid>
            <Grid item xs={12} lg={8}>
                <Paper className="p-3">
                    <div className="mb-3">
                        <Tabs value={valueTabs} onChange={handleChangeTab} indicatorColor="primary" textColor="primary">
                            <Tab label="class History" value="class_history" />
                            <Tab label="Teacher's Menu" value="teacher_menu" />
                            <Tab label="Day off" value="day_off" />
                        </Tabs>
                    </div>
                    <div>
                        {valueTabs === "class_history" && (
                            <TeacherClassHistory teacherProfile={teacherProfile} teacherId={id} />
                        )}
                        {valueTabs === "teacher_menu" && <TeachersMenu teacherId={id} />}
                        {valueTabs === "day_off" && <TeacherDayOff teacherId={id} />}
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                {/* modal */}
                <Modal show={modalForm} centered scrollable onHide={handleCloseModal}>
                    <Modal.Header>
                        <Modal.Title>Edit Teacher</Modal.Title>
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body>
                        <FormCreate
                            onCancel={handleCloseModal}
                            onSubmit={handleEditTeacher}
                            initialValuesTeacher={initialValuesTeacher}
                        />
                    </Modal.Body>
                </Modal>
            </Grid>
        </Grid>
    );
}
