import { Grid, Paper } from "@material-ui/core";
import React from "react";
import MonthlySumContent from "../../components/report_monthly/MonthlySumContent";

export default function MonthlySummary() {
    return (
        <Paper className="p-2 p-lg-3">
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <MonthlySumContent />
                </Grid>
            </Grid>
        </Paper>
    );
}
